import Image from "next/image";

function ChatButton() {
  return (
    <div className="fixed bottom-[0.625rem] right-[0.625rem] z-50">
      <div className="flex h-[3.5rem] w-[3.5rem] animate-scaleUpDown cursor-pointer items-center justify-center rounded-full border-[0.5px] border-primary/30 bg-white shadow-2xl hover:animate-none lg:h-[4.063rem] lg:w-[4.063rem]">
        <button className="relative h-[2.813rem]  w-[2.813rem] lg:h-[3.125rem] lg:w-[3.125rem]">
          <Image
            src="/assets/logo/massanger-icon.png"
            fill
            alt="messenger-icon"
          />
        </button>
      </div>
    </div>
  );
}

export default ChatButton;
