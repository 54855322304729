import { PropTypes } from "prop-types";

import { HomeIcon, OfficeIcon, OthersIcon } from "../../icon";

function AddressLabel({ label, className }) {
  return (
    <>
      {label === "home" ? (
        <div
          className={`flex max-w-[5.5rem] items-center justify-center bg-tertiary-400 ${className}`}
        >
          <HomeIcon className="h-[0.813rem] w-[0.75rem] text-white" />
          <span className="ml-[0.613rem] text-[0.875rem] font-semibold leading-[1.063rem] text-white">
            Home
          </span>
        </div>
      ) : label === "office" ? (
        <div
          className={`flex max-w-[5.5rem] items-center justify-center bg-tertiary-400 ${className}`}
        >
          <OfficeIcon className="h-[0.813rem] w-[0.813rem] text-white" />
          <span className="ml-[0.563rem] text-[0.875rem] font-semibold leading-[1.063rem] text-white">
            Office
          </span>
        </div>
      ) : (
        <div
          className={`flex max-w-[5.5rem] items-center justify-center bg-tertiary-400 ${className}`}
        >
          <OthersIcon className="h-[0.875rem] w-[0.75rem] text-white" />
          <span className="ml-[0.458rem] text-[0.875rem] font-semibold leading-[1.063rem] text-white">
            Others
          </span>
        </div>
      )}
    </>
  );
}

export default AddressLabel;

AddressLabel.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
};

AddressLabel.defaultProps = {
  label: "home",
  className: "h-[2.125rem] rounded-lg w-full",
};
