import { useMutation } from "@tanstack/react-query";

import { applyCouponApi, removeCouponApi } from "@/api/end-points/coupon";

export const useApplyCoupon = (useQueryOptions) => {
  const { onSuccess = () => {}, onError = () => {} } = useQueryOptions || {};

  return useMutation(applyCouponApi, {
    onSuccess: (res) => {
      onSuccess({
        status: res.status,
        message: res.data.message || "Coupon applied",
      });
    },
    onError: (error) => {
      onError({
        status: error.response.status,
        data: error.response.data,
        message: error.message,
      });
    },
  });
};

export const useRemoveCoupon = (useQueryOptions) => {
  const { onSuccess = () => {}, onError = () => {} } = useQueryOptions || {};

  return useMutation(removeCouponApi, {
    onSuccess: (res) => {
      onSuccess({
        status: res.status,
        message: res.data.message || "Coupon removed",
      });
    },
    onError: (error) => {
      onError({
        status: error.response.status,
        data: error.response.data,
        message: error.message,
      });
    },
  });
};
