import OtpInput from "react18-input-otp";

import PropTypes from "prop-types";

import Styles from "./appOtp.module.css";

function AppOtpInput({ numbersOfInput, otpValue, onOtpChange, onOtpSubmit }) {
  return (
    <OtpInput
      isInputNum
      numInputs={numbersOfInput}
      separator={" "}
      containerStyle={`${Styles.opt_container}`}
      inputStyle={`${Styles.singleInput}`}
      placeholder={"------"}
      value={otpValue}
      onChange={onOtpChange}
      shouldAutoFocus
      onSubmit={onOtpSubmit}
    />
  );
}

AppOtpInput.propTypes = {
  numbersOfInput: PropTypes.number,
  otpValue: PropTypes.string,
  onOtpChange: PropTypes.func,
  onOtpSubmit: PropTypes.func,
};

AppOtpInput.defaultProps = {
  numbersOfInput: 6,
};

export default AppOtpInput;
