import Link from "next/link";

import GetDirectionButton from "@/components/actions/button/GetDirectionBtn";
import { EmailIcon, MapIcon, MobileIcon } from "@/components/data-display/icon";
import { RobodocInfo } from "@/utils/constants/robodoc-info.constants";

function GetInTouch({ className }) {
  return (
    <div
      className={`${className} flex flex-col gap-[1.75rem] overflow-hidden text-[0.938rem] leading-[1.5rem] text-grayDisplay-400`}
    >
      <div className="flex items-start gap-[0.875rem]">
        <MapIcon className="mt-[0.4rem] h-[1rem] w-[0.813rem]" />
        <div className="w-[90%] space-y-2 sm:w-[60%] lg:w-full">
          <p>
            <span className="font-bold">Corporate Office: </span>
            {RobodocInfo.officeAddress}
          </p>
          <p>
            <span className="font-bold">Pickup Point: </span>
            {RobodocInfo.pickupPoint}
          </p>
          <div>
            <GetDirectionButton />
          </div>
        </div>
      </div>

      <div className="flex items-start gap-[0.875rem]">
        <MobileIcon className="mt-[0.2rem] h-[0.969rem] w-[0.969rem]" />
        <Link href={"tel:" + RobodocInfo.phone1}>{RobodocInfo.phone1}</Link>
      </div>

      <div className="flex items-start gap-[0.875rem]">
        <div>
          <EmailIcon className="mt-[0.5rem] h-[0.774rem] w-[0.969rem]" />
        </div>
        <div className="flex flex-col">
          <Link href={"mailto:" + RobodocInfo.email1}>
            {RobodocInfo.email1}
          </Link>
          <Link href={"mailto:" + RobodocInfo.email2}>
            {RobodocInfo.email2}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default GetInTouch;
