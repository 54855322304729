import { toast } from "react-toastify";

import ErrorIcon from "@/icons/error-toast.svg";
import SuccessIcon from "@/icons/success-toast.svg";

import ToastUI from "./ToastUI";
import Styles from "./toast.module.css";

const toastOptions = {
  position: "top-right",
  autoClose: 1500,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  closeButton: false,
  icon: false,
  className: Styles.toast_wrapper,
  limit: 1,
};

export const successToast = ({ message, ...rest }) =>
  toast.success(<ToastUI message={message} Icon={SuccessIcon} />, {
    ...toastOptions,
    ...rest,
  });

export const errorToast = ({ message, ...rest }) => {
  toast.error(<ToastUI message={message} Icon={ErrorIcon} />, {
    ...toastOptions,
    ...rest,
  });
};
