import { useQuery } from "@tanstack/react-query";

import {
  getCategoryDataApi,
  getCategoryMenuApi,
} from "@/api/end-points/category";

export const useGetCategoryMenu = (useQueryOptions) => {
  const { query } = useQueryOptions || {};
  const queries = {};
  if (query?.slug) queries.category_slug = query.slug;

  return useQuery({
    queryKey: ["category-menu"],
    queryFn: () => getCategoryMenuApi(queries),
    ...useQueryOptions,
    select: (res) => {
      return res.data?.data;
    },
    onError: (error) => {
      return error.response.data;
    },
  });
};

export const useGetCategoryData = (useQueryOptions) => {
  const { query } = useQueryOptions || {};

  const { slug, page = 1, sort_by } = query;

  const queries = { slug, page };
  if (sort_by) queries["sort_by"] = sort_by;

  return useQuery({
    queryKey: ["category", slug, page, sort_by],
    queryFn: () => getCategoryDataApi(queries),
    keepPreviousData: true,
    ...useQueryOptions,
    select: (res) => {
      return res?.data?.data;
    },
    onError: (error) => {
      return error?.response?.data;
    },
  });
};
