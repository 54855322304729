import Image from "next/image";
import Link from "next/link";

import PropTypes from "prop-types";

import { InlineLoaderIcon, RemoveIcon } from "@/components/data-display/icon";

import CartItemQty from "./CartItemQty";

function CartTableMobile({
  onDelete,
  removeModalId,
  targetProduct,
  cartProductsData,
  handleUpdateQuantity,
}) {
  return (
    <div className="flex flex-col gap-[2.5rem]">
      {cartProductsData?.map((productData) => (
        <div className="relative" key={productData.slug}>
          {targetProduct === productData.slug && (
            <div className="absolute inset-0 z-50 flex items-center justify-center bg-white/80">
              <InlineLoaderIcon className="h-[3rem] w-[3rem]" />
            </div>
          )}
          <section className="flex items-start justify-between">
            <Link href={`/product/${productData.slug}`}>
              <div className="flex gap-[1rem]">
                <div className="shrink-0">
                  <Image
                    width={62}
                    height={62}
                    alt={productData.name}
                    src={productData.image}
                  />
                </div>

                <p className="line-clamp-3 max-w-[70%] text-[1rem]">
                  {productData.name}
                </p>
              </div>
            </Link>

            <label
              className="mt-[0.438rem]"
              htmlFor={removeModalId ?? ""}
              onClick={() => onDelete(productData.slug)}
            >
              <RemoveIcon className="hover:cursor-pointer hover:text-error-color" />
            </label>
          </section>

          <p className="mt-2 flex items-center justify-between">
            <div className="flex w-2/6 flex-wrap items-center space-x-[0.5rem]">
              <p className="text-[1.125rem] font-semibold text-grayDisplay-900">
                {productData.has_discount_price
                  ? `৳${productData.discount_price}`
                  : `৳${productData.price}`}
              </p>
              {productData.has_discount_price && (
                <p className="text-grayDisplay-850 line-through">
                  ৳{productData.price}
                </p>
              )}
            </div>

            <CartItemQty
              productData={productData}
              handleUpdateQuantity={handleUpdateQuantity}
            />

            <div className="w-2/6">
              <p className="text-right text-[1.125rem] font-semibold text-grayDisplay-900">
                ৳{productData.subtotal}
              </p>
            </div>
          </p>
        </div>
      ))}
    </div>
  );
}

CartTableMobile.defaultProps = {
  onDelete: () => {},
  handleUpdateQuantity: () => {},
};

CartTableMobile.propTypes = {
  cartProductsData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      quantity: PropTypes.number.isRequired,
      price: PropTypes.number.isRequired,
      has_discount_price: PropTypes.bool,
      discount_price: PropTypes.number,
      subtotal: PropTypes.number,
      current_stock: PropTypes.number.isRequired,
    }).isRequired
  ),
  handleUpdateQuantity: PropTypes.func,
  onDelete: PropTypes.func,
  removeModalId: PropTypes.string,
  targetProduct: PropTypes.string,
};

export default CartTableMobile;
