import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useSession } from "next-auth/react";
import { useRouter } from "next/router";

import { useGetMainMenu } from "@/api/client/menu";
import { useGetNotificationsData } from "@/api/client/notifications";
import { useGetWishListData } from "@/api/client/wishlist";
import { updateCartItemCount } from "@/store/feature/cartSlice";
import { getLoaderState } from "@/store/feature/loaderSlice";
import {
  replaceWishlistItems,
  updateWishlistItemCount,
} from "@/store/feature/wishlistSlice";
import menuData from "@/utils/constants/mainMenu.json";
import { messageProvider } from "@/utils/constants/socialMedia.constant";
import { isCartSidebarVisible } from "@/utils/helpers/path.helpers";

import { TrackOrderModal } from "../actions";
import ChatButton from "../actions/button/ChatBtn";
import Loader from "../data-display/loaderSvg";
import CartSidebarWrapper from "../data-display/wrapper/cartSidebar";
import NavBar from "../sections/navbar";
import TopNavbar from "../sections/navbar/TopNavbar";
import FooterBottom from "./footer/bottom";
import FooterMiddle from "./footer/middle";
import FooterTop from "./footer/top";
import MenuBar from "./menuBar/MenuBar";

function Layout({ children }) {
  const { pathname } = useRouter();
  const [menuOptions, setMenuOptions] = useState(menuData);

  const { isLoading } = useSelector(getLoaderState);

  const { data: session } = useSession();

  const isAuthenticated = session?.user?.token;

  const dispatch = useDispatch();

  useGetMainMenu({
    onSuccess: (menuData) => {
      setMenuOptions(menuData);
    },
  });

  useGetNotificationsData({
    onSuccess: (data) => {
      dispatch(updateCartItemCount(data.cartProductCount));
      dispatch(updateWishlistItemCount(data.wishlistProductCount));
    },
  });

  useGetWishListData({
    enabled: !!isAuthenticated,

    onSuccess: (data) => {
      const { wishlist } = data;
      if (wishlist && wishlist.length > 0) {
        const wishlistMap = {};
        wishlist.forEach(({ slug }) => {
          wishlistMap[slug] = true;
        });
        dispatch(replaceWishlistItems(wishlistMap));
      }
    },
  });

  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(replaceWishlistItems({}));
    }
  }, [isAuthenticated]);

  return (
    <>
      <header className="sticky z-50 bg-white shadow-[0_6px_28px_rgba(27,25,73,0.06)] md:-top-[45px] lg:-top-[140px]">
        <TopNavbar />
        <NavBar menuData={menuOptions} />
        <MenuBar menus={menuOptions} />
      </header>

      <main className="min-h-[65vh] lg:min-h-[80vh]">
        {isLoading ? <Loader /> : children}
      </main>

      <footer>
        <FooterTop />
        <div className="border-t" />
        <FooterMiddle />
        <FooterBottom />
      </footer>

      <TrackOrderModal />
      {isCartSidebarVisible(pathname) && <CartSidebarWrapper />}

      <a href={messageProvider.messenger} target="_blank" rel="noreferrer">
        <ChatButton />
      </a>
    </>
  );
}

export default Layout;
