import { useDispatch, useSelector } from "react-redux";

import { getOrderState, updateTrackOrder } from "@/store/feature";

import { CenteredModal } from "../CenteredModal";
import { TrackOrderForm } from "./TrackOrderForm";

export const TrackOrderModal = () => {
  const { isTrackOrder } = useSelector(getOrderState);

  const dispatch = useDispatch();

  const handleHideModal = () => {
    dispatch(updateTrackOrder(false));
  };

  return (
    <CenteredModal isOpen={isTrackOrder} onOpenChange={handleHideModal}>
      <CenteredModal.Content title="Track Order">
        <TrackOrderForm isModal />
      </CenteredModal.Content>
    </CenteredModal>
  );
};
