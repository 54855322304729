import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useDispatch } from "react-redux";

import { useRouter } from "next/router";

import {
  useAddToCart,
  useDeleteCartData,
  useGetCartData,
} from "@/api/client/cart";
import { useGetNotificationsData } from "@/api/client/notifications";
import PrimaryBtn from "@/components/actions/button/primaryBtn";
import TertiaryBtn from "@/components/actions/button/tertiaryBtn";
import {
  CartSidebarIcon,
  CloseIcon,
  InlineLoaderIcon,
} from "@/components/data-display/icon";
import CartTableMobile from "@/components/data-display/table/cartTable/CartTableMobile";
import EmptyCart from "@/components/feedback/emptyCart";
import { errorToast, successToast } from "@/components/feedback/toast";
import { updateCartItemCount } from "@/store/feature/cartSlice";
import { hideMainScrollbar, showMainScrollbar } from "@/utils/helpers";

import Styles from "./CartSidebar.module.css";

function CartSidebar({ cartProductCount }, ref) {
  const [visibilityClass, setVisibilityClass] = useState("hidden");
  const [animationClass, setAnimationClass] = useState("translate-x-[100%]");
  const [targetedProduct, setTargetedProduct] = useState(null);

  const router = useRouter();
  const dispatch = useDispatch();

  useEffect(() => {
    hideMainScrollbar({ isActive: visibilityClass === "block" });

    return () => {
      showMainScrollbar();
    };
  }, [visibilityClass]);

  useEffect(() => {
    makeCartSidebarHidden();
  }, [router]);

  const { refetch: refetchNotificaitonData } = useGetNotificationsData({
    enabled: false,
  });

  useImperativeHandle(ref, () => ({
    makeCartSidebarVisible: () => {
      setVisibilityClass("block");
      setAnimationClass("translate-x-[0]");
    },
  }));

  const makeCartSidebarHidden = () => {
    setVisibilityClass("hidden");
    setAnimationClass("translate-x-[100%]");
  };

  const {
    data: cartData,
    refetch: refetchCart,
    isInitialLoading: isCartLoading,
  } = useGetCartData({
    enabled: cartProductCount > 0 && visibilityClass === "block",
  });

  const { mutate: addToCart } = useAddToCart({
    onSuccess: ({ cartData }) => {
      refetchCart();
      setTargetedProduct(null);
      dispatch(updateCartItemCount(cartData?.cart?.products?.length));
    },
    onError: ({ message }) => {
      errorToast({ message });
      setTargetedProduct(null);
    },
  });

  const { mutate: deleteCartData, isLoading: isDeletingCartData } =
    useDeleteCartData({
      onSuccess: async (res) => {
        successToast({ message: res.message });
        const { isSuccess: isRefetched } = await refetchCart();
        if (isRefetched) setTargetedProduct(null);
        refetchNotificaitonData();
      },
      onError: (error) => {
        setTargetedProduct(null);
        errorToast({ message: error.message });
      },
    });

  const handleQuantity = (slug, qty) => {
    const selectedProduct = cartData?.products?.find((el) => el.slug === slug);

    if (!selectedProduct) return;

    setTargetedProduct(slug);
    addToCart({
      product: slug,
      quantity: qty,
      from_cart: true,
    });
  };

  const handleDeleteCartData = (product) => {
    if (isDeletingCartData) return;

    setTargetedProduct(product);
    deleteCartData({ product });
  };

  return (
    <>
      <div
        onClick={makeCartSidebarHidden}
        className={`fixed inset-0 z-[100] bg-[#1B2D49]/40 ${visibilityClass}`}
      />
      <div
        className={`opacity-1 fixed inset-y-0 right-0 z-[101] ml-auto h-full w-full bg-white shadow-inner sm:w-[25.625rem]
        ${Styles.innerShadow} ${animationClass} ${Styles.sidebarTransition}`}
      >
        <div className="flex h-[10%] items-center justify-between border-grayDisplay-100 px-[1.25rem] sm:border-b">
          <div className="flex items-center gap-[1rem]">
            <CartSidebarIcon className="h-[1.375rem] w-[1.375rem] sm:h-[1.563rem] sm:w-[1.563rem]" />
            <p className="text-[1.125rem] font-semibold leading-[1.5rem] text-grayDisplay-400">
              My Shopping Cart
            </p>
          </div>

          <button onClick={makeCartSidebarHidden}>
            <CloseIcon className="h-[0.875rem] w-[0.875rem]" />
          </button>
        </div>

        {isCartLoading && (
          <div className="flex h-[90%] items-center justify-center">
            <InlineLoaderIcon className="absolute left-[calc(50%_-_3.5rem)] top-[calc(50%_-_3.5rem)] inline-block h-[7rem] w-[7rem]" />
          </div>
        )}

        {!isCartLoading && cartProductCount > 0 ? (
          <div className="relative flex h-[90%] flex-col justify-between">
            <div
              className={`overflow-y-auto px-[1.25rem] py-[1.75rem] ${Styles.customScrollBar}`}
            >
              <CartTableMobile
                targetProduct={targetedProduct}
                onDelete={handleDeleteCartData}
                cartProductsData={cartData?.products}
                handleUpdateQuantity={handleQuantity}
              />
            </div>
            <div className="flex h-[9.625rem] flex-col gap-[1.5rem] pb-[1.5rem]">
              <div className="flex justify-between border-t border-grayDisplay-100 px-[1.25rem] pt-[1rem]">
                <p className="text-[0.938rem] leading-[1.625rem] text-grayDisplay-700">
                  Sub Total
                </p>

                <p className="flex text-[1.063rem] font-semibold leading-[1.625rem] text-grayDisplay-900">
                  <span>&#2547;</span>
                  {cartData?.total_product_price_with_discount}
                </p>
              </div>

              <div className="flex justify-between px-[1.25rem]">
                <TertiaryBtn
                  btnText="View Cart"
                  className="h-[3rem] w-[48%]"
                  onClick={() => router.push("/my-cart")}
                />

                <PrimaryBtn
                  btnText="Checkout"
                  className="h-[3rem] w-[48%]"
                  onClick={() => router.push("/checkout")}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="flex h-[90%] items-center justify-center">
            <EmptyCart />
          </div>
        )}
      </div>
    </>
  );
}

export default forwardRef(CartSidebar);
