import Image from "next/image";

import PropTypes from "prop-types";

export const SingleBanner = (props) => {
  const { rounded, imageURL, className, imageAltText, imageOptions } = props;

  return (
    <div
      className={`${
        rounded && "overflow-hidden rounded-xl md:rounded-2xl"
      } ${className}`}
    >
      <Image
        src={imageURL}
        alt={imageAltText}
        height={imageOptions.height}
        width={imageOptions.width}
        quality={40}
      />
    </div>
  );
};

SingleBanner.defaultProps = {
  rounded: true,
  imageAltText: "banner-image",
  imageOptions: {
    height: 480,
    width: 1450,
  },
};

SingleBanner.propTypes = {
  rounded: PropTypes.bool,
  imageURL: PropTypes.string.isRequired,
  imageAltText: PropTypes.string,
  className: PropTypes.string,
  imageOptions: PropTypes.object,
};
