import React from "react";

import { useRouter } from "next/router";

import TertiaryBtn from "@/components/actions/button/tertiaryBtn";

function AuthDropdown({ hideTooltip }) {
  const router = useRouter();

  const onSignIn = () => {
    hideTooltip();
    router.push(`/signin`);
  };

  return (
    <div className="bg-white px-[0.2rem] py-[1.5rem]">
      <TertiaryBtn
        btnText="Sign In"
        onClick={onSignIn}
        className="h-[2.625rem] w-[8.813rem] font-[1rem] transition delay-100 duration-300 ease-in-out hover:bg-tertiary-400 hover:text-white active:bg-tertiary-400 active:text-white"
      />
    </div>
  );
}

export default AuthDropdown;
