import { useEffect, useState } from "react";

function useCountdown(totalSeconds = 60) {
  const [timeLeft, setTimeLeft] = useState(totalSeconds);

  useEffect(() => {
    if (timeLeft <= 0) return;

    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const resetTimer = (newTotalSecond = totalSeconds) => {
    setTimeLeft(newTotalSecond);
  };

  const hours = Math.floor(timeLeft / 3600);
  const minutes = Math.floor((timeLeft / 60) % 60);
  const seconds = timeLeft % 60;

  return {
    hours: hours < 10 ? "0" + hours : hours,
    minutes: minutes < 10 ? "0" + minutes : minutes,
    seconds: seconds < 10 ? "0" + seconds : seconds,
    timerOver: timeLeft === 0,
    resetTimer,
  };
}

export default useCountdown;
