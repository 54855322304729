import PropTypes from "prop-types";

function Indicator({ itemCount, children, className }) {
  const MAX_COUNT_SHOW = 99;

  return (
    <button className={`relative inline-flex items-center ${className}`}>
      {itemCount > 0 && (
        <span className="absolute -right-3 -top-3 inline-flex h-[1.625rem] w-[1.625rem] items-center justify-center rounded-full bg-primary px-1 py-1 text-center text-[0.75rem] text-white">
          {itemCount > MAX_COUNT_SHOW ? "99+" : itemCount}
        </span>
      )}
      {children}
    </button>
  );
}

Indicator.propTypes = {
  itemCount: PropTypes.number,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Indicator;
