import { combineReducers, configureStore } from "@reduxjs/toolkit";

import cartSlice from "./feature/cartSlice";
import loaderSlice from "./feature/loaderSlice";
import orderSlice from "./feature/orderSlice";
import wishlistSlice from "./feature/wishlistSlice";

const combineReducer = combineReducers({
  loader: loaderSlice,
  cart: cartSlice,
  wishlist: wishlistSlice,
  order: orderSlice,
});

const store = configureStore({
  reducer: combineReducer,
});

export default store;
