import { useEffect, useRef, useState } from "react";

import { useRouter } from "next/router";

import { AnimatePresence, motion, useInView } from "framer-motion";

import { useGetProductSuggestions } from "@/api/client/product";
import SearchInput from "@/components/data-input/searchInput";
import { useClickOutside, useDebounce } from "@/hooks";
import localStorage from "@/services/local-storage";

import SearchSuggestions from "../SearchSuggestions";

export const SearchBar = () => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [isFocused, setFocused] = useState(false);

  const router = useRouter();
  const searchBarRef = useRef(null);
  const isInView = useInView(searchBarRef);

  useClickOutside(searchBarRef, () => setFocused(false));
  const debouncedValue = useDebounce(searchKeyword, 500);

  useEffect(() => {
    setSearchKeyword(router.query?.query ?? "");
  }, [router.query]);

  useEffect(() => {
    if (isFocused) suggestionRefetch();
    if (!debouncedValue) removeSuggestionData();
  }, [debouncedValue, isFocused]);

  const {
    data: suggestionData,
    refetch: suggestionRefetch,
    remove: removeSuggestionData,
    isFetching: isSuggetionLoading,
  } = useGetProductSuggestions(searchKeyword?.trim(), { enabled: false });

  const handleChange = (value) => {
    if (!isFocused) setFocused(true);
    setSearchKeyword(value);
  };

  const handleSearch = () => {
    if (!searchKeyword?.trim()) return;

    setFocused(false);
    router.push({
      pathname: "/search",
      query: { query: searchKeyword },
    });
    handleStoreKeyword(searchKeyword);
  };

  const handleStoreKeyword = (keyword) => {
    const KEYWORDS_LIMIT = 8;
    let storedKeywords = localStorage.get("recentSearches") || [];

    if (storedKeywords.includes(keyword)) {
      storedKeywords = storedKeywords.filter((el) => el !== keyword);
    }
    const newKeywords = [keyword, ...storedKeywords].slice(0, KEYWORDS_LIMIT);
    localStorage.add("recentSearches", newKeywords);
  };

  return (
    <div
      ref={searchBarRef}
      className="relative order-3 col-span-2 mt-[1.875rem] md:order-2 md:mt-0"
    >
      <SearchInput
        value={searchKeyword}
        onSearch={handleSearch}
        onChange={handleChange}
        isLoading={isSuggetionLoading}
        onFocus={() => setFocused(true)}
      />

      <AnimatePresence>
        {isInView && isFocused && (
          <motion.div
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="absolute top-full z-50 w-full"
          >
            <SearchSuggestions
              query={searchKeyword}
              searchedProducts={suggestionData}
              deactivateFocus={() => setFocused(false)}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
