import { GenericDrawer } from "..";
import ProfileDropdown from "../../dropdown/profileDropdown";

function ProfileMenu({ isActive, onHide }) {
  return (
    <GenericDrawer isActive={isActive} onHide={onHide}>
      <div className="pt-1">
        <ProfileDropdown hideTooltip={onHide} />
      </div>
    </GenericDrawer>
  );
}

export default ProfileMenu;
