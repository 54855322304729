export const MainMenus = [
  {
    name: "Components",
    slug: "components",
    child: [
      { name: "Batteries", slug: "batteries" },
      { name: "Switch", slug: "switches" },
      { name: "Capacitors", slug: "capacitors" },
      { name: "Relay", slug: "relay" },
      { name: "Resistors", slug: "resistors" },
      { name: "Transformers", slug: "transformers" },
      { name: "Transistors", slug: "transistors" },
      { name: "Regulators", slug: "regulators" },
      { name: "LEDs", slug: "led" },
      { name: "Diode", slug: "diode" },
      { name: "Inductor", slug: "inductor" },
      { name: "Burner / Programmer", slug: "burner-programmer" },
      { name: "Crystal Oscillator", slug: "crystal-oscillator" },
      { name: "Solar", slug: "solar" },
    ],
  },
  { name: "Resistors", slug: "resistors", child: [] },
  { name: "Actuator", slug: "actuator", child: [] },
  { name: "Breadboard", slug: "breadboard", child: [] },
  { name: "Burner / Programmer", slug: "burner-programmer", child: [] },
];

export const FixedMenus = [
  { name: "Innovation Kit", slug: "innovation-kit" },
  { name: "Gadgets & Electronics", slug: "gadgets-electronics" },
];
