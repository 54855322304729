import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  wishlistProductCount: 0,
  wishlistItems: {},
};

const wishlistSlice = createSlice({
  name: "wishlist",
  initialState,
  reducers: {
    updateWishlistItemCount: (state, action) => {
      state.wishlistProductCount = Math.ceil(action.payload);
    },

    addWishlistItem: (state, action) => {
      state.wishlistItems[action.payload] = true;
    },

    removeWishlistItem: (state, action) => {
      delete state.wishlistItems[action.payload];
    },

    replaceWishlistItems: (state, action) => {
      state.wishlistItems = action.payload;
    },
  },
});

export default wishlistSlice.reducer;

export const {
  updateWishlistItemCount,
  addWishlistItem,
  removeWishlistItem,
  replaceWishlistItems,
} = wishlistSlice.actions;

export const getWishlistState = (state) => state.wishlist;
