import { getSession } from "next-auth/react";

import Cookies from "js-cookie";
import uniqid from "uniqid";

export const generateGuestUser = () => {
  let id = uniqid();
  const time = Date.now().toString();
  const lastFourChar = time.substring(time.length - 4);
  id = id + lastFourChar;

  return { id };
};

export const setGuestUser = (guestUserId, expiresTimeInDay = 365) => {
  if (!guestUserId) return;

  Cookies.set("robodoc_guest_user", JSON.stringify(guestUserId), {
    expires: expiresTimeInDay,
  });
};

export const getGuestUser = () => {
  const guestUser = Cookies.get("robodoc_guest_user");

  if (guestUser) {
    return JSON.parse(guestUser);
  }

  return null;
};

export const removeGuestUser = () => {
  Cookies.remove("robodoc_guest_user");
};

export async function handleGuestUser() {
  const userData = await getSession();
  if (userData || getGuestUser()) return;

  setGuestUser(generateGuestUser());
}
