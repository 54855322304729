/* eslint-disable @next/next/no-img-element */
import { useEffect, useState } from "react";

import PropTypes from "prop-types";

import Styles from "./StarRating.module.css";

const COUNT = 5;
const INACTIVE_STAR = "/assets/icons/star.svg";
const ACTIVE_STAR = "/assets/icons/star-fill.svg";

const getReviewLabel = (rating) => {
  switch (rating) {
    case 1:
      return `Poor (${rating?.toFixed(2)})`;
    case 2:
      return `Okay (${rating?.toFixed(2)})`;
    case 3:
      return `Neutral (${rating?.toFixed(2)})`;
    case 4:
      return `Good (${rating?.toFixed(2)})`;
    case 5:
      return `Excellent (${rating?.toFixed(2)})`;

    default:
      return "";
  }
};

export const StarRating = ({
  label,
  value,
  isRequired,
  errorMessage,
  changeHandler,
}) => {
  const [rating, setRating] = useState(value ?? null);
  const [hoverFill, setHoverFill] = useState(null);
  const [isAnimate, setAnimate] = useState(false);

  useEffect(() => {
    setRating(value);
  }, [value]);

  const stars = Array.from({ length: COUNT }, () => INACTIVE_STAR);

  const handleChange = (value) => {
    setAnimate(true);
    changeHandler(value);
    setTimeout(() => setAnimate(false), 300);
  };

  return (
    <div className="mb-4">
      <p className="my-2 font-medium">
        {label}
        {isRequired && <span className="text-required-color">&nbsp;*</span>}
      </p>

      <div className="flex items-center gap-4">
        <div className="-ml-2">
          {stars.map((el, i) => {
            const ratedValue = i + 1;

            return (
              <img
                key={i}
                alt="Rate the Product"
                onMouseLeave={() => setHoverFill(null)}
                onClick={() => handleChange(ratedValue)}
                onMouseEnter={() => setHoverFill(ratedValue)}
                src={ratedValue <= (hoverFill || rating) ? ACTIVE_STAR : el}
                className={`inline-block h-7 w-7 p-1 hover:cursor-pointer min-[375px]:h-8 min-[375px]:w-8 sm:h-10 sm:w-10 sm:p-2 ${
                  isAnimate && ratedValue == rating
                    ? Styles.animate
                    : "hover:animate-pulse"
                }`}
              />
            );
          })}
        </div>

        <p className="font-semibold">{getReviewLabel(rating)}</p>
      </div>
      {errorMessage && (
        <p className="mt-[0.35rem] text-[#FF4242] text-[0.875] first-letter:uppercase">
          {errorMessage}
        </p>
      )}
    </div>
  );
};

StarRating.propTypes = {
  label: PropTypes.string,
  value: PropTypes.number,
  isRequired: PropTypes.bool,
  changeHandler: PropTypes.func,
  errorMessage: PropTypes.string,
};
