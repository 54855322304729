import { useMutation, useQuery } from "@tanstack/react-query";

import {
  addToCartApi,
  deleteCartDataApi,
  getCartDataApi,
  getExpressAdress,
  updateCartAddressApi,
  updateCartDeliveryMethod,
} from "@/api/end-points/cart";

export const useGetCartData = (useQueryOptions) => {
  return useQuery({
    queryKey: ["cart"],
    queryFn: getCartDataApi,
    ...useQueryOptions,
    select: (res) => {
      const cartData = res.data?.data?.cart;
      return cartData;
    },
    onError: (error) => {
      return error.response.data;
    },
  });
};

export const useGetExpressData = (useQueryOptions) => {
  return useQuery({
    queryKey: ["expressAddress"],
    queryFn: getExpressAdress,
    ...useQueryOptions,
    select: (res) => {
      const expressData = res.data?.data.addresses;
      return expressData;
    },
    onError: (error) => {
      return error.response.data;
    },
  });
};

export const useAddToCart = (useQueryOptions) => {
  const { onSuccess = () => {}, onError = () => {} } = useQueryOptions || {};

  return useMutation(addToCartApi, {
    onSuccess: (res) => {
      onSuccess({
        status: res.status,
        message: res.data.message || "Added to cart successfully",
        cartData: res.data?.data,
      });
    },
    onError: (error) => {
      onError({
        status: error.response.status,
        data: error.response.data,
        message: error.response.data.errors[0].message,
      });
    },
  });
};

export const useBuyNow = (useQueryOptions) => {
  const { onSuccess = () => {}, onError = () => {} } = useQueryOptions || {};

  return useMutation(addToCartApi, {
    onSuccess: (res) => {
      onSuccess({
        status: res.status,
        message: res.data.message || "Added to cart successfully",
        cartData: res.data?.data,
      });
    },
    onError: (error) => {
      onError({
        status: error.response.status,
        data: error.response.data,
        message: error.response.data.errors[0].message,
      });
    },
  });
};

export const useDeleteCartData = (useQueryOptions) => {
  const { onSuccess = () => {}, onError = () => {} } = useQueryOptions || {};

  return useMutation(deleteCartDataApi, {
    onSuccess: (res) => {
      onSuccess({
        status: res.status,
        message: res.data.message || "Product successfully removed",
        cartData: res.data?.data,
      });
    },
    onError: (error) => {
      onError({
        status: error.response.status,
        data: error.response.data,
        message: error.response.data.errors[0].message,
      });
    },
  });
};

export const useUpdateCartAddress = (useQueryOptions) => {
  const { onSuccess = () => {}, onError = () => {} } = useQueryOptions || {};

  return useMutation(updateCartAddressApi, {
    onSuccess: (res) => {
      onSuccess({
        status: res.status,
        message: res.data.message,
        cartData: res.data?.data,
      });
    },
    onError: (error) => {
      onError({
        status: error.response.status,
        data: error.response.data,
        message: error.response.data.errors[0].message,
      });
    },
  });
};

export const useUpdateCartDeliveryMethod = (useQueryOptions) => {
  const { onSuccess = () => {}, onError = () => {} } = useQueryOptions || {};

  return useMutation(updateCartDeliveryMethod, {
    onSuccess: (res) => {
      onSuccess({
        status: res.status,
        message: res.data.message,
        cartData: res.data?.data,
      });
    },
    onError: (error) => {
      onError({
        status: error.response.status,
        data: error.response.data,
        message: error.response.data.errors[0].message,
      });
    },
  });
};

export const useUpdateCartExpressDeliveryMethod = (useQueryOptions) => {
  const { onSuccess = () => {}, onError = () => {} } = useQueryOptions || {};

  return useMutation(updateCartDeliveryMethod, {
    onSuccess: (res) => {
      onSuccess({
        status: res.status,
        message: res.data.message,
        cartData: res.data?.data,
      });
    },
    onError: (error) => {
      onError({
        status: error.response.status,
        data: error.response.data,
        message: error.response.data.errors[0].message,
      });
    },
  });
};
