import PropTypes from "prop-types";

import { VerifyEmailIcon } from "@/components/data-display";

function VerifyEmail({ className = "h-[32rem]", emailAddress }) {
  return (
    <div className={`${className} flex items-center`}>
      <div className="flex flex-col items-center">
        <VerifyEmailIcon className="h-[5rem] w-[5rem]" />
        <h2 className="mb-[1.5rem] mt-[2.5rem] text-center text-[1.375rem] font-semibold leading-[2rem] text-grayDisplay-900 md:text-[1.75rem]">
          Verify your email address
        </h2>
        <div className="max-w-[30.625rem] text-center text-[0.938rem] leading-[1.563rem] text-grayDisplay-400 md:text-[1rem] md:leading-[1.625rem]">
          Confirmation link has been sent to&nbsp;
          <span className="font-semibold text-grayDisplay-700 underline">
            {emailAddress}
          </span>
          . Go to your inbox and verify your email address
        </div>
      </div>
    </div>
  );
}

VerifyEmail.propTypes = {
  className: PropTypes.string,
  emailAddress: PropTypes.string,
  userName: PropTypes.string,
};

export default VerifyEmail;
