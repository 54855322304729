import { apiClient } from "@/services/api-client";

export const getWishListApi = () => {
  return apiClient({
    url: "/v1/wishlist",
    method: "GET",
  });
};

export const addWishListApi = (data) => {
  return apiClient({
    url: "/v1/wishlist",
    method: "PUT",
    data,
  });
};

export const removeWishListApi = (data) => {
  return apiClient({
    url: "/v1/wishlist",
    method: "DELETE",
    data,
  });
};

export const addAllWishListToCartApi = () => {
  return apiClient({
    url: "/v2/wishlist-to-cart",
    method: "PUT",
  });
};
