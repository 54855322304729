import Head from "next/head";

function MetaData({ title, image, description, url, keywords, type, schema }) {
  return (
    <Head>
      <link rel="shortcut icon" href="/favicon.png" />
      <title>{title}</title>

      <link rel="canonical" href={url} key="canonical" />
      <meta name="keywords" content={keywords} key="og-keywords" />
      <meta name="description" content={description} key="desc" />

      {/* open graph */}
      <meta property="og:title" content={title} key="og-title" />
      <meta property="og:image" content={image} key="og-image" />
      <meta
        property="og:description"
        content={description}
        key="og-description"
      />
      <meta property="og:url" content={url} key="og-url" />
      <meta property="og:type" content={type} key="og-type" />

      {/* twitter open graph */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content={url} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />

      {/* google console site verification */}
      <meta
        name="google-site-verification"
        content={`${process.env.NEXT_PUBLIC_GOOGLE_SITE_VERIFY_ID}`}
      />
      {/* fb domain verification */}
      <meta
        name="facebook-domain-verification"
        content={`${process.env.NEXT_PUBLIC_FB_DOMAIN_VERIFY_ID}`}
      />

      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
      />
    </Head>
  );
}

MetaData.defaultProps = {
  title: "Robodoc:Best Robotics Shop In Bangladesh | RoboDoc",
  image: "/preview.png",
  description:
    "Robodoc is the best robotics shop in Bangladesh. We provide best quality robots and robotic kits at affordable prices.",
  url: "https://www.robodocbd.com/",
  keywords:
    "Robodocbd, Robodoc, Electronics, Robot, Robotics, Best Online Shop, Best Online Shop in bd,  Best Online Shop in Bangladesh, Best Online Electronics Shop in bd, eCommerce, EEE, CSE, Project, online, shopping, Bangladesh, students, varsity, teachers, study, programming, Robo, Robodoc, RobodocBD, Lessons, Experiments, tech, Robotics Package, low price electronics, best robotics shop in Bangladesh, best online robotics shop in Bangladesh, best online robotics shop",
  type: "website",

  schema: {
    "@context": "https://schema.org",
    "@type": "ElectronicsStore",
    name: "RoboDoc Limited",
    image:
      "https://robodocstorage.blob.core.windows.net/static-images/sharing_logo_rd.png",
    "@id": "https://robodocbd.com/",
    url: "https://robodocbd.com/",
    telephone: "+880 1322 908 240",
    address: {
      "@type": "PostalAddress",
      streetAddress:
        "Zakir Complex (9th Floor), Ka-218, Kuril Chowrasta, Dhaka-1229, Bangladesh.",
      addressLocality: "Dhaka",
      postalCode: "1229",
      addressCountry: "BD",
    },
    geo: {
      "@type": "GeoCoordinates",
      latitude: "23.8159209",
      longitude: "90.4190932",
    },
    sameAs: [
      "https://www.facebook.com/robodocbd",
      "https://www.youtube.com/channel/UCrZqnFy_w_uglgEHbujjjVg",
    ],
  },
};

export default MetaData;
