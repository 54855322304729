import { useDispatch } from "react-redux";

import { signOut } from "next-auth/react";
import Link from "next/link";
import { useRouter } from "next/router";

import { errorToast, successToast } from "@/components/feedback/toast";
import { generateGuestUser, setGuestUser } from "@/services/guest-user";
import { updateTrackOrder } from "@/store/feature";
import { updateCartItemCount } from "@/store/feature/cartSlice";
import { updateWishlistItemCount } from "@/store/feature/wishlistSlice";
import { accountDropdownList } from "@/utils/constants/accountDropdown.constant";

function ProfileDropdown({ hideTooltip }) {
  const router = useRouter();
  const dispatch = useDispatch();

  const clickHandler = async (slug) => {
    hideTooltip?.();

    if (slug === "sign-out") {
      try {
        const res = await signOut({ redirect: false });
        if (res) {
          router.push("/");
          dispatch(updateCartItemCount(0));
          dispatch(updateWishlistItemCount(0));
          setGuestUser(generateGuestUser());
          successToast({ message: "Sign out successfull!" });
        }
      } catch {
        errorToast({ message: "Sign out failed!" });
      }
    } else if (slug === "order-track") {
      setTimeout(() => dispatch(updateTrackOrder(true)), 500);
    }
  };

  return (
    <ul className="mt-[0.863rem] rounded-sm border-grayDisplay-100 px-[0.625rem] pb-[1.063rem] text-[1.125rem] font-medium text-grayDisplay-900">
      {accountDropdownList.map(({ slug, label }) => (
        <li
          key={slug}
          className="cursor-pointer rounded-[0.313rem] transition-colors duration-200 hover:bg-grayDisplay-150"
        >
          {slug === "sign-out" || slug === "order-track" ? (
            <p
              onClick={() => clickHandler(slug)}
              className="px-[1.25rem] py-[0.625rem]"
            >
              {label}
            </p>
          ) : (
            <Link
              href={`/${slug}`}
              onClick={() => hideTooltip()}
              className="block px-[1.25rem] py-[0.625rem]"
            >
              {label}
            </Link>
          )}
        </li>
      ))}
    </ul>
  );
}

export default ProfileDropdown;
