import PropTypes from "prop-types";

import CouponForm from "@/components/data-input/couponForm";
import { deliveryMethods } from "@/utils/constants";

export function CartSummaryCard({
  cartData = {},
  refetchCartData,
  isCartDataFetching,
}) {
  return (
    <div className="rounded-[0.75rem] bg-[#FAFBFC]/70">
      <div className="rounded-t-[0.75rem] bg-[#F5F7FA] p-[1.25rem]">
        <p className="text-[1.125rem] font-semibold leading-[1.75rem] text-grayDisplay-900">
          Order Summary
        </p>
      </div>
      <div className="p-[1.25rem]">
        <div className="space-y-[1.563rem] text-[1rem] leading-[1.625rem]">
          <div className="flex items-center justify-between">
            <p className="font-normal  text-grayDisplay-400">Sub Total</p>
            <p className="font-semibold text-grayDisplay-900">
              ৳{cartData?.total_product_price}
            </p>
          </div>

          {cartData?.delivery_method !== deliveryMethods.OFFICE_PICKUP && (
            <div className="flex items-center justify-between">
              <p className="font-normal text-grayDisplay-400">Shipping Cost</p>
              <p className="font-semibold text-grayDisplay-900">
                ৳{cartData?.shipping_fee}
              </p>
            </div>
          )}
          <div className="mb-[1.563rem] border-b border-b-grayDisplay-100"></div>

          {cartData?.discounts?.map((discount) => (
            <div
              className="flex items-center justify-between"
              key={discount?.key}
            >
              <p className="font-normal text-grayDisplay-400">
                {discount?.text}
              </p>
              <p className="font-semibold text-discount-color">
                ৳{discount?.amount}
              </p>
            </div>
          ))}

          <div className="flex items-center justify-between">
            <p className="font-normal text-grayDisplay-900">Total Amount</p>
            <p className="text-[1.125rem] font-semibold text-grayDisplay-900">
              ৳{cartData?.cart_total_amount}
            </p>
          </div>
        </div>
      </div>
      <div className="border-b border-b-grayDisplay-100"></div>
      <div className="px-[1.25rem] py-[1.5rem]">
        <div className="w-full">
          <CouponForm
            coupon={cartData?.coupon}
            refetchCart={refetchCartData}
            isCartRefetching={isCartDataFetching}
          />
        </div>
      </div>
    </div>
  );
}

CartSummaryCard.propTypes = {
  cartData: PropTypes.object,
  refetchCartData: PropTypes.func,
  isCartDataFetching: PropTypes.bool,
};
