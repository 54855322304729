import { useEffect, useState } from "react";

import classNames from "classnames";

import { DecrementIcon, IncrementIcon } from "../../icon";

const CartItemQty = ({ productData, handleUpdateQuantity }) => {
  const [productQty, setProductQty] = useState(productData.quantity);

  const isStockOut = productData.current_stock <= 0;
  const isDecrementDisabled = isStockOut || productQty <= 1;
  const isIncrementDisabled =
    isStockOut || productQty === productData.current_stock;

  useEffect(() => {
    setProductQty(productData.quantity);
  }, [productData]);

  useEffect(() => {
    if (productQty === productData.quantity) return;

    const timer = setTimeout(() => {
      handleUpdateQuantity(productData.slug, productQty);
    }, 700);

    return () => clearTimeout(timer);
  }, [productQty]);

  return (
    <div className="flex w-2/6 items-center space-x-[1.25rem]">
      <button
        disabled={isDecrementDisabled}
        onClick={() => setProductQty(productQty - 1)}
        className="h-[1.5rem] w-[1.5rem] rounded-[0.313rem] bg-grayDisplay-350"
      >
        <DecrementIcon
          className={classNames(
            {
              "text-grayDisplay-900 hover:text-primary": !isDecrementDisabled,
            },
            "text-grayDisplay-850"
          )}
        />
      </button>
      <span className="text-[1.125rem] font-medium text-grayDisplay-900">
        {productQty}
      </span>
      <button
        className="h-[1.5rem] w-[1.5rem] rounded-[0.313rem] bg-grayDisplay-350"
        disabled={isIncrementDisabled}
        onClick={() => setProductQty(productQty + 1)}
      >
        <IncrementIcon
          className={classNames(
            {
              "text-grayDisplay-900 hover:text-primary": !isIncrementDisabled,
            },
            "text-grayDisplay-850"
          )}
        />
      </button>
    </div>
  );
};

export default CartItemQty;
