import { useQuery } from "@tanstack/react-query";

import { getMainMenuApi } from "@/api/end-points/menu";

export const useGetMainMenu = (useQueryOptions) => {
  return useQuery({
    queryKey: ["main-menu"],
    queryFn: getMainMenuApi,
    ...useQueryOptions,
    select: (res) => {
      return res.data?.data.main_menu;
    },
  });
};
