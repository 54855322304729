import * as Yup from "yup";

export const reviewSchema = Yup.object({
  rating: Yup.number().min(1, "Rating can not be empty").max(5),
  review: Yup.string()
    .trim()
    .required("Review can not be empty")
    .min(10)
    .max(500),
});
