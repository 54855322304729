import * as Yup from "yup";

import { emailRegex, phoneRegex } from "@/utils/patterns";

export const profileSchema = Yup.object({
  full_name: Yup.string()
    .trim()
    .required("Full name can not be empty")
    .min(3, "Full name should have a minimum length of 3")
    .max(30, "Full name should have a max length of 30"),
  gender: Yup.string().nullable(true),
  birthday: Yup.object({
    year: Yup.number(),
    month: Yup.number(),
    day: Yup.number(),
  }).nullable(true),
});

export const emailSchema = Yup.object({
  email: Yup.string()
    .required("Email address can not be empty")
    .matches(emailRegex, "Invalid email address"),
});

export const phoneSchema = Yup.object({
  phone: Yup.string()
    .required("Phone number can not be empty")
    .matches(phoneRegex, "Invalid phone number"),
});
