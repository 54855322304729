export function formatStatus(status) {
  return status
    .replace(/_/g, " ")
    .replace(/\b\w/g, (letter) => letter.toUpperCase());
}

export function formatStatusForColor(status) {
  return status.replace(/_/g, "-");
}

export function formatSentence(sentence) {
  return sentence
    .toLowerCase()
    .replace(/(^|\s)\S/g, (letter) => letter.toUpperCase());
}

export function formatDeliveryAddress(options) {
  return options?.map((option) => ({
    value: option.address_id,
    label: option.full_address,
  }));
}
