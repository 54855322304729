import PropTypes from "prop-types";

import ToastCloseIcon from "@/icons/toast-close.svg";

function ToastUI({ Icon, message }) {
  return (
    <div
      className="flex items-center justify-between gap-[0.75rem] rounded-[20px] bg-grayDisplay-700 px-[1.25rem] py-[1.125rem] font-medium md:gap-[1.5rem]"
      role="alert"
    >
      <div className="flex items-center space-x-[1rem]">
        <div>
          <Icon className="inline-block h-[28px] w-[28px]" />
        </div>
        <div className="w-full text-[1.125rem] font-medium text-[#FFFFFF]">
          {message}
        </div>
      </div>
      <div>
        <ToastCloseIcon />
      </div>
    </div>
  );
}

export default ToastUI;

ToastUI.propTypes = {
  Icon: PropTypes.any,
  message: PropTypes.string,
};
