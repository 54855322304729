import { useState } from "react";
import { useForm } from "react-hook-form";

import Image from "next/image";
import Link from "next/link";

import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";

import { useCreateProductReview } from "@/api/client/product";
import { StarRating } from "@/components/data-input";
import AppTextAreaInput from "@/components/data-input/inputTextArea";
import { errorToast, successToast } from "@/components/feedback/toast";
import { reviewSchema } from "@/schema/formSchema";

import PrimaryBtn from "../../button/primaryBtn";
import SecondaryBtn from "../../button/secondaryBtn";
import { GenericModal } from "../GenericModal";

export const WriteReview = (props) => {
  const {
    product_name,
    product_image,
    price,
    discount_price,
    has_discount,
    total,
    quantity,
    product_slug,
    orderId,
    orderNumber,
    orderDate,
    is_reviewed,
  } = props;
  const [isOpen, setOpen] = useState(false);
  const defaultValues = { rating: 0, review: "" };

  const {
    watch,
    reset,
    register,
    setValue,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(reviewSchema),
  });

  const { isLoading, mutate: createReview } = useCreateProductReview({
    onSuccess: () => {
      handleModalChange();
      successToast({ message: "Review submitted successfully" });
    },
    onError: ({ message }) => {
      errorToast({ message, autoClose: 2000 });
    },
  });

  const handleModalChange = () => {
    setOpen((prevState) => !prevState);
    if (isOpen) reset({ rating: 0, review: "" });
  };

  const onSubmit = (data) => {
    if (is_reviewed) {
      errorToast({ message: "You have already reviewed this product" });
      handleModalChange();
      return;
    }
    createReview({ product_slug, payload: { ...data, order_key: orderId } });
  };

  return (
    <GenericModal isOpen={isOpen} onOpenChange={handleModalChange}>
      <GenericModal.Trigger>
        <p role="button" className="font-semibold text-primary-400">
          Write a Review
        </p>
      </GenericModal.Trigger>

      <GenericModal.Content title="Write a Review">
        <div className="mt-5 flex flex-col gap-[1.25rem] md:flex-row">
          <p>
            Order ID&nbsp;
            <span className="font-medium text-primary">{orderNumber}</span>
          </p>
          <p>{orderDate}</p>
        </div>

        <div className="flex flex-row py-10">
          <div className="min-w-[4.5rem]">
            <Link href={`/product/${product_slug}`}>
              <Image
                width={60}
                height={60}
                alt={product_name}
                src={product_image}
              />
            </Link>
          </div>
          <div>
            <p className="mb-3">
              <Link href={`/product/${product_slug}`}>{product_name}</Link>
            </p>
            <span className="mr-4 font-semibold">
              {quantity}x ৳{has_discount ? discount_price : price}
            </span>
            <span className="font-semibold  text-primary-400">৳{total}</span>
          </div>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <StarRating
            isRequired
            value={watch("rating")}
            label="Please rate this product"
            errorMessage={errors.rating?.message}
            changeHandler={(value) => {
              clearErrors("rating");
              setValue("rating", value);
            }}
          />

          <AppTextAreaInput
            name="review"
            isFieldRequired
            error={errors.review}
            register={register}
            labelName="Your Review"
            placeholder="Write your review"
          />

          <div className="mt-3 flex justify-between gap-[1rem] md:justify-end md:gap-[1.25rem]">
            <SecondaryBtn
              btnText="Cancel"
              isDisabled={isLoading}
              onClick={handleModalChange}
              className="w-full py-[0.875rem] md:w-[10rem] md:py-[1rem]"
            />

            <PrimaryBtn
              btnText="Submit"
              isLoading={isLoading}
              isDisabled={isLoading}
              className="w-full py-[.875rem] md:w-[10rem] md:py-[1rem]"
            />
          </div>
        </form>
      </GenericModal.Content>
    </GenericModal>
  );
};

WriteReview.propTypes = {
  product_image: PropTypes.string,
  product_name: PropTypes.string,
  quantity: PropTypes.number,
  price: PropTypes.number,
  orderId: PropTypes.string,
  total: PropTypes.number,
  product_slug: PropTypes.string,
  orderDate: PropTypes.string,
  orderNumber: PropTypes.string,
};
