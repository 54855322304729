import * as Yup from "yup";

export const orderCancelConfirmSchema = Yup.object({
  notes: Yup.string().trim().max(250),
  reason: Yup.string().required("Cancellation Reason is Required"),
  terms: Yup.boolean().oneOf([true], "Must Accept Terms and Conditions"),
});

export const trackingOrderSchema = Yup.object({
  order: Yup.string()
    .trim()
    .required("Order ID is a required field")
    .min(8, "Order ID at least 8 characters")
    .max(13, "Order ID at most 13 characters"),
});
