import { useEffect, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useForm } from "react-hook-form";

import { useRouter } from "next/router";

import { usePostSubscribeToNewsletter } from "@/api/client/newsletter";
import { SubscriptionModal } from "@/components/actions";
import {
  InlineLoaderIcon,
  PaperPlaneIcon,
} from "@/components/data-display/icon";
import { errorToast, successToast } from "@/components/feedback/toast";
import statusCodes from "@/utils/constants/http-status-codes";

import SocialMediaLink from "./socialMediaLink";

function FooterTop() {
  const [isSubscriptionModal, setSubscriptionModal] = useState(false);

  const router = useRouter();

  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    router.events.on("routeChangeComplete", clearEmailField);
    return () => {
      router.events.off("routeChangeComplete", clearEmailField);
    };
  }, [router.events]);

  const {
    register,
    handleSubmit,
    clearErrors,
    reset,
    setError,
    setFocus,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const {
    isLoading: isNewsletterSubscriptionLoading,
    mutate: subscribeToNewsletter,
  } = usePostSubscribeToNewsletter({
    onSuccess: ({ status, message }) => {
      if (status === statusCodes.CREATED) {
        setSubscriptionModal(true);
      } else {
        successToast({ message });
      }
      reset({ email: "" });
    },
    onError: ({ data }) => {
      errorToast({ message: data.message, autoClose: 2000 });
    },
  });

  const getGRecaptchaToken = async () => {
    if (!executeRecaptcha) {
      return;
    }

    const gReCaptchaToken = await executeRecaptcha("newsLetterFormSubmit");

    return gReCaptchaToken;
  };

  const handleSubscription = async (data) => {
    if (!data.email) {
      return setError("email", { message: "Email is a required field" });
    }
    data.gRecaptchaToken = await getGRecaptchaToken();
    subscribeToNewsletter(data);
  };

  const clearEmailField = () => {
    reset({ email: "" });
  };

  return (
    <>
      <hr className="border-t-[0.5px] border-grayDisplay-100" />
      <div className="container flex flex-col py-10 sm:flex-row sm:justify-between">
        <div className="flex flex-col space-y-3 sm:flex-row sm:items-center sm:space-y-0">
          <div className="flex items-end space-x-1 uppercase sm:block sm:items-center sm:space-x-0">
            <p className="text-[0.75rem] leading-[1.563rem] text-grayDisplay-300 sm:text-[0.938rem]">
              SIGN UP TO
            </p>
            <p className="text-[1rem] font-semibold leading-[1.563rem] text-grayDisplay-700 sm:text-[1.375rem]">
              NEWSLETTER
            </p>
          </div>

          <div className="relative sm:ml-10">
            {isNewsletterSubscriptionLoading && (
              <div className="absolute inset-0 top-3 z-50">
                <InlineLoaderIcon />
              </div>
            )}

            <form
              autoComplete="off"
              onSubmit={handleSubmit(handleSubscription)}
              className="relative h-[2.875rem] w-full rounded-[2.625rem] border border-grayDisplay-200 md:w-[21.875rem] lg:w-[33.75rem]"
            >
              <div className="h-full w-full rounded-[2.625rem]">
                <input
                  type="text"
                  className="h-full w-full rounded-[2.625rem] border-0 pl-[1.5rem] text-[0.875rem] leading-[1.375rem] focus:border-grayDisplay-200 focus:outline-none focus:ring-0 focus:ring-grayDisplay-200 sm:text-[1rem]"
                  placeholder="Enter your email address…"
                  name="email"
                  {...register("email")}
                  onBlur={(e) => !e.target.value && clearErrors("email")}
                />
              </div>

              <button
                onClick={() => setFocus("email")}
                className={`absolute right-[0.75rem] top-[50%] flex h-[2.3rem] w-[2.3rem] translate-y-[-50%] cursor-pointer items-center justify-center rounded-full hover:bg-[#EDFFF3]`}
              >
                <PaperPlaneIcon />
              </button>
            </form>
            {errors?.email && (
              <p className="mt-[0.35rem] text-[#FF4242] text-[0.875] first-letter:uppercase">
                {errors?.email?.message}
              </p>
            )}
          </div>
        </div>
        <SocialMediaLink />
      </div>

      <SubscriptionModal
        isActive={isSubscriptionModal}
        onHide={() => setSubscriptionModal(false)}
      />
    </>
  );
}

export default FooterTop;
