import * as Yup from "yup";

import { emailRegex } from "@/utils/patterns";

export const questionSchema = Yup.object({
  title: Yup.string()
    .trim()
    .required("Question can not be empty")
    .min(5, "Question should have a minimum length of 5")
    .max(120, "Question must be at most 120 characters"),
  email: Yup.string()
    .required("Email can not be empty")
    .matches(emailRegex, "Invalid email address"),
});
