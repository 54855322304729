export const paymentMethods = [
  {
    value: "cash_on_delivery",
    label: "Cash on Delivery",
    isShowable: true,
  },
  {
    value: "bkash",
    label: "Bkash",
    isShowable: true,
  },
  {
    value: "ssl_commerz",
    label: "SSLCommerz",
    isShowable: true,
  },
];

export const paymentOptions = {
  BKASH: "bkash",
  CASH_ON_DELIVERY: "cash_on_delivery",
  SSL_COMMERZ: "ssl_commerz",
};

export const paymentTermsLinks = [
  {
    title: "Terms of Use",
    link: "/terms-of-use",
    followingText: ",",
  },
  {
    title: "Privacy Policy",
    link: "/privacy-policy",
    followingText: "&",
  },
  {
    title: "Return & Refund Policy",
    link: "/shipping-and-refund-policy",
  },
];
