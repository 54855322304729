import { PaymentTerms, Tooltip } from "@/components/data-display";

import PrimaryBtn from "../primaryBtn";

export function PayNowBtn({
  isOrderCreating,
  createOrderRequest,
  isPayementTermsChecked,
  setPayementTermsChecked,
  isPaymentRequirementsChecked,
}) {
  const paymentTooltipContent = `To complete your order, please accept the Terms of Use, Privacy Policy, and Return & Refund Policy.`;
  const btnProps = {
    btnText: "Confirm Order",
    className: "h-[3rem] w-full lg:h-[3.5rem]",
    isLoading: isOrderCreating,
    onClick: createOrderRequest,
  };

  return (
    <>
      <PaymentTerms
        isPayementTermsChecked={isPayementTermsChecked}
        setPayementTermsChecked={setPayementTermsChecked}
      />
      {!isPaymentRequirementsChecked ? (
        <Tooltip content={<p>{paymentTooltipContent}</p>}>
          <PrimaryBtn isDisabled {...btnProps} />
        </Tooltip>
      ) : (
        <PrimaryBtn {...btnProps} />
      )}
    </>
  );
}
