import PropTypes from "prop-types";

import { TickMarkIcon } from "@/components/data-display";

import PrimaryBtn from "../../button/primaryBtn";

const QuestionSubmitted = ({ onHide }) => {
  return (
    <div className="my-6 flex flex-col items-center justify-center gap-4 text-center">
      <TickMarkIcon className="mx-auto" />

      <p className="my-3 max-w-[26rem] text-[2rem] font-semibold text-primary">
        Your Question is Submitted Successfully!
      </p>

      <p className="max-w-[28rem]">
        We will review your question and answer your question with the next few
        hours
      </p>

      <PrimaryBtn
        onClick={onHide}
        btnText="Continue Shopping"
        className="my-4 h-[3.125rem] w-[18.75rem]"
      />
    </div>
  );
};

QuestionSubmitted.propTypes = {
  onHide: PropTypes.func,
};

export default QuestionSubmitted;
