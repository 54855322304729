export const genderOptions = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
];

export const deliveryOptions = [
  {
    value: "home_delivery",
    label: "Home Delivery",
    name: "deliveryMethod",
  },
  {
    value: "express_delivery",
    label: "Express Delivery",
    name: "deliveryMethod",
  },
  {
    value: "office_pickup",
    label: "Office Pickup",
    name: "deliveryMethod",
  },
];

export const orderCancelReasonOptions = [
  {
    id: "unsatisfactory-return-policy",
    label: "Unsatisfactory Return Policy",
    value: "unsatisfactory_return_policy",
  },
  {
    id: "change-of-mind",
    label: "Change of Mind",
    value: "change_of_mind",
  },
  {
    id: "high-delivery-charge",
    label: "High Delivery Charge",
    value: "high_delivery_charge",
  },
  {
    id: "expensive-shipping-and-service-charges",
    label: "Expensive Shipping and Service Charges",
    value: "expensive_shipping_and_service_charges",
  },
  {
    id: "long-delivery-time",
    label: "Long Delivery Time",
    value: "long_delivery_time",
  },
  {
    id: "lack-of-payment-options",
    label: "Lack of Payment Options",
    value: "lack_of_payment_options",
  },
  {
    id: "others",
    label: "Others",
    value: "others",
  },
];
