import Link from "next/link";
import { useRouter } from "next/router";

import classNames from "classnames";
import PropTypes from "prop-types";

function LinkList({ data, title, className, ...props }) {
  const { pathname } = useRouter();

  return (
    <div className={className ? className : ""}>
      {title && (
        <p className="mb-[1.375rem] text-[1rem] font-semibold leading-[1.125rem] text-grayDisplay-700">
          {title}
        </p>
      )}

      <ul className="flex h-full flex-col gap-[1rem] overflow-hidden text-[0.938rem] leading-[1.125rem] text-grayDisplay-400">
        {data?.map(({ link, title }) => (
          <li
            key={link}
            className={classNames(
              {
                "text-primary": pathname === link,
              },
              "max-w-[9.375rem] hover:text-primary"
            )}
          >
            <Link href={link} {...props}>
              {title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

LinkList.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string,
  className: PropTypes.string,
};

export default LinkList;
