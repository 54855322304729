import { useEffect, useRef, useState } from "react";

import Link from "next/link";

import PropTypes from "prop-types";

import { RobodocLogoIcon } from "@/components/data-display/icon";

import { ExtraMenu } from "./ExtraMenu";
import MegaDropDown from "./MegaDropDown";

function MenuBar({ menus }) {
  const [isLogoHidden, setIsLogoHidden] = useState(true);

  const navRef = useRef(null);

  const toggleLogo = ([e]) => {
    setIsLogoHidden(!(e.intersectionRatio < 1));
  };

  useEffect(() => {
    const observer = new IntersectionObserver(toggleLogo, {
      threshold: [1],
    });
    const current = navRef.current;

    if (navRef.current) {
      observer.observe(navRef.current);

      return () => {
        observer.unobserve(current);
      };
    }
  }, [isLogoHidden]);

  return (
    <nav ref={navRef} className="hidden bg-white pb-[1rem] pt-[2rem] lg:block">
      <div className="container flex items-center justify-between">
        {!isLogoHidden && (
          <Link href="/">
            <RobodocLogoIcon className="h-[2rem] w-[7.333rem] xl:h-[3rem] xl:w-[11rem]" />
          </Link>
        )}

        {menus.length > 0 &&
          menus.map((menu, index) => {
            return (
              <MegaDropDown
                isLogoHidden={isLogoHidden}
                menuData={menu}
                key={menu.slug}
                position={index}
              />
            );
          })}

        {menus.length > 0 && <ExtraMenu isLogoHidden={isLogoHidden} />}
      </div>
    </nav>
  );
}

MenuBar.propTypes = {
  menus: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      description: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.oneOf([null]),
      ]),
      logo: PropTypes.string,
      banner: PropTypes.string,
      menus: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          slug: PropTypes.string.isRequired,
          description: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.oneOf([null]),
          ]),
          logo: PropTypes.string,
          banner: PropTypes.string,
          full_path: PropTypes.string,
        })
      ),
      full_path: PropTypes.string,
    })
  ).isRequired,
};

export default MenuBar;
