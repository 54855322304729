import classNames from "classnames";
import PropTypes from "prop-types";

import { InlineLoaderIcon } from "@/components/data-display/icon";

function TertiaryBtn({ onClick, btnText, className, isLoading, isDisabled }) {
  return (
    <button
      className={classNames(
        {
          "border-secondary text-disable-text": isDisabled,
          "border-tertiary-400  text-tertiary transition-colors duration-500 hover:border-tertiary-600 hover:text-tertiary-900 active:border-tertiary-600 active:text-tertiary-900":
            !isDisabled,
        },
        `relative rounded-[10px] border-[1px] bg-white text-[1.125rem] font-semibold tracking-normal ${className}`
      )}
      disabled={isDisabled || isLoading}
      onClick={onClick}
    >
      {isLoading && (
        <InlineLoaderIcon className="absolute left-[calc(50%_-_1.5rem)] top-[calc(50%_-_1.5rem)] inline-block h-[3rem] w-[3rem]" />
      )}
      <p
        className={classNames(
          {
            invisible: isLoading,
            visible: !isLoading,
          },
          "inline-block"
        )}
      >
        {btnText}
      </p>
    </button>
  );
}

TertiaryBtn.propTypes = {
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  btnText: PropTypes.string,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
};

TertiaryBtn.defaultProps = {
  isLoading: false,
  isDisabled: false,
};

export default TertiaryBtn;
