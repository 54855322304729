import Link from "next/link";

import DirectionIcon from "@/icons/direction.svg";
import { RobodocInfo } from "@/utils/constants";

function GetDirectionButton({
  btnText = "Get Direction",
  directionLink = RobodocInfo.pickupAddressUrl,
}) {
  return (
    <Link target="_blank" href={directionLink}>
      <button className="flex h-[2.75rem] w-[10.375rem] items-center justify-center space-x-[0.625rem] rounded-[0.625rem] bg-grayDisplay-600">
        <DirectionIcon />
        <p className="font-medium text-grayDisplay-700">{btnText}</p>
      </button>
    </Link>
  );
}

export default GetDirectionButton;
