import PropTypes from "prop-types";

import { PaymentMethod } from "../../PaymentMethod";

export const PaymentInfoCard = ({
  dateTime,
  transactionID,
  paymentMethod,
  paymentUrl,
  paymentVisible,
}) => {
  return (
    <div className="rounded-[0.75rem] border border-[#EBEEF2]">
      <div className="border-b-1 rounded-t-[0.75rem] border-[#EBEEF2] p-5">
        <p className="text-[1.125rem] font-semibold leading-[1.75rem] text-grayDisplay-900">
          Payment Information
        </p>
      </div>

      <div className="border-b border-[#EBEEF2]" />

      <div className="p-5">
        <PaymentMethod
          paymentUrl={paymentUrl}
          paymentMethod={paymentMethod}
          paymentVisible={paymentVisible}
        />

        {transactionID && (
          <div className="mt-4 flex items-center justify-between gap-2 lg:gap-4">
            <p className="min-w-[6.875rem] break-keep text-sm text-[#414E5F]">
              Transaction ID
            </p>
            <p className="break-all font-medium">{transactionID}</p>
          </div>
        )}

        {dateTime && (
          <div className="mt-4 flex items-center justify-between gap-2 lg:gap-4">
            <p className="min-w-[6.875rem] text-sm text-[#414E5F]">
              Date & Time
            </p>
            <p className="break-all font-medium">{dateTime}</p>
          </div>
        )}
      </div>
    </div>
  );
};

PaymentInfoCard.propTypes = {
  dateTime: PropTypes.string,
  transactionID: PropTypes.string,
  paymentMethod: PropTypes.string,
};
