function FooterBottom() {
  const currentYear = new Date().getFullYear();

  return (
    <div>
      <div className="mx-auto border-t border-t-grayDisplay-100 xl:w-[90%]"></div>
      <div className="container flex flex-col items-center gap-[2.098rem] py-[2.063rem] md:flex-row md:justify-center">
        <div className="text-[0.875rem] leading-[1.063rem] text-grayDisplay-700">
          <span className="font-bold">RoboDoc&nbsp;</span>
          <span className="font-normal">
            © {currentYear}. All Rights Reserved.
          </span>
        </div>
      </div>
    </div>
  );
}

export default FooterBottom;
