import * as Yup from "yup";

export const newUserSchema = Yup.object({
  full_name: Yup.string()
    .trim()
    .required("Full Name is a required field")
    .max(30, "Full Name must be at most 30 characters")
    .min(3, "Full Name must be at least 3 characters"),
  email: Yup.string().email().required(),
});
