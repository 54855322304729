import { useState } from "react";

import { useSession } from "next-auth/react";

import { ProfileMenu } from "@/components/actions";
import AuthDropdown from "@/components/actions/dropdown/auth";
import ProfileDropdown from "@/components/actions/dropdown/profileDropdown";
import { AccountIcon } from "@/components/data-display/icon";
import Tooltip from "@/components/data-display/tooltip";
import useViewport from "@/hooks/useViewport";

export const AccountMenu = () => {
  const [isProfileMenu, setProfileMenu] = useState(false);
  const [tooltipInstance, setTooptipInstance] = useState(null);

  const { data: session } = useSession();
  const { width: windowWidth } = useViewport();

  const isMobile = windowWidth <= 768;
  const isAuthenticated = !!session?.user?.token;

  return (
    <>
      {!isMobile ? (
        <Tooltip
          placement="bottom-end"
          content={
            isAuthenticated ? (
              <ProfileDropdown hideTooltip={() => tooltipInstance?.hide()} />
            ) : (
              <AuthDropdown hideTooltip={() => tooltipInstance?.hide()} />
            )
          }
          hideElement={(instance) => setTooptipInstance(instance)}
        >
          <AccountIcon className="mb-1 h-[2.125rem] w-[2.125rem] md:mb-0 md:h-[2.425rem] md:w-[2.425rem] lg:h-[2.625rem] lg:w-[2.625rem]" />
        </Tooltip>
      ) : !isAuthenticated ? (
        <Tooltip
          placement="bottom-end"
          content={<AuthDropdown hideTooltip={() => tooltipInstance?.hide()} />}
        >
          <AccountIcon className="mb-1 h-[2.125rem] w-[2.125rem] md:mb-0 md:h-[2.425rem] md:w-[2.425rem] lg:h-[2.625rem] lg:w-[2.625rem]" />
        </Tooltip>
      ) : (
        <span onClick={() => setProfileMenu(!isProfileMenu)}>
          <AccountIcon className="mb-1 h-[2.125rem] w-[2.125rem] md:mb-0 md:h-[2.425rem] md:w-[2.425rem] lg:h-[2.625rem] lg:w-[2.625rem]" />
        </span>
      )}

      <ProfileMenu
        isActive={isProfileMenu}
        onHide={() => setProfileMenu(false)}
      />
    </>
  );
};
