import Image from "next/image";

function SSLCommerzBanner() {
  return (
    <>
      <div className="hidden lg:block">
        <Image
          width={5011}
          height={587}
          alt="ssl-commerz"
          src="/assets/images/sslcommerz-desktop.png"
          quality={40}
        />
      </div>
      <div className="hidden md:block lg:hidden">
        <Image
          height={844}
          width={3751}
          alt="ssl-commerz"
          src="/assets/images/sslcommerz-tab.png"
          quality={40}
        />
      </div>

      <div className="md:hidden">
        <Image
          width={1058}
          height={2448}
          alt="ssl-commerz"
          src="/assets/images/sslcommerz-mobile.png"
          quality={40}
        />
      </div>
    </>
  );
}

export default SSLCommerzBanner;
