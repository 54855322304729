import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cartProductCount: 0,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    updateCartItemCount: (state, action) => {
      state.cartProductCount = Math.ceil(action.payload);
    },
  },
});

export default cartSlice.reducer;

export const { updateCartItemCount } = cartSlice.actions;
export const getCartState = (state) => state.cart;
