import { useRouter } from "next/router";

import PropTypes from "prop-types";

import { StarCelebrationIcon } from "@/components/data-display";

import PrimaryBtn from "../../button/primaryBtn";
import { CenteredModal } from "../CenteredModal";

export function SubscriptionModal({ isActive, onHide }) {
  const router = useRouter();

  const redirectAndCloseModal = () => {
    onHide();
    router.push("/");
  };

  return (
    <CenteredModal isOpen={isActive} onOpenChange={onHide}>
      <CenteredModal.Content>
        <div className="flex flex-col items-center pt-[3rem]">
          <StarCelebrationIcon className="h-[7rem] w-[6rem]" />
          <p className="mb-[1.125rem] mt-[1.5rem] w-[17.375rem] text-center text-[2rem] font-semibold text-primary lg:w-full">
            Thank you for your Subscriptions!
          </p>
          <p className="mb-[1.125rem] text-center text-[1.063rem]">
            You will receive news for new product, exclusive offers, <br />
            and limited time promotions
          </p>

          <PrimaryBtn
            btnText="Continue Shopping"
            className="mb-[2.7rem] h-[3.5rem] w-[18.75rem] lg:mb-[5rem]"
            onClick={redirectAndCloseModal}
          />
        </div>
      </CenteredModal.Content>
    </CenteredModal>
  );
}

SubscriptionModal.propTypes = {
  isActive: PropTypes.bool,
  onHide: PropTypes.func,
};
