import classNames from "classnames";
import PropTypes from "prop-types";

import { InlineLoaderIcon } from "@/components/data-display/icon";

function QuaternaryBtn({ onClick, btnText, className, isLoading, isDisabled }) {
  return (
    <button
      className={classNames(
        {
          "border-secondary text-disable-text": isDisabled,
          "border-quaternary bg-white text-quaternary transition-colors duration-500 hover:border-quaternary-600  hover:bg-quaternary-100  active:border-quaternary-600 active:bg-quaternary-100 ":
            !isDisabled,
        },
        `relative rounded-[10px] border-[1px] text-[1.125rem] font-semibold tracking-normal ${className}`
      )}
      disabled={isDisabled || isLoading}
      onClick={onClick}
    >
      {isLoading && (
        <InlineLoaderIcon className="absolute left-[calc(50%_-_1.5rem)] top-[calc(50%_-_1.5rem)] inline-block h-[3rem] w-[3rem]" />
      )}
      <p
        className={classNames(
          {
            invisible: isLoading,
            visible: !isLoading,
          },
          "inline-block"
        )}
      >
        {btnText}
      </p>
    </button>
  );
}

QuaternaryBtn.propTypes = {
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  btnText: PropTypes.string,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
};

QuaternaryBtn.defaultProps = {
  isLoading: false,
  isDisabled: false,
};

export default QuaternaryBtn;
