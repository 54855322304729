import { getSession } from "next-auth/react";

import axios from "axios";

export const mockApiClient = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_BASE_MOCK_URL,

  timeout: 20000,

  headers: {
    "X-Requested-With": "XMLHttpRequest",
  },
});

mockApiClient.interceptors.request.use(
  async function (config) {
    if (typeof window !== "undefined") {
      const session = await getSession();

      if (session) {
        config.headers.Authorization = `Bearer ${session?.user?.token}`;
      }
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

mockApiClient.interceptors.response.use(
  function (response) {
    return {
      data: response.data,
      status: response.status,
      statusText: response.statusText,
    };
  },
  function (error) {
    /*
      TODO: clear token if status code is unauthorize
    */
    return Promise.reject(error);
  }
);
