import PropTypes from "prop-types";

import PrimaryBtn from "../../button/primaryBtn";
import SecondaryBtn from "../../button/secondaryBtn";
import { CenteredModal } from "../CenteredModal";

export const RemoveModal = ({
  title,
  description,
  requestConfirmation,
  isModalOpen,
  isLoading,
  hideModal,
}) => {
  const confirmRemoval = () => {
    requestConfirmation();
    hideModal();
  };

  return (
    <CenteredModal isOpen={isModalOpen} onOpenChange={hideModal}>
      <CenteredModal.Content title={title}>
        <p className="mt-[1rem] text-[0.875rem] text-grayDisplay-400 md:text-[1rem]">
          {description}
        </p>

        <div className="mt-[2rem] flex justify-between gap-[1rem] md:mt-[2.375rem] md:justify-end md:gap-[1.25rem]">
          <SecondaryBtn
            btnText="Cancel"
            onClick={hideModal}
            isDisabled={isLoading}
            className="w-full py-[0.875rem] md:w-[10rem] md:py-[1rem]"
          />

          <PrimaryBtn
            btnText="Remove"
            isLoading={isLoading}
            isDisabled={isLoading}
            onClick={confirmRemoval}
            className="w-full py-[0.875rem] md:w-[10rem] md:py-[1rem]"
          />
        </div>
      </CenteredModal.Content>
    </CenteredModal>
  );
};

RemoveModal.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  requestConfirmation: PropTypes.func,
  isLoading: PropTypes.bool,
  isModalOpen: PropTypes.bool,
  hideModal: PropTypes.func,
};
