import { apiClient } from "@/services/api-client";

export const getCategoryMenuApi = (params) => {
  return apiClient({
    method: "GET",
    url: `/v1/categories`,
    params,
  });
};

export const getCategoryDataApi = ({ slug, page, sort_by }) => {
  const queries = { page, sort_by };

  return apiClient({
    url: `/v1/categories/${slug}`,
    method: "GET",
    params: queries,
  });
};
