import PropTypes from "prop-types";

import { CartSidebarIcon } from "@/components/data-display/icon";

function StickyCartButton({ cartProductCount, onStickyCartClick }) {
  return (
    <div className="fixed right-[1rem] top-[45%] z-20">
      <button onClick={onStickyCartClick}>
        <div className="relative flex h-[3.25rem] w-[3.25rem] items-center justify-center rounded-[2.25rem] bg-white p-[1.125rem] shadow-cart hover:bg-[#EFFFF4] active:bg-[#EFFFF4] lg:h-[4rem] lg:w-[4rem]">
          {cartProductCount > 0 && (
            <span className="absolute -right-1 -top-1 flex h-[1.375rem] w-[1.375rem] items-center justify-center rounded-full bg-tertiary-400 text-center text-[0.688rem] leading-none text-white lg:-right-2 lg:-top-2 lg:h-[1.75rem] lg:w-[1.75rem] lg:text-[0.813rem]">
              {cartProductCount > 99 ? "99+" : cartProductCount}
            </span>
          )}
          <CartSidebarIcon />
        </div>
      </button>
    </div>
  );
}

StickyCartButton.propTypes = {
  onStickyCartClick: PropTypes.func,
};

export default StickyCartButton;
