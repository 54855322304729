const cartSidebarVisiblePaths = [
  "/",
  "/category",
  "/search",
  "/product",
  "/brands",
  "/new-products",
  "/offers",
  "/popular-products",
  "/special-products",
  "/today-deals",
];

const getBaseStaticPathname = (pathname) => {
  if (pathname.includes("[")) {
    return pathname.split("/[")[0];
  }

  return pathname;
};

export const isCartSidebarVisible = (pathname) => {
  return cartSidebarVisiblePaths.includes(getBaseStaticPathname(pathname));
};
