export function hideMainScrollbar({ isActive, delay = 0 }) {
  if (typeof window !== "undefined") {
    if (isActive) {
      document.querySelector("body").classList.add("overflow-hidden");
      document.querySelector("body").classList.add("lg:mr-[17px]");
    } else {
      setTimeout(() => {
        showMainScrollbar();
      }, delay);
    }
  }
}

export function showMainScrollbar() {
  document.querySelector("body").classList.remove("overflow-hidden");
  document.querySelector("body").classList.remove("lg:mr-[17px]");
}
