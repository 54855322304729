import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
// optional
import "tippy.js/themes/light.css";

function Tooltip({
  children,
  placement,
  content,
  hideElement,
  trigger = true,
  onClickOutside,
}) {
  return (
    <Tippy
      trigger={trigger}
      interactive
      placement={placement}
      content={content}
      theme="light"
      onClickOutside={onClickOutside}
      hideOnClick={true}
      onShow={(instance) => hideElement(instance)}
    >
      <div>{children}</div>
    </Tippy>
  );
}

Tooltip.defaultProps = {
  placement: "top",
  trigger: "mouseenter",
  hideElement: () => null,
};

export default Tooltip;
