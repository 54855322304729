import { useDispatch } from "react-redux";

import { useMutation, useQuery } from "@tanstack/react-query";

import {
  addAllWishListToCartApi,
  addWishListApi,
  getWishListApi,
  removeWishListApi,
} from "@/api/end-points/wishlist";
import {
  addWishlistItem,
  removeWishlistItem,
} from "@/store/feature/wishlistSlice";

export const useGetWishListData = (useQueryOptions) => {
  return useQuery({
    queryKey: ["wishlist"],
    queryFn: getWishListApi,
    ...useQueryOptions,
    select: (wishlistResponse) => {
      return wishlistResponse.data?.data;
    },
  });
};

export const useAddWishlistData = (useQueryOptions) => {
  const dispatch = useDispatch();
  const { onSuccess = () => {}, onError = () => {} } = useQueryOptions || {};
  return useMutation(addWishListApi, {
    onSuccess: (res, payload) => {
      dispatch(addWishlistItem(payload.product));
      onSuccess({
        status: res.status,
        message: "Added to wishlist successfully",
        wishlist: res.data.data?.wishlist,
      });
    },
    onError: (error) => {
      onError({
        status: error.response.status,
        data: error.response.data,
        message: error.response.data.errors[0].message,
      });
    },
  });
};

export const useRemoveWishlistData = (useQueryOptions) => {
  const dispatch = useDispatch();
  const { onSuccess = () => {}, onError = () => {} } = useQueryOptions || {};

  return useMutation(removeWishListApi, {
    onSuccess: (res, payload) => {
      dispatch(removeWishlistItem(payload.product));
      onSuccess({
        status: res.status,
        message: res.data?.message || "Removed from wishlist successfully ",
        wishlist: res.data.data?.wishlist,
      });
    },
    onError: (error) => {
      onError({
        status: error.response.status,
        data: error.response.data,
        message: error.response.data.errors[0].message,
      });
    },
  });
};

export const useAddAllWishlistToCart = (useQueryOptions) => {
  const { onSuccess = () => {}, onError = () => {} } = useQueryOptions || {};

  return useMutation(addAllWishListToCartApi, {
    onSuccess: (res) => {
      onSuccess({
        status: res.status,
        message: res.data?.message || "All products added to cart",
      });
    },
    onError: (error) => {
      onError({
        status: error.response.status,
        data: error.response.data,
        message: error.response.data.errors[0].message,
      });
    },
  });
};
