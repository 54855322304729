import PropTypes from "prop-types";

import AppRadioInput from "@/components/data-input/radio-input";

import { GenericModal } from "../GenericModal";

export function SortModal({ sortData, onChange, value, isOpen, onHide }) {
  return (
    <GenericModal isOpen={isOpen} onOpenChange={onHide}>
      <GenericModal.Content title="Sort By">
        <div className="mt-9 flex flex-col gap-3">
          {sortData?.map((option) => (
            <AppRadioInput
              key={option?.value}
              id={option.value}
              labelName={option?.label}
              value={option.value}
              name="sort"
              onChange={onChange}
              isChecked={value?.value === option.value}
            />
          ))}
        </div>
      </GenericModal.Content>
    </GenericModal>
  );
}

SortModal.propTypes = {
  sortData: PropTypes.array,
  onChange: PropTypes.func,
  onHide: PropTypes.func,
  value: PropTypes.object,
  isOpen: PropTypes.bool,
};
