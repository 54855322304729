import PropTypes from "prop-types";

import { StarCelebrationIcon } from "@/components/data-display";

import PrimaryBtn from "../../button/primaryBtn";
import { CenteredModal } from "../CenteredModal";

export const ContactSubmitSuccessModal = ({ isModalOpen, hideModal }) => {
  return (
    <CenteredModal isOpen={isModalOpen} onOpenChange={hideModal}>
      <CenteredModal.Content>
        <div className="flex flex-col items-center pt-[3rem]">
          <StarCelebrationIcon className="h-[7rem] w-[6rem]" />
          <h1 className="mb-[1.125rem] mt-[1.5rem] w-[17.375rem] text-center text-[2rem] font-semibold text-primary lg:w-full">
            Thank You for your message!
          </h1>
          <p className="mb-[1.125rem] text-center text-[1.063rem]">
            We will get back to you real soon. Thanks for contacting us!
          </p>

          <PrimaryBtn
            btnText="Ok"
            className="mb-[2.7rem] h-[3.5rem] w-[18.75rem] lg:mb-[5rem]"
            onClick={hideModal}
          />
        </div>
      </CenteredModal.Content>
    </CenteredModal>
  );
};

ContactSubmitSuccessModal.propTypes = {
  isModalOpen: PropTypes.bool,
  hideModal: PropTypes.func,
};
