import { useEffect, useState } from "react";

import PropTypes from "prop-types";

import { useUpdateCartAddress } from "@/api/client/cart";
import AddressLabel from "@/components/data-display/label/addressLabel";
import AppRadioInput from "@/components/data-input/radio-input";
import { errorToast, successToast } from "@/components/feedback/toast";

import PrimaryBtn from "../../button/primaryBtn";
import SecondaryBtn from "../../button/secondaryBtn";
import { GenericModal } from "../GenericModal";

export function SavedAddressModal({
  title,
  shippingAddresses,
  cartAddress,
  refetchCartData,
  isModalOpen,
  hideModal,
}) {
  const [selectedAddressId, setSelectedAddressId] = useState(cartAddress?.uuid);

  const { isLoading: isCartAddressUpdating, mutate: updateCartAddress } =
    useUpdateCartAddress({
      onSuccess: () => {
        successToast({ message: "Cart address updated successfully" });
        refetchCartData();
      },
      onError: ({ message }) => {
        errorToast({ message });
      },
    });

  const onSelectAddress = (addressId) => {
    setSelectedAddressId(addressId);
  };

  const onUpdateCartAddress = (e) => {
    e.preventDefault();
    if (selectedAddressId === cartAddress?.uuid) {
      errorToast({ message: "It is already current cart address!" });
      return;
    }
    updateCartAddress({ address: selectedAddressId });
    hideModal();
  };

  const onCancel = () => {
    setSelectedAddressId(cartAddress?.uuid);
    hideModal();
  };

  useEffect(() => {
    setSelectedAddressId(cartAddress?.uuid);
  }, [cartAddress]);

  return (
    <GenericModal isOpen={isModalOpen} onOpenChange={hideModal}>
      <GenericModal.Content
        title={title}
        className="max-h-[80%] lg:!max-w-[46rem] xl:!max-w-[72rem]"
      >
        <div className="w-full">
          <div className="mb-[1.5rem] hidden grid-cols-[1.5fr_2fr_3fr_2fr_1fr] justify-between gap-[1rem] bg-[#F1F3F6]/50 px-[1.5rem] py-[0.813rem] text-[0.875rem] font-medium leading-[1.5rem] text-grayDisplay-400 md:mt-[1.5rem] md:grid">
            <p>Label</p>
            <p>Name</p>
            <p>Address</p>
            <p>Phone Number</p>
            <p></p>
          </div>

          {shippingAddresses?.length === 0 && (
            <h1 className="text-center text-2xl">
              No shipping Address available
            </h1>
          )}

          {shippingAddresses?.length !== 0 && (
            <form
              className="mt-[2.5rem] items-start space-y-[2rem] md:mt-0 md:px-[1.5rem]"
              onSubmit={onUpdateCartAddress}
            >
              {shippingAddresses?.map((address) => (
                <div
                  className="grid items-start gap-[1rem] text-[0.875rem] leading-[1.375rem] text-grayDisplay-900 md:grid-cols-[1.5fr_2fr_3fr_2fr_1fr]"
                  key={address.uuid}
                >
                  <div className="mb-[0.875rem] flex items-center justify-between md:mb-0">
                    <AddressLabel
                      label={address.label}
                      className="h-[1.875rem] w-[5.5rem] rounded-[0.375rem]"
                    />

                    <div className="md:hidden">
                      <AppRadioInput
                        name="saved-address-mobile"
                        value={address.uuid}
                        id={address.uuid}
                        onChange={() => onSelectAddress(address.uuid)}
                        isChecked={address.uuid === selectedAddressId}
                      />
                    </div>
                  </div>
                  <div className="overflow-hidden text-ellipsis">
                    <p>{address?.full_name}</p>
                  </div>
                  <div className="overflow-hidden text-ellipsis">
                    <p className="break-all">{address?.full_address}</p>
                  </div>
                  <p>{address?.phone_number}</p>
                  <div className="hidden justify-self-end md:block">
                    <AppRadioInput
                      name="saved-address"
                      value={address.uuid}
                      id={address.uuid}
                      onChange={() => onSelectAddress(address.uuid)}
                      isChecked={address.uuid === selectedAddressId}
                    />
                  </div>
                </div>
              ))}
              <div className="flex w-full justify-end pb-[2rem]">
                <div className="flex w-full justify-between md:w-auto md:justify-normal md:space-x-[1.25rem]">
                  <SecondaryBtn
                    btnText="Cancel"
                    className="h-[3rem] w-[7rem] sm:h-[3.5rem] sm:w-[10rem]"
                    onClick={onCancel}
                  />

                  <PrimaryBtn
                    btnText="Save"
                    className="h-[3rem] w-[7rem] sm:h-[3.5rem] sm:w-[10rem]"
                    isLoading={isCartAddressUpdating}
                  />
                </div>
              </div>
            </form>
          )}
        </div>
      </GenericModal.Content>
    </GenericModal>
  );
}

SavedAddressModal.propTypes = {
  shippingAddresses: PropTypes.array,
  cartAddress: PropTypes.object,
  refetchCartData: PropTypes.func,
};
