import { useState } from "react";
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";

import { useUpdateEmail } from "@/api/client/profile";
import AppInput from "@/components/data-input/input-field";
import { errorToast } from "@/components/feedback/toast";
import { emailSchema } from "@/schema/formSchema";

import PrimaryBtn from "../../button/primaryBtn";
import SecondaryBtn from "../../button/secondaryBtn";
import { GenericModal } from "../GenericModal";
import VerifyEmail from "./VerifyEmail";

export function ChangeEmailModal({ isOpen, onHide }) {
  const [isEmailGiven, setIsEmailGiven] = useState(false);

  const {
    reset,
    watch,
    register,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(emailSchema),
  });

  const { isLoading, mutate: updateEmail } = useUpdateEmail({
    onSuccess: () => {
      setIsEmailGiven(true);
    },
    onError: ({ message, status }) => {
      if (status === 422) {
        setError("email", {
          message,
        });
      } else {
        errorToast({ message });
      }
    },
  });

  const handleCloseModal = () => {
    setIsEmailGiven(false);
    reset({ email: "" });
    onHide();
  };

  const onSubmit = (data) => {
    updateEmail(data);
  };

  return (
    <GenericModal isOpen={isOpen} onOpenChange={handleCloseModal}>
      <GenericModal.Content
        title={`${!isEmailGiven ? "Change Email Address" : ""}`}
      >
        {!isEmailGiven ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-[3rem] mt-[6.313rem] md:mb-[7.5rem] md:mt-[7.313rem]">
              <AppInput
                isFieldRequired
                register={register}
                name="email"
                error={errors.email}
                labelName="New Email Address"
                placeholder="Enter email address"
              />
            </div>
            <div className="mb-[3rem] mt-[2.5rem] flex justify-between gap-[1rem] md:justify-end md:gap-[1.25rem]">
              <SecondaryBtn
                btnText="Cancel"
                isDisabled={isLoading}
                onClick={handleCloseModal}
                className="w-full py-[.875rem] md:w-[10rem] md:py-[1rem]"
              />

              <PrimaryBtn
                type="submit"
                btnText="Submit"
                isLoading={isLoading}
                className="w-full py-[.875rem] md:w-[10rem] md:py-[1rem]"
              />
            </div>
          </form>
        ) : (
          <div className="flex flex-col justify-center px-[1.313rem] pb-[3rem] pt-[1.625rem] md:pb-[4.375rem]">
            <VerifyEmail
              emailAddress={watch("email")}
              className="mt-[0.625rem] h-full justify-center"
            />
            <div className="mt-[3.313rem] flex justify-center md:mt-[4rem]">
              <PrimaryBtn
                btnText="Ok"
                onClick={handleCloseModal}
                className="h-[3rem] w-full text-[1rem] leading-[1.625rem] md:h-[3.5rem] md:w-[13.75rem] md:text-[1.125rem]"
              />
            </div>
          </div>
        )}
      </GenericModal.Content>
    </GenericModal>
  );
}
