import { useRef } from "react";
import { useSelector } from "react-redux";

import StickyCartButton from "@/components/actions/button/stickyCartBtn";
import CartSidebar from "@/components/sections/cartSidebar";
import { getCartState } from "@/store/feature/cartSlice";

function CartSidebarWrapper() {
  const cartSidebarRef = useRef(null);

  const { cartProductCount } = useSelector(getCartState);

  const onStickyCartClick = () => {
    cartSidebarRef.current?.makeCartSidebarVisible();
  };

  return (
    <div>
      <StickyCartButton
        cartProductCount={cartProductCount}
        onStickyCartClick={onStickyCartClick}
      />
      <CartSidebar ref={cartSidebarRef} cartProductCount={cartProductCount} />
    </div>
  );
}

export default CartSidebarWrapper;
