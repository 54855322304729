import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isTrackOrder: false,
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    updateTrackOrder: (state, action) => {
      state.isTrackOrder = action.payload;
    },
  },
});

export default orderSlice.reducer;

export const { updateTrackOrder } = orderSlice.actions;
export const getOrderState = (state) => state.order;
