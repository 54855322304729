/* eslint-disable react/display-name */
import { forwardRef, useImperativeHandle } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import { useRouter } from "next/router";

import { yupResolver } from "@hookform/resolvers/yup";

import { usePostOrderTracking } from "@/api/client/order";
import AppInput from "@/components/data-input/input-field";
import { trackingOrderSchema } from "@/schema/formSchema";
import { updateTrackOrder } from "@/store/feature";

import PrimaryBtn from "../../button/primaryBtn";
import SecondaryBtn from "../../button/secondaryBtn";

export const TrackOrderForm = forwardRef(
  ({ isModal, onCloseFormRequest }, ref) => {
    const router = useRouter();
    const dispatch = useDispatch();

    const {
      reset,
      register,
      setError,
      handleSubmit,
      formState: { errors },
    } = useForm({
      mode: "onBlur",
      reValidateMode: "onBlur",
      resolver: yupResolver(trackingOrderSchema),
    });

    const { mutate: orderTracker, isLoading: isTrackOrderLoading } =
      usePostOrderTracking({
        onSuccess: ({ data }) => {
          reset();
          router.push(`/my-orders/${data.order_key}`);
          handleHideModal();
        },
        onError: ({ message }) => {
          setError("order", {
            type: "custom",
            message: message,
          });
        },
      });

    const handleOrderTrack = (data) => {
      orderTracker({
        tracking_number: data.order,
      });
    };

    useImperativeHandle(ref, () => ({
      formReset: () => {
        reset();
      },
    }));

    const handleHideModal = () => {
      reset();
      dispatch(updateTrackOrder(false));
    };

    const redirectToFaqDesktop = () => {
      reset();
      onCloseFormRequest(reset);
      router.push("/faq");
    };

    const redirectToFaqMobile = () => {
      handleHideModal();
      router.push("/faq");
    };

    return (
      <div className="w-full min-w-[19rem] cursor-default md:min-w-[14.5rem] md:px-[0.7rem] md:py-[0.85rem]">
        <form
          onSubmit={handleSubmit(handleOrderTrack)}
          className={isModal ? "mt-[2rem]" : "flex gap-4"}
        >
          <AppInput
            name="order"
            register={register}
            labelName="Order Id"
            error={errors.order}
            className="h-[3rem]"
            placeholder="Your Order Id"
          />

          {isModal ? (
            <>
              <div className="mt-2">
                <p
                  onClick={redirectToFaqMobile}
                  className="ml-auto mt-[0.85rem] cursor-pointer text-right text-primary underline"
                >
                  Need help?
                </p>
              </div>

              <div className="mt-[2rem] flex gap-4 ">
                <SecondaryBtn
                  type="button"
                  btnText="Cancel"
                  onClick={handleHideModal}
                  className="block h-[3rem] w-full text-[1rem]"
                />

                <PrimaryBtn
                  type="submit"
                  btnText="Order Track"
                  isLoading={isTrackOrderLoading}
                  className="block h-[3rem] w-full text-[1rem]"
                />
              </div>
            </>
          ) : (
            <PrimaryBtn
              type="submit"
              btnText="Order Track"
              className="block h-[3rem] w-full text-[1rem] md:mt-[2.2rem] md:w-[10rem]"
              isLoading={isTrackOrderLoading}
            />
          )}
        </form>

        {!isModal && (
          <p
            onClick={redirectToFaqDesktop}
            className="my-[0.5rem] inline-block cursor-pointer text-right text-primary underline"
          >
            Need help?
          </p>
        )}
      </div>
    );
  }
);

TrackOrderForm.defaultProps = {
  isModal: false,
};
