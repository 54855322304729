import { useState } from "react";
import { useForm } from "react-hook-form";

import { useSession } from "next-auth/react";

import { yupResolver } from "@hookform/resolvers/yup";

import {
  useUpdatePhoneNumber,
  useVerifyPhoneNumber,
} from "@/api/client/profile";
import AppInput from "@/components/data-input/input-field";
import Otp from "@/components/data-input/otp";
import { errorToast, successToast } from "@/components/feedback/toast";
import { phoneSchema } from "@/schema/formSchema";

import PrimaryBtn from "../../button/primaryBtn";
import SecondaryBtn from "../../button/secondaryBtn";
import { GenericModal } from "../GenericModal";

export function ChangePhoneModal({ isOpen, onHide, setPhoneNumber }) {
  const [isPhoneGiven, setIsPhoneGiven] = useState(false);
  const [signature, setSignature] = useState(null);

  const { update: updateSession } = useSession();

  const {
    reset,
    watch,
    register,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(phoneSchema),
  });

  const { isLoading: isUpdateLoading, mutate: updatePhoneNumber } =
    useUpdatePhoneNumber({
      onSuccess: (data) => {
        setSignature(data.data.signature);
        setIsPhoneGiven(true);
      },
      onError: ({ message, status }) => {
        if (status === 422) {
          setError("phone", { message });
        } else {
          errorToast({ message });
        }
      },
    });

  const { isLoading: isVerifyLoading, mutate: verifyPhoneNumber } =
    useVerifyPhoneNumber({
      onSuccess: (data) => {
        setPhoneNumber("phone_number", watch("phone"));
        updateSession({
          phone: data.phone_number,
        });
        handleCloseModal();
        successToast({ message: "Phone number successfully updated" });
      },
      onError: ({ message }) => {
        errorToast({ message });
      },
    });

  const handleCloseModal = () => {
    setIsPhoneGiven(false);
    reset({ phone: "" });
    onHide();
  };

  const onSubmit = (data) => {
    updatePhoneNumber(data);
  };

  const handleVerifyOtp = (value) => {
    if (value?.length !== 6) {
      errorToast({ message: "Otp input can not be empty" });
      return;
    }
    const payload = { phone: watch("phone"), otp: value, signature };
    verifyPhoneNumber(payload);
  };

  return (
    <GenericModal isOpen={isOpen} onOpenChange={handleCloseModal}>
      <GenericModal.Content
        title={`${!isPhoneGiven ? "Change Phone Number" : ""}`}
      >
        {!isPhoneGiven ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-[3rem] mt-[6.313rem] md:mb-[7.5rem] md:mt-[7.313rem]">
              <AppInput
                name="phone"
                isFieldRequired
                register={register}
                error={errors.phone}
                labelName="New Phone Number"
                placeholder="Enter phone number"
              />
            </div>
            <div className="mb-[3rem] mt-[2.5rem] flex justify-between gap-[1rem] md:justify-end md:gap-[1.25rem]">
              <SecondaryBtn
                btnText="Cancel"
                onClick={handleCloseModal}
                isDisabled={isUpdateLoading}
                className="w-full py-[0.875rem] md:w-[10rem] md:py-[1rem]"
              />

              <PrimaryBtn
                btnText="Submit"
                isLoading={isUpdateLoading}
                className="w-full py-[.875rem] md:w-[10rem] md:py-[1rem]"
              />
            </div>
          </form>
        ) : (
          <div className="flex flex-col justify-center pb-[3rem]">
            <Otp
              isLoading={isVerifyLoading}
              submitOtp={handleVerifyOtp}
              phoneNumber={watch("phone")}
              updatePhoneNumber={updatePhoneNumber}
              changePhoneHandler={() => setIsPhoneGiven(false)}
            />
          </div>
        )}
      </GenericModal.Content>
    </GenericModal>
  );
}
