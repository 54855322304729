import Image from "next/image";

function EmptyCart() {
  return (
    <div className="flex flex-col items-center justify-center gap-[1.25rem]">
      <Image
        src="/assets/images/cart-empty.png"
        height={169}
        width={151}
        alt="Empty Cart"
      />

      <p className="max-w-[16.875rem] text-center text-[0.875rem] leading-[1.25rem] text-grayDisplay-300">
        Your cart is empty! Start shopping
      </p>
    </div>
  );
}

export default EmptyCart;
