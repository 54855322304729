import PropTypes from "prop-types";

import { BkashIcon, HandCashIcon, SSLCommerzIcon } from "../icon";
import { CompletePayment } from "./CompletePayment";
import { PaymentMethodBlock } from "./PaymentMethodBlock";

const paymentMethodComponents = {
  cash_on_delivery: (
    <PaymentMethodBlock
      title="Cash On Delivery"
      icon={<HandCashIcon width={28} height={22} />}
    />
  ),
  bkash: <PaymentMethodBlock icon={<BkashIcon height={30} width={40} />} />,
  ssl_commerz: <PaymentMethodBlock icon={<SSLCommerzIcon />} />,
};

export const PaymentMethod = ({
  paymentUrl,
  paymentMethod,
  paymentVisible,
}) => {
  return (
    <>
      {paymentVisible ? (
        <CompletePayment url={paymentUrl} />
      ) : (
        paymentMethodComponents[paymentMethod]
      )}
    </>
  );
};

PaymentMethod.propTypes = {
  paymentUrl: PropTypes.string,
  paymentVisible: PropTypes.bool,
  paymentMethod: PropTypes.string,
};
