export const getProductStockText = (stock) => {
  const maxAvailabilityToShow = 20;
  const dangerStockAmount = 10;

  if (stock === 0) {
    return <p className="font-semibold text-error-color">Out of Stock</p>;
  } else if (stock > maxAvailabilityToShow) {
    return <p className="font-semibold text-primary">Available</p>;
  } else if (stock < dangerStockAmount) {
    return (
      <p>
        Availability:&nbsp;
        <span className="font-semibold text-error-color">{stock}</span>
      </p>
    );
  } else {
    return (
      <p>
        Availability:&nbsp;
        <span className="font-semibold text-primary">{stock}</span>
      </p>
    );
  }
};

export const getCartStockText = (stock) => {
  const dangerStockAmount = 10;

  if (stock === 0) {
    return <p className="mt-2 font-medium text-error-color">Out of Stock</p>;
  } else if (stock < dangerStockAmount) {
    return (
      <p className="mt-2 font-medium text-error-color">
        {stock} Qty. Available
      </p>
    );
  }
};
