import Link from "next/link";

import Accordion from "@/components/data-display/accordion";
import SSLCommerzBanner from "@/components/data-display/banner/SSLCommerz";
import { RobodocLogoIcon } from "@/components/data-display/icon";
import {
  aboutLinks,
  customerServiceLinks,
  otherLinks,
} from "@/utils/constants";
import { LayoutFeatureFlags } from "@/utils/feature-flags";

import GetInTouch from "./GetInTouch";
import LinkList from "./LinkList";
import StoreHours from "./StoreHours";

function FooterMiddle() {
  return (
    <div className="container w-full pb-[1rem] pt-[2.25rem] sm:pb-[2.75rem] sm:pt-[2.75rem]">
      <div className="flex flex-col gap-3 text-[0.938rem] md:flex-row md:justify-between lg:gap-4">
        <div className="md:w-[32%] lg:w-[40%] xl:w-[25%]">
          <div className="hidden sm:mb-[1.875rem] sm:inline-block">
            <Link href="/">
              <RobodocLogoIcon />
            </Link>
          </div>

          <div className="hidden sm:block">
            <GetInTouch />
          </div>

          <div className="sm:hidden">
            <div className="mb-[1.354rem]">
              <Accordion
                label="Get in Touch"
                Component={
                  <GetInTouch className="duration-350 transition-all delay-100" />
                }
                isAccordionOpen={true}
              />
            </div>

            <div className="mb-[1.354rem]">
              <Accordion
                label="Other Links"
                Component={
                  <LinkList
                    data={otherLinks}
                    className="duration-350 transition-all delay-100"
                    target="_blank"
                  />
                }
              />
            </div>

            <div className="mb-[1.354rem]">
              <Accordion
                label="Store Hours"
                Component={
                  <StoreHours
                    showTitle={false}
                    className="duration-350 transition-all delay-100"
                  />
                }
              />
            </div>

            <div className="mb-[1.354rem]">
              <Accordion
                label="About"
                Component={
                  <LinkList
                    data={aboutLinks}
                    className="duration-350 transition-all delay-100"
                  />
                }
              />
            </div>

            <div className="mb-[1.354rem]">
              <Accordion
                label="Customer Service"
                Component={
                  <LinkList
                    data={customerServiceLinks}
                    className="duration-350 transition-all delay-100"
                  />
                }
              />
            </div>
          </div>
        </div>

        <div className="hidden sm:my-3 sm:block md:my-0">
          <LinkList
            title="Other Links"
            data={otherLinks}
            className="mb-[2.25rem]"
            target="_blank"
          />

          <StoreHours />
        </div>

        <LinkList title="About" data={aboutLinks} className="hidden sm:block" />

        <LinkList
          title="Customer Service"
          data={customerServiceLinks}
          className="hidden sm:block"
        />
      </div>

      {LayoutFeatureFlags.SSLCommerzBanner && (
        <div className="mt-[2.75rem] md:mt-[3.75rem]">
          <SSLCommerzBanner />
        </div>
      )}
    </div>
  );
}

export default FooterMiddle;
