import { apiClient } from "@/services/api-client";

export const applyCouponApi = (payload) => {
  return apiClient({
    url: `/v1/coupons`,
    method: "POST",
    data: payload,
  });
};

export const removeCouponApi = (payload) => {
  return apiClient({
    url: `v1/coupons`,
    method: "DELETE",
    data: payload,
  });
};
