export const protectedRoutes = [
  "addresses",
  "my-account",
  "my-orders",
  "order-cancel-confirm",
  "order-cancel-success",
  "order-success",
  "payment-info",
  "update-email",
];

export const innovationKitRedirectionUrls = {
  "cholonto-robot-0123973": "product/cholonto-robot-0123973",
  "product-details/cholonto-robot-0123973": "product/cholonto-robot-0123973",
  "product/detail/cholonto-robot-0123973": "product/cholonto-robot-0123973",
  "remote-control-car-0123970": "product/remote-control-car-0123970",
  "product-details/remote-control-car-0123970":
    "product/remote-control-car-0123970",
  "product/detail/remote-control-car-0123970":
    "product/remote-control-car-0123970",
  "robo-frog-0123972": "product/robo-frog-0123972",
  "product-details/robo-frog-0123972": "product/robo-frog-0123972",
  "product/detail/robo-frog-0123972": "product/robo-frog-0123972",
  "torith-rohosso-0123969": "product/torith-rohosso-0123969",
  "product-details/torith-rohosso-0123969": "product/torith-rohosso-0123969",
  "product/detail/torith-rohosso-0123969": "product/torith-rohosso-0123969",
  "racing-car-0123971": "product/racing-car-0123971",
  "product-details/racing-car-0123971": "product/racing-car-0123971",
  "product/detail/racing-car-0123971": "product/racing-car-0123971",
  innovationkit: " ",
};

export const redirectionUrls = {
  "category/arduino": "category/arduino-price-in-bangladesh",
  corporate: " ",
  "product/latest": " ",
  "ic-7400": "product/ic-7400",
  "product-details/ic-7400": "product/ic-7400",
  "product/detail/ic-7400": "product/ic-7400",
  "ic-7402": "product/ic-7402",
  "product-details/ic-7402": "product/ic-7402",
  "product/detail/ic-7402": "product/ic-7402",
  "ic-7404": "product/ic-7404",
  "product-details/ic-7404": "product/ic-7404",
  "product/detail/ic-7404": "product/ic-7404",
  "ic-7408": "product/ic-7408",
  "product-details/ic-7408": "product/ic-7408",
  "product/detail/ic-7408": "product/ic-7408",
  "ic-7410": "product/ic-7410",
  "product-details/ic-7410": "product/ic-7410",
  "product/detail/ic-7410": "product/ic-7410",
  "ic-7432": "product/ic-7432",
  "product-details/ic-7432": "product/ic-7432",
  "product/detail/ic-7432": "product/ic-7432",
  "ic-7486": "product/ic-7486",
  "product-details/ic-7486": "product/ic-7486",
  "product/detail/ic-7486": "product/ic-7486",
  "ic-74151": "product/ic-74151",
  "product-details/ic-74151": "product/ic-74151",
  "product/detail/ic-74151": "product/ic-74151",
  "ic-74153": "product/ic-74153",
  "product-details/ic-74153": "product/ic-74153",
  "product/detail/ic-74153": "product/ic-74153",
  "ic-74138": "product/ic-74138",
  "product-details/ic-74138": "product/ic-74138",
  "product/detail/ic-74138": "product/ic-74138",
  "ic-74238": "product/ic-74238",
  "product-details/ic-74238": "product/ic-74238",
  "product/detail/ic-74238": "product/ic-74238",
  "ic-74595": "product/ic-74595",
  "product-details/ic-74595": "product/ic-74595",
  "product/detail/ic-74595": "product/ic-74595",
  "ic-4073": "product/ic-4073",
  "product-details/ic-4073": "product/ic-4073",
  "product/detail/ic-4073": "product/ic-4073",
  "ic-4075": "product/ic-4075",
  "product-details/ic-4075": "product/ic-4075",
  "product/detail/ic-4075": "product/ic-4075",
  "lm7805-voltage-regulator-5v": "product/lm7805-voltage-regulator-5v",
  "product-details/lm7805-voltage-regulator-5v":
    "product/lm7805-voltage-regulator-5v",
  "product/detail/lm7805-voltage-regulator-5v":
    "product/lm7805-voltage-regulator-5v",
  "9v-battery-pakko": "product/9v-battery-pakko",
  "product-details/9v-battery-pakko": "product/9v-battery-pakko",
  "product/detail/9v-battery-pakko": "product/9v-battery-pakko",
  "9v-battery-new-leader": "product/9v-battery-new-leader",
  "product-details/9v-battery-new-leader": "product/9v-battery-new-leader",
  "product/detail/9v-battery-new-leader": "product/9v-battery-new-leader",
  "9v-battery-connector": "product/9v-battery-connector",
  "product-details/9v-battery-connector": "product/9v-battery-connector",
  "product/detail/9v-battery-connector": "product/9v-battery-connector",
  "1.5v-battery": "product/1.5v-battery",
  "product-details/1.5v-battery": "product/1.5v-battery",
  "product/detail/1.5v-battery": "product/1.5v-battery",
  "ne555-timer-ic": "product/ne555-timer-ic",
  "product-details/ne555-timer-ic": "product/ne555-timer-ic",
  "product/detail/ne555-timer-ic": "product/ne555-timer-ic",
  "jumper-wires-m-to-m-20-pieces": "product/jumper-wires-m-to-m-20-pieces",
  "product-details/jumper-wires-m-to-m-20-pieces":
    "product/jumper-wires-m-to-m-20-pieces",
  "product/detail/jumper-wires-m-to-m-20-pieces":
    "product/jumper-wires-m-to-m-20-pieces",
  "jumper-wires-m-to-f-20-pieces": "product/jumper-wires-m-to-f-20-pieces",
  "product-details/jumper-wires-m-to-f-20-pieces":
    "product/jumper-wires-m-to-f-20-pieces",
  "product/detail/jumper-wires-m-to-f-20-pieces":
    "product/jumper-wires-m-to-f-20-pieces",
  "jumper-wires-f-to-f-20-pieces": "product/jumper-wires-f-to-f-20-pieces",
  "product-details/jumper-wires-f-to-f-20-pieces":
    "product/jumper-wires-f-to-f-20-pieces",
  "product/detail/jumper-wires-f-to-f-20-pieces":
    "product/jumper-wires-f-to-f-20-pieces",
  "0.01-uf-ceramic-capacitor": "product/0.01-uf-ceramic-capacitor",
  "product-details/0.01-uf-ceramic-capacitor":
    "product/0.01-uf-ceramic-capacitor",
  "product/detail/0.01-uf-ceramic-capacitor":
    "product/0.01-uf-ceramic-capacitor",
  "ldr-small": "product/ldr-small",
  "product-details/ldr-small": "product/ldr-small",
  "product/detail/ldr-small": "product/ldr-small",
  "ic-7411": "product/ic-7411",
  "product-details/ic-7411": "product/ic-7411",
  "product/detail/ic-7411": "product/ic-7411",
  "white-led-5mm": "product/white-led-5mm",
  "product-details/white-led-5mm": "product/white-led-5mm",
  "product/detail/white-led-5mm": "product/white-led-5mm",
  "green-led-5mm": "product/green-led-5mm",
  "product-details/green-led-5mm": "product/green-led-5mm",
  "product/detail/green-led-5mm": "product/green-led-5mm",
  "yellow-led-5mm": "product/yellow-led-5mm",
  "product-details/yellow-led-5mm": "product/yellow-led-5mm",
  "product/detail/yellow-led-5mm": "product/yellow-led-5mm",
  "red-led-5mm": "product/red-led-5mm",
  "product-details/red-led-5mm": "product/red-led-5mm",
  "product/detail/red-led-5mm": "product/red-led-5mm",
  "multi-color-led-5mm-fast": "product/multi-color-led-5mm-fast",
  "product-details/multi-color-led-5mm-fast":
    "product/multi-color-led-5mm-fast",
  "product/detail/multi-color-led-5mm-fast": "product/multi-color-led-5mm-fast",
  "arduino-uno": "product/arduino-uno",
  "product-details/arduino-uno": "product/arduino-uno",
  "product/detail/arduino-uno": "product/arduino-uno",
  "arduino-mega-2560-r3": "product/arduino-mega-2560-r3",
  "product-details/arduino-mega-2560-r3": "product/arduino-mega-2560-r3",
  "product/detail/arduino-mega-2560-r3": "product/arduino-mega-2560-r3",
  "10k-ohms-potentiometer": "product/10k-ohms-potentiometer",
  "product-details/10k-ohms-potentiometer": "product/10k-ohms-potentiometer",
  "product/detail/10k-ohms-potentiometer": "product/10k-ohms-potentiometer",
  ht12e: "product/ht12e",
  "product-details/ht12e": "product/ht12e",
  "product/detail/ht12e": "product/ht12e",
  ht12d: "product/ht12d",
  "product-details/ht12d": "product/ht12d",
  "product/detail/ht12d": "product/ht12d",
  l293d: "product/l293d",
  "product-details/l293d": "product/l293d",
  "product/detail/l293d": "product/l293d",
  "0.1uf-630v-capacitor": "product/0.1uf-630v-capacitor",
  "product-details/0.1uf-630v-capacitor": "product/0.1uf-630v-capacitor",
  "product/detail/0.1uf-630v-capacitor": "product/0.1uf-630v-capacitor",
  bt136: "product/bt136",
  "product-details/bt136": "product/bt136",
  "product/detail/bt136": "product/bt136",
  "rf-transmitter-receiver-pair": "product/rf-transmitter-receiver-pair",
  "product-details/rf-transmitter-receiver-pair":
    "product/rf-transmitter-receiver-pair",
  "product/detail/rf-transmitter-receiver-pair":
    "product/rf-transmitter-receiver-pair",
  "micro-metal-gear-motor": "product/micro-metal-gear-motor",
  "product-details/micro-metal-gear-motor": "product/micro-metal-gear-motor",
  "product/detail/micro-metal-gear-motor": "product/micro-metal-gear-motor",
  "micro-metal-motor-mount-set": "product/micro-metal-motor-mount-set",
  "product-details/micro-metal-motor-mount-set":
    "product/micro-metal-motor-mount-set",
  "product/detail/micro-metal-motor-mount-set":
    "product/micro-metal-motor-mount-set",
  "db3-diac": "product/db3-diac",
  "product-details/db3-diac": "product/db3-diac",
  "product/detail/db3-diac": "product/db3-diac",
  "100-uf-100-v": "product/100-uf-100-v",
  "product-details/100-uf-100-v": "product/100-uf-100-v",
  "product/detail/100-uf-100-v": "product/100-uf-100-v",
  "small-breadboard": "product/small-breadboard",
  "product-details/small-breadboard": "product/small-breadboard",
  "product/detail/small-breadboard": "product/small-breadboard",
  "small-breadboard-transparent": "product/small-breadboard-transparent",
  "product-details/small-breadboard-transparent":
    "product/small-breadboard-transparent",
  "product/detail/small-breadboard-transparent":
    "product/small-breadboard-transparent",
  "breadboard-big": "product/breadboard-big",
  "product-details/breadboard-big": "product/breadboard-big",
  "product/detail/breadboard-big": "product/breadboard-big",
  "breadboard-transparent": "product/breadboard-transparent",
  "product-details/breadboard-transparent": "product/breadboard-transparent",
  "product/detail/breadboard-transparent": "product/breadboard-transparent",
  "mini-solderless-breadboard": "product/mini-solderless-breadboard",
  "product-details/mini-solderless-breadboard":
    "product/mini-solderless-breadboard",
  "product/detail/mini-solderless-breadboard":
    "product/mini-solderless-breadboard",
  "7-segment-display-cc": "product/7-segment-display-cc",
  "product-details/7-segment-display-cc": "product/7-segment-display-cc",
  "product/detail/7-segment-display-cc": "product/7-segment-display-cc",
  "7-segment-display-ca": "product/7-segment-display-ca",
  "product-details/7-segment-display-ca": "product/7-segment-display-ca",
  "product/detail/7-segment-display-ca": "product/7-segment-display-ca",
  "bluetooth-module-breakout-hc-05": "product/bluetooth-module-breakout-hc-05",
  "product-details/bluetooth-module-breakout-hc-05":
    "product/bluetooth-module-breakout-hc-05",
  "product/detail/bluetooth-module-breakout-hc-05":
    "product/bluetooth-module-breakout-hc-05",
  "raspberry-pi-3-model-b": "product/raspberry-pi-3-model-b",
  "product-details/raspberry-pi-3-model-b": "product/raspberry-pi-3-model-b",
  "product/detail/raspberry-pi-3-model-b": "product/raspberry-pi-3-model-b",
  "robotics-pack-for-robo101": "product/robotics-pack-for-robo101",
  "product-details/robotics-pack-for-robo101":
    "product/robotics-pack-for-robo101",
  "product/detail/robotics-pack-for-robo101":
    "product/robotics-pack-for-robo101",
  "blue-led-5mm": "product/blue-led-5mm",
  "product-details/blue-led-5mm": "product/blue-led-5mm",
  "product/detail/blue-led-5mm": "product/blue-led-5mm",
  "yellow-led-3mm": "product/yellow-led-3mm",
  "product-details/yellow-led-3mm": "product/yellow-led-3mm",
  "product/detail/yellow-led-3mm": "product/yellow-led-3mm",
  "red-led-3mm": "product/red-led-3mm",
  "product-details/red-led-3mm": "product/red-led-3mm",
  "product/detail/red-led-3mm": "product/red-led-3mm",
  "hc-sr04-ultrasonic-sensor": "product/hc-sr04-ultrasonic-sensor",
  "product-details/hc-sr04-ultrasonic-sensor":
    "product/hc-sr04-ultrasonic-sensor",
  "product/detail/hc-sr04-ultrasonic-sensor":
    "product/hc-sr04-ultrasonic-sensor",
  "ic-7474": "product/ic-7474",
  "product-details/ic-7474": "product/ic-7474",
  "product/detail/ic-7474": "product/ic-7474",
  "ic-7473": "product/ic-7473",
  "product-details/ic-7473": "product/ic-7473",
  "product/detail/ic-7473": "product/ic-7473",
  "glue-gun-stick": "product/glue-gun-stick",
  "product-details/glue-gun-stick": "product/glue-gun-stick",
  "product/detail/glue-gun-stick": "product/glue-gun-stick",
  "6-pin-tactile-switch-push": "product/6-pin-tactile-switch-push",
  "product-details/6-pin-tactile-switch-push":
    "product/6-pin-tactile-switch-push",
  "product/detail/6-pin-tactile-switch-push":
    "product/6-pin-tactile-switch-push",
  "ldr-big": "product/ldr-big",
  "product-details/ldr-big": "product/ldr-big",
  "product/detail/ldr-big": "product/ldr-big",
  "l298n-dual-motor-controller-module":
    "product/l298n-dual-motor-controller-module",
  "product-details/l298n-dual-motor-controller-module":
    "product/l298n-dual-motor-controller-module",
  "product/detail/l298n-dual-motor-controller-module":
    "product/l298n-dual-motor-controller-module",
  "l298n-stepper-motor-driver-green":
    "product/l298n-stepper-motor-driver-green",
  "product-details/l298n-stepper-motor-driver-green":
    "product/l298n-stepper-motor-driver-green",
  "product/detail/l298n-stepper-motor-driver-green":
    "product/l298n-stepper-motor-driver-green",
  "68-uf-16v": "product/68-uf-16v",
  "product-details/68-uf-16v": "product/68-uf-16v",
  "product/detail/68-uf-16v": "product/68-uf-16v",
  "10-uf-capacitor": "product/10-uf-capacitor",
  "product-details/10-uf-capacitor": "product/10-uf-capacitor",
  "product/detail/10-uf-capacitor": "product/10-uf-capacitor",
  "100-uf-16v": "product/100-uf-16v",
  "product-details/100-uf-16v": "product/100-uf-16v",
  "product/detail/100-uf-16v": "product/100-uf-16v",
  "100-uf-25-v": "product/100-uf-25-v",
  "product-details/100-uf-25-v": "product/100-uf-25-v",
  "product/detail/100-uf-25-v": "product/100-uf-25-v",
  "ceramic-capacitor-103-10nf-0.01uf":
    "product/ceramic-capacitor-103-10nf-0.01uf",
  "product-details/ceramic-capacitor-103-10nf-0.01uf":
    "product/ceramic-capacitor-103-10nf-0.01uf",
  "product/detail/ceramic-capacitor-103-10nf-0.01uf":
    "product/ceramic-capacitor-103-10nf-0.01uf",
  "ceramic-capacitor-104-100nf-.1uf":
    "product/ceramic-capacitor-104-100nf-.1uf",
  "product-details/ceramic-capacitor-104-100nf-.1uf":
    "product/ceramic-capacitor-104-100nf-.1uf",
  "product/detail/ceramic-capacitor-104-100nf-.1uf":
    "product/ceramic-capacitor-104-100nf-.1uf",
  "green-led-3mm": "product/green-led-3mm",
  "product-details/green-led-3mm": "product/green-led-3mm",
  "product/detail/green-led-3mm": "product/green-led-3mm",
  "100-ohm-1-4w-resistor": "product/100-ohm-1-4w-resistor",
  "product-details/100-ohm-1-4w-resistor": "product/100-ohm-1-4w-resistor",
  "product/detail/100-ohm-1-4w-resistor": "product/100-ohm-1-4w-resistor",
  "150-ohm-1-4w-resistor": "product/150-ohm-1-4w-resistor",
  "product-details/150-ohm-1-4w-resistor": "product/150-ohm-1-4w-resistor",
  "product/detail/150-ohm-1-4w-resistor": "product/150-ohm-1-4w-resistor",
  "220-ohm-1-4w-resistor": "product/220-ohm-1-4w-resistor",
  "product-details/220-ohm-1-4w-resistor": "product/220-ohm-1-4w-resistor",
  "product/detail/220-ohm-1-4w-resistor": "product/220-ohm-1-4w-resistor",
  "470-ohm-1-4w-resistor": "product/470-ohm-1-4w-resistor",
  "product-details/470-ohm-1-4w-resistor": "product/470-ohm-1-4w-resistor",
  "product/detail/470-ohm-1-4w-resistor": "product/470-ohm-1-4w-resistor",
  "electret-microphone": "product/electret-microphone",
  "product-details/electret-microphone": "product/electret-microphone",
  "product/detail/electret-microphone": "product/electret-microphone",
  speaker: "product/speaker",
  "product-details/speaker": "product/speaker",
  "product/detail/speaker": "product/speaker",
  "speaker-oval-shape": "product/speaker-oval-shape",
  "product-details/speaker-oval-shape": "product/speaker-oval-shape",
  "product/detail/speaker-oval-shape": "product/speaker-oval-shape",
  bc547: "product/bc547",
  "product-details/bc547": "product/bc547",
  "product/detail/bc547": "product/bc547",
  "1k-ohm-1-4w-resistor": "product/1k-ohm-1-4w-resistor",
  "product-details/1k-ohm-1-4w-resistor": "product/1k-ohm-1-4w-resistor",
  "product/detail/1k-ohm-1-4w-resistor": "product/1k-ohm-1-4w-resistor",
  "10k-ohm-1-4w-resistor": "product/10k-ohm-1-4w-resistor",
  "product-details/10k-ohm-1-4w-resistor": "product/10k-ohm-1-4w-resistor",
  "product/detail/10k-ohm-1-4w-resistor": "product/10k-ohm-1-4w-resistor",
  "47k-ohm-1-4w-resistor": "product/47k-ohm-1-4w-resistor",
  "product-details/47k-ohm-1-4w-resistor": "product/47k-ohm-1-4w-resistor",
  "product/detail/47k-ohm-1-4w-resistor": "product/47k-ohm-1-4w-resistor",
  "680k-ohm-1-4w-resistor": "product/680k-ohm-1-4w-resistor",
  "product-details/680k-ohm-1-4w-resistor": "product/680k-ohm-1-4w-resistor",
  "product/detail/680k-ohm-1-4w-resistor": "product/680k-ohm-1-4w-resistor",
  "ceramic-capacitor-102-1nf": "product/ceramic-capacitor-102-1nf",
  "product-details/ceramic-capacitor-102-1nf":
    "product/ceramic-capacitor-102-1nf",
  "product/detail/ceramic-capacitor-102-1nf":
    "product/ceramic-capacitor-102-1nf",
  "100k-ohms-potentiometer": "product/100k-ohms-potentiometer",
  "product-details/100k-ohms-potentiometer": "product/100k-ohms-potentiometer",
  "product/detail/100k-ohms-potentiometer": "product/100k-ohms-potentiometer",
  "100k-ohm-1-4w-resistor": "product/100k-ohm-1-4w-resistor",
  "product-details/100k-ohm-1-4w-resistor": "product/100k-ohm-1-4w-resistor",
  "product/detail/100k-ohm-1-4w-resistor": "product/100k-ohm-1-4w-resistor",
  bc548: "product/bc548",
  "product-details/bc548": "product/bc548",
  "product/detail/bc548": "product/bc548",
  bc557: "product/bc557",
  "product-details/bc557": "product/bc557",
  "product/detail/bc557": "product/bc557",
  "ceramic-capacitor-473-0.047-uf": "product/ceramic-capacitor-473-0.047-uf",
  "product-details/ceramic-capacitor-473-0.047-uf":
    "product/ceramic-capacitor-473-0.047-uf",
  "product/detail/ceramic-capacitor-473-0.047-uf":
    "product/ceramic-capacitor-473-0.047-uf",
  "digital-multimeter-dt9205a": "product/digital-multimeter-dt9205a",
  "product-details/digital-multimeter-dt9205a":
    "product/digital-multimeter-dt9205a",
  "product/detail/digital-multimeter-dt9205a":
    "product/digital-multimeter-dt9205a",
  "330-ohm-1-4w-resistor": "product/330-ohm-1-4w-resistor",
  "product-details/330-ohm-1-4w-resistor": "product/330-ohm-1-4w-resistor",
  "product/detail/330-ohm-1-4w-resistor": "product/330-ohm-1-4w-resistor",
  "150k-ohm-1-4w-resistor": "product/150k-ohm-1-4w-resistor",
  "product-details/150k-ohm-1-4w-resistor": "product/150k-ohm-1-4w-resistor",
  "product/detail/150k-ohm-1-4w-resistor": "product/150k-ohm-1-4w-resistor",
  "21k-ohm-1-4w-resistor": "product/21k-ohm-1-4w-resistor",
  "product-details/21k-ohm-1-4w-resistor": "product/21k-ohm-1-4w-resistor",
  "product/detail/21k-ohm-1-4w-resistor": "product/21k-ohm-1-4w-resistor",
  "4-pin-push-switch": "product/4-pin-push-switch",
  "product-details/4-pin-push-switch": "product/4-pin-push-switch",
  "product/detail/4-pin-push-switch": "product/4-pin-push-switch",
  "slide-switch": "product/slide-switch",
  "product-details/slide-switch": "product/slide-switch",
  "product/detail/slide-switch": "product/slide-switch",
  irf540: "product/irf540",
  "product-details/irf540": "product/irf540",
  "product/detail/irf540": "product/irf540",
  "dip-switch-3-bit": "product/dip-switch-3-bit",
  "product-details/dip-switch-3-bit": "product/dip-switch-3-bit",
  "product/detail/dip-switch-3-bit": "product/dip-switch-3-bit",
  bc558: "product/bc558",
  "product-details/bc558": "product/bc558",
  "product/detail/bc558": "product/bc558",
  "transformer-12v-600ma": "product/transformer-12v-600ma",
  "product-details/transformer-12v-600ma": "product/transformer-12v-600ma",
  "product/detail/transformer-12v-600ma": "product/transformer-12v-600ma",
  "transformer-12v-1000ma-or-1a": "product/transformer-12v-1000ma-or-1a",
  "product-details/transformer-12v-1000ma-or-1a":
    "product/transformer-12v-1000ma-or-1a",
  "product/detail/transformer-12v-1000ma-or-1a":
    "product/transformer-12v-1000ma-or-1a",
  "transformer-12v-3000ma-or-3a": "product/transformer-12v-3000ma-or-3a",
  "product-details/transformer-12v-3000ma-or-3a":
    "product/transformer-12v-3000ma-or-3a",
  "product/detail/transformer-12v-3000ma-or-3a":
    "product/transformer-12v-3000ma-or-3a",
  "1n4007-silicon-diode": "product/1n4007-silicon-diode",
  "product-details/1n4007-silicon-diode": "product/1n4007-silicon-diode",
  "product/detail/1n4007-silicon-diode": "product/1n4007-silicon-diode",
  "1n4148-silicon-diode": "product/1n4148-silicon-diode",
  "product-details/1n4148-silicon-diode": "product/1n4148-silicon-diode",
  "product/detail/1n4148-silicon-diode": "product/1n4148-silicon-diode",
  "5.6k-ohm-1-4w-resistor": "product/5.6k-ohm-1-4w-resistor",
  "product-details/5.6k-ohm-1-4w-resistor": "product/5.6k-ohm-1-4w-resistor",
  "product/detail/5.6k-ohm-1-4w-resistor": "product/5.6k-ohm-1-4w-resistor",
  "4700uf-25v": "product/4700uf-25v",
  "product-details/4700uf-25v": "product/4700uf-25v",
  "product/detail/4700uf-25v": "product/4700uf-25v",
  "dip-switch-4-bit": "product/dip-switch-4-bit",
  "product-details/dip-switch-4-bit": "product/dip-switch-4-bit",
  "product/detail/dip-switch-4-bit": "product/dip-switch-4-bit",
  "glue-gun-100w": "product/glue-gun-100w",
  "product-details/glue-gun-100w": "product/glue-gun-100w",
  "product/detail/glue-gun-100w": "product/glue-gun-100w",
  "glue-gun-60-w": "product/glue-gun-60-w",
  "product-details/glue-gun-60-w": "product/glue-gun-60-w",
  "product/detail/glue-gun-60-w": "product/glue-gun-60-w",
  "ic-74150": "product/ic-74150",
  "product-details/ic-74150": "product/ic-74150",
  "product/detail/ic-74150": "product/ic-74150",
  "ic-74154": "product/ic-74154",
  "product-details/ic-74154": "product/ic-74154",
  "product/detail/ic-74154": "product/ic-74154",
  "battery-holder-4-pieces-aa": "product/battery-holder-4-pieces-aa",
  "product-details/battery-holder-4-pieces-aa":
    "product/battery-holder-4-pieces-aa",
  "product/detail/battery-holder-4-pieces-aa":
    "product/battery-holder-4-pieces-aa",
  "3.3-5v-power-supply-module": "product/3.3-5v-power-supply-module",
  "product-details/3.3-5v-power-supply-module":
    "product/3.3-5v-power-supply-module",
  "product/detail/3.3-5v-power-supply-module":
    "product/3.3-5v-power-supply-module",
  "12v-2a-power-adapter": "product/12v-2a-power-adapter",
  "product-details/12v-2a-power-adapter": "product/12v-2a-power-adapter",
  "product/detail/12v-2a-power-adapter": "product/12v-2a-power-adapter",
  "breadboard-jumper-wire-set": "product/breadboard-jumper-wire-set",
  "product-details/breadboard-jumper-wire-set":
    "product/breadboard-jumper-wire-set",
  "product/detail/breadboard-jumper-wire-set":
    "product/breadboard-jumper-wire-set",
  "ic-7420": "product/ic-7420",
  "product-details/ic-7420": "product/ic-7420",
  "product/detail/ic-7420": "product/ic-7420",
  "ic-7421": "product/ic-7421",
  "product-details/ic-7421": "product/ic-7421",
  "product/detail/ic-7421": "product/ic-7421",
  "ic-7425": "product/ic-7425",
  "product-details/ic-7425": "product/ic-7425",
  "product/detail/ic-7425": "product/ic-7425",
  "ic-4072": "product/ic-4072",
  "product-details/ic-4072": "product/ic-4072",
  "product/detail/ic-4072": "product/ic-4072",
  "ic-4011": "product/ic-4011",
  "product-details/ic-4011": "product/ic-4011",
  "product/detail/ic-4011": "product/ic-4011",
  "ic-7427": "product/ic-7427",
  "product-details/ic-7427": "product/ic-7427",
  "product/detail/ic-7427": "product/ic-7427",
  "laser-diode-5v-5mw-650nm": "product/laser-diode-5v-5mw-650nm",
  "product-details/laser-diode-5v-5mw-650nm":
    "product/laser-diode-5v-5mw-650nm",
  "product/detail/laser-diode-5v-5mw-650nm": "product/laser-diode-5v-5mw-650nm",
  "2n2222": "product/2n2222",
  "product-details/2n2222": "product/2n2222",
  "product/detail/2n2222": "product/2n2222",
  "lcd-display-16-x-2-with-header": "product/lcd-display-16-x-2-with-header",
  "product-details/lcd-display-16-x-2-with-header":
    "product/lcd-display-16-x-2-with-header",
  "product/detail/lcd-display-16-x-2-with-header":
    "product/lcd-display-16-x-2-with-header",
  "variable-capacitor-trimmer": "product/variable-capacitor-trimmer",
  "product-details/variable-capacitor-trimmer":
    "product/variable-capacitor-trimmer",
  "product/detail/variable-capacitor-trimmer":
    "product/variable-capacitor-trimmer",
  "arduino-nano": "product/arduino-nano",
  "product-details/arduino-nano": "product/arduino-nano",
  "product/detail/arduino-nano": "product/arduino-nano",
  "arduino-leonardo": "product/arduino-leonardo",
  "product-details/arduino-leonardo": "product/arduino-leonardo",
  "product/detail/arduino-leonardo": "product/arduino-leonardo",
  "1000uf-25v": "product/1000uf-25v",
  "product-details/1000uf-25v": "product/1000uf-25v",
  "product/detail/1000uf-25v": "product/1000uf-25v",
  irf9640: "product/irf9640",
  "product-details/irf9640": "product/irf9640",
  "product/detail/irf9640": "product/irf9640",
  tip41c: "product/tip41c",
  "product-details/tip41c": "product/tip41c",
  "product/detail/tip41c": "product/tip41c",
  tip42c: "product/tip42c",
  "product-details/tip42c": "product/tip42c",
  "product/detail/tip42c": "product/tip42c",
  "lodestar-soldering-iron-40-w": "product/lodestar-soldering-iron-40-w",
  "product-details/lodestar-soldering-iron-40-w":
    "product/lodestar-soldering-iron-40-w",
  "product/detail/lodestar-soldering-iron-40-w":
    "product/lodestar-soldering-iron-40-w",
  "soldering-iron-60-w": "product/soldering-iron-60-w",
  "product-details/soldering-iron-60-w": "product/soldering-iron-60-w",
  "product/detail/soldering-iron-60-w": "product/soldering-iron-60-w",
  "soldering-wire-per-gauge": "product/soldering-wire-per-gauge",
  "product-details/soldering-wire-per-gauge":
    "product/soldering-wire-per-gauge",
  "product/detail/soldering-wire-per-gauge": "product/soldering-wire-per-gauge",
  "usb-type-a-connector": "product/usb-type-a-connector",
  "product-details/usb-type-a-connector": "product/usb-type-a-connector",
  "product/detail/usb-type-a-connector": "product/usb-type-a-connector",
  "270-ohm-1-4w-resistor": "product/270-ohm-1-4w-resistor",
  "product-details/270-ohm-1-4w-resistor": "product/270-ohm-1-4w-resistor",
  "product/detail/270-ohm-1-4w-resistor": "product/270-ohm-1-4w-resistor",
  "digital-multimeter-ut33b": "product/digital-multimeter-ut33b",
  "product-details/digital-multimeter-ut33b":
    "product/digital-multimeter-ut33b",
  "product/detail/digital-multimeter-ut33b": "product/digital-multimeter-ut33b",
  irf630: "product/irf630",
  "product-details/irf630": "product/irf630",
  "product/detail/irf630": "product/irf630",
  "2.2-uf-50v": "product/2.2-uf-50v",
  "product-details/2.2-uf-50v": "product/2.2-uf-50v",
  "product/detail/2.2-uf-50v": "product/2.2-uf-50v",
  "680-ohm-1-4w-resistor": "product/680-ohm-1-4w-resistor",
  "product-details/680-ohm-1-4w-resistor": "product/680-ohm-1-4w-resistor",
  "product/detail/680-ohm-1-4w-resistor": "product/680-ohm-1-4w-resistor",
  "12k-ohm-1-4w-resistor": "product/12k-ohm-1-4w-resistor",
  "product-details/12k-ohm-1-4w-resistor": "product/12k-ohm-1-4w-resistor",
  "product/detail/12k-ohm-1-4w-resistor": "product/12k-ohm-1-4w-resistor",
  "step-up-transformer-3000ma-or-3a":
    "product/step-up-transformer-3000ma-or-3a",
  "product-details/step-up-transformer-3000ma-or-3a":
    "product/step-up-transformer-3000ma-or-3a",
  "product/detail/step-up-transformer-3000ma-or-3a":
    "product/step-up-transformer-3000ma-or-3a",
  "12v-battery-charger-son-1205": "product/12v-battery-charger-son-1205",
  "product-details/12v-battery-charger-son-1205":
    "product/12v-battery-charger-son-1205",
  "product/detail/12v-battery-charger-son-1205":
    "product/12v-battery-charger-son-1205",
  "12v-battery-rechargeable": "product/12v-battery-rechargeable",
  "product-details/12v-battery-rechargeable":
    "product/12v-battery-rechargeable",
  "product/detail/12v-battery-rechargeable": "product/12v-battery-rechargeable",
  "arduino-pro-micro": "product/arduino-pro-micro",
  "product-details/arduino-pro-micro": "product/arduino-pro-micro",
  "product/detail/arduino-pro-micro": "product/arduino-pro-micro",
  "50k-ohms-potentiometer": "product/50k-ohms-potentiometer",
  "product-details/50k-ohms-potentiometer": "product/50k-ohms-potentiometer",
  "product/detail/50k-ohms-potentiometer": "product/50k-ohms-potentiometer",
  "220-uf-25v": "product/220-uf-25v",
  "product-details/220-uf-25v": "product/220-uf-25v",
  "product/detail/220-uf-25v": "product/220-uf-25v",
  "1n5819-schottky-diode": "product/1n5819-schottky-diode",
  "product-details/1n5819-schottky-diode": "product/1n5819-schottky-diode",
  "product/detail/1n5819-schottky-diode": "product/1n5819-schottky-diode",
  "inductor-10uh": "product/inductor-10uh",
  "product-details/inductor-10uh": "product/inductor-10uh",
  "product/detail/inductor-10uh": "product/inductor-10uh",
  "4.7k-ohm-1-4w-resistor": "product/4.7k-ohm-1-4w-resistor",
  "product-details/4.7k-ohm-1-4w-resistor": "product/4.7k-ohm-1-4w-resistor",
  "product/detail/4.7k-ohm-1-4w-resistor": "product/4.7k-ohm-1-4w-resistor",
  "1.5v-battery-aa": "product/1.5v-battery-aa",
  "product-details/1.5v-battery-aa": "product/1.5v-battery-aa",
  "product/detail/1.5v-battery-aa": "product/1.5v-battery-aa",
  "1.0m-ohm-1-4w-resistor": "product/1.0m-ohm-1-4w-resistor",
  "product-details/1.0m-ohm-1-4w-resistor": "product/1.0m-ohm-1-4w-resistor",
  "product/detail/1.0m-ohm-1-4w-resistor": "product/1.0m-ohm-1-4w-resistor",
  "ir-receiver-tsop4838": "product/ir-receiver-tsop4838",
  "product-details/ir-receiver-tsop4838": "product/ir-receiver-tsop4838",
  "product/detail/ir-receiver-tsop4838": "product/ir-receiver-tsop4838",
  tcrt5000: "product/tcrt5000",
  "product-details/tcrt5000": "product/tcrt5000",
  "product/detail/tcrt5000": "product/tcrt5000",
  "lm358-hq": "product/lm358-hq",
  "product-details/lm358-hq": "product/lm358-hq",
  "product/detail/lm358-hq": "product/lm358-hq",
  lm386: "product/lm386",
  "product-details/lm386": "product/lm386",
  "product/detail/lm386": "product/lm386",
  lm35: "product/lm35",
  "product-details/lm35": "product/lm35",
  "product/detail/lm35": "product/lm35",
  "l293d-motor-driver-shield": "product/l293d-motor-driver-shield",
  "product-details/l293d-motor-driver-shield":
    "product/l293d-motor-driver-shield",
  "product/detail/l293d-motor-driver-shield":
    "product/l293d-motor-driver-shield",
  "l7809-voltage-regulator-9v": "product/l7809-voltage-regulator-9v",
  "product-details/l7809-voltage-regulator-9v":
    "product/l7809-voltage-regulator-9v",
  "product/detail/l7809-voltage-regulator-9v":
    "product/l7809-voltage-regulator-9v",
  "l7812-voltage-regulator-12v": "product/l7812-voltage-regulator-12v",
  "product-details/l7812-voltage-regulator-12v":
    "product/l7812-voltage-regulator-12v",
  "product/detail/l7812-voltage-regulator-12v":
    "product/l7812-voltage-regulator-12v",
  "glue-gun-80-w": "product/glue-gun-80-w",
  "product-details/glue-gun-80-w": "product/glue-gun-80-w",
  "product/detail/glue-gun-80-w": "product/glue-gun-80-w",
  "solder-resin": "product/solder-resin",
  "product-details/solder-resin": "product/solder-resin",
  "product/detail/solder-resin": "product/solder-resin",
  "2.2m-ohm-1-4w-resistor": "product/2.2m-ohm-1-4w-resistor",
  "product-details/2.2m-ohm-1-4w-resistor": "product/2.2m-ohm-1-4w-resistor",
  "product/detail/2.2m-ohm-1-4w-resistor": "product/2.2m-ohm-1-4w-resistor",
  "robot-car-chassis-set": "product/robot-car-chassis-set",
  "product-details/robot-car-chassis-set": "product/robot-car-chassis-set",
  "product/detail/robot-car-chassis-set": "product/robot-car-chassis-set",
  "buzzer-3-9v-hq": "product/buzzer-3-9v-hq",
  "product-details/buzzer-3-9v-hq": "product/buzzer-3-9v-hq",
  "product/detail/buzzer-3-9v-hq": "product/buzzer-3-9v-hq",
  "matrix-keypad-3*4": "product/matrix-keypad-3*4",
  "product-details/matrix-keypad-3*4": "product/matrix-keypad-3*4",
  "product/detail/matrix-keypad-3*4": "product/matrix-keypad-3*4",
  "matrix-keypad-4-4": "product/matrix-keypad-4-4",
  "product-details/matrix-keypad-4-4": "product/matrix-keypad-4-4",
  "product/detail/matrix-keypad-4-4": "product/matrix-keypad-4-4",
  "1n4732-zener-diode": "product/1n4732-zener-diode",
  "product-details/1n4732-zener-diode": "product/1n4732-zener-diode",
  "product/detail/1n4732-zener-diode": "product/1n4732-zener-diode",
  "transformer-9v-1000ma-or-1a": "product/transformer-9v-1000ma-or-1a",
  "product-details/transformer-9v-1000ma-or-1a":
    "product/transformer-9v-1000ma-or-1a",
  "product/detail/transformer-9v-1000ma-or-1a":
    "product/transformer-9v-1000ma-or-1a",
  "10k-ohm-ntc-thermistor": "product/10k-ohm-ntc-thermistor",
  "product-details/10k-ohm-ntc-thermistor": "product/10k-ohm-ntc-thermistor",
  "product/detail/10k-ohm-ntc-thermistor": "product/10k-ohm-ntc-thermistor",
  "5k-ohm-ntc-thermistor": "product/5k-ohm-ntc-thermistor",
  "product-details/5k-ohm-ntc-thermistor": "product/5k-ohm-ntc-thermistor",
  "product/detail/5k-ohm-ntc-thermistor": "product/5k-ohm-ntc-thermistor",
  "10k-ohm-precision-ntc-thermistor":
    "product/10k-ohm-precision-ntc-thermistor",
  "product-details/10k-ohm-precision-ntc-thermistor":
    "product/10k-ohm-precision-ntc-thermistor",
  "product/detail/10k-ohm-precision-ntc-thermistor":
    "product/10k-ohm-precision-ntc-thermistor",
  "5-ohm-ntc-thermistor": "product/5-ohm-ntc-thermistor",
  "product-details/5-ohm-ntc-thermistor": "product/5-ohm-ntc-thermistor",
  "product/detail/5-ohm-ntc-thermistor": "product/5-ohm-ntc-thermistor",
  "micro-servo-dxw90": "product/micro-servo-dxw90",
  "product-details/micro-servo-dxw90": "product/micro-servo-dxw90",
  "product/detail/micro-servo-dxw90": "product/micro-servo-dxw90",
  bc369: "product/bc369",
  "product-details/bc369": "product/bc369",
  "product/detail/bc369": "product/bc369",
  "2.2k-ohm-1-4w-resistor": "product/2.2k-ohm-1-4w-resistor",
  "product-details/2.2k-ohm-1-4w-resistor": "product/2.2k-ohm-1-4w-resistor",
  "product/detail/2.2k-ohm-1-4w-resistor": "product/2.2k-ohm-1-4w-resistor",
  "2n3904": "product/2n3904",
  "product-details/2n3904": "product/2n3904",
  "product/detail/2n3904": "product/2n3904",
  "2n2907": "product/2n2907",
  "product-details/2n2907": "product/2n2907",
  "product/detail/2n2907": "product/2n2907",
  ca3130: "product/ca3130",
  "product-details/ca3130": "product/ca3130",
  "product/detail/ca3130": "product/ca3130",
  "pin-header-male-to-female": "product/pin-header-male-to-female",
  "product-details/pin-header-male-to-female":
    "product/pin-header-male-to-female",
  "product/detail/pin-header-male-to-female":
    "product/pin-header-male-to-female",
  "pin-header-male-to-male": "product/pin-header-male-to-male",
  "product-details/pin-header-male-to-male": "product/pin-header-male-to-male",
  "product/detail/pin-header-male-to-male": "product/pin-header-male-to-male",
  "0.22-uf-50v": "product/0.22-uf-50v",
  "product-details/0.22-uf-50v": "product/0.22-uf-50v",
  "product/detail/0.22-uf-50v": "product/0.22-uf-50v",
  "68k-ohm-1-4w-resistor": "product/68k-ohm-1-4w-resistor",
  "product-details/68k-ohm-1-4w-resistor": "product/68k-ohm-1-4w-resistor",
  "product/detail/68k-ohm-1-4w-resistor": "product/68k-ohm-1-4w-resistor",
  "15k-ohm-1-4w-resistor": "product/15k-ohm-1-4w-resistor",
  "product-details/15k-ohm-1-4w-resistor": "product/15k-ohm-1-4w-resistor",
  "product/detail/15k-ohm-1-4w-resistor": "product/15k-ohm-1-4w-resistor",
  "1.5k-ohm-1-4w-resistor": "product/1.5k-ohm-1-4w-resistor",
  "product-details/1.5k-ohm-1-4w-resistor": "product/1.5k-ohm-1-4w-resistor",
  "product/detail/1.5k-ohm-1-4w-resistor": "product/1.5k-ohm-1-4w-resistor",
  "22-ohm-1-4w-resistor": "product/22-ohm-1-4w-resistor",
  "product-details/22-ohm-1-4w-resistor": "product/22-ohm-1-4w-resistor",
  "product/detail/22-ohm-1-4w-resistor": "product/22-ohm-1-4w-resistor",
  "1.2k-ohm-1-4w-resistor": "product/1.2k-ohm-1-4w-resistor",
  "product-details/1.2k-ohm-1-4w-resistor": "product/1.2k-ohm-1-4w-resistor",
  "product/detail/1.2k-ohm-1-4w-resistor": "product/1.2k-ohm-1-4w-resistor",
  "1.8k-ohm-1-4w-resistor": "product/1.8k-ohm-1-4w-resistor",
  "product-details/1.8k-ohm-1-4w-resistor": "product/1.8k-ohm-1-4w-resistor",
  "product/detail/1.8k-ohm-1-4w-resistor": "product/1.8k-ohm-1-4w-resistor",
  "2.7k-ohm-1-4w-resistor": "product/2.7k-ohm-1-4w-resistor",
  "product-details/2.7k-ohm-1-4w-resistor": "product/2.7k-ohm-1-4w-resistor",
  "product/detail/2.7k-ohm-1-4w-resistor": "product/2.7k-ohm-1-4w-resistor",
  "3.3k-ohm-1-4w-resistor": "product/3.3k-ohm-1-4w-resistor",
  "product-details/3.3k-ohm-1-4w-resistor": "product/3.3k-ohm-1-4w-resistor",
  "product/detail/3.3k-ohm-1-4w-resistor": "product/3.3k-ohm-1-4w-resistor",
  "22k-ohm-1-4w-resistor": "product/22k-ohm-1-4w-resistor",
  "product-details/22k-ohm-1-4w-resistor": "product/22k-ohm-1-4w-resistor",
  "product/detail/22k-ohm-1-4w-resistor": "product/22k-ohm-1-4w-resistor",
  "27k-ohm-1-4w-resistor": "product/27k-ohm-1-4w-resistor",
  "product-details/27k-ohm-1-4w-resistor": "product/27k-ohm-1-4w-resistor",
  "product/detail/27k-ohm-1-4w-resistor": "product/27k-ohm-1-4w-resistor",
  "33k-ohm-1-4w-resistor": "product/33k-ohm-1-4w-resistor",
  "product-details/33k-ohm-1-4w-resistor": "product/33k-ohm-1-4w-resistor",
  "product/detail/33k-ohm-1-4w-resistor": "product/33k-ohm-1-4w-resistor",
  "39k-ohm-1-4w-resistor": "product/39k-ohm-1-4w-resistor",
  "product-details/39k-ohm-1-4w-resistor": "product/39k-ohm-1-4w-resistor",
  "product/detail/39k-ohm-1-4w-resistor": "product/39k-ohm-1-4w-resistor",
  "56k-ohm-1-4w-resistor": "product/56k-ohm-1-4w-resistor",
  "product-details/56k-ohm-1-4w-resistor": "product/56k-ohm-1-4w-resistor",
  "product/detail/56k-ohm-1-4w-resistor": "product/56k-ohm-1-4w-resistor",
  "120k-ohm-1-4w-resistor": "product/120k-ohm-1-4w-resistor",
  "product-details/120k-ohm-1-4w-resistor": "product/120k-ohm-1-4w-resistor",
  "product/detail/120k-ohm-1-4w-resistor": "product/120k-ohm-1-4w-resistor",
  "180k-ohm-1-4w-resistor": "product/180k-ohm-1-4w-resistor",
  "product-details/180k-ohm-1-4w-resistor": "product/180k-ohm-1-4w-resistor",
  "product/detail/180k-ohm-1-4w-resistor": "product/180k-ohm-1-4w-resistor",
  "390k-ohm-1-4w-resistor": "product/390k-ohm-1-4w-resistor",
  "product-details/390k-ohm-1-4w-resistor": "product/390k-ohm-1-4w-resistor",
  "product/detail/390k-ohm-1-4w-resistor": "product/390k-ohm-1-4w-resistor",
  "470k-ohm-1-4w-resistor": "product/470k-ohm-1-4w-resistor",
  "product-details/470k-ohm-1-4w-resistor": "product/470k-ohm-1-4w-resistor",
  "product/detail/470k-ohm-1-4w-resistor": "product/470k-ohm-1-4w-resistor",
  "560k-ohm-1-4w-resistor": "product/560k-ohm-1-4w-resistor",
  "product-details/560k-ohm-1-4w-resistor": "product/560k-ohm-1-4w-resistor",
  "product/detail/560k-ohm-1-4w-resistor": "product/560k-ohm-1-4w-resistor",
  "820k-ohm-1-4w-resistor": "product/820k-ohm-1-4w-resistor",
  "product-details/820k-ohm-1-4w-resistor": "product/820k-ohm-1-4w-resistor",
  "product/detail/820k-ohm-1-4w-resistor": "product/820k-ohm-1-4w-resistor",
  "12v-relay": "product/12v-relay",
  "product-details/12v-relay": "product/12v-relay",
  "product/detail/12v-relay": "product/12v-relay",
  "24v-relay": "product/24v-relay",
  "product-details/24v-relay": "product/24v-relay",
  "product/detail/24v-relay": "product/24v-relay",
  "4-channel-relay-5v": "product/4-channel-relay-5v",
  "product-details/4-channel-relay-5v": "product/4-channel-relay-5v",
  "product/detail/4-channel-relay-5v": "product/4-channel-relay-5v",
  "2-channel-relay-5v": "product/2-channel-relay-5v",
  "product-details/2-channel-relay-5v": "product/2-channel-relay-5v",
  "product/detail/2-channel-relay-5v": "product/2-channel-relay-5v",
  "micro-metal-gear-motor-300-rpm": "product/micro-metal-gear-motor-300-rpm",
  "product-details/micro-metal-gear-motor-300-rpm":
    "product/micro-metal-gear-motor-300-rpm",
  "product/detail/micro-metal-gear-motor-300-rpm":
    "product/micro-metal-gear-motor-300-rpm",
  "micro-metal-gear-motor-400-rpm": "product/micro-metal-gear-motor-400-rpm",
  "product-details/micro-metal-gear-motor-400-rpm":
    "product/micro-metal-gear-motor-400-rpm",
  "product/detail/micro-metal-gear-motor-400-rpm":
    "product/micro-metal-gear-motor-400-rpm",
  "micro-metal-gear-motor-600-rpm": "product/micro-metal-gear-motor-600-rpm",
  "product-details/micro-metal-gear-motor-600-rpm":
    "product/micro-metal-gear-motor-600-rpm",
  "product/detail/micro-metal-gear-motor-600-rpm":
    "product/micro-metal-gear-motor-600-rpm",
  "pololu-wheel-pair-42x19mm": "product/pololu-wheel-pair-42x19mm",
  "product-details/pololu-wheel-pair-42x19mm":
    "product/pololu-wheel-pair-42x19mm",
  "product/detail/pololu-wheel-pair-42x19mm":
    "product/pololu-wheel-pair-42x19mm",
  "usb-a-to-b-cable-0.5m": "product/usb-a-to-b-cable-0.5m",
  "product-details/usb-a-to-b-cable-0.5m": "product/usb-a-to-b-cable-0.5m",
  "product/detail/usb-a-to-b-cable-0.5m": "product/usb-a-to-b-cable-0.5m",
  lm66: "product/lm66",
  "product-details/lm66": "product/lm66",
  "product/detail/lm66": "product/lm66",
  bt151: "product/bt151",
  "product-details/bt151": "product/bt151",
  "product/detail/bt151": "product/bt151",
  c1815: "product/c1815",
  "product-details/c1815": "product/c1815",
  "product/detail/c1815": "product/c1815",
  "2p4m": "product/2p4m",
  "product-details/2p4m": "product/2p4m",
  "product/detail/2p4m": "product/2p4m",
  a1015: "product/a1015",
  "product-details/a1015": "product/a1015",
  "product/detail/a1015": "product/a1015",
  "1n5226-zener-diode": "product/1n5226-zener-diode",
  "product-details/1n5226-zener-diode": "product/1n5226-zener-diode",
  "product/detail/1n5226-zener-diode": "product/1n5226-zener-diode",
  "1n5227-zener-diode": "product/1n5227-zener-diode",
  "product-details/1n5227-zener-diode": "product/1n5227-zener-diode",
  "product/detail/1n5227-zener-diode": "product/1n5227-zener-diode",
  "1n5230-zener-diode": "product/1n5230-zener-diode",
  "product-details/1n5230-zener-diode": "product/1n5230-zener-diode",
  "product/detail/1n5230-zener-diode": "product/1n5230-zener-diode",
  "1n5236-zener-diode": "product/1n5236-zener-diode",
  "product-details/1n5236-zener-diode": "product/1n5236-zener-diode",
  "product/detail/1n5236-zener-diode": "product/1n5236-zener-diode",
  "1n5242-zener-diode": "product/1n5242-zener-diode",
  "product-details/1n5242-zener-diode": "product/1n5242-zener-diode",
  "product/detail/1n5242-zener-diode": "product/1n5242-zener-diode",
  "1n5245-zener-diode": "product/1n5245-zener-diode",
  "product-details/1n5245-zener-diode": "product/1n5245-zener-diode",
  "product/detail/1n5245-zener-diode": "product/1n5245-zener-diode",
  "1n5243-zener-diode": "product/1n5243-zener-diode",
  "product-details/1n5243-zener-diode": "product/1n5243-zener-diode",
  "product/detail/1n5243-zener-diode": "product/1n5243-zener-diode",
  "1n5240-zener-diode": "product/1n5240-zener-diode",
  "product-details/1n5240-zener-diode": "product/1n5240-zener-diode",
  "product/detail/1n5240-zener-diode": "product/1n5240-zener-diode",
  "1n4733-zener-diode": "product/1n4733-zener-diode",
  "product-details/1n4733-zener-diode": "product/1n4733-zener-diode",
  "product/detail/1n4733-zener-diode": "product/1n4733-zener-diode",
  "1n4734-zener-diode": "product/1n4734-zener-diode",
  "product-details/1n4734-zener-diode": "product/1n4734-zener-diode",
  "product/detail/1n4734-zener-diode": "product/1n4734-zener-diode",
  "1n4740-zener-diode": "product/1n4740-zener-diode",
  "product-details/1n4740-zener-diode": "product/1n4740-zener-diode",
  "product/detail/1n4740-zener-diode": "product/1n4740-zener-diode",
  "wild-scorpion-11.1v-900mah-li-po-battery":
    "product/wild-scorpion-11.1v-900mah-li-po-battery",
  "product-details/wild-scorpion-11.1v-900mah-li-po-battery":
    "product/wild-scorpion-11.1v-900mah-li-po-battery",
  "product/detail/wild-scorpion-11.1v-900mah-li-po-battery":
    "product/wild-scorpion-11.1v-900mah-li-po-battery",
  "wild-scorpion-11.1v-1500mah-li-po-battery":
    "product/wild-scorpion-11.1v-1500mah-li-po-battery",
  "product-details/wild-scorpion-11.1v-1500mah-li-po-battery":
    "product/wild-scorpion-11.1v-1500mah-li-po-battery",
  "product/detail/wild-scorpion-11.1v-1500mah-li-po-battery":
    "product/wild-scorpion-11.1v-1500mah-li-po-battery",
  "wild-scorpion-11.1v-2200mah-li-po-battery":
    "product/wild-scorpion-11.1v-2200mah-li-po-battery",
  "product-details/wild-scorpion-11.1v-2200mah-li-po-battery":
    "product/wild-scorpion-11.1v-2200mah-li-po-battery",
  "product/detail/wild-scorpion-11.1v-2200mah-li-po-battery":
    "product/wild-scorpion-11.1v-2200mah-li-po-battery",
  "imaxrc-b3-compact-li-po-charger-original":
    "product/imaxrc-b3-compact-li-po-charger-original",
  "product-details/imaxrc-b3-compact-li-po-charger-original":
    "product/imaxrc-b3-compact-li-po-charger-original",
  "product/detail/imaxrc-b3-compact-li-po-charger-original":
    "product/imaxrc-b3-compact-li-po-charger-original",
  "jackly-professional-31-in-1-screwdriver-set":
    "product/jackly-professional-31-in-1-screwdriver-set",
  "product-details/jackly-professional-31-in-1-screwdriver-set":
    "product/jackly-professional-31-in-1-screwdriver-set",
  "product/detail/jackly-professional-31-in-1-screwdriver-set":
    "product/jackly-professional-31-in-1-screwdriver-set",
  "micro-metal-gear-motor-200-rpm": "product/micro-metal-gear-motor-200-rpm",
  "product-details/micro-metal-gear-motor-200-rpm":
    "product/micro-metal-gear-motor-200-rpm",
  "product/detail/micro-metal-gear-motor-200-rpm":
    "product/micro-metal-gear-motor-200-rpm",
  "zip-tie": "product/zip-tie",
  "product-details/zip-tie": "product/zip-tie",
  "product/detail/zip-tie": "product/zip-tie",
  "servo-motor-mg996r-360-degree": "product/servo-motor-mg996r-360-degree",
  "product-details/servo-motor-mg996r-360-degree":
    "product/servo-motor-mg996r-360-degree",
  "product/detail/servo-motor-mg996r-360-degree":
    "product/servo-motor-mg996r-360-degree",
  "servo-motor-mg996r-180-degree": "product/servo-motor-mg996r-180-degree",
  "product-details/servo-motor-mg996r-180-degree":
    "product/servo-motor-mg996r-180-degree",
  "product/detail/servo-motor-mg996r-180-degree":
    "product/servo-motor-mg996r-180-degree",
  "servo-motor-sg91r": "product/servo-motor-sg91r",
  "product-details/servo-motor-sg91r": "product/servo-motor-sg91r",
  "product/detail/servo-motor-sg91r": "product/servo-motor-sg91r",
  "gas-sensor-mq-2": "product/gas-sensor-mq-2",
  "product-details/gas-sensor-mq-2": "product/gas-sensor-mq-2",
  "product/detail/gas-sensor-mq-2": "product/gas-sensor-mq-2",
  "330k-ohm-1-4w-resistor": "product/330k-ohm-1-4w-resistor",
  "product-details/330k-ohm-1-4w-resistor": "product/330k-ohm-1-4w-resistor",
  "product/detail/330k-ohm-1-4w-resistor": "product/330k-ohm-1-4w-resistor",
  "basic-robotics-kits-package": "product/basic-robotics-kits-package",
  "product-details/basic-robotics-kits-package":
    "product/basic-robotics-kits-package",
  "product/detail/basic-robotics-kits-package":
    "product/basic-robotics-kits-package",
  "basic-robotics-kits-package-with-rechargeable-battery":
    "product/basic-robotics-kits-package-with-rechargeable-battery",
  "product-details/basic-robotics-kits-package-with-rechargeable-battery":
    "product/basic-robotics-kits-package-with-rechargeable-battery",
  "product/detail/basic-robotics-kits-package-with-rechargeable-battery":
    "product/basic-robotics-kits-package-with-rechargeable-battery",
  "ir-transmitter-photodiode": "product/ir-transmitter-photodiode",
  "product-details/ir-transmitter-photodiode":
    "product/ir-transmitter-photodiode",
  "product/detail/ir-transmitter-photodiode":
    "product/ir-transmitter-photodiode",
  "ir-receiver-photodiode": "product/ir-receiver-photodiode",
  "product-details/ir-receiver-photodiode": "product/ir-receiver-photodiode",
  "product/detail/ir-receiver-photodiode": "product/ir-receiver-photodiode",
  "22-uf-capacitor": "product/22-uf-capacitor",
  "product-details/22-uf-capacitor": "product/22-uf-capacitor",
  "product/detail/22-uf-capacitor": "product/22-uf-capacitor",
  "rechargeable-li-ion-battery-2-cells-with-charger":
    "product/rechargeable-li-ion-battery-2-cells-with-charger",
  "product-details/rechargeable-li-ion-battery-2-cells-with-charger":
    "product/rechargeable-li-ion-battery-2-cells-with-charger",
  "product/detail/rechargeable-li-ion-battery-2-cells-with-charger":
    "product/rechargeable-li-ion-battery-2-cells-with-charger",
  "raspberry-pi-camera-module": "product/raspberry-pi-camera-module",
  "product-details/raspberry-pi-camera-module":
    "product/raspberry-pi-camera-module",
  "product/detail/raspberry-pi-camera-module":
    "product/raspberry-pi-camera-module",
  "ic-74283": "product/ic-74283",
  "product-details/ic-74283": "product/ic-74283",
  "product/detail/ic-74283": "product/ic-74283",
  "ic-74157": "product/ic-74157",
  "product-details/ic-74157": "product/ic-74157",
  "product/detail/ic-74157": "product/ic-74157",
  "dip-switch-8-bit": "product/dip-switch-8-bit",
  "product-details/dip-switch-8-bit": "product/dip-switch-8-bit",
  "product/detail/dip-switch-8-bit": "product/dip-switch-8-bit",
  "li-po-battery-voltage-tester": "product/li-po-battery-voltage-tester",
  "product-details/li-po-battery-voltage-tester":
    "product/li-po-battery-voltage-tester",
  "product/detail/li-po-battery-voltage-tester":
    "product/li-po-battery-voltage-tester",
  "sim900-quad-band-gsm-gprs-shield":
    "product/sim900-quad-band-gsm-gprs-shield",
  "product-details/sim900-quad-band-gsm-gprs-shield":
    "product/sim900-quad-band-gsm-gprs-shield",
  "product/detail/sim900-quad-band-gsm-gprs-shield":
    "product/sim900-quad-band-gsm-gprs-shield",
  "ic-max232": "product/ic-max232",
  "product-details/ic-max232": "product/ic-max232",
  "product/detail/ic-max232": "product/ic-max232",
  "ad8232-heart-rate-monitor": "product/ad8232-heart-rate-monitor",
  "product-details/ad8232-heart-rate-monitor":
    "product/ad8232-heart-rate-monitor",
  "product/detail/ad8232-heart-rate-monitor":
    "product/ad8232-heart-rate-monitor",
  "mpu-6050-triple-axis-accelerometer-and-gyro-breakout":
    "product/mpu-6050-triple-axis-accelerometer-and-gyro-breakout",
  "product-details/mpu-6050-triple-axis-accelerometer-and-gyro-breakout":
    "product/mpu-6050-triple-axis-accelerometer-and-gyro-breakout",
  "product/detail/mpu-6050-triple-axis-accelerometer-and-gyro-breakout":
    "product/mpu-6050-triple-axis-accelerometer-and-gyro-breakout",
  "pulse-sensor": "product/pulse-sensor",
  "product-details/pulse-sensor": "product/pulse-sensor",
  "product/detail/pulse-sensor": "product/pulse-sensor",
  "raspberry-pi-3-case": "product/raspberry-pi-3-case",
  "product-details/raspberry-pi-3-case": "product/raspberry-pi-3-case",
  "product/detail/raspberry-pi-3-case": "product/raspberry-pi-3-case",
  "raspberry-pi-case-transparent": "product/raspberry-pi-case-transparent",
  "product-details/raspberry-pi-case-transparent":
    "product/raspberry-pi-case-transparent",
  "product/detail/raspberry-pi-case-transparent":
    "product/raspberry-pi-case-transparent",
  "esho-robot-banai": "product/esho-robot-banai",
  "product-details/esho-robot-banai": "product/esho-robot-banai",
  "product/detail/esho-robot-banai": "product/esho-robot-banai",
  "pyroelectric-infrared-motion-sensor-module":
    "product/pyroelectric-infrared-motion-sensor-module",
  "product-details/pyroelectric-infrared-motion-sensor-module":
    "product/pyroelectric-infrared-motion-sensor-module",
  "product/detail/pyroelectric-infrared-motion-sensor-module":
    "product/pyroelectric-infrared-motion-sensor-module",
  lm741: "product/lm741",
  "product-details/lm741": "product/lm741",
  "product/detail/lm741": "product/lm741",
  "raspberry-pi-power-adapter-hq": "product/raspberry-pi-power-adapter-hq",
  "product-details/raspberry-pi-power-adapter-hq":
    "product/raspberry-pi-power-adapter-hq",
  "product/detail/raspberry-pi-power-adapter-hq":
    "product/raspberry-pi-power-adapter-hq",
  "hdmi-cable-raspberry-pi-compatible":
    "product/hdmi-cable-raspberry-pi-compatible",
  "product-details/hdmi-cable-raspberry-pi-compatible":
    "product/hdmi-cable-raspberry-pi-compatible",
  "product/detail/hdmi-cable-raspberry-pi-compatible":
    "product/hdmi-cable-raspberry-pi-compatible",
  veroboard: "product/veroboard",
  "product-details/veroboard": "product/veroboard",
  "product/detail/veroboard": "product/veroboard",
  "5-array-ir-sensor": "product/5-array-ir-sensor",
  "product-details/5-array-ir-sensor": "product/5-array-ir-sensor",
  "product/detail/5-array-ir-sensor": "product/5-array-ir-sensor",
  "servo-motor-sg90": "product/servo-motor-sg90",
  "product-details/servo-motor-sg90": "product/servo-motor-sg90",
  "product/detail/servo-motor-sg90": "product/servo-motor-sg90",
  "teamgroup-16gb-microsd-card-original":
    "product/teamgroup-16gb-microsd-card-original",
  "product-details/teamgroup-16gb-microsd-card-original":
    "product/teamgroup-16gb-microsd-card-original",
  "product/detail/teamgroup-16gb-microsd-card-original":
    "product/teamgroup-16gb-microsd-card-original",
  "dht22-temperature-and-humidity-sensor":
    "product/dht22-temperature-and-humidity-sensor",
  "product-details/dht22-temperature-and-humidity-sensor":
    "product/dht22-temperature-and-humidity-sensor",
  "product/detail/dht22-temperature-and-humidity-sensor":
    "product/dht22-temperature-and-humidity-sensor",
  "push-switch-2-pin": "product/push-switch-2-pin",
  "product-details/push-switch-2-pin": "product/push-switch-2-pin",
  "product/detail/push-switch-2-pin": "product/push-switch-2-pin",
  "dht11-temperature-and-humidity-sensor":
    "product/dht11-temperature-and-humidity-sensor",
  "product-details/dht11-temperature-and-humidity-sensor":
    "product/dht11-temperature-and-humidity-sensor",
  "product/detail/dht11-temperature-and-humidity-sensor":
    "product/dht11-temperature-and-humidity-sensor",
  "9v-rechargeable-battery": "product/9v-rechargeable-battery",
  "product-details/9v-rechargeable-battery": "product/9v-rechargeable-battery",
  "product/detail/9v-rechargeable-battery": "product/9v-rechargeable-battery",
  "everlast-value-charger": "product/everlast-value-charger",
  "product-details/everlast-value-charger": "product/everlast-value-charger",
  "product/detail/everlast-value-charger": "product/everlast-value-charger",
  "esp8266-wifi-module": "product/esp8266-wifi-module",
  "product-details/esp8266-wifi-module": "product/esp8266-wifi-module",
  "product/detail/esp8266-wifi-module": "product/esp8266-wifi-module",
  "mini-starter-kit": "product/mini-starter-kit",
  "product-details/mini-starter-kit": "product/mini-starter-kit",
  "product/detail/mini-starter-kit": "product/mini-starter-kit",
  "robot-car-chassis-4wd-set": "product/robot-car-chassis-4wd-set",
  "product-details/robot-car-chassis-4wd-set":
    "product/robot-car-chassis-4wd-set",
  "product/detail/robot-car-chassis-4wd-set":
    "product/robot-car-chassis-4wd-set",
  "ic-4046": "product/ic-4046",
  "product-details/ic-4046": "product/ic-4046",
  "product/detail/ic-4046": "product/ic-4046",
  "2032-battery-holder": "product/2032-battery-holder",
  "product-details/2032-battery-holder": "product/2032-battery-holder",
  "product/detail/2032-battery-holder": "product/2032-battery-holder",
  "8-pin-ic-socket": "product/8-pin-ic-socket",
  "product-details/8-pin-ic-socket": "product/8-pin-ic-socket",
  "product/detail/8-pin-ic-socket": "product/8-pin-ic-socket",
  "14-pin-ic-socket": "product/14-pin-ic-socket",
  "product-details/14-pin-ic-socket": "product/14-pin-ic-socket",
  "product/detail/14-pin-ic-socket": "product/14-pin-ic-socket",
  "28-pin-ic-socket": "product/28-pin-ic-socket",
  "product-details/28-pin-ic-socket": "product/28-pin-ic-socket",
  "product/detail/28-pin-ic-socket": "product/28-pin-ic-socket",
  "green-connector-2-pin-kf128": "product/green-connector-2-pin-kf128",
  "product-details/green-connector-2-pin-kf128":
    "product/green-connector-2-pin-kf128",
  "product/detail/green-connector-2-pin-kf128":
    "product/green-connector-2-pin-kf128",
  "ir-sensor-module-single-array": "product/ir-sensor-module-single-array",
  "product-details/ir-sensor-module-single-array":
    "product/ir-sensor-module-single-array",
  "product/detail/ir-sensor-module-single-array":
    "product/ir-sensor-module-single-array",
  "gas-sensor-mq-5": "product/gas-sensor-mq-5",
  "product-details/gas-sensor-mq-5": "product/gas-sensor-mq-5",
  "product/detail/gas-sensor-mq-5": "product/gas-sensor-mq-5",
  "water-sensor-module": "product/water-sensor-module",
  "product-details/water-sensor-module": "product/water-sensor-module",
  "product/detail/water-sensor-module": "product/water-sensor-module",
  "soil-moisture-sensor": "product/soil-moisture-sensor",
  "product-details/soil-moisture-sensor": "product/soil-moisture-sensor",
  "product/detail/soil-moisture-sensor": "product/soil-moisture-sensor",
  "1-channel-relay-5v": "product/1-channel-relay-5v",
  "product-details/1-channel-relay-5v": "product/1-channel-relay-5v",
  "product/detail/1-channel-relay-5v": "product/1-channel-relay-5v",
  "6v-relay": "product/6v-relay",
  "product-details/6v-relay": "product/6v-relay",
  "product/detail/6v-relay": "product/6v-relay",
  "5v-relay": "product/5v-relay",
  "product-details/5v-relay": "product/5v-relay",
  "product/detail/5v-relay": "product/5v-relay",
  "barometric-pressure-sensor-bmp180":
    "product/barometric-pressure-sensor-bmp180",
  "product-details/barometric-pressure-sensor-bmp180":
    "product/barometric-pressure-sensor-bmp180",
  "product/detail/barometric-pressure-sensor-bmp180":
    "product/barometric-pressure-sensor-bmp180",
  "16mhz-crystal-oscillator": "product/16mhz-crystal-oscillator",
  "product-details/16mhz-crystal-oscillator":
    "product/16mhz-crystal-oscillator",
  "product/detail/16mhz-crystal-oscillator": "product/16mhz-crystal-oscillator",
  "20mhz-crystal-oscillator": "product/20mhz-crystal-oscillator",
  "product-details/20mhz-crystal-oscillator":
    "product/20mhz-crystal-oscillator",
  "product/detail/20mhz-crystal-oscillator": "product/20mhz-crystal-oscillator",
  "pickit-3-pic-microcontroller-programmer":
    "product/pickit-3-pic-microcontroller-programmer",
  "product-details/pickit-3-pic-microcontroller-programmer":
    "product/pickit-3-pic-microcontroller-programmer",
  "product/detail/pickit-3-pic-microcontroller-programmer":
    "product/pickit-3-pic-microcontroller-programmer",
  pic16f877a: "product/pic16f877a",
  "product-details/pic16f877a": "product/pic16f877a",
  "product/detail/pic16f877a": "product/pic16f877a",
  pic16f73: "product/pic16f73",
  "product-details/pic16f73": "product/pic16f73",
  "product/detail/pic16f73": "product/pic16f73",
  pic16f676: "product/pic16f676",
  "product-details/pic16f676": "product/pic16f676",
  "product/detail/pic16f676": "product/pic16f676",
  pic16f628a: "product/pic16f628a",
  "product-details/pic16f628a": "product/pic16f628a",
  "product/detail/pic16f628a": "product/pic16f628a",
  pic18f452: "product/pic18f452",
  "product-details/pic18f452": "product/pic18f452",
  "product/detail/pic18f452": "product/pic18f452",
  "ceramic-capacitor-101-100pf-10nf":
    "product/ceramic-capacitor-101-100pf-10nf",
  "product-details/ceramic-capacitor-101-100pf-10nf":
    "product/ceramic-capacitor-101-100pf-10nf",
  "product/detail/ceramic-capacitor-101-100pf-10nf":
    "product/ceramic-capacitor-101-100pf-10nf",
  "ceramic-capacitor-220-22pf": "product/ceramic-capacitor-220-22pf",
  "product-details/ceramic-capacitor-220-22pf":
    "product/ceramic-capacitor-220-22pf",
  "product/detail/ceramic-capacitor-220-22pf":
    "product/ceramic-capacitor-220-22pf",
  "dc-power-jack": "product/dc-power-jack",
  "product-details/dc-power-jack": "product/dc-power-jack",
  "product/detail/dc-power-jack": "product/dc-power-jack",
  "dc-socket": "product/dc-socket",
  "product-details/dc-socket": "product/dc-socket",
  "product/detail/dc-socket": "product/dc-socket",
  "raspberry-pi-zero-w": "product/raspberry-pi-zero-w",
  "product-details/raspberry-pi-zero-w": "product/raspberry-pi-zero-w",
  "product/detail/raspberry-pi-zero-w": "product/raspberry-pi-zero-w",
  "raspberry-pi-zero-w-acrylic-case":
    "product/raspberry-pi-zero-w-acrylic-case",
  "product-details/raspberry-pi-zero-w-acrylic-case":
    "product/raspberry-pi-zero-w-acrylic-case",
  "product/detail/raspberry-pi-zero-w-acrylic-case":
    "product/raspberry-pi-zero-w-acrylic-case",
  "veroboard-double-layer-6x8cm": "product/veroboard-double-layer-6x8cm",
  "product-details/veroboard-double-layer-6x8cm":
    "product/veroboard-double-layer-6x8cm",
  "product/detail/veroboard-double-layer-6x8cm":
    "product/veroboard-double-layer-6x8cm",
  "veroboard-double-layer-7x9cm": "product/veroboard-double-layer-7x9cm",
  "product-details/veroboard-double-layer-7x9cm":
    "product/veroboard-double-layer-7x9cm",
  "product/detail/veroboard-double-layer-7x9cm":
    "product/veroboard-double-layer-7x9cm",
  "ic-4027": "product/ic-4027",
  "product-details/ic-4027": "product/ic-4027",
  "product/detail/ic-4027": "product/ic-4027",
  "ic-4026": "product/ic-4026",
  "product-details/ic-4026": "product/ic-4026",
  "product/detail/ic-4026": "product/ic-4026",
  "heat-sink-set": "product/heat-sink-set",
  "product-details/heat-sink-set": "product/heat-sink-set",
  "product/detail/heat-sink-set": "product/heat-sink-set",
  "sim900a-gsm-gprs-module-v4.0": "product/sim900a-gsm-gprs-module-v4.0",
  "product-details/sim900a-gsm-gprs-module-v4.0":
    "product/sim900a-gsm-gprs-module-v4.0",
  "product/detail/sim900a-gsm-gprs-module-v4.0":
    "product/sim900a-gsm-gprs-module-v4.0",
  "pic-programming-adapter-for-pickit-2,-pickit-3":
    "product/pic-programming-adapter-for-pickit-2,-pickit-3",
  "product-details/pic-programming-adapter-for-pickit-2,-pickit-3":
    "product/pic-programming-adapter-for-pickit-2,-pickit-3",
  "product/detail/pic-programming-adapter-for-pickit-2,-pickit-3":
    "product/pic-programming-adapter-for-pickit-2,-pickit-3",
  "card-reader": "product/card-reader",
  "product-details/card-reader": "product/card-reader",
  "product/detail/card-reader": "product/card-reader",
  "4mhz-crystal-oscillator": "product/4mhz-crystal-oscillator",
  "product-details/4mhz-crystal-oscillator": "product/4mhz-crystal-oscillator",
  "product/detail/4mhz-crystal-oscillator": "product/4mhz-crystal-oscillator",
  "8mhz-crystal-oscillator": "product/8mhz-crystal-oscillator",
  "product-details/8mhz-crystal-oscillator": "product/8mhz-crystal-oscillator",
  "product/detail/8mhz-crystal-oscillator": "product/8mhz-crystal-oscillator",
  "peltier-thermo-electric-cooler-tec1-12706":
    "product/peltier-thermo-electric-cooler-tec1-12706",
  "product-details/peltier-thermo-electric-cooler-tec1-12706":
    "product/peltier-thermo-electric-cooler-tec1-12706",
  "product/detail/peltier-thermo-electric-cooler-tec1-12706":
    "product/peltier-thermo-electric-cooler-tec1-12706",
  "5v-stepper-motor-with-uln2003-driver-board":
    "product/5v-stepper-motor-with-uln2003-driver-board",
  "product-details/5v-stepper-motor-with-uln2003-driver-board":
    "product/5v-stepper-motor-with-uln2003-driver-board",
  "product/detail/5v-stepper-motor-with-uln2003-driver-board":
    "product/5v-stepper-motor-with-uln2003-driver-board",
  "125khz-rfid-card": "product/125khz-rfid-card",
  "product-details/125khz-rfid-card": "product/125khz-rfid-card",
  "product/detail/125khz-rfid-card": "product/125khz-rfid-card",
  "a4988-stepper-motor-driver-module":
    "product/a4988-stepper-motor-driver-module",
  "product-details/a4988-stepper-motor-driver-module":
    "product/a4988-stepper-motor-driver-module",
  "product/detail/a4988-stepper-motor-driver-module":
    "product/a4988-stepper-motor-driver-module",
  "1uf-capacitor-50v": "product/1uf-capacitor-50v",
  "product-details/1uf-capacitor-50v": "product/1uf-capacitor-50v",
  "product/detail/1uf-capacitor-50v": "product/1uf-capacitor-50v",
  "ic-4017": "product/ic-4017",
  "product-details/ic-4017": "product/ic-4017",
  "product/detail/ic-4017": "product/ic-4017",
  "rc522-rfid-card-reader-detector": "product/rc522-rfid-card-reader-detector",
  "product-details/rc522-rfid-card-reader-detector":
    "product/rc522-rfid-card-reader-detector",
  "product/detail/rc522-rfid-card-reader-detector":
    "product/rc522-rfid-card-reader-detector",
  "13.56-mhz-rfid-card": "product/13.56-mhz-rfid-card",
  "product-details/13.56-mhz-rfid-card": "product/13.56-mhz-rfid-card",
  "product/detail/13.56-mhz-rfid-card": "product/13.56-mhz-rfid-card",
  "125khz-rfid-reader-module": "product/125khz-rfid-reader-module",
  "product-details/125khz-rfid-reader-module":
    "product/125khz-rfid-reader-module",
  "product/detail/125khz-rfid-reader-module":
    "product/125khz-rfid-reader-module",
  "ceramic-capacitor-470-47-pf": "product/ceramic-capacitor-470-47-pf",
  "product-details/ceramic-capacitor-470-47-pf":
    "product/ceramic-capacitor-470-47-pf",
  "product/detail/ceramic-capacitor-470-47-pf":
    "product/ceramic-capacitor-470-47-pf",
  "3.57mhz-crystal-oscillator": "product/3.57mhz-crystal-oscillator",
  "product-details/3.57mhz-crystal-oscillator":
    "product/3.57mhz-crystal-oscillator",
  "product/detail/3.57mhz-crystal-oscillator":
    "product/3.57mhz-crystal-oscillator",
  "2n7000": "product/2n7000",
  "product-details/2n7000": "product/2n7000",
  "product/detail/2n7000": "product/2n7000",
  "serial-i2c-lcd-display-adapter": "product/serial-i2c-lcd-display-adapter",
  "product-details/serial-i2c-lcd-display-adapter":
    "product/serial-i2c-lcd-display-adapter",
  "product/detail/serial-i2c-lcd-display-adapter":
    "product/serial-i2c-lcd-display-adapter",
  "ic-7485": "product/ic-7485",
  "product-details/ic-7485": "product/ic-7485",
  "product/detail/ic-7485": "product/ic-7485",
  "ic-7447": "product/ic-7447",
  "product-details/ic-7447": "product/ic-7447",
  "product/detail/ic-7447": "product/ic-7447",
  "qtr-8a-reflectance-sensor-array": "product/qtr-8a-reflectance-sensor-array",
  "product-details/qtr-8a-reflectance-sensor-array":
    "product/qtr-8a-reflectance-sensor-array",
  "product/detail/qtr-8a-reflectance-sensor-array":
    "product/qtr-8a-reflectance-sensor-array",
  "xl6009-step-up-boost-converter": "product/xl6009-step-up-boost-converter",
  "product-details/xl6009-step-up-boost-converter":
    "product/xl6009-step-up-boost-converter",
  "product/detail/xl6009-step-up-boost-converter":
    "product/xl6009-step-up-boost-converter",
  "lm2596-step-down-buck-converter": "product/lm2596-step-down-buck-converter",
  "product-details/lm2596-step-down-buck-converter":
    "product/lm2596-step-down-buck-converter",
  "product/detail/lm2596-step-down-buck-converter":
    "product/lm2596-step-down-buck-converter",
  "1k-ohm-ntc-thermistor": "product/1k-ohm-ntc-thermistor",
  "product-details/1k-ohm-ntc-thermistor": "product/1k-ohm-ntc-thermistor",
  "product/detail/1k-ohm-ntc-thermistor": "product/1k-ohm-ntc-thermistor",
  "arduino-ethernet-shield-w5100": "product/arduino-ethernet-shield-w5100",
  "product-details/arduino-ethernet-shield-w5100":
    "product/arduino-ethernet-shield-w5100",
  "product/detail/arduino-ethernet-shield-w5100":
    "product/arduino-ethernet-shield-w5100",
  "dc-fan-12v-3.1": "product/dc-fan-12v-3.1",
  "product-details/dc-fan-12v-3.1": "product/dc-fan-12v-3.1",
  "product/detail/dc-fan-12v-3.1": "product/dc-fan-12v-3.1",
  "esp-12f-wifi-module": "product/esp-12f-wifi-module",
  "product-details/esp-12f-wifi-module": "product/esp-12f-wifi-module",
  "product/detail/esp-12f-wifi-module": "product/esp-12f-wifi-module",
  "veroboard-single-layer-7x9cm": "product/veroboard-single-layer-7x9cm",
  "product-details/veroboard-single-layer-7x9cm":
    "product/veroboard-single-layer-7x9cm",
  "product/detail/veroboard-single-layer-7x9cm":
    "product/veroboard-single-layer-7x9cm",
  "ic-74107": "product/ic-74107",
  "product-details/ic-74107": "product/ic-74107",
  "product/detail/ic-74107": "product/ic-74107",
  "mini-epoxy-solar-cell-module": "product/mini-epoxy-solar-cell-module",
  "product-details/mini-epoxy-solar-cell-module":
    "product/mini-epoxy-solar-cell-module",
  "product/detail/mini-epoxy-solar-cell-module":
    "product/mini-epoxy-solar-cell-module",
  "7-segment-display-cc-3-digit": "product/7-segment-display-cc-3-digit",
  "product-details/7-segment-display-cc-3-digit":
    "product/7-segment-display-cc-3-digit",
  "product/detail/7-segment-display-cc-3-digit":
    "product/7-segment-display-cc-3-digit",
  "6-segment-battery-display": "product/6-segment-battery-display",
  "product-details/6-segment-battery-display":
    "product/6-segment-battery-display",
  "product/detail/6-segment-battery-display":
    "product/6-segment-battery-display",
  "lcd-display-20-x-4": "product/lcd-display-20-x-4",
  "product-details/lcd-display-20-x-4": "product/lcd-display-20-x-4",
  "product/detail/lcd-display-20-x-4": "product/lcd-display-20-x-4",
  "1k-ohms-potentiometer": "product/1k-ohms-potentiometer",
  "product-details/1k-ohms-potentiometer": "product/1k-ohms-potentiometer",
  "product/detail/1k-ohms-potentiometer": "product/1k-ohms-potentiometer",
  "10k-ohm-trimpot": "product/10k-ohm-trimpot",
  "product-details/10k-ohm-trimpot": "product/10k-ohm-trimpot",
  "product/detail/10k-ohm-trimpot": "product/10k-ohm-trimpot",
  "crocodile-clip-pair": "product/crocodile-clip-pair",
  "product-details/crocodile-clip-pair": "product/crocodile-clip-pair",
  "product/detail/crocodile-clip-pair": "product/crocodile-clip-pair",
  "sm3388tsop1738-ir-receiver": "product/sm3388tsop1738-ir-receiver",
  "product-details/sm3388tsop1738-ir-receiver":
    "product/sm3388tsop1738-ir-receiver",
  "product/detail/sm3388tsop1738-ir-receiver":
    "product/sm3388tsop1738-ir-receiver",
  "3.5mm-jack-plug": "product/3.5mm-jack-plug",
  "product-details/3.5mm-jack-plug": "product/3.5mm-jack-plug",
  "product/detail/3.5mm-jack-plug": "product/3.5mm-jack-plug",
  "miniq-metal-ball-caster-wheel": "product/miniq-metal-ball-caster-wheel",
  "product-details/miniq-metal-ball-caster-wheel":
    "product/miniq-metal-ball-caster-wheel",
  "product/detail/miniq-metal-ball-caster-wheel":
    "product/miniq-metal-ball-caster-wheel",
  "robotics-pack-for-robo101-v.2": "product/robotics-pack-for-robo101-v.2",
  "product-details/robotics-pack-for-robo101-v.2":
    "product/robotics-pack-for-robo101-v.2",
  "product/detail/robotics-pack-for-robo101-v.2":
    "product/robotics-pack-for-robo101-v.2",
  "magnetic-door-switch-set": "product/magnetic-door-switch-set",
  "product-details/magnetic-door-switch-set":
    "product/magnetic-door-switch-set",
  "product/detail/magnetic-door-switch-set": "product/magnetic-door-switch-set",
  "rgb-led-5mm": "product/rgb-led-5mm",
  "product-details/rgb-led-5mm": "product/rgb-led-5mm",
  "product/detail/rgb-led-5mm": "product/rgb-led-5mm",
  "5v-2a-power-bank-charger-module": "product/5v-2a-power-bank-charger-module",
  "product-details/5v-2a-power-bank-charger-module":
    "product/5v-2a-power-bank-charger-module",
  "product/detail/5v-2a-power-bank-charger-module":
    "product/5v-2a-power-bank-charger-module",
  "raspberry-pi-3-model-b-plus": "product/raspberry-pi-3-model-b-plus",
  "product-details/raspberry-pi-3-model-b-plus":
    "product/raspberry-pi-3-model-b-plus",
  "product/detail/raspberry-pi-3-model-b-plus":
    "product/raspberry-pi-3-model-b-plus",
  "10-ohm-1-4w-resistor": "product/10-ohm-1-4w-resistor",
  "product-details/10-ohm-1-4w-resistor": "product/10-ohm-1-4w-resistor",
  "product/detail/10-ohm-1-4w-resistor": "product/10-ohm-1-4w-resistor",
  lm3914: "product/lm3914",
  "product-details/lm3914": "product/lm3914",
  "product/detail/lm3914": "product/lm3914",
  "5v-1a-power-adapter": "product/5v-1a-power-adapter",
  "product-details/5v-1a-power-adapter": "product/5v-1a-power-adapter",
  "product/detail/5v-1a-power-adapter": "product/5v-1a-power-adapter",
  "gas-sensor-mq-135": "product/gas-sensor-mq-135",
  "product-details/gas-sensor-mq-135": "product/gas-sensor-mq-135",
  "product/detail/gas-sensor-mq-135": "product/gas-sensor-mq-135",
  "heat-sink-compound-by-halnziye": "product/heat-sink-compound-by-halnziye",
  "product-details/heat-sink-compound-by-halnziye":
    "product/heat-sink-compound-by-halnziye",
  "product/detail/heat-sink-compound-by-halnziye":
    "product/heat-sink-compound-by-halnziye",
  "water-flow-sensor-g1-2": "product/water-flow-sensor-g1-2",
  "product-details/water-flow-sensor-g1-2": "product/water-flow-sensor-g1-2",
  "product/detail/water-flow-sensor-g1-2": "product/water-flow-sensor-g1-2",
  "gps-module-neo6mv2": "product/gps-module-neo6mv2",
  "product-details/gps-module-neo6mv2": "product/gps-module-neo6mv2",
  "product/detail/gps-module-neo6mv2": "product/gps-module-neo6mv2",
  "microsd-card-module-for-arduino": "product/microsd-card-module-for-arduino",
  "product-details/microsd-card-module-for-arduino":
    "product/microsd-card-module-for-arduino",
  "product/detail/microsd-card-module-for-arduino":
    "product/microsd-card-module-for-arduino",
  "12v-5a-power-adapter": "product/12v-5a-power-adapter",
  "product-details/12v-5a-power-adapter": "product/12v-5a-power-adapter",
  "product/detail/12v-5a-power-adapter": "product/12v-5a-power-adapter",
  "ic-4033": "product/ic-4033",
  "product-details/ic-4033": "product/ic-4033",
  "product/detail/ic-4033": "product/ic-4033",
  "ds3231-rtc-module-with-battery": "product/ds3231-rtc-module-with-battery",
  "product-details/ds3231-rtc-module-with-battery":
    "product/ds3231-rtc-module-with-battery",
  "product/detail/ds3231-rtc-module-with-battery":
    "product/ds3231-rtc-module-with-battery",
  "470uf-25v": "product/470uf-25v",
  "product-details/470uf-25v": "product/470uf-25v",
  "product/detail/470uf-25v": "product/470uf-25v",
  "2200uf-16v": "product/2200uf-16v",
  "product-details/2200uf-16v": "product/2200uf-16v",
  "product/detail/2200uf-16v": "product/2200uf-16v",
  "2200uf-25v": "product/2200uf-25v",
  "product-details/2200uf-25v": "product/2200uf-25v",
  "product/detail/2200uf-25v": "product/2200uf-25v",
  "2200uf-35v": "product/2200uf-35v",
  "product-details/2200uf-35v": "product/2200uf-35v",
  "product/detail/2200uf-35v": "product/2200uf-35v",
  "2200uf-50v": "product/2200uf-50v",
  "product-details/2200uf-50v": "product/2200uf-50v",
  "product/detail/2200uf-50v": "product/2200uf-50v",
  "820uf-6.3v": "product/820uf-6.3v",
  "product-details/820uf-6.3v": "product/820uf-6.3v",
  "product/detail/820uf-6.3v": "product/820uf-6.3v",
  "1000uf-50v": "product/1000uf-50v",
  "product-details/1000uf-50v": "product/1000uf-50v",
  "product/detail/1000uf-50v": "product/1000uf-50v",
  "100uf-50v": "product/100uf-50v",
  "product-details/100uf-50v": "product/100uf-50v",
  "product/detail/100uf-50v": "product/100uf-50v",
  "330uf-50v": "product/330uf-50v",
  "product-details/330uf-50v": "product/330uf-50v",
  "product/detail/330uf-50v": "product/330uf-50v",
  "10uf-25v": "product/10uf-25v",
  "product-details/10uf-25v": "product/10uf-25v",
  "product/detail/10uf-25v": "product/10uf-25v",
  "0.1uf-50v": "product/0.1uf-50v",
  "product-details/0.1uf-50v": "product/0.1uf-50v",
  "product/detail/0.1uf-50v": "product/0.1uf-50v",
  "1000uf-35v": "product/1000uf-35v",
  "product-details/1000uf-35v": "product/1000uf-35v",
  "product/detail/1000uf-35v": "product/1000uf-35v",
  "33uf-50v": "product/33uf-50v",
  "product-details/33uf-50v": "product/33uf-50v",
  "product/detail/33uf-50v": "product/33uf-50v",
  "470uf-16v": "product/470uf-16v",
  "product-details/470uf-16v": "product/470uf-16v",
  "product/detail/470uf-16v": "product/470uf-16v",
  "3.3uf-50v": "product/3.3uf-50v",
  "product-details/3.3uf-50v": "product/3.3uf-50v",
  "product/detail/3.3uf-50v": "product/3.3uf-50v",
  "10uf-16v": "product/10uf-16v",
  "product-details/10uf-16v": "product/10uf-16v",
  "product/detail/10uf-16v": "product/10uf-16v",
  "22uf-16v": "product/22uf-16v",
  "product-details/22uf-16v": "product/22uf-16v",
  "product/detail/22uf-16v": "product/22uf-16v",
  "4.7uf-50v": "product/4.7uf-50v",
  "product-details/4.7uf-50v": "product/4.7uf-50v",
  "product/detail/4.7uf-50v": "product/4.7uf-50v",
  "68-uf-ceramic-capacitor": "product/68-uf-ceramic-capacitor",
  "product-details/68-uf-ceramic-capacitor": "product/68-uf-ceramic-capacitor",
  "product/detail/68-uf-ceramic-capacitor": "product/68-uf-ceramic-capacitor",
  "33nf-33000pf-333k-ceramic-capacitors":
    "product/33nf-33000pf-333k-ceramic-capacitors",
  "product-details/33nf-33000pf-333k-ceramic-capacitors":
    "product/33nf-33000pf-333k-ceramic-capacitors",
  "product/detail/33nf-33000pf-333k-ceramic-capacitors":
    "product/33nf-33000pf-333k-ceramic-capacitors",
  ".33uf-33k-ceramic-capacitors": "product/.33uf-33k-ceramic-capacitors",
  "product-details/.33uf-33k-ceramic-capacitors":
    "product/.33uf-33k-ceramic-capacitors",
  "product/detail/.33uf-33k-ceramic-capacitors":
    "product/.33uf-33k-ceramic-capacitors",
  "20nf-ceramic-capacitors": "product/20nf-ceramic-capacitors",
  "product-details/20nf-ceramic-capacitors": "product/20nf-ceramic-capacitors",
  "product/detail/20nf-ceramic-capacitors": "product/20nf-ceramic-capacitors",
  "10nf-ceramic-capacitors": "product/10nf-ceramic-capacitors",
  "product-details/10nf-ceramic-capacitors": "product/10nf-ceramic-capacitors",
  "product/detail/10nf-ceramic-capacitors": "product/10nf-ceramic-capacitors",
  "3.5mm-female-stereo-audio-connector":
    "product/3.5mm-female-stereo-audio-connector",
  "product-details/3.5mm-female-stereo-audio-connector":
    "product/3.5mm-female-stereo-audio-connector",
  "product/detail/3.5mm-female-stereo-audio-connector":
    "product/3.5mm-female-stereo-audio-connector",
  "high-sensitivity-microphone-sensor-module":
    "product/high-sensitivity-microphone-sensor-module",
  "product-details/high-sensitivity-microphone-sensor-module":
    "product/high-sensitivity-microphone-sensor-module",
  "product/detail/high-sensitivity-microphone-sensor-module":
    "product/high-sensitivity-microphone-sensor-module",
  "vibration-sensor-module": "product/vibration-sensor-module",
  "product-details/vibration-sensor-module": "product/vibration-sensor-module",
  "product/detail/vibration-sensor-module": "product/vibration-sensor-module",
  "gas-sensor-mq-7": "product/gas-sensor-mq-7",
  "product-details/gas-sensor-mq-7": "product/gas-sensor-mq-7",
  "product/detail/gas-sensor-mq-7": "product/gas-sensor-mq-7",
  "gas-sensor-mq-9": "product/gas-sensor-mq-9",
  "product-details/gas-sensor-mq-9": "product/gas-sensor-mq-9",
  "product/detail/gas-sensor-mq-9": "product/gas-sensor-mq-9",
  "ne556-dual-timer-ic": "product/ne556-dual-timer-ic",
  "product-details/ne556-dual-timer-ic": "product/ne556-dual-timer-ic",
  "product/detail/ne556-dual-timer-ic": "product/ne556-dual-timer-ic",
  "glue-gun-mini-20-w": "product/glue-gun-mini-20-w",
  "product-details/glue-gun-mini-20-w": "product/glue-gun-mini-20-w",
  "product/detail/glue-gun-mini-20-w": "product/glue-gun-mini-20-w",
  "glue-gun-stick-mini": "product/glue-gun-stick-mini",
  "product-details/glue-gun-stick-mini": "product/glue-gun-stick-mini",
  "product/detail/glue-gun-stick-mini": "product/glue-gun-stick-mini",
  "digital-multimeter-ut33b-plus": "product/digital-multimeter-ut33b-plus",
  "product-details/digital-multimeter-ut33b-plus":
    "product/digital-multimeter-ut33b-plus",
  "product/detail/digital-multimeter-ut33b-plus":
    "product/digital-multimeter-ut33b-plus",
  "nodemcu-lua-v3-esp8266-wifi-with-ch340g":
    "product/nodemcu-lua-v3-esp8266-wifi-with-ch340g",
  "product-details/nodemcu-lua-v3-esp8266-wifi-with-ch340g":
    "product/nodemcu-lua-v3-esp8266-wifi-with-ch340g",
  "product/detail/nodemcu-lua-v3-esp8266-wifi-with-ch340g":
    "product/nodemcu-lua-v3-esp8266-wifi-with-ch340g",
  "nodemcu-lua-v2-esp8266-wifi-with-cp2102":
    "product/nodemcu-lua-v2-esp8266-wifi-with-cp2102",
  "product-details/nodemcu-lua-v2-esp8266-wifi-with-cp2102":
    "product/nodemcu-lua-v2-esp8266-wifi-with-cp2102",
  "product/detail/nodemcu-lua-v2-esp8266-wifi-with-cp2102":
    "product/nodemcu-lua-v2-esp8266-wifi-with-cp2102",
  "multi-color-led-5mm-slow": "product/multi-color-led-5mm-slow",
  "product-details/multi-color-led-5mm-slow":
    "product/multi-color-led-5mm-slow",
  "product/detail/multi-color-led-5mm-slow": "product/multi-color-led-5mm-slow",
  "16x2-lcd-keypad-shield": "product/16x2-lcd-keypad-shield",
  "product-details/16x2-lcd-keypad-shield": "product/16x2-lcd-keypad-shield",
  "product/detail/16x2-lcd-keypad-shield": "product/16x2-lcd-keypad-shield",
  "2.1mm-barrel-type-male-dc-power-jack":
    "product/2.1mm-barrel-type-male-dc-power-jack",
  "product-details/2.1mm-barrel-type-male-dc-power-jack":
    "product/2.1mm-barrel-type-male-dc-power-jack",
  "product/detail/2.1mm-barrel-type-male-dc-power-jack":
    "product/2.1mm-barrel-type-male-dc-power-jack",
  "2.1mm-barrel-type-female-dc-power-jack":
    "product/2.1mm-barrel-type-female-dc-power-jack",
  "product-details/2.1mm-barrel-type-female-dc-power-jack":
    "product/2.1mm-barrel-type-female-dc-power-jack",
  "product/detail/2.1mm-barrel-type-female-dc-power-jack":
    "product/2.1mm-barrel-type-female-dc-power-jack",
  "micro-jst-1.25mm-2-pin-female-connector":
    "product/micro-jst-1.25mm-2-pin-female-connector",
  "product-details/micro-jst-1.25mm-2-pin-female-connector":
    "product/micro-jst-1.25mm-2-pin-female-connector",
  "product/detail/micro-jst-1.25mm-2-pin-female-connector":
    "product/micro-jst-1.25mm-2-pin-female-connector",
  "joystick-shield": "product/joystick-shield",
  "product-details/joystick-shield": "product/joystick-shield",
  "product/detail/joystick-shield": "product/joystick-shield",
  "flexcable-for-raspberry-pi-camera-module":
    "product/flexcable-for-raspberry-pi-camera-module",
  "product-details/flexcable-for-raspberry-pi-camera-module":
    "product/flexcable-for-raspberry-pi-camera-module",
  "product/detail/flexcable-for-raspberry-pi-camera-module":
    "product/flexcable-for-raspberry-pi-camera-module",
  "soldering-iron-stand": "product/soldering-iron-stand",
  "product-details/soldering-iron-stand": "product/soldering-iron-stand",
  "product/detail/soldering-iron-stand": "product/soldering-iron-stand",
  "raspberry-pi-touch-display-3.5": "product/raspberry-pi-touch-display-3.5",
  "product-details/raspberry-pi-touch-display-3.5":
    "product/raspberry-pi-touch-display-3.5",
  "product/detail/raspberry-pi-touch-display-3.5":
    "product/raspberry-pi-touch-display-3.5",
  "1.8-spi-tft-lcd-display-module": "product/1.8-spi-tft-lcd-display-module",
  "product-details/1.8-spi-tft-lcd-display-module":
    "product/1.8-spi-tft-lcd-display-module",
  "product/detail/1.8-spi-tft-lcd-display-module":
    "product/1.8-spi-tft-lcd-display-module",
  "3.7v-4200mah-18650-rechargeable-li-ion-battery":
    "product/3.7v-4200mah-18650-rechargeable-li-ion-battery",
  "product-details/3.7v-4200mah-18650-rechargeable-li-ion-battery":
    "product/3.7v-4200mah-18650-rechargeable-li-ion-battery",
  "product/detail/3.7v-4200mah-18650-rechargeable-li-ion-battery":
    "product/3.7v-4200mah-18650-rechargeable-li-ion-battery",
  "pam8403-mini-5v-digital-amplifier-board":
    "product/pam8403-mini-5v-digital-amplifier-board",
  "product-details/pam8403-mini-5v-digital-amplifier-board":
    "product/pam8403-mini-5v-digital-amplifier-board",
  "product/detail/pam8403-mini-5v-digital-amplifier-board":
    "product/pam8403-mini-5v-digital-amplifier-board",
  "pam8403-mini-digital-amplifier-board-3w3w":
    "product/pam8403-mini-digital-amplifier-board-3w3w",
  "product-details/pam8403-mini-digital-amplifier-board-3w3w":
    "product/pam8403-mini-digital-amplifier-board-3w3w",
  "product/detail/pam8403-mini-digital-amplifier-board-3w3w":
    "product/pam8403-mini-digital-amplifier-board-3w3w",
  "arduino-cmos-camera-module": "product/arduino-cmos-camera-module",
  "product-details/arduino-cmos-camera-module":
    "product/arduino-cmos-camera-module",
  "product/detail/arduino-cmos-camera-module":
    "product/arduino-cmos-camera-module",
  "transcend-16gb-microsd-card-original":
    "product/transcend-16gb-microsd-card-original",
  "product-details/transcend-16gb-microsd-card-original":
    "product/transcend-16gb-microsd-card-original",
  "product/detail/transcend-16gb-microsd-card-original":
    "product/transcend-16gb-microsd-card-original",
  tyn604: "product/tyn604",
  "product-details/tyn604": "product/tyn604",
  "product/detail/tyn604": "product/tyn604",
  "33-ohm-14w-resistor": "product/33-ohm-14w-resistor",
  "product-details/33-ohm-14w-resistor": "product/33-ohm-14w-resistor",
  "product/detail/33-ohm-14w-resistor": "product/33-ohm-14w-resistor",
  "shiledz-v.1-super-pack": "product/shiledz-v.1-super-pack",
  "product-details/shiledz-v.1-super-pack": "product/shiledz-v.1-super-pack",
  "product/detail/shiledz-v.1-super-pack": "product/shiledz-v.1-super-pack",
  "neodymium-magnet-10x5x2mm": "product/neodymium-magnet-10x5x2mm",
  "product-details/neodymium-magnet-10x5x2mm":
    "product/neodymium-magnet-10x5x2mm",
  "product/detail/neodymium-magnet-10x5x2mm":
    "product/neodymium-magnet-10x5x2mm",
  "neodymium-magnet-10x5x3mm": "product/neodymium-magnet-10x5x3mm",
  "product-details/neodymium-magnet-10x5x3mm":
    "product/neodymium-magnet-10x5x3mm",
  "product/detail/neodymium-magnet-10x5x3mm":
    "product/neodymium-magnet-10x5x3mm",
  "tb6612fng-dual-motor-driver": "product/tb6612fng-dual-motor-driver",
  "product-details/tb6612fng-dual-motor-driver":
    "product/tb6612fng-dual-motor-driver",
  "product/detail/tb6612fng-dual-motor-driver":
    "product/tb6612fng-dual-motor-driver",
  "flysky-fs-r6b-original": "product/flysky-fs-r6b-original",
  "product-details/flysky-fs-r6b-original": "product/flysky-fs-r6b-original",
  "product/detail/flysky-fs-r6b-original": "product/flysky-fs-r6b-original",
  "flysky-fs-ct6b-transmitter-pack-original":
    "product/flysky-fs-ct6b-transmitter-pack-original",
  "product-details/flysky-fs-ct6b-transmitter-pack-original":
    "product/flysky-fs-ct6b-transmitter-pack-original",
  "product/detail/flysky-fs-ct6b-transmitter-pack-original":
    "product/flysky-fs-ct6b-transmitter-pack-original",
  "shiledz-v.1-plug-n-play": "product/shiledz-v.1-plug-n-play",
  "product-details/shiledz-v.1-plug-n-play": "product/shiledz-v.1-plug-n-play",
  "product/detail/shiledz-v.1-plug-n-play": "product/shiledz-v.1-plug-n-play",
  "piezo-vibration-sensor": "product/piezo-vibration-sensor",
  "product-details/piezo-vibration-sensor": "product/piezo-vibration-sensor",
  "product/detail/piezo-vibration-sensor": "product/piezo-vibration-sensor",
  "force-sensitive-resistor-0.5": "product/force-sensitive-resistor-0.5",
  "product-details/force-sensitive-resistor-0.5":
    "product/force-sensitive-resistor-0.5",
  "product/detail/force-sensitive-resistor-0.5":
    "product/force-sensitive-resistor-0.5",
  "inductor-1uh": "product/inductor-1uh",
  "product-details/inductor-1uh": "product/inductor-1uh",
  "product/detail/inductor-1uh": "product/inductor-1uh",
  "sandisk-16gb-microsd-card-original":
    "product/sandisk-16gb-microsd-card-original",
  "product-details/sandisk-16gb-microsd-card-original":
    "product/sandisk-16gb-microsd-card-original",
  "product/detail/sandisk-16gb-microsd-card-original":
    "product/sandisk-16gb-microsd-card-original",
  "heat-sink-set-premium": "product/heat-sink-set-premium",
  "product-details/heat-sink-set-premium": "product/heat-sink-set-premium",
  "product/detail/heat-sink-set-premium": "product/heat-sink-set-premium",
  "raspberry-pi-standard-power-adapter":
    "product/raspberry-pi-standard-power-adapter",
  "product-details/raspberry-pi-standard-power-adapter":
    "product/raspberry-pi-standard-power-adapter",
  "product/detail/raspberry-pi-standard-power-adapter":
    "product/raspberry-pi-standard-power-adapter",
  "raspberry-pi-case-with-cooling-fan":
    "product/raspberry-pi-case-with-cooling-fan",
  "product-details/raspberry-pi-case-with-cooling-fan":
    "product/raspberry-pi-case-with-cooling-fan",
  "product/detail/raspberry-pi-case-with-cooling-fan":
    "product/raspberry-pi-case-with-cooling-fan",
  "raspberry-pi-case-premium": "product/raspberry-pi-case-premium",
  "product-details/raspberry-pi-case-premium":
    "product/raspberry-pi-case-premium",
  "product/detail/raspberry-pi-case-premium":
    "product/raspberry-pi-case-premium",
  "sim800l-quad-band-gsmgprs-shield":
    "product/sim800l-quad-band-gsmgprs-shield",
  "product-details/sim800l-quad-band-gsmgprs-shield":
    "product/sim800l-quad-band-gsmgprs-shield",
  "product/detail/sim800l-quad-band-gsmgprs-shield":
    "product/sim800l-quad-band-gsmgprs-shield",
  "infrared-flame-detection-sensor-module":
    "product/infrared-flame-detection-sensor-module",
  "product-details/infrared-flame-detection-sensor-module":
    "product/infrared-flame-detection-sensor-module",
  "product/detail/infrared-flame-detection-sensor-module":
    "product/infrared-flame-detection-sensor-module",
  "6v-micro-water-pump-motor": "product/6v-micro-water-pump-motor",
  "product-details/6v-micro-water-pump-motor":
    "product/6v-micro-water-pump-motor",
  "product/detail/6v-micro-water-pump-motor":
    "product/6v-micro-water-pump-motor",
  "sim808-gsm-gps-gprs-shield-with-antenna":
    "product/sim808-gsm-gps-gprs-shield-with-antenna",
  "product-details/sim808-gsm-gps-gprs-shield-with-antenna":
    "product/sim808-gsm-gps-gprs-shield-with-antenna",
  "product/detail/sim808-gsm-gps-gprs-shield-with-antenna":
    "product/sim808-gsm-gps-gprs-shield-with-antenna",
  "bts7960-motor-driver-module": "product/bts7960-motor-driver-module",
  "product-details/bts7960-motor-driver-module":
    "product/bts7960-motor-driver-module",
  "product/detail/bts7960-motor-driver-module":
    "product/bts7960-motor-driver-module",
  "gas-sensor-mq-3": "product/gas-sensor-mq-3",
  "product-details/gas-sensor-mq-3": "product/gas-sensor-mq-3",
  "product/detail/gas-sensor-mq-3": "product/gas-sensor-mq-3",
  "gas-sensor-mq-4": "product/gas-sensor-mq-4",
  "product-details/gas-sensor-mq-4": "product/gas-sensor-mq-4",
  "product/detail/gas-sensor-mq-4": "product/gas-sensor-mq-4",
  "gas-sensor-mq-6": "product/gas-sensor-mq-6",
  "product-details/gas-sensor-mq-6": "product/gas-sensor-mq-6",
  "product/detail/gas-sensor-mq-6": "product/gas-sensor-mq-6",
  "gas-sensor-mq-8": "product/gas-sensor-mq-8",
  "product-details/gas-sensor-mq-8": "product/gas-sensor-mq-8",
  "product/detail/gas-sensor-mq-8": "product/gas-sensor-mq-8",
  "capacitive-touch-sensor-module": "product/capacitive-touch-sensor-module",
  "product-details/capacitive-touch-sensor-module":
    "product/capacitive-touch-sensor-module",
  "product/detail/capacitive-touch-sensor-module":
    "product/capacitive-touch-sensor-module",
  "push-button-module": "product/push-button-module",
  "product-details/push-button-module": "product/push-button-module",
  "product/detail/push-button-module": "product/push-button-module",
  "sandisk-64gb-microsd-card-original":
    "product/sandisk-64gb-microsd-card-original",
  "product-details/sandisk-64gb-microsd-card-original":
    "product/sandisk-64gb-microsd-card-original",
  "product/detail/sandisk-64gb-microsd-card-original":
    "product/sandisk-64gb-microsd-card-original",
  "adata-16gb-microsd-card-original":
    "product/adata-16gb-microsd-card-original",
  "product-details/adata-16gb-microsd-card-original":
    "product/adata-16gb-microsd-card-original",
  "product/detail/adata-16gb-microsd-card-original":
    "product/adata-16gb-microsd-card-original",
  "adata-32gb-microsd-card-original":
    "product/adata-32gb-microsd-card-original",
  "product-details/adata-32gb-microsd-card-original":
    "product/adata-32gb-microsd-card-original",
  "product/detail/adata-32gb-microsd-card-original":
    "product/adata-32gb-microsd-card-original",
  lf353: "product/lf353",
  "product-details/lf353": "product/lf353",
  "product/detail/lf353": "product/lf353",
  "r307-optical-fingerprint-reader": "product/r307-optical-fingerprint-reader",
  "product-details/r307-optical-fingerprint-reader":
    "product/r307-optical-fingerprint-reader",
  "product/detail/r307-optical-fingerprint-reader":
    "product/r307-optical-fingerprint-reader",
  "raindrop-sensor-module": "product/raindrop-sensor-module",
  "product-details/raindrop-sensor-module": "product/raindrop-sensor-module",
  "product/detail/raindrop-sensor-module": "product/raindrop-sensor-module",
  "dc-gear-motor-and-wheel": "product/dc-gear-motor-and-wheel",
  "product-details/dc-gear-motor-and-wheel": "product/dc-gear-motor-and-wheel",
  "product/detail/dc-gear-motor-and-wheel": "product/dc-gear-motor-and-wheel",
  "tenergy-lipo-life-balance-charger":
    "product/tenergy-lipo-life-balance-charger",
  "product-details/tenergy-lipo-life-balance-charger":
    "product/tenergy-lipo-life-balance-charger",
  "product/detail/tenergy-lipo-life-balance-charger":
    "product/tenergy-lipo-life-balance-charger",
  atmega16a: "product/atmega16a",
  "product-details/atmega16a": "product/atmega16a",
  "product/detail/atmega16a": "product/atmega16a",
  atmega32a: "product/atmega32a",
  "product-details/atmega32a": "product/atmega32a",
  "product/detail/atmega32a": "product/atmega32a",
  atmega328p: "product/atmega328p",
  "product-details/atmega328p": "product/atmega328p",
  "product/detail/atmega328p": "product/atmega328p",
  "neodymium-magnet-3x1mm": "product/neodymium-magnet-3x1mm",
  "product-details/neodymium-magnet-3x1mm": "product/neodymium-magnet-3x1mm",
  "product/detail/neodymium-magnet-3x1mm": "product/neodymium-magnet-3x1mm",
  "neodymium-magnet-2x3mm": "product/neodymium-magnet-2x3mm",
  "product-details/neodymium-magnet-2x3mm": "product/neodymium-magnet-2x3mm",
  "product/detail/neodymium-magnet-2x3mm": "product/neodymium-magnet-2x3mm",
  "neodymium-magnet-7x6mm": "product/neodymium-magnet-7x6mm",
  "product-details/neodymium-magnet-7x6mm": "product/neodymium-magnet-7x6mm",
  "product/detail/neodymium-magnet-7x6mm": "product/neodymium-magnet-7x6mm",
  at89c51: "product/at89c51",
  "product-details/at89c51": "product/at89c51",
  "product/detail/at89c51": "product/at89c51",
  at89s52: "product/at89s52",
  "product-details/at89s52": "product/at89s52",
  "product/detail/at89s52": "product/at89s52",
  at89c55: "product/at89c55",
  "product-details/at89c55": "product/at89c55",
  "product/detail/at89c55": "product/at89c55",
  "usbasp-usbisp-avr-programmer": "product/usbasp-usbisp-avr-programmer",
  "product-details/usbasp-usbisp-avr-programmer":
    "product/usbasp-usbisp-avr-programmer",
  "product/detail/usbasp-usbisp-avr-programmer":
    "product/usbasp-usbisp-avr-programmer",
  "piezo-element": "product/piezo-element",
  "product-details/piezo-element": "product/piezo-element",
  "product/detail/piezo-element": "product/piezo-element",
  "ic-adc0804": "product/ic-adc0804",
  "product-details/ic-adc0804": "product/ic-adc0804",
  "product/detail/ic-adc0804": "product/ic-adc0804",
  "ic-adc0808": "product/ic-adc0808",
  "product-details/ic-adc0808": "product/ic-adc0808",
  "product/detail/ic-adc0808": "product/ic-adc0808",
  "l7806-voltage-regulator-6v": "product/l7806-voltage-regulator-6v",
  "product-details/l7806-voltage-regulator-6v":
    "product/l7806-voltage-regulator-6v",
  "product/detail/l7806-voltage-regulator-6v":
    "product/l7806-voltage-regulator-6v",
  "l7808-voltage-regulator-8v": "product/l7808-voltage-regulator-8v",
  "product-details/l7808-voltage-regulator-8v":
    "product/l7808-voltage-regulator-8v",
  "product/detail/l7808-voltage-regulator-8v":
    "product/l7808-voltage-regulator-8v",
  "arduino-pro-mini": "product/arduino-pro-mini",
  "product-details/arduino-pro-mini": "product/arduino-pro-mini",
  "product/detail/arduino-pro-mini": "product/arduino-pro-mini",
  "3.7v-lithium-polymer-battery": "product/3.7v-lithium-polymer-battery",
  "product-details/3.7v-lithium-polymer-battery":
    "product/3.7v-lithium-polymer-battery",
  "product/detail/3.7v-lithium-polymer-battery":
    "product/3.7v-lithium-polymer-battery",
  "3.7v-lithium-polymer-battery-600mah":
    "product/3.7v-lithium-polymer-battery-600mah",
  "product-details/3.7v-lithium-polymer-battery-600mah":
    "product/3.7v-lithium-polymer-battery-600mah",
  "product/detail/3.7v-lithium-polymer-battery-600mah":
    "product/3.7v-lithium-polymer-battery-600mah",
  "3.7v-lithium-polymer-battery-500mah":
    "product/3.7v-lithium-polymer-battery-500mah",
  "product-details/3.7v-lithium-polymer-battery-500mah":
    "product/3.7v-lithium-polymer-battery-500mah",
  "product/detail/3.7v-lithium-polymer-battery-500mah":
    "product/3.7v-lithium-polymer-battery-500mah",
  "grove-ear-clip-heart-rate-sensor":
    "product/grove-ear-clip-heart-rate-sensor",
  "product-details/grove-ear-clip-heart-rate-sensor":
    "product/grove-ear-clip-heart-rate-sensor",
  "product/detail/grove-ear-clip-heart-rate-sensor":
    "product/grove-ear-clip-heart-rate-sensor",
  "mini-lithium-battery-charger-module":
    "product/mini-lithium-battery-charger-module",
  "product-details/mini-lithium-battery-charger-module":
    "product/mini-lithium-battery-charger-module",
  "product/detail/mini-lithium-battery-charger-module":
    "product/mini-lithium-battery-charger-module",
  "helping-hand-magnifier-led-light-with-soldering-stand":
    "product/helping-hand-magnifier-led-light-with-soldering-stand",
  "product-details/helping-hand-magnifier-led-light-with-soldering-stand":
    "product/helping-hand-magnifier-led-light-with-soldering-stand",
  "product/detail/helping-hand-magnifier-led-light-with-soldering-stand":
    "product/helping-hand-magnifier-led-light-with-soldering-stand",
  "gravity-analog-ph-sensor-meter-kit":
    "product/gravity-analog-ph-sensor-meter-kit",
  "product-details/gravity-analog-ph-sensor-meter-kit":
    "product/gravity-analog-ph-sensor-meter-kit",
  "product/detail/gravity-analog-ph-sensor-meter-kit":
    "product/gravity-analog-ph-sensor-meter-kit",
  "9v-battery-suder": "product/9v-battery-suder",
  "product-details/9v-battery-suder": "product/9v-battery-suder",
  "product/detail/9v-battery-suder": "product/9v-battery-suder",
  "gy-521-acceleration-gyroscope-module":
    "product/gy-521-acceleration-gyroscope-module",
  "product-details/gy-521-acceleration-gyroscope-module":
    "product/gy-521-acceleration-gyroscope-module",
  "product/detail/gy-521-acceleration-gyroscope-module":
    "product/gy-521-acceleration-gyroscope-module",
  "shafi-solar-water-pump": "product/shafi-solar-water-pump",
  "product-details/shafi-solar-water-pump": "product/shafi-solar-water-pump",
  "product/detail/shafi-solar-water-pump": "product/shafi-solar-water-pump",
  "47-ohm-14w-resistor": "product/47-ohm-14w-resistor",
  "product-details/47-ohm-14w-resistor": "product/47-ohm-14w-resistor",
  "product/detail/47-ohm-14w-resistor": "product/47-ohm-14w-resistor",
  "hc-sr505-pir-motion-sensor": "product/hc-sr505-pir-motion-sensor",
  "product-details/hc-sr505-pir-motion-sensor":
    "product/hc-sr505-pir-motion-sensor",
  "product/detail/hc-sr505-pir-motion-sensor":
    "product/hc-sr505-pir-motion-sensor",
  usbisp: "product/usbisp",
  "product-details/usbisp": "product/usbisp",
  "product/detail/usbisp": "product/usbisp",
  "9v-battery-connector-barrel-jack":
    "product/9v-battery-connector-barrel-jack",
  "product-details/9v-battery-connector-barrel-jack":
    "product/9v-battery-connector-barrel-jack",
  "product/detail/9v-battery-connector-barrel-jack":
    "product/9v-battery-connector-barrel-jack",
  "soldering-wire-barrel-shape": "product/soldering-wire-barrel-shape",
  "product-details/soldering-wire-barrel-shape":
    "product/soldering-wire-barrel-shape",
  "product/detail/soldering-wire-barrel-shape":
    "product/soldering-wire-barrel-shape",
  "ceramic-capacitor-33-pf": "product/ceramic-capacitor-33-pf",
  "product-details/ceramic-capacitor-33-pf": "product/ceramic-capacitor-33-pf",
  "product/detail/ceramic-capacitor-33-pf": "product/ceramic-capacitor-33-pf",
  "transformers-6v-1000ma-or-1a": "product/transformers-6v-1000ma-or-1a",
  "product-details/transformers-6v-1000ma-or-1a":
    "product/transformers-6v-1000ma-or-1a",
  "product/detail/transformers-6v-1000ma-or-1a":
    "product/transformers-6v-1000ma-or-1a",
  "bluetooth-module-breakout-hc-06": "product/bluetooth-module-breakout-hc-06",
  "product-details/bluetooth-module-breakout-hc-06":
    "product/bluetooth-module-breakout-hc-06",
  "product/detail/bluetooth-module-breakout-hc-06":
    "product/bluetooth-module-breakout-hc-06",
  "bluetooth-module-hc-08": "product/bluetooth-module-hc-08",
  "product-details/bluetooth-module-hc-08": "product/bluetooth-module-hc-08",
  "product/detail/bluetooth-module-hc-08": "product/bluetooth-module-hc-08",
  "piezo-element-20mm": "product/piezo-element-20mm",
  "product-details/piezo-element-20mm": "product/piezo-element-20mm",
  "product/detail/piezo-element-20mm": "product/piezo-element-20mm",
  "zip-tie-4-inch": "product/zip-tie-4-inch",
  "product-details/zip-tie-4-inch": "product/zip-tie-4-inch",
  "product/detail/zip-tie-4-inch": "product/zip-tie-4-inch",
  "battery-holder-2-pieces-aa": "product/battery-holder-2-pieces-aa",
  "product-details/battery-holder-2-pieces-aa":
    "product/battery-holder-2-pieces-aa",
  "product/detail/battery-holder-2-pieces-aa":
    "product/battery-holder-2-pieces-aa",
  "arduino-lilypad": "product/arduino-lilypad",
  "product-details/arduino-lilypad": "product/arduino-lilypad",
  "product/detail/arduino-lilypad": "product/arduino-lilypad",
  "flex-sensor-4.5": "product/flex-sensor-4.5",
  "product-details/flex-sensor-4.5": "product/flex-sensor-4.5",
  "product/detail/flex-sensor-4.5": "product/flex-sensor-4.5",
  "wild-scorpion-7.4v-500mah-li-po-battery":
    "product/wild-scorpion-7.4v-500mah-li-po-battery",
  "product-details/wild-scorpion-7.4v-500mah-li-po-battery":
    "product/wild-scorpion-7.4v-500mah-li-po-battery",
  "product/detail/wild-scorpion-7.4v-500mah-li-po-battery":
    "product/wild-scorpion-7.4v-500mah-li-po-battery",
  "wild-scorpion-7.4v-900mah-li-po-battery":
    "product/wild-scorpion-7.4v-900mah-li-po-battery",
  "product-details/wild-scorpion-7.4v-900mah-li-po-battery":
    "product/wild-scorpion-7.4v-900mah-li-po-battery",
  "product/detail/wild-scorpion-7.4v-900mah-li-po-battery":
    "product/wild-scorpion-7.4v-900mah-li-po-battery",
  "2.8-inch-spi-tft-lcd-touch-display-module-v.2":
    "product/2.8-inch-spi-tft-lcd-touch-display-module-v.2",
  "product-details/2.8-inch-spi-tft-lcd-touch-display-module-v.2":
    "product/2.8-inch-spi-tft-lcd-touch-display-module-v.2",
  "product/detail/2.8-inch-spi-tft-lcd-touch-display-module-v.2":
    "product/2.8-inch-spi-tft-lcd-touch-display-module-v.2",
  "400-rpm-micro-metal-gear-motor": "product/400-rpm-micro-metal-gear-motor",
  "product-details/400-rpm-micro-metal-gear-motor":
    "product/400-rpm-micro-metal-gear-motor",
  "product/detail/400-rpm-micro-metal-gear-motor":
    "product/400-rpm-micro-metal-gear-motor",
  "micro-metal-gear-motor-500-rpm": "product/micro-metal-gear-motor-500-rpm",
  "product-details/micro-metal-gear-motor-500-rpm":
    "product/micro-metal-gear-motor-500-rpm",
  "product/detail/micro-metal-gear-motor-500-rpm":
    "product/micro-metal-gear-motor-500-rpm",
  "motor-mounting-bracket-37mm": "product/motor-mounting-bracket-37mm",
  "product-details/motor-mounting-bracket-37mm":
    "product/motor-mounting-bracket-37mm",
  "product/detail/motor-mounting-bracket-37mm":
    "product/motor-mounting-bracket-37mm",
  "ic-storage-box": "product/ic-storage-box",
  "product-details/ic-storage-box": "product/ic-storage-box",
  "product/detail/ic-storage-box": "product/ic-storage-box",
  "3.7v-lithium-polymer-battery-1000mah":
    "product/3.7v-lithium-polymer-battery-1000mah",
  "product-details/3.7v-lithium-polymer-battery-1000mah":
    "product/3.7v-lithium-polymer-battery-1000mah",
  "product/detail/3.7v-lithium-polymer-battery-1000mah":
    "product/3.7v-lithium-polymer-battery-1000mah",
  "digital-multimeter-test-lead-probes":
    "product/digital-multimeter-test-lead-probes",
  "product-details/digital-multimeter-test-lead-probes":
    "product/digital-multimeter-test-lead-probes",
  "product/detail/digital-multimeter-test-lead-probes":
    "product/digital-multimeter-test-lead-probes",
  "ftdi-usb-to-uart-ttl-converter-ft232rl":
    "product/ftdi-usb-to-uart-ttl-converter-ft232rl",
  "product-details/ftdi-usb-to-uart-ttl-converter-ft232rl":
    "product/ftdi-usb-to-uart-ttl-converter-ft232rl",
  "product/detail/ftdi-usb-to-uart-ttl-converter-ft232rl":
    "product/ftdi-usb-to-uart-ttl-converter-ft232rl",
  "hc-sr04-ultrasonic-sensor-holder":
    "product/hc-sr04-ultrasonic-sensor-holder",
  "product-details/hc-sr04-ultrasonic-sensor-holder":
    "product/hc-sr04-ultrasonic-sensor-holder",
  "product/detail/hc-sr04-ultrasonic-sensor-holder":
    "product/hc-sr04-ultrasonic-sensor-holder",
  "i2c-lcd-display-162": "product/i2c-lcd-display-162",
  "product-details/i2c-lcd-display-162": "product/i2c-lcd-display-162",
  "product/detail/i2c-lcd-display-162": "product/i2c-lcd-display-162",
  "imax-b6-multi-function-lipo-balance-charger":
    "product/imax-b6-multi-function-lipo-balance-charger",
  "product-details/imax-b6-multi-function-lipo-balance-charger":
    "product/imax-b6-multi-function-lipo-balance-charger",
  "product/detail/imax-b6-multi-function-lipo-balance-charger":
    "product/imax-b6-multi-function-lipo-balance-charger",
  "51-avr-mcu-minimum-system-board-development-board":
    "product/51-avr-mcu-minimum-system-board-development-board",
  "product-details/51-avr-mcu-minimum-system-board-development-board":
    "product/51-avr-mcu-minimum-system-board-development-board",
  "product/detail/51-avr-mcu-minimum-system-board-development-board":
    "product/51-avr-mcu-minimum-system-board-development-board",
  "8-channel-relay": "product/8-channel-relay",
  "product-details/8-channel-relay": "product/8-channel-relay",
  "product/detail/8-channel-relay": "product/8-channel-relay",
  "small-fan": "product/small-fan",
  "product-details/small-fan": "product/small-fan",
  "product/detail/small-fan": "product/small-fan",
  "mini-voltmeter": "product/mini-voltmeter",
  "product-details/mini-voltmeter": "product/mini-voltmeter",
  "product/detail/mini-voltmeter": "product/mini-voltmeter",
  "micro-jst-1.25mm-2-pin-male-and-female-connector":
    "product/micro-jst-1.25mm-2-pin-male-and-female-connector",
  "product-details/micro-jst-1.25mm-2-pin-male-and-female-connector":
    "product/micro-jst-1.25mm-2-pin-male-and-female-connector",
  "product/detail/micro-jst-1.25mm-2-pin-male-and-female-connector":
    "product/micro-jst-1.25mm-2-pin-male-and-female-connector",
  "ad8232-heart-rate-monitor-ecg-module-set":
    "product/ad8232-heart-rate-monitor-ecg-module-set",
  "product-details/ad8232-heart-rate-monitor-ecg-module-set":
    "product/ad8232-heart-rate-monitor-ecg-module-set",
  "product/detail/ad8232-heart-rate-monitor-ecg-module-set":
    "product/ad8232-heart-rate-monitor-ecg-module-set",
  "ball-caster": "product/ball-caster",
  "product-details/ball-caster": "product/ball-caster",
  "product/detail/ball-caster": "product/ball-caster",
  "caster-wheel": "product/caster-wheel",
  "product-details/caster-wheel": "product/caster-wheel",
  "product/detail/caster-wheel": "product/caster-wheel",
  "power-supply-for-raspberry-pi-4-with-switch-type-c-5v-3a":
    "product/power-supply-for-raspberry-pi-4-with-switch-type-c-5v-3a",
  "product-details/power-supply-for-raspberry-pi-4-with-switch-type-c-5v-3a":
    "product/power-supply-for-raspberry-pi-4-with-switch-type-c-5v-3a",
  "product/detail/power-supply-for-raspberry-pi-4-with-switch-type-c-5v-3a":
    "product/power-supply-for-raspberry-pi-4-with-switch-type-c-5v-3a",
  "servo-wheel-with-thick-rubber-tire":
    "product/servo-wheel-with-thick-rubber-tire",
  "product-details/servo-wheel-with-thick-rubber-tire":
    "product/servo-wheel-with-thick-rubber-tire",
  "product/detail/servo-wheel-with-thick-rubber-tire":
    "product/servo-wheel-with-thick-rubber-tire",
  "3d-printers-parts-ball-bearing": "product/3d-printers-parts-ball-bearing",
  "product-details/3d-printers-parts-ball-bearing":
    "product/3d-printers-parts-ball-bearing",
  "product/detail/3d-printers-parts-ball-bearing":
    "product/3d-printers-parts-ball-bearing",
  "cnc-linear-motion-bearing": "product/cnc-linear-motion-bearing",
  "product-details/cnc-linear-motion-bearing":
    "product/cnc-linear-motion-bearing",
  "product/detail/cnc-linear-motion-bearing":
    "product/cnc-linear-motion-bearing",
  "super-capacitor-2.7v-50f": "product/super-capacitor-2.7v-50f",
  "product-details/super-capacitor-2.7v-50f":
    "product/super-capacitor-2.7v-50f",
  "product/detail/super-capacitor-2.7v-50f": "product/super-capacitor-2.7v-50f",
  "cnc-router-sk8-linear-rail-shaft-support-xyz-table-1-pair":
    "product/cnc-router-sk8-linear-rail-shaft-support-xyz-table-1-pair",
  "product-details/cnc-router-sk8-linear-rail-shaft-support-xyz-table-1-pair":
    "product/cnc-router-sk8-linear-rail-shaft-support-xyz-table-1-pair",
  "product/detail/cnc-router-sk8-linear-rail-shaft-support-xyz-table-1-pair":
    "product/cnc-router-sk8-linear-rail-shaft-support-xyz-table-1-pair",
  "liquid-level-sensor": "product/liquid-level-sensor",
  "product-details/liquid-level-sensor": "product/liquid-level-sensor",
  "product/detail/liquid-level-sensor": "product/liquid-level-sensor",
  "4x4-matrix-array-16-keys-switch-keypad":
    "product/4x4-matrix-array-16-keys-switch-keypad",
  "product-details/4x4-matrix-array-16-keys-switch-keypad":
    "product/4x4-matrix-array-16-keys-switch-keypad",
  "product/detail/4x4-matrix-array-16-keys-switch-keypad":
    "product/4x4-matrix-array-16-keys-switch-keypad",
  "rs232-to-rs485-communication-data-converter":
    "product/rs232-to-rs485-communication-data-converter",
  "product-details/rs232-to-rs485-communication-data-converter":
    "product/rs232-to-rs485-communication-data-converter",
  "product/detail/rs232-to-rs485-communication-data-converter":
    "product/rs232-to-rs485-communication-data-converter",
  "3-in-1-battery-balancer-lcd-display":
    "product/3-in-1-battery-balancer-lcd-display",
  "product-details/3-in-1-battery-balancer-lcd-display":
    "product/3-in-1-battery-balancer-lcd-display",
  "product/detail/3-in-1-battery-balancer-lcd-display":
    "product/3-in-1-battery-balancer-lcd-display",
  "dc-12v-led-digital-time-delay-relay-module":
    "product/dc-12v-led-digital-time-delay-relay-module",
  "product-details/dc-12v-led-digital-time-delay-relay-module":
    "product/dc-12v-led-digital-time-delay-relay-module",
  "product/detail/dc-12v-led-digital-time-delay-relay-module":
    "product/dc-12v-led-digital-time-delay-relay-module",
  "raspberry-pi-pico": "product/raspberry-pi-pico",
  "product-details/raspberry-pi-pico": "product/raspberry-pi-pico",
  "product/detail/raspberry-pi-pico": "product/raspberry-pi-pico",
  "turbidity-sensor-module": "product/turbidity-sensor-module",
  "product-details/turbidity-sensor-module": "product/turbidity-sensor-module",
  "product/detail/turbidity-sensor-module": "product/turbidity-sensor-module",
  "jst-3pin-jumper": "product/jst-3pin-jumper",
  "product-details/jst-3pin-jumper": "product/jst-3pin-jumper",
  "product/detail/jst-3pin-jumper": "product/jst-3pin-jumper",
  "ldr-sensor-module": "product/ldr-sensor-module",
  "product-details/ldr-sensor-module": "product/ldr-sensor-module",
  "product/detail/ldr-sensor-module": "product/ldr-sensor-module",
  "12v-solar-light": "product/12v-solar-light",
  "product-details/12v-solar-light": "product/12v-solar-light",
  "product/detail/12v-solar-light": "product/12v-solar-light",
  "ua741-op-amp-ic": "product/ua741-op-amp-ic",
  "product-details/ua741-op-amp-ic": "product/ua741-op-amp-ic",
  "product/detail/ua741-op-amp-ic": "product/ua741-op-amp-ic",
  "veroboard-single-layer-12x18cm": "product/veroboard-single-layer-12x18cm",
  "product-details/veroboard-single-layer-12x18cm":
    "product/veroboard-single-layer-12x18cm",
  "product/detail/veroboard-single-layer-12x18cm":
    "product/veroboard-single-layer-12x18cm",
  "9v-2a-power-adapter": "product/9v-2a-power-adapter",
  "product-details/9v-2a-power-adapter": "product/9v-2a-power-adapter",
  "product/detail/9v-2a-power-adapter": "product/9v-2a-power-adapter",
  "jst-3-pin-male-female-connector": "product/jst-3-pin-male-female-connector",
  "product-details/jst-3-pin-male-female-connector":
    "product/jst-3-pin-male-female-connector",
  "product/detail/jst-3-pin-male-female-connector":
    "product/jst-3-pin-male-female-connector",
  "5v-2a-power-adapter": "product/5v-2a-power-adapter",
  "product-details/5v-2a-power-adapter": "product/5v-2a-power-adapter",
  "product/detail/5v-2a-power-adapter": "product/5v-2a-power-adapter",
  "dc-fan-12v": "product/dc-fan-12v",
  "product-details/dc-fan-12v": "product/dc-fan-12v",
  "product/detail/dc-fan-12v": "product/dc-fan-12v",
  "gy-906-infrared-temperature-sensor":
    "product/gy-906-infrared-temperature-sensor",
  "product-details/gy-906-infrared-temperature-sensor":
    "product/gy-906-infrared-temperature-sensor",
  "product/detail/gy-906-infrared-temperature-sensor":
    "product/gy-906-infrared-temperature-sensor",
  "raspberry-pi-4-model-b-4gb": "product/raspberry-pi-4-model-b-4gb",
  "product-details/raspberry-pi-4-model-b-4gb":
    "product/raspberry-pi-4-model-b-4gb",
  "product/detail/raspberry-pi-4-model-b-4gb":
    "product/raspberry-pi-4-model-b-4gb",
  "newstar-na-380s-ic-regulated-multiple-acdc-adaptor":
    "product/newstar-na-380s-ic-regulated-multiple-acdc-adaptor",
  "product-details/newstar-na-380s-ic-regulated-multiple-acdc-adaptor":
    "product/newstar-na-380s-ic-regulated-multiple-acdc-adaptor",
  "product/detail/newstar-na-380s-ic-regulated-multiple-acdc-adaptor":
    "product/newstar-na-380s-ic-regulated-multiple-acdc-adaptor",
  "12v-2a-power-adapter-original": "product/12v-2a-power-adapter-original",
  "product-details/12v-2a-power-adapter-original":
    "product/12v-2a-power-adapter-original",
  "product/detail/12v-2a-power-adapter-original":
    "product/12v-2a-power-adapter-original",
  "9v-2a-power-adapter-original": "product/9v-2a-power-adapter-original",
  "product-details/9v-2a-power-adapter-original":
    "product/9v-2a-power-adapter-original",
  "product/detail/9v-2a-power-adapter-original":
    "product/9v-2a-power-adapter-original",
  "5v-2a-power-adapter-original": "product/5v-2a-power-adapter-original",
  "product-details/5v-2a-power-adapter-original":
    "product/5v-2a-power-adapter-original",
  "product/detail/5v-2a-power-adapter-original":
    "product/5v-2a-power-adapter-original",
  "5v-mini-water-pump-motor": "product/5v-mini-water-pump-motor",
  "product-details/5v-mini-water-pump-motor":
    "product/5v-mini-water-pump-motor",
  "product/detail/5v-mini-water-pump-motor": "product/5v-mini-water-pump-motor",
  "3-pin-terminal-block": "product/3-pin-terminal-block",
  "product-details/3-pin-terminal-block": "product/3-pin-terminal-block",
  "product/detail/3-pin-terminal-block": "product/3-pin-terminal-block",
  "mikrotik-rb450gx4-gigabit-ethernet-router":
    "product/mikrotik-rb450gx4-gigabit-ethernet-router",
  "product-details/mikrotik-rb450gx4-gigabit-ethernet-router":
    "product/mikrotik-rb450gx4-gigabit-ethernet-router",
  "product/detail/mikrotik-rb450gx4-gigabit-ethernet-router":
    "product/mikrotik-rb450gx4-gigabit-ethernet-router",
  "cambium-networks-cnpilot-e410-indoor":
    "product/cambium-networks-cnpilot-e410-indoor",
  "product-details/cambium-networks-cnpilot-e410-indoor":
    "product/cambium-networks-cnpilot-e410-indoor",
  "product/detail/cambium-networks-cnpilot-e410-indoor":
    "product/cambium-networks-cnpilot-e410-indoor",
  "cambium-30w-power-supply-30w,-56v":
    "product/cambium-30w-power-supply-30w,-56v",
  "product-details/cambium-30w-power-supply-30w,-56v":
    "product/cambium-30w-power-supply-30w,-56v",
  "product/detail/cambium-30w-power-supply-30w,-56v":
    "product/cambium-30w-power-supply-30w,-56v",
  "mje3055-power-transistors": "product/mje3055-power-transistors",
  "product-details/mje3055-power-transistors":
    "product/mje3055-power-transistors",
  "product/detail/mje3055-power-transistors":
    "product/mje3055-power-transistors",
  "digital-multimeter-ut33d-plus": "product/digital-multimeter-ut33d-plus",
  "product-details/digital-multimeter-ut33d-plus":
    "product/digital-multimeter-ut33d-plus",
  "product/detail/digital-multimeter-ut33d-plus":
    "product/digital-multimeter-ut33d-plus",
  "sony-alkaine-blue-batteries-aa-2-pieces":
    "product/sony-alkaine-blue-batteries-aa-2-pieces",
  "product-details/sony-alkaine-blue-batteries-aa-2-pieces":
    "product/sony-alkaine-blue-batteries-aa-2-pieces",
  "product/detail/sony-alkaine-blue-batteries-aa-2-pieces":
    "product/sony-alkaine-blue-batteries-aa-2-pieces",
  "touchless-automatic-hand-sanitizer-dispenser":
    "product/touchless-automatic-hand-sanitizer-dispenser",
  "product-details/touchless-automatic-hand-sanitizer-dispenser":
    "product/touchless-automatic-hand-sanitizer-dispenser",
  "product/detail/touchless-automatic-hand-sanitizer-dispenser":
    "product/touchless-automatic-hand-sanitizer-dispenser",
  "wxd3-13-4k7-ohm-multi-turn-wire-wound-potentiometer":
    "product/wxd3-13-4k7-ohm-multi-turn-wire-wound-potentiometer",
  "product-details/wxd3-13-4k7-ohm-multi-turn-wire-wound-potentiometer":
    "product/wxd3-13-4k7-ohm-multi-turn-wire-wound-potentiometer",
  "product/detail/wxd3-13-4k7-ohm-multi-turn-wire-wound-potentiometer":
    "product/wxd3-13-4k7-ohm-multi-turn-wire-wound-potentiometer",
  "i2c-oled-display-module-128x64-0.96":
    "product/i2c-oled-display-module-128x64-0.96",
  "product-details/i2c-oled-display-module-128x64-0.96":
    "product/i2c-oled-display-module-128x64-0.96",
  "product/detail/i2c-oled-display-module-128x64-0.96":
    "product/i2c-oled-display-module-128x64-0.96",
  "capacitive-soil-moisture-sensor": "product/capacitive-soil-moisture-sensor",
  "product-details/capacitive-soil-moisture-sensor":
    "product/capacitive-soil-moisture-sensor",
  "product/detail/capacitive-soil-moisture-sensor":
    "product/capacitive-soil-moisture-sensor",
  "ic-7490": "product/ic-7490",
  "product-details/ic-7490": "product/ic-7490",
  "product/detail/ic-7490": "product/ic-7490",
  "ic-74175": "product/ic-74175",
  "product-details/ic-74175": "product/ic-74175",
  "product/detail/ic-74175": "product/ic-74175",
  "ic-74195": "product/ic-74195",
  "product-details/ic-74195": "product/ic-74195",
  "product/detail/ic-74195": "product/ic-74195",
  "ic-74273": "product/ic-74273",
  "product-details/ic-74273": "product/ic-74273",
  "product/detail/ic-74273": "product/ic-74273",
  lm324: "product/lm324",
  "product-details/lm324": "product/lm324",
  "product/detail/lm324": "product/lm324",
  "ic-4040": "product/ic-4040",
  "product-details/ic-4040": "product/ic-4040",
  "product/detail/ic-4040": "product/ic-4040",
  "ic-4060": "product/ic-4060",
  "product-details/ic-4060": "product/ic-4060",
  "product/detail/ic-4060": "product/ic-4060",
  "ic-4071": "product/ic-4071",
  "product-details/ic-4071": "product/ic-4071",
  "product/detail/ic-4071": "product/ic-4071",
  "irf9540-p-channel-mosfet-transistor":
    "product/irf9540-p-channel-mosfet-transistor",
  "product-details/irf9540-p-channel-mosfet-transistor":
    "product/irf9540-p-channel-mosfet-transistor",
  "product/detail/irf9540-p-channel-mosfet-transistor":
    "product/irf9540-p-channel-mosfet-transistor",
  "2n3906-power-transistor": "product/2n3906-power-transistor",
  "product-details/2n3906-power-transistor": "product/2n3906-power-transistor",
  "product/detail/2n3906-power-transistor": "product/2n3906-power-transistor",
  "3.3m-ohm-resistor": "product/3.3m-ohm-resistor",
  "product-details/3.3m-ohm-resistor": "product/3.3m-ohm-resistor",
  "product/detail/3.3m-ohm-resistor": "product/3.3m-ohm-resistor",
  "ir-transmitter-photodiode-3mm": "product/ir-transmitter-photodiode-3mm",
  "product-details/ir-transmitter-photodiode-3mm":
    "product/ir-transmitter-photodiode-3mm",
  "product/detail/ir-transmitter-photodiode-3mm":
    "product/ir-transmitter-photodiode-3mm",
  "ir-receiver-photodiode-3mm": "product/ir-receiver-photodiode-3mm",
  "product-details/ir-receiver-photodiode-3mm":
    "product/ir-receiver-photodiode-3mm",
  "product/detail/ir-receiver-photodiode-3mm":
    "product/ir-receiver-photodiode-3mm",
  "4-channel-infrared-tracking-sensor-module":
    "product/4-channel-infrared-tracking-sensor-module",
  "product-details/4-channel-infrared-tracking-sensor-module":
    "product/4-channel-infrared-tracking-sensor-module",
  "product/detail/4-channel-infrared-tracking-sensor-module":
    "product/4-channel-infrared-tracking-sensor-module",
  "1k-1-watt-carbon-resistors": "product/1k-1-watt-carbon-resistors",
  "product-details/1k-1-watt-carbon-resistors":
    "product/1k-1-watt-carbon-resistors",
  "product/detail/1k-1-watt-carbon-resistors":
    "product/1k-1-watt-carbon-resistors",
  "220k-ohm-resistor": "product/220k-ohm-resistor",
  "product-details/220k-ohm-resistor": "product/220k-ohm-resistor",
  "product/detail/220k-ohm-resistor": "product/220k-ohm-resistor",
  "soldering-wire-1-koel": "product/soldering-wire-1-koel",
  "product-details/soldering-wire-1-koel": "product/soldering-wire-1-koel",
  "product/detail/soldering-wire-1-koel": "product/soldering-wire-1-koel",
  "12v-zener-diode": "product/12v-zener-diode",
  "product-details/12v-zener-diode": "product/12v-zener-diode",
  "product/detail/12v-zener-diode": "product/12v-zener-diode",
  "ceramic-capacitor-470pf-471j": "product/ceramic-capacitor-470pf-471j",
  "product-details/ceramic-capacitor-470pf-471j":
    "product/ceramic-capacitor-470pf-471j",
  "product/detail/ceramic-capacitor-470pf-471j":
    "product/ceramic-capacitor-470pf-471j",
  "220v-ac-power-cable-cord": "product/220v-ac-power-cable-cord",
  "product-details/220v-ac-power-cable-cord":
    "product/220v-ac-power-cable-cord",
  "product/detail/220v-ac-power-cable-cord": "product/220v-ac-power-cable-cord",
  "usb-to-rs232-db9-serial-adapter-cable":
    "product/usb-to-rs232-db9-serial-adapter-cable",
  "product-details/usb-to-rs232-db9-serial-adapter-cable":
    "product/usb-to-rs232-db9-serial-adapter-cable",
  "product/detail/usb-to-rs232-db9-serial-adapter-cable":
    "product/usb-to-rs232-db9-serial-adapter-cable",
  "d-8085-8-bit-microprocessor-ic": "product/d-8085-8-bit-microprocessor-ic",
  "product-details/d-8085-8-bit-microprocessor-ic":
    "product/d-8085-8-bit-microprocessor-ic",
  "product/detail/d-8085-8-bit-microprocessor-ic":
    "product/d-8085-8-bit-microprocessor-ic",
  "ka2206b-ic-2.3w-dual-audio-power-amplifier":
    "product/ka2206b-ic-2.3w-dual-audio-power-amplifier",
  "product-details/ka2206b-ic-2.3w-dual-audio-power-amplifier":
    "product/ka2206b-ic-2.3w-dual-audio-power-amplifier",
  "product/detail/ka2206b-ic-2.3w-dual-audio-power-amplifier":
    "product/ka2206b-ic-2.3w-dual-audio-power-amplifier",
  "neodymium-magnet-coin-shape-20x2-mm":
    "product/neodymium-magnet-coin-shape-20x2-mm",
  "product-details/neodymium-magnet-coin-shape-20x2-mm":
    "product/neodymium-magnet-coin-shape-20x2-mm",
  "product/detail/neodymium-magnet-coin-shape-20x2-mm":
    "product/neodymium-magnet-coin-shape-20x2-mm",
  "shielded-usb-type-b-female-port-4-pins-pcb-mount-jack-connector":
    "product/shielded-usb-type-b-female-port-4-pins-pcb-mount-jack-connector",
  "product-details/shielded-usb-type-b-female-port-4-pins-pcb-mount-jack-connector":
    "product/shielded-usb-type-b-female-port-4-pins-pcb-mount-jack-connector",
  "product/detail/shielded-usb-type-b-female-port-4-pins-pcb-mount-jack-connector":
    "product/shielded-usb-type-b-female-port-4-pins-pcb-mount-jack-connector",
  "glass-fiber-damping-plate-shock-absorber-for-quadcopter-cc3d-flight-control":
    "product/glass-fiber-damping-plate-shock-absorber-for-quadcopter-cc3d-flight-control",
  "product-details/glass-fiber-damping-plate-shock-absorber-for-quadcopter-cc3d-flight-control":
    "product/glass-fiber-damping-plate-shock-absorber-for-quadcopter-cc3d-flight-control",
  "product/detail/glass-fiber-damping-plate-shock-absorber-for-quadcopter-cc3d-flight-control":
    "product/glass-fiber-damping-plate-shock-absorber-for-quadcopter-cc3d-flight-control",
  "33-ohm-5w-resistor": "product/33-ohm-5w-resistor",
  "product-details/33-ohm-5w-resistor": "product/33-ohm-5w-resistor",
  "product/detail/33-ohm-5w-resistor": "product/33-ohm-5w-resistor",
  "voltage-sensor-module-dc-0-25v": "product/voltage-sensor-module-dc-0-25v",
  "product-details/voltage-sensor-module-dc-0-25v":
    "product/voltage-sensor-module-dc-0-25v",
  "product/detail/voltage-sensor-module-dc-0-25v":
    "product/voltage-sensor-module-dc-0-25v",
  "rechargeable-aa-4000mah-3.6v-ni-cd-battery":
    "product/rechargeable-aa-4000mah-3.6v-ni-cd-battery",
  "product-details/rechargeable-aa-4000mah-3.6v-ni-cd-battery":
    "product/rechargeable-aa-4000mah-3.6v-ni-cd-battery",
  "product/detail/rechargeable-aa-4000mah-3.6v-ni-cd-battery":
    "product/rechargeable-aa-4000mah-3.6v-ni-cd-battery",
  "3.7v-li-ion-battery-holder-two-cell":
    "product/3.7v-li-ion-battery-holder-two-cell",
  "product-details/3.7v-li-ion-battery-holder-two-cell":
    "product/3.7v-li-ion-battery-holder-two-cell",
  "product/detail/3.7v-li-ion-battery-holder-two-cell":
    "product/3.7v-li-ion-battery-holder-two-cell",
  "battery-holder-3-pieces-aa": "product/battery-holder-3-pieces-aa",
  "product-details/battery-holder-3-pieces-aa":
    "product/battery-holder-3-pieces-aa",
  "product/detail/battery-holder-3-pieces-aa":
    "product/battery-holder-3-pieces-aa",
  at89c2051: "product/at89c2051",
  "product-details/at89c2051": "product/at89c2051",
  "product/detail/at89c2051": "product/at89c2051",
  atmega8a: "product/atmega8a",
  "product-details/atmega8a": "product/atmega8a",
  "product/detail/atmega8a": "product/atmega8a",
  "3-channel-relay": "product/3-channel-relay",
  "product-details/3-channel-relay": "product/3-channel-relay",
  "product/detail/3-channel-relay": "product/3-channel-relay",
  "smart-robot-car-wheel": "product/smart-robot-car-wheel",
  "product-details/smart-robot-car-wheel": "product/smart-robot-car-wheel",
  "product/detail/smart-robot-car-wheel": "product/smart-robot-car-wheel",
  "pic-microcontroller-minimum-system-board-development-board":
    "product/pic-microcontroller-minimum-system-board-development-board",
  "product-details/pic-microcontroller-minimum-system-board-development-board":
    "product/pic-microcontroller-minimum-system-board-development-board",
  "product/detail/pic-microcontroller-minimum-system-board-development-board":
    "product/pic-microcontroller-minimum-system-board-development-board",
  "avr-trainer-kit-pro": "product/avr-trainer-kit-pro",
  "product-details/avr-trainer-kit-pro": "product/avr-trainer-kit-pro",
  "product/detail/avr-trainer-kit-pro": "product/avr-trainer-kit-pro",
  "jst-xh-2.54-4-pin-male-female-plug-connector":
    "product/jst-xh-2.54-4-pin-male-female-plug-connector",
  "product-details/jst-xh-2.54-4-pin-male-female-plug-connector":
    "product/jst-xh-2.54-4-pin-male-female-plug-connector",
  "product/detail/jst-xh-2.54-4-pin-male-female-plug-connector":
    "product/jst-xh-2.54-4-pin-male-female-plug-connector",
  "jst-xh-2.54-5-pin-male-female-plug-connector":
    "product/jst-xh-2.54-5-pin-male-female-plug-connector",
  "product-details/jst-xh-2.54-5-pin-male-female-plug-connector":
    "product/jst-xh-2.54-5-pin-male-female-plug-connector",
  "product/detail/jst-xh-2.54-5-pin-male-female-plug-connector":
    "product/jst-xh-2.54-5-pin-male-female-plug-connector",
  "jst-xh-2.54-6-pin-male-female-plug-connector":
    "product/jst-xh-2.54-6-pin-male-female-plug-connector",
  "product-details/jst-xh-2.54-6-pin-male-female-plug-connector":
    "product/jst-xh-2.54-6-pin-male-female-plug-connector",
  "product/detail/jst-xh-2.54-6-pin-male-female-plug-connector":
    "product/jst-xh-2.54-6-pin-male-female-plug-connector",
  "9v-battery-connector-with-wire-clip":
    "product/9v-battery-connector-with-wire-clip",
  "product-details/9v-battery-connector-with-wire-clip":
    "product/9v-battery-connector-with-wire-clip",
  "product/detail/9v-battery-connector-with-wire-clip":
    "product/9v-battery-connector-with-wire-clip",
  "usb-tester-voltmeter-ammeter": "product/usb-tester-voltmeter-ammeter",
  "product-details/usb-tester-voltmeter-ammeter":
    "product/usb-tester-voltmeter-ammeter",
  "product/detail/usb-tester-voltmeter-ammeter":
    "product/usb-tester-voltmeter-ammeter",
  "usb-power-meter-voltmeter-ammeter-tester":
    "product/usb-power-meter-voltmeter-ammeter-tester",
  "product-details/usb-power-meter-voltmeter-ammeter-tester":
    "product/usb-power-meter-voltmeter-ammeter-tester",
  "product/detail/usb-power-meter-voltmeter-ammeter-tester":
    "product/usb-power-meter-voltmeter-ammeter-tester",
  "soldering-wire-1-koel-premium-quality":
    "product/soldering-wire-1-koel-premium-quality",
  "product-details/soldering-wire-1-koel-premium-quality":
    "product/soldering-wire-1-koel-premium-quality",
  "product/detail/soldering-wire-1-koel-premium-quality":
    "product/soldering-wire-1-koel-premium-quality",
  "12v-high-pressure-water-pump-full-set":
    "product/12v-high-pressure-water-pump-full-set",
  "product-details/12v-high-pressure-water-pump-full-set":
    "product/12v-high-pressure-water-pump-full-set",
  "product/detail/12v-high-pressure-water-pump-full-set":
    "product/12v-high-pressure-water-pump-full-set",
  "pwm-signal-generator-module": "product/pwm-signal-generator-module",
  "product-details/pwm-signal-generator-module":
    "product/pwm-signal-generator-module",
  "product/detail/pwm-signal-generator-module":
    "product/pwm-signal-generator-module",
  "project-kits-arduino-nano,-esp8266,-dht11,-mq135,-mq2,-ultrasonic-sensor,-led,-jumper-wire,-resistors,-breadboard":
    "product/project-kits-arduino-nano,-esp8266,-dht11,-mq135,-mq2,-ultrasonic-sensor,-led,-jumper-wire,-resistors,-breadboard",
  "product-details/project-kits-arduino-nano,-esp8266,-dht11,-mq135,-mq2,-ultrasonic-sensor,-led,-jumper-wire,-resistors,-breadboard":
    "product/project-kits-arduino-nano,-esp8266,-dht11,-mq135,-mq2,-ultrasonic-sensor,-led,-jumper-wire,-resistors,-breadboard",
  "product/detail/project-kits-arduino-nano,-esp8266,-dht11,-mq135,-mq2,-ultrasonic-sensor,-led,-jumper-wire,-resistors,-breadboard":
    "product/project-kits-arduino-nano,-esp8266,-dht11,-mq135,-mq2,-ultrasonic-sensor,-led,-jumper-wire,-resistors,-breadboard",
  "veroboard-stripboard-dotted-type":
    "product/veroboard-stripboard-dotted-type",
  "product-details/veroboard-stripboard-dotted-type":
    "product/veroboard-stripboard-dotted-type",
  "product/detail/veroboard-stripboard-dotted-type":
    "product/veroboard-stripboard-dotted-type",
  "3.7v-5200mah-18650-rechargeable-li-ion-battery":
    "product/3.7v-5200mah-18650-rechargeable-li-ion-battery",
  "product-details/3.7v-5200mah-18650-rechargeable-li-ion-battery":
    "product/3.7v-5200mah-18650-rechargeable-li-ion-battery",
  "product/detail/3.7v-5200mah-18650-rechargeable-li-ion-battery":
    "product/3.7v-5200mah-18650-rechargeable-li-ion-battery",
  "usb-type-c-data-cable": "product/usb-type-c-data-cable",
  "product-details/usb-type-c-data-cable": "product/usb-type-c-data-cable",
  "product/detail/usb-type-c-data-cable": "product/usb-type-c-data-cable",
  "vibration-motor-module": "product/vibration-motor-module",
  "product-details/vibration-motor-module": "product/vibration-motor-module",
  "product/detail/vibration-motor-module": "product/vibration-motor-module",
  "mini-usb-2.0-a-to-mini-b-cable-for-arduino-nano-white":
    "product/mini-usb-2.0-a-to-mini-b-cable-for-arduino-nano-white",
  "product-details/mini-usb-2.0-a-to-mini-b-cable-for-arduino-nano-white":
    "product/mini-usb-2.0-a-to-mini-b-cable-for-arduino-nano-white",
  "product/detail/mini-usb-2.0-a-to-mini-b-cable-for-arduino-nano-white":
    "product/mini-usb-2.0-a-to-mini-b-cable-for-arduino-nano-white",
  "mini-usb-2.0-a-to-mini-b-cable-for-arduino-nano-black":
    "product/mini-usb-2.0-a-to-mini-b-cable-for-arduino-nano-black",
  "product-details/mini-usb-2.0-a-to-mini-b-cable-for-arduino-nano-black":
    "product/mini-usb-2.0-a-to-mini-b-cable-for-arduino-nano-black",
  "product/detail/mini-usb-2.0-a-to-mini-b-cable-for-arduino-nano-black":
    "product/mini-usb-2.0-a-to-mini-b-cable-for-arduino-nano-black",
  "dc-dc-step-down-ca-1235-buck-converter-power-supply-module":
    "product/dc-dc-step-down-ca-1235-buck-converter-power-supply-module",
  "product-details/dc-dc-step-down-ca-1235-buck-converter-power-supply-module":
    "product/dc-dc-step-down-ca-1235-buck-converter-power-supply-module",
  "product/detail/dc-dc-step-down-ca-1235-buck-converter-power-supply-module":
    "product/dc-dc-step-down-ca-1235-buck-converter-power-supply-module",
  "dc-dc-step-down-buck-converter-power-supply-module":
    "product/dc-dc-step-down-buck-converter-power-supply-module",
  "product-details/dc-dc-step-down-buck-converter-power-supply-module":
    "product/dc-dc-step-down-buck-converter-power-supply-module",
  "product/detail/dc-dc-step-down-buck-converter-power-supply-module":
    "product/dc-dc-step-down-buck-converter-power-supply-module",
  "computer-power-cord": "product/computer-power-cord",
  "product-details/computer-power-cord": "product/computer-power-cord",
  "product/detail/computer-power-cord": "product/computer-power-cord",
  "i2c-oled-display-module-128x32-0.91":
    "product/i2c-oled-display-module-128x32-0.91",
  "product-details/i2c-oled-display-module-128x32-0.91":
    "product/i2c-oled-display-module-128x32-0.91",
  "product/detail/i2c-oled-display-module-128x32-0.91":
    "product/i2c-oled-display-module-128x32-0.91",
  "xh-m604-battery-charger-control-module":
    "product/xh-m604-battery-charger-control-module",
  "product-details/xh-m604-battery-charger-control-module":
    "product/xh-m604-battery-charger-control-module",
  "product/detail/xh-m604-battery-charger-control-module":
    "product/xh-m604-battery-charger-control-module",
  "ai-thinker-ra-02": "product/ai-thinker-ra-02",
  "product-details/ai-thinker-ra-02": "product/ai-thinker-ra-02",
  "product/detail/ai-thinker-ra-02": "product/ai-thinker-ra-02",
  "arduino-uno-r3-smd": "product/arduino-uno-r3-smd",
  "product-details/arduino-uno-r3-smd": "product/arduino-uno-r3-smd",
  "product/detail/arduino-uno-r3-smd": "product/arduino-uno-r3-smd",
  "stereo-audio-breakout-board-3.5mm":
    "product/stereo-audio-breakout-board-3.5mm",
  "product-details/stereo-audio-breakout-board-3.5mm":
    "product/stereo-audio-breakout-board-3.5mm",
  "product/detail/stereo-audio-breakout-board-3.5mm":
    "product/stereo-audio-breakout-board-3.5mm",
  "tda7293-amplifier-ic": "product/tda7293-amplifier-ic",
  "product-details/tda7293-amplifier-ic": "product/tda7293-amplifier-ic",
  "product/detail/tda7293-amplifier-ic": "product/tda7293-amplifier-ic",
  irf840: "product/irf840",
  "product-details/irf840": "product/irf840",
  "product/detail/irf840": "product/irf840",
  "esp8266-esp-01-wifi-module": "product/esp8266-esp-01-wifi-module",
  "product-details/esp8266-esp-01-wifi-module":
    "product/esp8266-esp-01-wifi-module",
  "product/detail/esp8266-esp-01-wifi-module":
    "product/esp8266-esp-01-wifi-module",
  "usb-esp8266-esp01-esp-01-uart-serial-adapter-programmer":
    "product/usb-esp8266-esp01-esp-01-uart-serial-adapter-programmer",
  "product-details/usb-esp8266-esp01-esp-01-uart-serial-adapter-programmer":
    "product/usb-esp8266-esp01-esp-01-uart-serial-adapter-programmer",
  "product/detail/usb-esp8266-esp01-esp-01-uart-serial-adapter-programmer":
    "product/usb-esp8266-esp01-esp-01-uart-serial-adapter-programmer",
  "usb-head-switchboard-male-connector-to-type-c-micro-female-usb-2.54-4p-transfer-test-board":
    "product/usb-head-switchboard-male-connector-to-type-c-micro-female-usb-2.54-4p-transfer-test-board",
  "product-details/usb-head-switchboard-male-connector-to-type-c-micro-female-usb-2.54-4p-transfer-test-board":
    "product/usb-head-switchboard-male-connector-to-type-c-micro-female-usb-2.54-4p-transfer-test-board",
  "product/detail/usb-head-switchboard-male-connector-to-type-c-micro-female-usb-2.54-4p-transfer-test-board":
    "product/usb-head-switchboard-male-connector-to-type-c-micro-female-usb-2.54-4p-transfer-test-board",
  "dc-dc-5v-to-3.5v12v-usb-step-updown-power-supply-module":
    "product/dc-dc-5v-to-3.5v12v-usb-step-updown-power-supply-module",
  "product-details/dc-dc-5v-to-3.5v12v-usb-step-updown-power-supply-module":
    "product/dc-dc-5v-to-3.5v12v-usb-step-updown-power-supply-module",
  "product/detail/dc-dc-5v-to-3.5v12v-usb-step-updown-power-supply-module":
    "product/dc-dc-5v-to-3.5v12v-usb-step-updown-power-supply-module",
  "oscilloscope-kit-dso138": "product/oscilloscope-kit-dso138",
  "product-details/oscilloscope-kit-dso138": "product/oscilloscope-kit-dso138",
  "product/detail/oscilloscope-kit-dso138": "product/oscilloscope-kit-dso138",
  "bluetooth-audio-receiver-board": "product/bluetooth-audio-receiver-board",
  "product-details/bluetooth-audio-receiver-board":
    "product/bluetooth-audio-receiver-board",
  "product/detail/bluetooth-audio-receiver-board":
    "product/bluetooth-audio-receiver-board",
  "ir-obstacle-avoidance-sensor": "product/ir-obstacle-avoidance-sensor",
  "product-details/ir-obstacle-avoidance-sensor":
    "product/ir-obstacle-avoidance-sensor",
  "product/detail/ir-obstacle-avoidance-sensor":
    "product/ir-obstacle-avoidance-sensor",
  "servo-motor-mg995": "product/servo-motor-mg995",
  "product-details/servo-motor-mg995": "product/servo-motor-mg995",
  "product/detail/servo-motor-mg995": "product/servo-motor-mg995",
  "servo-motor-mount-set": "product/servo-motor-mount-set",
  "product-details/servo-motor-mount-set": "product/servo-motor-mount-set",
  "product/detail/servo-motor-mount-set": "product/servo-motor-mount-set",
  "current-sensor-acs712-30a": "product/current-sensor-acs712-30a",
  "product-details/current-sensor-acs712-30a":
    "product/current-sensor-acs712-30a",
  "product/detail/current-sensor-acs712-30a":
    "product/current-sensor-acs712-30a",
  ir2110: "product/ir2110",
  "product-details/ir2110": "product/ir2110",
  "product/detail/ir2110": "product/ir2110",
  "50k-ohm-variable-resistor-potentiometer":
    "product/50k-ohm-variable-resistor-potentiometer",
  "product-details/50k-ohm-variable-resistor-potentiometer":
    "product/50k-ohm-variable-resistor-potentiometer",
  "product/detail/50k-ohm-variable-resistor-potentiometer":
    "product/50k-ohm-variable-resistor-potentiometer",
  "ac-dc-12v-400ma-isolated-power-buck-converter":
    "product/ac-dc-12v-400ma-isolated-power-buck-converter",
  "product-details/ac-dc-12v-400ma-isolated-power-buck-converter":
    "product/ac-dc-12v-400ma-isolated-power-buck-converter",
  "product/detail/ac-dc-12v-400ma-isolated-power-buck-converter":
    "product/ac-dc-12v-400ma-isolated-power-buck-converter",
  "dc3-12v-n20-gear-motor-and-mount-bracket-and-43mm-wheel":
    "product/dc3-12v-n20-gear-motor-and-mount-bracket-and-43mm-wheel",
  "product-details/dc3-12v-n20-gear-motor-and-mount-bracket-and-43mm-wheel":
    "product/dc3-12v-n20-gear-motor-and-mount-bracket-and-43mm-wheel",
  "product/detail/dc3-12v-n20-gear-motor-and-mount-bracket-and-43mm-wheel":
    "product/dc3-12v-n20-gear-motor-and-mount-bracket-and-43mm-wheel",
  "hr202-humidity-detection-sensor-module":
    "product/hr202-humidity-detection-sensor-module",
  "product-details/hr202-humidity-detection-sensor-module":
    "product/hr202-humidity-detection-sensor-module",
  "product/detail/hr202-humidity-detection-sensor-module":
    "product/hr202-humidity-detection-sensor-module",
  "esp32-cam-wifi-bluetooth-camera-module":
    "product/esp32-cam-wifi-bluetooth-camera-module",
  "product-details/esp32-cam-wifi-bluetooth-camera-module":
    "product/esp32-cam-wifi-bluetooth-camera-module",
  "product/detail/esp32-cam-wifi-bluetooth-camera-module":
    "product/esp32-cam-wifi-bluetooth-camera-module",
  "5v-2a-charge-discharge-integrated-module":
    "product/5v-2a-charge-discharge-integrated-module",
  "product-details/5v-2a-charge-discharge-integrated-module":
    "product/5v-2a-charge-discharge-integrated-module",
  "product/detail/5v-2a-charge-discharge-integrated-module":
    "product/5v-2a-charge-discharge-integrated-module",
  "74hc245": "product/74hc245",
  "product-details/74hc245": "product/74hc245",
  "product/detail/74hc245": "product/74hc245",
  "tp4056-type-c-usb-lithium-battery-charger-module":
    "product/tp4056-type-c-usb-lithium-battery-charger-module",
  "product-details/tp4056-type-c-usb-lithium-battery-charger-module":
    "product/tp4056-type-c-usb-lithium-battery-charger-module",
  "product/detail/tp4056-type-c-usb-lithium-battery-charger-module":
    "product/tp4056-type-c-usb-lithium-battery-charger-module",
  mc34063: "product/mc34063",
  "product-details/mc34063": "product/mc34063",
  "product/detail/mc34063": "product/mc34063",
  "tp5100-2a-lithium-battery-charging-module":
    "product/tp5100-2a-lithium-battery-charging-module",
  "product-details/tp5100-2a-lithium-battery-charging-module":
    "product/tp5100-2a-lithium-battery-charging-module",
  "product/detail/tp5100-2a-lithium-battery-charging-module":
    "product/tp5100-2a-lithium-battery-charging-module",
  "speed-detecting-sensor": "product/speed-detecting-sensor",
  "product-details/speed-detecting-sensor": "product/speed-detecting-sensor",
  "product/detail/speed-detecting-sensor": "product/speed-detecting-sensor",
  "5v-3a-power-adapter-for-raspberry-pi":
    "product/5v-3a-power-adapter-for-raspberry-pi",
  "product-details/5v-3a-power-adapter-for-raspberry-pi":
    "product/5v-3a-power-adapter-for-raspberry-pi",
  "product/detail/5v-3a-power-adapter-for-raspberry-pi":
    "product/5v-3a-power-adapter-for-raspberry-pi",
  "c945-transistor": "product/c945-transistor",
  "product-details/c945-transistor": "product/c945-transistor",
  "product/detail/c945-transistor": "product/c945-transistor",
  "ups-battery": "product/ups-battery",
  "product-details/ups-battery": "product/ups-battery",
  "product/detail/ups-battery": "product/ups-battery",
  "sharp-distance-sensor-10-80cm-2y0a21":
    "product/sharp-distance-sensor-10-80cm-2y0a21",
  "product-details/sharp-distance-sensor-10-80cm-2y0a21":
    "product/sharp-distance-sensor-10-80cm-2y0a21",
  "product/detail/sharp-distance-sensor-10-80cm-2y0a21":
    "product/sharp-distance-sensor-10-80cm-2y0a21",
  "5v-laser-receiver-sensor-detection-module":
    "product/5v-laser-receiver-sensor-detection-module",
  "product-details/5v-laser-receiver-sensor-detection-module":
    "product/5v-laser-receiver-sensor-detection-module",
  "product/detail/5v-laser-receiver-sensor-detection-module":
    "product/5v-laser-receiver-sensor-detection-module",
  "3296-trimmer-potentiometer": "product/3296-trimmer-potentiometer",
  "product-details/3296-trimmer-potentiometer":
    "product/3296-trimmer-potentiometer",
  "product/detail/3296-trimmer-potentiometer":
    "product/3296-trimmer-potentiometer",
  "gy-21-htu21-humidity-and-temperature-sensor-module":
    "product/gy-21-htu21-humidity-and-temperature-sensor-module",
  "product-details/gy-21-htu21-humidity-and-temperature-sensor-module":
    "product/gy-21-htu21-humidity-and-temperature-sensor-module",
  "product/detail/gy-21-htu21-humidity-and-temperature-sensor-module":
    "product/gy-21-htu21-humidity-and-temperature-sensor-module",
  "ttp223-capacitive-touch-sensor-module":
    "product/ttp223-capacitive-touch-sensor-module",
  "product-details/ttp223-capacitive-touch-sensor-module":
    "product/ttp223-capacitive-touch-sensor-module",
  "product/detail/ttp223-capacitive-touch-sensor-module":
    "product/ttp223-capacitive-touch-sensor-module",
  "heat-shrink-tube-4mm-width-1feet-length":
    "product/heat-shrink-tube-4mm-width-1feet-length",
  "product-details/heat-shrink-tube-4mm-width-1feet-length":
    "product/heat-shrink-tube-4mm-width-1feet-length",
  "product/detail/heat-shrink-tube-4mm-width-1feet-length":
    "product/heat-shrink-tube-4mm-width-1feet-length",
  "heat-shrink-tube-6mm-width-1feet-length":
    "product/heat-shrink-tube-6mm-width-1feet-length",
  "product-details/heat-shrink-tube-6mm-width-1feet-length":
    "product/heat-shrink-tube-6mm-width-1feet-length",
  "product/detail/heat-shrink-tube-6mm-width-1feet-length":
    "product/heat-shrink-tube-6mm-width-1feet-length",
  "ribbon-idc-wire-cable-1-feet": "product/ribbon-idc-wire-cable-1-feet",
  "product-details/ribbon-idc-wire-cable-1-feet":
    "product/ribbon-idc-wire-cable-1-feet",
  "product/detail/ribbon-idc-wire-cable-1-feet":
    "product/ribbon-idc-wire-cable-1-feet",
  "electrical-wire-1-feet": "product/electrical-wire-1-feet",
  "product-details/electrical-wire-1-feet": "product/electrical-wire-1-feet",
  "product/detail/electrical-wire-1-feet": "product/electrical-wire-1-feet",
  "electronic-digital-caliper": "product/electronic-digital-caliper",
  "product-details/electronic-digital-caliper":
    "product/electronic-digital-caliper",
  "product/detail/electronic-digital-caliper":
    "product/electronic-digital-caliper",
  "hx1838-infrared-remote-control-module":
    "product/hx1838-infrared-remote-control-module",
  "product-details/hx1838-infrared-remote-control-module":
    "product/hx1838-infrared-remote-control-module",
  "product/detail/hx1838-infrared-remote-control-module":
    "product/hx1838-infrared-remote-control-module",
  "lithium-battery-cr2032": "product/lithium-battery-cr2032",
  "product-details/lithium-battery-cr2032": "product/lithium-battery-cr2032",
  "product/detail/lithium-battery-cr2032": "product/lithium-battery-cr2032",
  "32.768-khz-crystal-oscillator": "product/32.768-khz-crystal-oscillator",
  "product-details/32.768-khz-crystal-oscillator":
    "product/32.768-khz-crystal-oscillator",
  "product/detail/32.768-khz-crystal-oscillator":
    "product/32.768-khz-crystal-oscillator",
  "usb-rfid-reader": "product/usb-rfid-reader",
  "product-details/usb-rfid-reader": "product/usb-rfid-reader",
  "product/detail/usb-rfid-reader": "product/usb-rfid-reader",
  "max30100-heart-rate-sensor": "product/max30100-heart-rate-sensor",
  "product-details/max30100-heart-rate-sensor":
    "product/max30100-heart-rate-sensor",
  "product/detail/max30100-heart-rate-sensor":
    "product/max30100-heart-rate-sensor",
  "tip122-npn-darlington-transistor":
    "product/tip122-npn-darlington-transistor",
  "product-details/tip122-npn-darlington-transistor":
    "product/tip122-npn-darlington-transistor",
  "product/detail/tip122-npn-darlington-transistor":
    "product/tip122-npn-darlington-transistor",
  "pin-header-male-to-male-l-shaped":
    "product/pin-header-male-to-male-l-shaped",
  "product-details/pin-header-male-to-male-l-shaped":
    "product/pin-header-male-to-male-l-shaped",
  "product/detail/pin-header-male-to-male-l-shaped":
    "product/pin-header-male-to-male-l-shaped",
  "dy-ap3015-dc-8-24v-stereo-digital-amplifier-board":
    "product/dy-ap3015-dc-8-24v-stereo-digital-amplifier-board",
  "product-details/dy-ap3015-dc-8-24v-stereo-digital-amplifier-board":
    "product/dy-ap3015-dc-8-24v-stereo-digital-amplifier-board",
  "product/detail/dy-ap3015-dc-8-24v-stereo-digital-amplifier-board":
    "product/dy-ap3015-dc-8-24v-stereo-digital-amplifier-board",
  "max9814-electret-microphone-amplifier-module":
    "product/max9814-electret-microphone-amplifier-module",
  "product-details/max9814-electret-microphone-amplifier-module":
    "product/max9814-electret-microphone-amplifier-module",
  "product/detail/max9814-electret-microphone-amplifier-module":
    "product/max9814-electret-microphone-amplifier-module",
  "nrf24l01-wireless-adapter-module":
    "product/nrf24l01-wireless-adapter-module",
  "product-details/nrf24l01-wireless-adapter-module":
    "product/nrf24l01-wireless-adapter-module",
  "product/detail/nrf24l01-wireless-adapter-module":
    "product/nrf24l01-wireless-adapter-module",
  "dt-06-wifi-module": "product/dt-06-wifi-module",
  "product-details/dt-06-wifi-module": "product/dt-06-wifi-module",
  "product/detail/dt-06-wifi-module": "product/dt-06-wifi-module",
  "dt266-digital-clamp-multimeter": "product/dt266-digital-clamp-multimeter",
  "product-details/dt266-digital-clamp-multimeter":
    "product/dt266-digital-clamp-multimeter",
  "product/detail/dt266-digital-clamp-multimeter":
    "product/dt266-digital-clamp-multimeter",
  "soldering-iron-45-w-original": "product/soldering-iron-45-w-original",
  "product-details/soldering-iron-45-w-original":
    "product/soldering-iron-45-w-original",
  "product/detail/soldering-iron-45-w-original":
    "product/soldering-iron-45-w-original",
  "mini-rc-drone-remote-control-helicopter-diy":
    "product/mini-rc-drone-remote-control-helicopter-diy",
  "product-details/mini-rc-drone-remote-control-helicopter-diy":
    "product/mini-rc-drone-remote-control-helicopter-diy",
  "product/detail/mini-rc-drone-remote-control-helicopter-diy":
    "product/mini-rc-drone-remote-control-helicopter-diy",
  "solid-core-wire-spool-9-gauge": "product/solid-core-wire-spool-9-gauge",
  "product-details/solid-core-wire-spool-9-gauge":
    "product/solid-core-wire-spool-9-gauge",
  "product/detail/solid-core-wire-spool-9-gauge":
    "product/solid-core-wire-spool-9-gauge",
  "isd1820-voice-recording-module": "product/isd1820-voice-recording-module",
  "product-details/isd1820-voice-recording-module":
    "product/isd1820-voice-recording-module",
  "product/detail/isd1820-voice-recording-module":
    "product/isd1820-voice-recording-module",
  "3.7v-lithium-polymer-battery-2500mah":
    "product/3.7v-lithium-polymer-battery-2500mah",
  "product-details/3.7v-lithium-polymer-battery-2500mah":
    "product/3.7v-lithium-polymer-battery-2500mah",
  "product/detail/3.7v-lithium-polymer-battery-2500mah":
    "product/3.7v-lithium-polymer-battery-2500mah",
  "veroboard-double-layer-9x15cm": "product/veroboard-double-layer-9x15cm",
  "product-details/veroboard-double-layer-9x15cm":
    "product/veroboard-double-layer-9x15cm",
  "product/detail/veroboard-double-layer-9x15cm":
    "product/veroboard-double-layer-9x15cm",
  "veroboard-double-layer-5x7cm": "product/veroboard-double-layer-5x7cm",
  "product-details/veroboard-double-layer-5x7cm":
    "product/veroboard-double-layer-5x7cm",
  "product/detail/veroboard-double-layer-5x7cm":
    "product/veroboard-double-layer-5x7cm",
  "veroboard-double-layer-3x7cm": "product/veroboard-double-layer-3x7cm",
  "product-details/veroboard-double-layer-3x7cm":
    "product/veroboard-double-layer-3x7cm",
  "product/detail/veroboard-double-layer-3x7cm":
    "product/veroboard-double-layer-3x7cm",
  "veroboard-double-layer-8x12cm": "product/veroboard-double-layer-8x12cm",
  "product-details/veroboard-double-layer-8x12cm":
    "product/veroboard-double-layer-8x12cm",
  "product/detail/veroboard-double-layer-8x12cm":
    "product/veroboard-double-layer-8x12cm",
  "super-glue": "product/super-glue",
  "product-details/super-glue": "product/super-glue",
  "product/detail/super-glue": "product/super-glue",
  "mini-solar-panel-3.7v": "product/mini-solar-panel-3.7v",
  "product-details/mini-solar-panel-3.7v": "product/mini-solar-panel-3.7v",
  "product/detail/mini-solar-panel-3.7v": "product/mini-solar-panel-3.7v",
  "bd135-transistor": "product/bd135-transistor",
  "product-details/bd135-transistor": "product/bd135-transistor",
  "product/detail/bd135-transistor": "product/bd135-transistor",
  "female-dc-connector-5.5mm-inner-1.7mm":
    "product/female-dc-connector-5.5mm-inner-1.7mm",
  "product-details/female-dc-connector-5.5mm-inner-1.7mm":
    "product/female-dc-connector-5.5mm-inner-1.7mm",
  "product/detail/female-dc-connector-5.5mm-inner-1.7mm":
    "product/female-dc-connector-5.5mm-inner-1.7mm",
  "male-dc-connector-5.5mm-inner-1.7mm":
    "product/male-dc-connector-5.5mm-inner-1.7mm",
  "product-details/male-dc-connector-5.5mm-inner-1.7mm":
    "product/male-dc-connector-5.5mm-inner-1.7mm",
  "product/detail/male-dc-connector-5.5mm-inner-1.7mm":
    "product/male-dc-connector-5.5mm-inner-1.7mm",
  "male-female-dc-connector-5.5mm-inner-1.7mm":
    "product/male-female-dc-connector-5.5mm-inner-1.7mm",
  "product-details/male-female-dc-connector-5.5mm-inner-1.7mm":
    "product/male-female-dc-connector-5.5mm-inner-1.7mm",
  "product/detail/male-female-dc-connector-5.5mm-inner-1.7mm":
    "product/male-female-dc-connector-5.5mm-inner-1.7mm",
  "soldering-service": "product/soldering-service",
  "product-details/soldering-service": "product/soldering-service",
  "product/detail/soldering-service": "product/soldering-service",
  "ky-008-laser-transmitter-module": "product/ky-008-laser-transmitter-module",
  "product-details/ky-008-laser-transmitter-module":
    "product/ky-008-laser-transmitter-module",
  "product/detail/ky-008-laser-transmitter-module":
    "product/ky-008-laser-transmitter-module",
  "9v-0.6a-power-adapter-tp-link-wifi":
    "product/9v-0.6a-power-adapter-tp-link-wifi",
  "product-details/9v-0.6a-power-adapter-tp-link-wifi":
    "product/9v-0.6a-power-adapter-tp-link-wifi",
  "product/detail/9v-0.6a-power-adapter-tp-link-wifi":
    "product/9v-0.6a-power-adapter-tp-link-wifi",
  "4-pin-push-switch-large": "product/4-pin-push-switch-large",
  "product-details/4-pin-push-switch-large": "product/4-pin-push-switch-large",
  "product/detail/4-pin-push-switch-large": "product/4-pin-push-switch-large",
  breadboard: "product/breadboard",
  "product-details/breadboard": "product/breadboard",
  "product/detail/breadboard": "product/breadboard",
  "dc-jack-female-connector": "product/dc-jack-female-connector",
  "product-details/dc-jack-female-connector":
    "product/dc-jack-female-connector",
  "product/detail/dc-jack-female-connector": "product/dc-jack-female-connector",
  "raspberry-pi-4-case-with-cooling-fan":
    "product/raspberry-pi-4-case-with-cooling-fan",
  "product-details/raspberry-pi-4-case-with-cooling-fan":
    "product/raspberry-pi-4-case-with-cooling-fan",
  "product/detail/raspberry-pi-4-case-with-cooling-fan":
    "product/raspberry-pi-4-case-with-cooling-fan",
  "dc-motor-4v-medium-size": "product/dc-motor-4v-medium-size",
  "product-details/dc-motor-4v-medium-size": "product/dc-motor-4v-medium-size",
  "product/detail/dc-motor-4v-medium-size": "product/dc-motor-4v-medium-size",
  "dc-motor-6v": "product/dc-motor-6v",
  "product-details/dc-motor-6v": "product/dc-motor-6v",
  "product/detail/dc-motor-6v": "product/dc-motor-6v",
  "tds-sensor": "product/tds-sensor",
  "product-details/tds-sensor": "product/tds-sensor",
  "product/detail/tds-sensor": "product/tds-sensor",
  moc3021: "product/moc3021",
  "product-details/moc3021": "product/moc3021",
  "product/detail/moc3021": "product/moc3021",
  bf495: "product/bf495",
  "product-details/bf495": "product/bf495",
  "product/detail/bf495": "product/bf495",
  "9v-relay": "product/9v-relay",
  "product-details/9v-relay": "product/9v-relay",
  "product/detail/9v-relay": "product/9v-relay",
  "boost-step-up-power-module-high-voltage-generator":
    "product/boost-step-up-power-module-high-voltage-generator",
  "product-details/boost-step-up-power-module-high-voltage-generator":
    "product/boost-step-up-power-module-high-voltage-generator",
  "product/detail/boost-step-up-power-module-high-voltage-generator":
    "product/boost-step-up-power-module-high-voltage-generator",
  "nrf24l01-wireless-transceiver-module":
    "product/nrf24l01-wireless-transceiver-module",
  "product-details/nrf24l01-wireless-transceiver-module":
    "product/nrf24l01-wireless-transceiver-module",
  "product/detail/nrf24l01-wireless-transceiver-module":
    "product/nrf24l01-wireless-transceiver-module",
  "nucleo-f446re-stm32-nucleo-64-development-board":
    "product/nucleo-f446re-stm32-nucleo-64-development-board",
  "product-details/nucleo-f446re-stm32-nucleo-64-development-board":
    "product/nucleo-f446re-stm32-nucleo-64-development-board",
  "product/detail/nucleo-f446re-stm32-nucleo-64-development-board":
    "product/nucleo-f446re-stm32-nucleo-64-development-board",
  "esp32-development-board": "product/esp32-development-board",
  "product-details/esp32-development-board": "product/esp32-development-board",
  "product/detail/esp32-development-board": "product/esp32-development-board",
  "2-slots-battery-charger": "product/2-slots-battery-charger",
  "product-details/2-slots-battery-charger": "product/2-slots-battery-charger",
  "product/detail/2-slots-battery-charger": "product/2-slots-battery-charger",
  "isd-1820-voice-recorder-module": "product/isd-1820-voice-recorder-module",
  "product-details/isd-1820-voice-recorder-module":
    "product/isd-1820-voice-recorder-module",
  "product/detail/isd-1820-voice-recorder-module":
    "product/isd-1820-voice-recorder-module",
  "cnc-shield-v4-for-arduino-nano": "product/cnc-shield-v4-for-arduino-nano",
  "product-details/cnc-shield-v4-for-arduino-nano":
    "product/cnc-shield-v4-for-arduino-nano",
  "product/detail/cnc-shield-v4-for-arduino-nano":
    "product/cnc-shield-v4-for-arduino-nano",
  "dot-matrix-display-module": "product/dot-matrix-display-module",
  "product-details/dot-matrix-display-module":
    "product/dot-matrix-display-module",
  "product/detail/dot-matrix-display-module":
    "product/dot-matrix-display-module",
  "dot-matrix-module-4-in-1-display-screen":
    "product/dot-matrix-module-4-in-1-display-screen",
  "product-details/dot-matrix-module-4-in-1-display-screen":
    "product/dot-matrix-module-4-in-1-display-screen",
  "product/detail/dot-matrix-module-4-in-1-display-screen":
    "product/dot-matrix-module-4-in-1-display-screen",
  "coin-type-vibration-motor-10mm": "product/coin-type-vibration-motor-10mm",
  "product-details/coin-type-vibration-motor-10mm":
    "product/coin-type-vibration-motor-10mm",
  "product/detail/coin-type-vibration-motor-10mm":
    "product/coin-type-vibration-motor-10mm",
  "heartbeat-sensor": "product/heartbeat-sensor",
  "product-details/heartbeat-sensor": "product/heartbeat-sensor",
  "product/detail/heartbeat-sensor": "product/heartbeat-sensor",
  "ir-receiver-tsop1838": "product/ir-receiver-tsop1838",
  "product-details/ir-receiver-tsop1838": "product/ir-receiver-tsop1838",
  "product/detail/ir-receiver-tsop1838": "product/ir-receiver-tsop1838",
  "3.7v-battery-holder": "product/3.7v-battery-holder",
  "product-details/3.7v-battery-holder": "product/3.7v-battery-holder",
  "product/detail/3.7v-battery-holder": "product/3.7v-battery-holder",
  "wxd3-13-2w-10k-potentiometer": "product/wxd3-13-2w-10k-potentiometer",
  "product-details/wxd3-13-2w-10k-potentiometer":
    "product/wxd3-13-2w-10k-potentiometer",
  "product/detail/wxd3-13-2w-10k-potentiometer":
    "product/wxd3-13-2w-10k-potentiometer",
  "robur-science-kit-the-magic-of-electicity":
    "product/robur-science-kit-the-magic-of-electicity",
  "product-details/robur-science-kit-the-magic-of-electicity":
    "product/robur-science-kit-the-magic-of-electicity",
  "product/detail/robur-science-kit-the-magic-of-electicity":
    "product/robur-science-kit-the-magic-of-electicity",
  "ic-74682": "product/ic-74682",
  "product-details/ic-74682": "product/ic-74682",
  "product/detail/ic-74682": "product/ic-74682",
  "zif-socket-40-pin": "product/zif-socket-40-pin",
  "product-details/zif-socket-40-pin": "product/zif-socket-40-pin",
  "product/detail/zif-socket-40-pin": "product/zif-socket-40-pin",
  "zif-socket-28-pin": "product/zif-socket-28-pin",
  "product-details/zif-socket-28-pin": "product/zif-socket-28-pin",
  "product/detail/zif-socket-28-pin": "product/zif-socket-28-pin",
  "ic-7483": "product/ic-7483",
  "product-details/ic-7483": "product/ic-7483",
  "product/detail/ic-7483": "product/ic-7483",
  "ic-7493": "product/ic-7493",
  "product-details/ic-7493": "product/ic-7493",
  "product/detail/ic-7493": "product/ic-7493",
  "mini-electric-hand-drill-slite": "product/mini-electric-hand-drill-slite",
  "product-details/mini-electric-hand-drill-slite":
    "product/mini-electric-hand-drill-slite",
  "product/detail/mini-electric-hand-drill-slite":
    "product/mini-electric-hand-drill-slite",
  "raspberry-pi-3-model-a-plus": "product/raspberry-pi-3-model-a-plus",
  "product-details/raspberry-pi-3-model-a-plus":
    "product/raspberry-pi-3-model-a-plus",
  "product/detail/raspberry-pi-3-model-a-plus":
    "product/raspberry-pi-3-model-a-plus",
  "passive-buzzer-module": "product/passive-buzzer-module",
  "product-details/passive-buzzer-module": "product/passive-buzzer-module",
  "product/detail/passive-buzzer-module": "product/passive-buzzer-module",
  "winbond-serial-nor-flash-w25q64fv-module":
    "product/winbond-serial-nor-flash-w25q64fv-module",
  "product-details/winbond-serial-nor-flash-w25q64fv-module":
    "product/winbond-serial-nor-flash-w25q64fv-module",
  "product/detail/winbond-serial-nor-flash-w25q64fv-module":
    "product/winbond-serial-nor-flash-w25q64fv-module",
  "hantek-6022bl-pc-digital-portable-oscilloscope":
    "product/hantek-6022bl-pc-digital-portable-oscilloscope",
  "product-details/hantek-6022bl-pc-digital-portable-oscilloscope":
    "product/hantek-6022bl-pc-digital-portable-oscilloscope",
  "product/detail/hantek-6022bl-pc-digital-portable-oscilloscope":
    "product/hantek-6022bl-pc-digital-portable-oscilloscope",
  "pl2303-usb-to-rs232-ttl-converter-adapter-module":
    "product/pl2303-usb-to-rs232-ttl-converter-adapter-module",
  "product-details/pl2303-usb-to-rs232-ttl-converter-adapter-module":
    "product/pl2303-usb-to-rs232-ttl-converter-adapter-module",
  "product/detail/pl2303-usb-to-rs232-ttl-converter-adapter-module":
    "product/pl2303-usb-to-rs232-ttl-converter-adapter-module",
  "robot-car-chassis-2wd-round-set": "product/robot-car-chassis-2wd-round-set",
  "product-details/robot-car-chassis-2wd-round-set":
    "product/robot-car-chassis-2wd-round-set",
  "product/detail/robot-car-chassis-2wd-round-set":
    "product/robot-car-chassis-2wd-round-set",
  "waterpoof-temperature-sensor": "product/waterpoof-temperature-sensor",
  "product-details/waterpoof-temperature-sensor":
    "product/waterpoof-temperature-sensor",
  "product/detail/waterpoof-temperature-sensor":
    "product/waterpoof-temperature-sensor",
  "robur-science-kit-the-magic-of-electricity-mini":
    "product/robur-science-kit-the-magic-of-electricity-mini",
  "product-details/robur-science-kit-the-magic-of-electricity-mini":
    "product/robur-science-kit-the-magic-of-electricity-mini",
  "product/detail/robur-science-kit-the-magic-of-electricity-mini":
    "product/robur-science-kit-the-magic-of-electricity-mini",
  "nrf24l01-wireless-transceiver-module-with-antenna":
    "product/nrf24l01-wireless-transceiver-module-with-antenna",
  "product-details/nrf24l01-wireless-transceiver-module-with-antenna":
    "product/nrf24l01-wireless-transceiver-module-with-antenna",
  "product/detail/nrf24l01-wireless-transceiver-module-with-antenna":
    "product/nrf24l01-wireless-transceiver-module-with-antenna",
  attiny25: "product/attiny25",
  "product-details/attiny25": "product/attiny25",
  "product/detail/attiny25": "product/attiny25",
  attiny45: "product/attiny45",
  "product-details/attiny45": "product/attiny45",
  "product/detail/attiny45": "product/attiny45",
  attiny85: "product/attiny85",
  "product-details/attiny85": "product/attiny85",
  "product/detail/attiny85": "product/attiny85",
  "8-channel-relay-5v": "product/8-channel-relay-5v",
  "product-details/8-channel-relay-5v": "product/8-channel-relay-5v",
  "product/detail/8-channel-relay-5v": "product/8-channel-relay-5v",
  "ic-74181": "product/ic-74181",
  "product-details/ic-74181": "product/ic-74181",
  "product/detail/ic-74181": "product/ic-74181",
  "10k-ohm-potentiometer-6-pin": "product/10k-ohm-potentiometer-6-pin",
  "product-details/10k-ohm-potentiometer-6-pin":
    "product/10k-ohm-potentiometer-6-pin",
  "product/detail/10k-ohm-potentiometer-6-pin":
    "product/10k-ohm-potentiometer-6-pin",
  "50k-ohm-potentiometer-6-pin": "product/50k-ohm-potentiometer-6-pin",
  "product-details/50k-ohm-potentiometer-6-pin":
    "product/50k-ohm-potentiometer-6-pin",
  "product/detail/50k-ohm-potentiometer-6-pin":
    "product/50k-ohm-potentiometer-6-pin",
  "tpa3116d2-digital-subwoofer-power-amplifier-board":
    "product/tpa3116d2-digital-subwoofer-power-amplifier-board",
  "product-details/tpa3116d2-digital-subwoofer-power-amplifier-board":
    "product/tpa3116d2-digital-subwoofer-power-amplifier-board",
  "product/detail/tpa3116d2-digital-subwoofer-power-amplifier-board":
    "product/tpa3116d2-digital-subwoofer-power-amplifier-board",
  "xh-m543-digital-dual-channel-audio-amplification-module":
    "product/xh-m543-digital-dual-channel-audio-amplification-module",
  "product-details/xh-m543-digital-dual-channel-audio-amplification-module":
    "product/xh-m543-digital-dual-channel-audio-amplification-module",
  "product/detail/xh-m543-digital-dual-channel-audio-amplification-module":
    "product/xh-m543-digital-dual-channel-audio-amplification-module",
  "xh-m313-btl-mono-digital-audio-power-amplifier-board":
    "product/xh-m313-btl-mono-digital-audio-power-amplifier-board",
  "product-details/xh-m313-btl-mono-digital-audio-power-amplifier-board":
    "product/xh-m313-btl-mono-digital-audio-power-amplifier-board",
  "product/detail/xh-m313-btl-mono-digital-audio-power-amplifier-board":
    "product/xh-m313-btl-mono-digital-audio-power-amplifier-board",
  "fx-audio-pt2313-volume-control-module-full-set":
    "product/fx-audio-pt2313-volume-control-module-full-set",
  "product-details/fx-audio-pt2313-volume-control-module-full-set":
    "product/fx-audio-pt2313-volume-control-module-full-set",
  "product/detail/fx-audio-pt2313-volume-control-module-full-set":
    "product/fx-audio-pt2313-volume-control-module-full-set",
  "ne5532-stereo-audio-pre-amplifier-tone-board":
    "product/ne5532-stereo-audio-pre-amplifier-tone-board",
  "product-details/ne5532-stereo-audio-pre-amplifier-tone-board":
    "product/ne5532-stereo-audio-pre-amplifier-tone-board",
  "product/detail/ne5532-stereo-audio-pre-amplifier-tone-board":
    "product/ne5532-stereo-audio-pre-amplifier-tone-board",
  "rc-board-v.1-plug-n-play": "product/rc-board-v.1-plug-n-play",
  "product-details/rc-board-v.1-plug-n-play":
    "product/rc-board-v.1-plug-n-play",
  "product/detail/rc-board-v.1-plug-n-play": "product/rc-board-v.1-plug-n-play",
  "robotics-pack-advanced": "product/robotics-pack-advanced",
  "product-details/robotics-pack-advanced": "product/robotics-pack-advanced",
  "product/detail/robotics-pack-advanced": "product/robotics-pack-advanced",
  "9v-battery-power-peak": "product/9v-battery-power-peak",
  "product-details/9v-battery-power-peak": "product/9v-battery-power-peak",
  "product/detail/9v-battery-power-peak": "product/9v-battery-power-peak",
  "tcs3200-color-sensor-module": "product/tcs3200-color-sensor-module",
  "product-details/tcs3200-color-sensor-module":
    "product/tcs3200-color-sensor-module",
  "product/detail/tcs3200-color-sensor-module":
    "product/tcs3200-color-sensor-module",
  "multi-servo-tester": "product/multi-servo-tester",
  "product-details/multi-servo-tester": "product/multi-servo-tester",
  "product/detail/multi-servo-tester": "product/multi-servo-tester",
  "dc-gear-motor": "product/dc-gear-motor",
  "product-details/dc-gear-motor": "product/dc-gear-motor",
  "product/detail/dc-gear-motor": "product/dc-gear-motor",
  "wild-scorpion-11.1v-1100mah-li-po-battery":
    "product/wild-scorpion-11.1v-1100mah-li-po-battery",
  "product-details/wild-scorpion-11.1v-1100mah-li-po-battery":
    "product/wild-scorpion-11.1v-1100mah-li-po-battery",
  "product/detail/wild-scorpion-11.1v-1100mah-li-po-battery":
    "product/wild-scorpion-11.1v-1100mah-li-po-battery",
  "mini-thermometer-and-hygrometer-module":
    "product/mini-thermometer-and-hygrometer-module",
  "product-details/mini-thermometer-and-hygrometer-module":
    "product/mini-thermometer-and-hygrometer-module",
  "product/detail/mini-thermometer-and-hygrometer-module":
    "product/mini-thermometer-and-hygrometer-module",
  "analog-voltmeter-mini": "product/analog-voltmeter-mini",
  "product-details/analog-voltmeter-mini": "product/analog-voltmeter-mini",
  "product/detail/analog-voltmeter-mini": "product/analog-voltmeter-mini",
  "ic-4518": "product/ic-4518",
  "product-details/ic-4518": "product/ic-4518",
  "product/detail/ic-4518": "product/ic-4518",
  "i2c-oled-display-module-128x64": "product/i2c-oled-display-module-128x64",
  "product-details/i2c-oled-display-module-128x64":
    "product/i2c-oled-display-module-128x64",
  "product/detail/i2c-oled-display-module-128x64":
    "product/i2c-oled-display-module-128x64",
  "dc-fan-5v": "product/dc-fan-5v",
  "product-details/dc-fan-5v": "product/dc-fan-5v",
  "product/detail/dc-fan-5v": "product/dc-fan-5v",
  "soccer-robot-pack": "product/soccer-robot-pack",
  "product-details/soccer-robot-pack": "product/soccer-robot-pack",
  "product/detail/soccer-robot-pack": "product/soccer-robot-pack",
  "wxd3-12-1k-ohm-multi-turn-wire-wound-potentiometer":
    "product/wxd3-12-1k-ohm-multi-turn-wire-wound-potentiometer",
  "product-details/wxd3-12-1k-ohm-multi-turn-wire-wound-potentiometer":
    "product/wxd3-12-1k-ohm-multi-turn-wire-wound-potentiometer",
  "product/detail/wxd3-12-1k-ohm-multi-turn-wire-wound-potentiometer":
    "product/wxd3-12-1k-ohm-multi-turn-wire-wound-potentiometer",
  "breadboard-jumbo": "product/breadboard-jumbo",
  "product-details/breadboard-jumbo": "product/breadboard-jumbo",
  "product/detail/breadboard-jumbo": "product/breadboard-jumbo",
  "raspberry-pi-4-model-b": "product/raspberry-pi-4-model-b",
  "product-details/raspberry-pi-4-model-b": "product/raspberry-pi-4-model-b",
  "product/detail/raspberry-pi-4-model-b": "product/raspberry-pi-4-model-b",
  "night-vision-camera-for-raspberry-pi":
    "product/night-vision-camera-for-raspberry-pi",
  "product-details/night-vision-camera-for-raspberry-pi":
    "product/night-vision-camera-for-raspberry-pi",
  "product/detail/night-vision-camera-for-raspberry-pi":
    "product/night-vision-camera-for-raspberry-pi",
  "mcp-m21-7000a-trainer-board": "product/mcp-m21-7000a-trainer-board",
  "product-details/mcp-m21-7000a-trainer-board":
    "product/mcp-m21-7000a-trainer-board",
  "product/detail/mcp-m21-7000a-trainer-board":
    "product/mcp-m21-7000a-trainer-board",
  "gwinstek-gps-4303-dc-power-supply":
    "product/gwinstek-gps-4303-dc-power-supply",
  "product-details/gwinstek-gps-4303-dc-power-supply":
    "product/gwinstek-gps-4303-dc-power-supply",
  "product/detail/gwinstek-gps-4303-dc-power-supply":
    "product/gwinstek-gps-4303-dc-power-supply",
  "gwinstek-gps-3030dd-dc-power-supply":
    "product/gwinstek-gps-3030dd-dc-power-supply",
  "product-details/gwinstek-gps-3030dd-dc-power-supply":
    "product/gwinstek-gps-3030dd-dc-power-supply",
  "product/detail/gwinstek-gps-3030dd-dc-power-supply":
    "product/gwinstek-gps-3030dd-dc-power-supply",
  "oscilloscope-probe": "product/oscilloscope-probe",
  "product-details/oscilloscope-probe": "product/oscilloscope-probe",
  "product/detail/oscilloscope-probe": "product/oscilloscope-probe",
  "fuse-0.5a": "product/fuse-0.5a",
  "product-details/fuse-0.5a": "product/fuse-0.5a",
  "product/detail/fuse-0.5a": "product/fuse-0.5a",
  "fuse-0.25a": "product/fuse-0.25a",
  "product-details/fuse-0.25a": "product/fuse-0.25a",
  "product/detail/fuse-0.25a": "product/fuse-0.25a",
  "fuse-5.0a": "product/fuse-5.0a",
  "product-details/fuse-5.0a": "product/fuse-5.0a",
  "product/detail/fuse-5.0a": "product/fuse-5.0a",
  "brother-tze-621": "product/brother-tze-621",
  "product-details/brother-tze-621": "product/brother-tze-621",
  "product/detail/brother-tze-621": "product/brother-tze-621",
  "sony-alkaine-blue-batteries-aaa-set-of-2":
    "product/sony-alkaine-blue-batteries-aaa-set-of-2",
  "product-details/sony-alkaine-blue-batteries-aaa-set-of-2":
    "product/sony-alkaine-blue-batteries-aaa-set-of-2",
  "product/detail/sony-alkaine-blue-batteries-aaa-set-of-2":
    "product/sony-alkaine-blue-batteries-aaa-set-of-2",
  "ir-f540-n-channel-power-mosfet": "product/ir-f540-n-channel-power-mosfet",
  "product-details/ir-f540-n-channel-power-mosfet":
    "product/ir-f540-n-channel-power-mosfet",
  "product/detail/ir-f540-n-channel-power-mosfet":
    "product/ir-f540-n-channel-power-mosfet",
  "silicon-diode-0.7v": "product/silicon-diode-0.7v",
  "product-details/silicon-diode-0.7v": "product/silicon-diode-0.7v",
  "product/detail/silicon-diode-0.7v": "product/silicon-diode-0.7v",
  "lab-oil": "product/lab-oil",
  "product-details/lab-oil": "product/lab-oil",
  "product/detail/lab-oil": "product/lab-oil",
  "micro-servo-mg90s": "product/micro-servo-mg90s",
  "product-details/micro-servo-mg90s": "product/micro-servo-mg90s",
  "product/detail/micro-servo-mg90s": "product/micro-servo-mg90s",
  "screwdriver-set-32-in-1": "product/screwdriver-set-32-in-1",
  "product-details/screwdriver-set-32-in-1": "product/screwdriver-set-32-in-1",
  "product/detail/screwdriver-set-32-in-1": "product/screwdriver-set-32-in-1",
  "screwdriver-set-42-in-1": "product/screwdriver-set-42-in-1",
  "product-details/screwdriver-set-42-in-1": "product/screwdriver-set-42-in-1",
  "product/detail/screwdriver-set-42-in-1": "product/screwdriver-set-42-in-1",
  "onoff-switch": "product/onoff-switch",
  "product-details/onoff-switch": "product/onoff-switch",
  "product/detail/onoff-switch": "product/onoff-switch",
  "sim800l-module-original": "product/sim800l-module-original",
  "product-details/sim800l-module-original": "product/sim800l-module-original",
  "product/detail/sim800l-module-original": "product/sim800l-module-original",
  "digital-multimeter-ut33d": "product/digital-multimeter-ut33d",
  "product-details/digital-multimeter-ut33d":
    "product/digital-multimeter-ut33d",
  "product/detail/digital-multimeter-ut33d": "product/digital-multimeter-ut33d",
  "buck-boost-module": "product/buck-boost-module",
  "product-details/buck-boost-module": "product/buck-boost-module",
  "product/detail/buck-boost-module": "product/buck-boost-module",
  "adjustable-boost-module-with-display":
    "product/adjustable-boost-module-with-display",
  "product-details/adjustable-boost-module-with-display":
    "product/adjustable-boost-module-with-display",
  "product/detail/adjustable-boost-module-with-display":
    "product/adjustable-boost-module-with-display",
  "monster-motor-driver": "product/monster-motor-driver",
  "product-details/monster-motor-driver": "product/monster-motor-driver",
  "product/detail/monster-motor-driver": "product/monster-motor-driver",
  "mono-digital-audio-power-amplifier-board-amp-module":
    "product/mono-digital-audio-power-amplifier-board-amp-module",
  "product-details/mono-digital-audio-power-amplifier-board-amp-module":
    "product/mono-digital-audio-power-amplifier-board-amp-module",
  "product/detail/mono-digital-audio-power-amplifier-board-amp-module":
    "product/mono-digital-audio-power-amplifier-board-amp-module",
  "voice-recognition-module-v3": "product/voice-recognition-module-v3",
  "product-details/voice-recognition-module-v3":
    "product/voice-recognition-module-v3",
  "product/detail/voice-recognition-module-v3":
    "product/voice-recognition-module-v3",
  "component-tester": "product/component-tester",
  "product-details/component-tester": "product/component-tester",
  "product/detail/component-tester": "product/component-tester",
  "wifi-relay-module-esp8266-5v": "product/wifi-relay-module-esp8266-5v",
  "product-details/wifi-relay-module-esp8266-5v":
    "product/wifi-relay-module-esp8266-5v",
  "product/detail/wifi-relay-module-esp8266-5v":
    "product/wifi-relay-module-esp8266-5v",
  "mini-pro-external-antenna-connector-wemos-d1-v1.0-16m-bytes":
    "product/mini-pro-external-antenna-connector-wemos-d1-v1.0-16m-bytes",
  "product-details/mini-pro-external-antenna-connector-wemos-d1-v1.0-16m-bytes":
    "product/mini-pro-external-antenna-connector-wemos-d1-v1.0-16m-bytes",
  "product/detail/mini-pro-external-antenna-connector-wemos-d1-v1.0-16m-bytes":
    "product/mini-pro-external-antenna-connector-wemos-d1-v1.0-16m-bytes",
  "cutter-tool": "product/cutter-tool",
  "product-details/cutter-tool": "product/cutter-tool",
  "product/detail/cutter-tool": "product/cutter-tool",
  "cutter-with-stripper": "product/cutter-with-stripper",
  "product-details/cutter-with-stripper": "product/cutter-with-stripper",
  "product/detail/cutter-with-stripper": "product/cutter-with-stripper",
  "5-channel-infrared-reflective-pir-sensor-module":
    "product/5-channel-infrared-reflective-pir-sensor-module",
  "product-details/5-channel-infrared-reflective-pir-sensor-module":
    "product/5-channel-infrared-reflective-pir-sensor-module",
  "product/detail/5-channel-infrared-reflective-pir-sensor-module":
    "product/5-channel-infrared-reflective-pir-sensor-module",
  "buck-converter-dc-dc-voltage-regulator":
    "product/buck-converter-dc-dc-voltage-regulator",
  "product-details/buck-converter-dc-dc-voltage-regulator":
    "product/buck-converter-dc-dc-voltage-regulator",
  "product/detail/buck-converter-dc-dc-voltage-regulator":
    "product/buck-converter-dc-dc-voltage-regulator",
  "buck-boost-board-power-supply-module":
    "product/buck-boost-board-power-supply-module",
  "product-details/buck-boost-board-power-supply-module":
    "product/buck-boost-board-power-supply-module",
  "product/detail/buck-boost-board-power-supply-module":
    "product/buck-boost-board-power-supply-module",
  "8-channel-infrared-tracking-sensor-module":
    "product/8-channel-infrared-tracking-sensor-module",
  "product-details/8-channel-infrared-tracking-sensor-module":
    "product/8-channel-infrared-tracking-sensor-module",
  "product/detail/8-channel-infrared-tracking-sensor-module":
    "product/8-channel-infrared-tracking-sensor-module",
  "water-level-control-switch": "product/water-level-control-switch",
  "product-details/water-level-control-switch":
    "product/water-level-control-switch",
  "product/detail/water-level-control-switch":
    "product/water-level-control-switch",
  "anti-cutter": "product/anti-cutter",
  "product-details/anti-cutter": "product/anti-cutter",
  "product/detail/anti-cutter": "product/anti-cutter",
  "smart-car-robot-wheel-plastic-tire-and-motor-mounting-bracket":
    "product/smart-car-robot-wheel-plastic-tire-and-motor-mounting-bracket",
  "product-details/smart-car-robot-wheel-plastic-tire-and-motor-mounting-bracket":
    "product/smart-car-robot-wheel-plastic-tire-and-motor-mounting-bracket",
  "product/detail/smart-car-robot-wheel-plastic-tire-and-motor-mounting-bracket":
    "product/smart-car-robot-wheel-plastic-tire-and-motor-mounting-bracket",
  "hc-sr04-smart-ultrasonic-sensor-holder":
    "product/hc-sr04-smart-ultrasonic-sensor-holder",
  "product-details/hc-sr04-smart-ultrasonic-sensor-holder":
    "product/hc-sr04-smart-ultrasonic-sensor-holder",
  "product/detail/hc-sr04-smart-ultrasonic-sensor-holder":
    "product/hc-sr04-smart-ultrasonic-sensor-holder",
  "power-bank-battery-case-18650-x-6-battery-charger-box":
    "product/power-bank-battery-case-18650-x-6-battery-charger-box",
  "product-details/power-bank-battery-case-18650-x-6-battery-charger-box":
    "product/power-bank-battery-case-18650-x-6-battery-charger-box",
  "product/detail/power-bank-battery-case-18650-x-6-battery-charger-box":
    "product/power-bank-battery-case-18650-x-6-battery-charger-box",
  "power-bank-battery-case-18650-x-7-battery-charger-box":
    "product/power-bank-battery-case-18650-x-7-battery-charger-box",
  "product-details/power-bank-battery-case-18650-x-7-battery-charger-box":
    "product/power-bank-battery-case-18650-x-7-battery-charger-box",
  "product/detail/power-bank-battery-case-18650-x-7-battery-charger-box":
    "product/power-bank-battery-case-18650-x-7-battery-charger-box",
  "power-bank-battery-case-18650-x-8-battery-charger-box":
    "product/power-bank-battery-case-18650-x-8-battery-charger-box",
  "product-details/power-bank-battery-case-18650-x-8-battery-charger-box":
    "product/power-bank-battery-case-18650-x-8-battery-charger-box",
  "product/detail/power-bank-battery-case-18650-x-8-battery-charger-box":
    "product/power-bank-battery-case-18650-x-8-battery-charger-box",
  "dc-motor-4v": "product/dc-motor-4v",
  "product-details/dc-motor-4v": "product/dc-motor-4v",
  "product/detail/dc-motor-4v": "product/dc-motor-4v",
  "electronic-heat-gun": "product/electronic-heat-gun",
  "product-details/electronic-heat-gun": "product/electronic-heat-gun",
  "product/detail/electronic-heat-gun": "product/electronic-heat-gun",
  "37v-battery-holder-three-cell-3008221":
    "product/37v-battery-holder-three-cell-3008221",
  "product-details/37v-battery-holder-three-cell-3008221":
    "product/37v-battery-holder-three-cell-3008221",
  "product/detail/37v-battery-holder-three-cell-3008221":
    "product/37v-battery-holder-three-cell-3008221",
  "digital-voltmeter-ammeter-1909221":
    "product/digital-voltmeter-ammeter-1909221",
  "product-details/digital-voltmeter-ammeter-1909221":
    "product/digital-voltmeter-ammeter-1909221",
  "product/detail/digital-voltmeter-ammeter-1909221":
    "product/digital-voltmeter-ammeter-1909221",
  "glue-gun-60-w-mini": "product/glue-gun-60-w-mini",
  "product-details/glue-gun-60-w-mini": "product/glue-gun-60-w-mini",
  "product/detail/glue-gun-60-w-mini": "product/glue-gun-60-w-mini",
  "universal-battery-charger-for-li-ion-battery":
    "product/universal-battery-charger-for-li-ion-battery",
  "product-details/universal-battery-charger-for-li-ion-battery":
    "product/universal-battery-charger-for-li-ion-battery",
  "product/detail/universal-battery-charger-for-li-ion-battery":
    "product/universal-battery-charger-for-li-ion-battery",
  "37v-lithium-polymer-battery-1000mah":
    "product/37v-lithium-polymer-battery-1000mah",
  "product-details/37v-lithium-polymer-battery-1000mah":
    "product/37v-lithium-polymer-battery-1000mah",
  "product/detail/37v-lithium-polymer-battery-1000mah":
    "product/37v-lithium-polymer-battery-1000mah",
  "drv8825-purple-stepper-motor-driver":
    "product/drv8825-purple-stepper-motor-driver",
  "product-details/drv8825-purple-stepper-motor-driver":
    "product/drv8825-purple-stepper-motor-driver",
  "product/detail/drv8825-purple-stepper-motor-driver":
    "product/drv8825-purple-stepper-motor-driver",
  "gy-us42-i2c-ultrasonic-sensor-board":
    "product/gy-us42-i2c-ultrasonic-sensor-board",
  "product-details/gy-us42-i2c-ultrasonic-sensor-board":
    "product/gy-us42-i2c-ultrasonic-sensor-board",
  "product/detail/gy-us42-i2c-ultrasonic-sensor-board":
    "product/gy-us42-i2c-ultrasonic-sensor-board",
  "37v-18650-rechargeable-li-ion-battery":
    "product/37v-18650-rechargeable-li-ion-battery",
  "product-details/37v-18650-rechargeable-li-ion-battery":
    "product/37v-18650-rechargeable-li-ion-battery",
  "product/detail/37v-18650-rechargeable-li-ion-battery":
    "product/37v-18650-rechargeable-li-ion-battery",
  "60w-soldering-iron-xcy-908": "product/60w-soldering-iron-xcy-908",
  "product-details/60w-soldering-iron-xcy-908":
    "product/60w-soldering-iron-xcy-908",
  "product/detail/60w-soldering-iron-xcy-908":
    "product/60w-soldering-iron-xcy-908",
  "soldering-iron-40w": "product/soldering-iron-40w",
  "product-details/soldering-iron-40w": "product/soldering-iron-40w",
  "product/detail/soldering-iron-40w": "product/soldering-iron-40w",
  "soldering-iron-60w-with-digital-lcd-display":
    "product/soldering-iron-60w-with-digital-lcd-display",
  "product-details/soldering-iron-60w-with-digital-lcd-display":
    "product/soldering-iron-60w-with-digital-lcd-display",
  "product/detail/soldering-iron-60w-with-digital-lcd-display":
    "product/soldering-iron-60w-with-digital-lcd-display",
  "37v-9900mah-18650-rechargeable-lithium-battery":
    "product/37v-9900mah-18650-rechargeable-lithium-battery",
  "product-details/37v-9900mah-18650-rechargeable-lithium-battery":
    "product/37v-9900mah-18650-rechargeable-lithium-battery",
  "product/detail/37v-9900mah-18650-rechargeable-lithium-battery":
    "product/37v-9900mah-18650-rechargeable-lithium-battery",
  "heat-shrink-tube-1-x-40mm": "product/heat-shrink-tube-1-x-40mm",
  "product-details/heat-shrink-tube-1-x-40mm":
    "product/heat-shrink-tube-1-x-40mm",
  "product/detail/heat-shrink-tube-1-x-40mm":
    "product/heat-shrink-tube-1-x-40mm",
  "heat-shrink-tube-2-x-40mm": "product/heat-shrink-tube-2-x-40mm",
  "product-details/heat-shrink-tube-2-x-40mm":
    "product/heat-shrink-tube-2-x-40mm",
  "product/detail/heat-shrink-tube-2-x-40mm":
    "product/heat-shrink-tube-2-x-40mm",
  "heat-shrink-tube-3-x-40mm": "product/heat-shrink-tube-3-x-40mm",
  "product-details/heat-shrink-tube-3-x-40mm":
    "product/heat-shrink-tube-3-x-40mm",
  "product/detail/heat-shrink-tube-3-x-40mm":
    "product/heat-shrink-tube-3-x-40mm",
  "heat-shrink-tube-4-x-40mm": "product/heat-shrink-tube-4-x-40mm",
  "product-details/heat-shrink-tube-4-x-40mm":
    "product/heat-shrink-tube-4-x-40mm",
  "product/detail/heat-shrink-tube-4-x-40mm":
    "product/heat-shrink-tube-4-x-40mm",
  "heat-shrink-tube-6-x-40mm": "product/heat-shrink-tube-6-x-40mm",
  "product-details/heat-shrink-tube-6-x-40mm":
    "product/heat-shrink-tube-6-x-40mm",
  "product/detail/heat-shrink-tube-6-x-40mm":
    "product/heat-shrink-tube-6-x-40mm",
  "heat-shrink-tube-8-x-80mm": "product/heat-shrink-tube-8-x-80mm",
  "product-details/heat-shrink-tube-8-x-80mm":
    "product/heat-shrink-tube-8-x-80mm",
  "product/detail/heat-shrink-tube-8-x-80mm":
    "product/heat-shrink-tube-8-x-80mm",
  "heat-shrink-tube-10-x-40mm": "product/heat-shrink-tube-10-x-40mm",
  "product-details/heat-shrink-tube-10-x-40mm":
    "product/heat-shrink-tube-10-x-40mm",
  "product/detail/heat-shrink-tube-10-x-40mm":
    "product/heat-shrink-tube-10-x-40mm",
  "heat-shrink-tube-14-x-40mm": "product/heat-shrink-tube-14-x-40mm",
  "product-details/heat-shrink-tube-14-x-40mm":
    "product/heat-shrink-tube-14-x-40mm",
  "product/detail/heat-shrink-tube-14-x-40mm":
    "product/heat-shrink-tube-14-x-40mm",
  "crocodile-clip-set": "product/crocodile-clip-set",
  "product-details/crocodile-clip-set": "product/crocodile-clip-set",
  "product/detail/crocodile-clip-set": "product/crocodile-clip-set",
  "bluetooth-controlled-arduino-car-kit":
    "product/bluetooth-controlled-arduino-car-kit",
  "product-details/bluetooth-controlled-arduino-car-kit":
    "product/bluetooth-controlled-arduino-car-kit",
  "product/detail/bluetooth-controlled-arduino-car-kit":
    "product/bluetooth-controlled-arduino-car-kit",
  "landing-gear-set": "product/landing-gear-set",
  "product-details/landing-gear-set": "product/landing-gear-set",
  "product/detail/landing-gear-set": "product/landing-gear-set",
  "nano-pro-v31": "product/nano-pro-v31",
  "product-details/nano-pro-v31": "product/nano-pro-v31",
  "product/detail/nano-pro-v31": "product/nano-pro-v31",
  "ic-7448": "product/ic-7448",
  "product-details/ic-7448": "product/ic-7448",
  "product/detail/ic-7448": "product/ic-7448",
  "37v-lithium-polymer-battery-1200mah":
    "product/37v-lithium-polymer-battery-1200mah",
  "product-details/37v-lithium-polymer-battery-1200mah":
    "product/37v-lithium-polymer-battery-1200mah",
  "product/detail/37v-lithium-polymer-battery-1200mah":
    "product/37v-lithium-polymer-battery-1200mah",
  "sim900-gsm-gprs-with-antenna": "product/sim900-gsm-gprs-with-antenna",
  "product-details/sim900-gsm-gprs-with-antenna":
    "product/sim900-gsm-gprs-with-antenna",
  "product/detail/sim900-gsm-gprs-with-antenna":
    "product/sim900-gsm-gprs-with-antenna",
  "solder-tip-head-cleaner": "product/solder-tip-head-cleaner",
  "product-details/solder-tip-head-cleaner": "product/solder-tip-head-cleaner",
  "product/detail/solder-tip-head-cleaner": "product/solder-tip-head-cleaner",
  "soldering-paste-bk-150-baku": "product/soldering-paste-bk-150-baku",
  "product-details/soldering-paste-bk-150-baku":
    "product/soldering-paste-bk-150-baku",
  "product/detail/soldering-paste-bk-150-baku":
    "product/soldering-paste-bk-150-baku",
  "audio-plug": "product/audio-plug",
  "product-details/audio-plug": "product/audio-plug",
  "product/detail/audio-plug": "product/audio-plug",
  "non-invasive-ac-current-sensor-split-core-current-transformer-sct-013-000-100a":
    "product/non-invasive-ac-current-sensor-split-core-current-transformer-sct-013-000-100a",
  "product-details/non-invasive-ac-current-sensor-split-core-current-transformer-sct-013-000-100a":
    "product/non-invasive-ac-current-sensor-split-core-current-transformer-sct-013-000-100a",
  "product/detail/non-invasive-ac-current-sensor-split-core-current-transformer-sct-013-000-100a":
    "product/non-invasive-ac-current-sensor-split-core-current-transformer-sct-013-000-100a",
  "ac-voltage-sensor-module": "product/ac-voltage-sensor-module",
  "product-details/ac-voltage-sensor-module":
    "product/ac-voltage-sensor-module",
  "product/detail/ac-voltage-sensor-module": "product/ac-voltage-sensor-module",
  "lcd-display-20-x-4-with-i2c": "product/lcd-display-20-x-4-with-i2c",
  "product-details/lcd-display-20-x-4-with-i2c":
    "product/lcd-display-20-x-4-with-i2c",
  "product/detail/lcd-display-20-x-4-with-i2c":
    "product/lcd-display-20-x-4-with-i2c",
  "37v-battery-holder-four-cell": "product/37v-battery-holder-four-cell",
  "product-details/37v-battery-holder-four-cell":
    "product/37v-battery-holder-four-cell",
  "product/detail/37v-battery-holder-four-cell":
    "product/37v-battery-holder-four-cell",
  "37v-lithium-polymer-battery-800mah":
    "product/37v-lithium-polymer-battery-800mah",
  "product-details/37v-lithium-polymer-battery-800mah":
    "product/37v-lithium-polymer-battery-800mah",
  "product/detail/37v-lithium-polymer-battery-800mah":
    "product/37v-lithium-polymer-battery-800mah",
  "37v-battery-holder-with-onoff-switch-three-cell":
    "product/37v-battery-holder-with-onoff-switch-three-cell",
  "product-details/37v-battery-holder-with-onoff-switch-three-cell":
    "product/37v-battery-holder-with-onoff-switch-three-cell",
  "product/detail/37v-battery-holder-with-onoff-switch-three-cell":
    "product/37v-battery-holder-with-onoff-switch-three-cell",
  "baseus-fast-charging-micro-b-usb-premium-cable-for-esp8266-and-esp32":
    "product/baseus-fast-charging-micro-b-usb-premium-cable-for-esp8266-and-esp32",
  "product-details/baseus-fast-charging-micro-b-usb-premium-cable-for-esp8266-and-esp32":
    "product/baseus-fast-charging-micro-b-usb-premium-cable-for-esp8266-and-esp32",
  "product/detail/baseus-fast-charging-micro-b-usb-premium-cable-for-esp8266-and-esp32":
    "product/baseus-fast-charging-micro-b-usb-premium-cable-for-esp8266-and-esp32",
  "usb-digital-microscope-1000x": "product/usb-digital-microscope-1000x",
  "product-details/usb-digital-microscope-1000x":
    "product/usb-digital-microscope-1000x",
  "product/detail/usb-digital-microscope-1000x":
    "product/usb-digital-microscope-1000x",
  "flysky-fs-ia6b-24g-6ch-ppm-and-ibus-receiver":
    "product/flysky-fs-ia6b-24g-6ch-ppm-and-ibus-receiver",
  "product-details/flysky-fs-ia6b-24g-6ch-ppm-and-ibus-receiver":
    "product/flysky-fs-ia6b-24g-6ch-ppm-and-ibus-receiver",
  "product/detail/flysky-fs-ia6b-24g-6ch-ppm-and-ibus-receiver":
    "product/flysky-fs-ia6b-24g-6ch-ppm-and-ibus-receiver",
  "flysky-fs-i6-24g-6ch-afhds-rc-transmitter":
    "product/flysky-fs-i6-24g-6ch-afhds-rc-transmitter",
  "product-details/flysky-fs-i6-24g-6ch-afhds-rc-transmitter":
    "product/flysky-fs-i6-24g-6ch-afhds-rc-transmitter",
  "product/detail/flysky-fs-i6-24g-6ch-afhds-rc-transmitter":
    "product/flysky-fs-i6-24g-6ch-afhds-rc-transmitter",
  "20a-brushed-esc-with-brake-for-rc-car-bot":
    "product/20a-brushed-esc-with-brake-for-rc-car-bot",
  "product-details/20a-brushed-esc-with-brake-for-rc-car-bot":
    "product/20a-brushed-esc-with-brake-for-rc-car-bot",
  "product/detail/20a-brushed-esc-with-brake-for-rc-car-bot":
    "product/20a-brushed-esc-with-brake-for-rc-car-bot",
  "a221210t-1400kv-brushless-motor": "product/a221210t-1400kv-brushless-motor",
  "product-details/a221210t-1400kv-brushless-motor":
    "product/a221210t-1400kv-brushless-motor",
  "product/detail/a221210t-1400kv-brushless-motor":
    "product/a221210t-1400kv-brushless-motor",
  "uni-t-utp3315tfl-ii-dc-power-supply-30v-5a":
    "product/uni-t-utp3315tfl-ii-dc-power-supply-30v-5a",
  "product-details/uni-t-utp3315tfl-ii-dc-power-supply-30v-5a":
    "product/uni-t-utp3315tfl-ii-dc-power-supply-30v-5a",
  "product/detail/uni-t-utp3315tfl-ii-dc-power-supply-30v-5a":
    "product/uni-t-utp3315tfl-ii-dc-power-supply-30v-5a",
  "st-link-v2-programmer-for-stm8-stm32":
    "product/st-link-v2-programmer-for-stm8-stm32",
  "product-details/st-link-v2-programmer-for-stm8-stm32":
    "product/st-link-v2-programmer-for-stm8-stm32",
  "product/detail/st-link-v2-programmer-for-stm8-stm32":
    "product/st-link-v2-programmer-for-stm8-stm32",
  "pixhawk-px4-248-flight-controller-32-bit-arm-for-multicopters":
    "product/pixhawk-px4-248-flight-controller-32-bit-arm-for-multicopters",
  "product-details/pixhawk-px4-248-flight-controller-32-bit-arm-for-multicopters":
    "product/pixhawk-px4-248-flight-controller-32-bit-arm-for-multicopters",
  "product/detail/pixhawk-px4-248-flight-controller-32-bit-arm-for-multicopters":
    "product/pixhawk-px4-248-flight-controller-32-bit-arm-for-multicopters",
  "copper-solder-wire-2mm-copper-braid-wick-spool":
    "product/copper-solder-wire-2mm-copper-braid-wick-spool",
  "product-details/copper-solder-wire-2mm-copper-braid-wick-spool":
    "product/copper-solder-wire-2mm-copper-braid-wick-spool",
  "product/detail/copper-solder-wire-2mm-copper-braid-wick-spool":
    "product/copper-solder-wire-2mm-copper-braid-wick-spool",
  "m-and-r-tra-fine-point-log-stainless-steel-140mm-tweezer-mr-11":
    "product/m-and-r-tra-fine-point-log-stainless-steel-140mm-tweezer-mr-11",
  "product-details/m-and-r-tra-fine-point-log-stainless-steel-140mm-tweezer-mr-11":
    "product/m-and-r-tra-fine-point-log-stainless-steel-140mm-tweezer-mr-11",
  "product/detail/m-and-r-tra-fine-point-log-stainless-steel-140mm-tweezer-mr-11":
    "product/m-and-r-tra-fine-point-log-stainless-steel-140mm-tweezer-mr-11",
  "curved-tips-118mm-tweezer-mr-15": "product/curved-tips-118mm-tweezer-mr-15",
  "product-details/curved-tips-118mm-tweezer-mr-15":
    "product/curved-tips-118mm-tweezer-mr-15",
  "product/detail/curved-tips-118mm-tweezer-mr-15":
    "product/curved-tips-118mm-tweezer-mr-15",
  "uni-t-ut890d-plus-digital-multimeter":
    "product/uni-t-ut890d-plus-digital-multimeter",
  "product-details/uni-t-ut890d-plus-digital-multimeter":
    "product/uni-t-ut890d-plus-digital-multimeter",
  "product/detail/uni-t-ut890d-plus-digital-multimeter":
    "product/uni-t-ut890d-plus-digital-multimeter",
  "buzz-wire-game": "product/buzz-wire-game",
  "product-details/buzz-wire-game": "product/buzz-wire-game",
  "product/detail/buzz-wire-game": "product/buzz-wire-game",
  "105pcs-mini-rotary-tool-accessories-kit-power-drill-dremel-rotary-multi-tool":
    "product/105pcs-mini-rotary-tool-accessories-kit-power-drill-dremel-rotary-multi-tool",
  "product-details/105pcs-mini-rotary-tool-accessories-kit-power-drill-dremel-rotary-multi-tool":
    "product/105pcs-mini-rotary-tool-accessories-kit-power-drill-dremel-rotary-multi-tool",
  "product/detail/105pcs-mini-rotary-tool-accessories-kit-power-drill-dremel-rotary-multi-tool":
    "product/105pcs-mini-rotary-tool-accessories-kit-power-drill-dremel-rotary-multi-tool",
  "portable-mini-usb-donut-humidifier":
    "product/portable-mini-usb-donut-humidifier",
  "product-details/portable-mini-usb-donut-humidifier":
    "product/portable-mini-usb-donut-humidifier",
  "product/detail/portable-mini-usb-donut-humidifier":
    "product/portable-mini-usb-donut-humidifier",
  "high-power-speaker-protect-relay-board-21-channel":
    "product/high-power-speaker-protect-relay-board-21-channel",
  "product-details/high-power-speaker-protect-relay-board-21-channel":
    "product/high-power-speaker-protect-relay-board-21-channel",
  "product/detail/high-power-speaker-protect-relay-board-21-channel":
    "product/high-power-speaker-protect-relay-board-21-channel",
  "w1219-dc-12v-temperature-controller-switch-module":
    "product/w1219-dc-12v-temperature-controller-switch-module",
  "product-details/w1219-dc-12v-temperature-controller-switch-module":
    "product/w1219-dc-12v-temperature-controller-switch-module",
  "product/detail/w1219-dc-12v-temperature-controller-switch-module":
    "product/w1219-dc-12v-temperature-controller-switch-module",
  "8045-propeller-cw-ccw-for-multicopter-rc-drone":
    "product/8045-propeller-cw-ccw-for-multicopter-rc-drone",
  "product-details/8045-propeller-cw-ccw-for-multicopter-rc-drone":
    "product/8045-propeller-cw-ccw-for-multicopter-rc-drone",
  "product/detail/8045-propeller-cw-ccw-for-multicopter-rc-drone":
    "product/8045-propeller-cw-ccw-for-multicopter-rc-drone",
  "1045-propeller-cw-ccw-for-multicopter-rc-drone":
    "product/1045-propeller-cw-ccw-for-multicopter-rc-drone",
  "product-details/1045-propeller-cw-ccw-for-multicopter-rc-drone":
    "product/1045-propeller-cw-ccw-for-multicopter-rc-drone",
  "product/detail/1045-propeller-cw-ccw-for-multicopter-rc-drone":
    "product/1045-propeller-cw-ccw-for-multicopter-rc-drone",
  "long-range-e01-ml01dp5-24ghz-rf-wireless-transceiver-module-with-antenna":
    "product/long-range-e01-ml01dp5-24ghz-rf-wireless-transceiver-module-with-antenna",
  "product-details/long-range-e01-ml01dp5-24ghz-rf-wireless-transceiver-module-with-antenna":
    "product/long-range-e01-ml01dp5-24ghz-rf-wireless-transceiver-module-with-antenna",
  "product/detail/long-range-e01-ml01dp5-24ghz-rf-wireless-transceiver-module-with-antenna":
    "product/long-range-e01-ml01dp5-24ghz-rf-wireless-transceiver-module-with-antenna",
  "gps-module-gy-gpsv3-neo-8m-with-ceramic-active-antenna":
    "product/gps-module-gy-gpsv3-neo-8m-with-ceramic-active-antenna",
  "product-details/gps-module-gy-gpsv3-neo-8m-with-ceramic-active-antenna":
    "product/gps-module-gy-gpsv3-neo-8m-with-ceramic-active-antenna",
  "product/detail/gps-module-gy-gpsv3-neo-8m-with-ceramic-active-antenna":
    "product/gps-module-gy-gpsv3-neo-8m-with-ceramic-active-antenna",
  "mini-enc28j60-ethernet-lan-network-module-for-arduino":
    "product/mini-enc28j60-ethernet-lan-network-module-for-arduino",
  "product-details/mini-enc28j60-ethernet-lan-network-module-for-arduino":
    "product/mini-enc28j60-ethernet-lan-network-module-for-arduino",
  "product/detail/mini-enc28j60-ethernet-lan-network-module-for-arduino":
    "product/mini-enc28j60-ethernet-lan-network-module-for-arduino",
  "pulse-generator-module-stepper-motor":
    "product/pulse-generator-module-stepper-motor",
  "product-details/pulse-generator-module-stepper-motor":
    "product/pulse-generator-module-stepper-motor",
  "product/detail/pulse-generator-module-stepper-motor":
    "product/pulse-generator-module-stepper-motor",
  "cn101a-dcac-12v-16a-amps-digital-lcd-power-programmable-timer":
    "product/cn101a-dcac-12v-16a-amps-digital-lcd-power-programmable-timer",
  "product-details/cn101a-dcac-12v-16a-amps-digital-lcd-power-programmable-timer":
    "product/cn101a-dcac-12v-16a-amps-digital-lcd-power-programmable-timer",
  "product/detail/cn101a-dcac-12v-16a-amps-digital-lcd-power-programmable-timer":
    "product/cn101a-dcac-12v-16a-amps-digital-lcd-power-programmable-timer",
  "10a-12v24v-adjustable-solar-charge-controller-with-lcd-display-and-dual-usb-port":
    "product/10a-12v24v-adjustable-solar-charge-controller-with-lcd-display-and-dual-usb-port",
  "product-details/10a-12v24v-adjustable-solar-charge-controller-with-lcd-display-and-dual-usb-port":
    "product/10a-12v24v-adjustable-solar-charge-controller-with-lcd-display-and-dual-usb-port",
  "product/detail/10a-12v24v-adjustable-solar-charge-controller-with-lcd-display-and-dual-usb-port":
    "product/10a-12v24v-adjustable-solar-charge-controller-with-lcd-display-and-dual-usb-port",
  "55-x-55-mini-solar-panel-5v": "product/55-x-55-mini-solar-panel-5v",
  "product-details/55-x-55-mini-solar-panel-5v":
    "product/55-x-55-mini-solar-panel-5v",
  "product/detail/55-x-55-mini-solar-panel-5v":
    "product/55-x-55-mini-solar-panel-5v",
  "f550-hexacopter-frame-6-axis-frame-kit":
    "product/f550-hexacopter-frame-6-axis-frame-kit",
  "product-details/f550-hexacopter-frame-6-axis-frame-kit":
    "product/f550-hexacopter-frame-6-axis-frame-kit",
  "product/detail/f550-hexacopter-frame-6-axis-frame-kit":
    "product/f550-hexacopter-frame-6-axis-frame-kit",
  "xh-m609-12-36v-battery-low-voltage-disconnect-protection-module-dc-output":
    "product/xh-m609-12-36v-battery-low-voltage-disconnect-protection-module-dc-output",
  "product-details/xh-m609-12-36v-battery-low-voltage-disconnect-protection-module-dc-output":
    "product/xh-m609-12-36v-battery-low-voltage-disconnect-protection-module-dc-output",
  "product/detail/xh-m609-12-36v-battery-low-voltage-disconnect-protection-module-dc-output":
    "product/xh-m609-12-36v-battery-low-voltage-disconnect-protection-module-dc-output",
  "acrylic-case-for-raspberry-pi-4-model-b":
    "product/acrylic-case-for-raspberry-pi-4-model-b",
  "product-details/acrylic-case-for-raspberry-pi-4-model-b":
    "product/acrylic-case-for-raspberry-pi-4-model-b",
  "product/detail/acrylic-case-for-raspberry-pi-4-model-b":
    "product/acrylic-case-for-raspberry-pi-4-model-b",
  "project-kits": "category/project-kits",
  "starter-kits": "category/starter-kits",
  robotics: "category/robotics",
  "analog-ics": "category/analog-ics",
  "digital-ics": "category/digital-ics",
  cables: "category/cables",
  components: "category/components",
  displays: "category/displays",
  "development-boards": "category/development-boards",
  "micro-controllers": "category/micro-controllers",
  modules: "category/modules",
  sensors: "category/sensors",
  tools: "category/tools",
  wireless: "category/wireless",
  others: "category/others",
  drone: "category/drone",
  "internet-of-things-iot": "category/internet-of-things-iot",
  "project-kits/digital-logic-design": "category/digital-logic-design",
  "project-kits/electric-circuits": "category/electric-circuits",
  "project-kits/analog-electronics": "category/analog-electronics",
  "project-kits/junior-design": "category/junior-design",
  "starter-kits/starter-kits": "category/starter-kits-new",
  "robotics/actuator": "category/actuator",
  "robotics/controller": "category/controller",
  "robotics/drivers": "category/drivers",
  "robotics/kits": "category/kits",
  "robotics/sensors": "category/sensors-new",
  "robotics/wheels": "category/wheels",
  "robotics/motors": "category/motors",
  "analog-ics/analog-ics": "category/analog-ics-new",
  "digital-ics/digital-ics": "category/digital-ics-new",
  "cables/wires": "category/wires",
  "cables/usb-cable": "category/usb-cable",
  "cables/connectors": "category/connectors",
  "components/batteries": "category/batteries",
  "components/switches": "category/switches",
  "components/capacitors": "category/capacitors",
  "components/relay": "category/relay",
  "components/resistors": "category/resistors",
  "components/transformers": "category/transformers",
  "components/transistors": "category/transistors",
  "components/regulators": "category/regulators",
  "components/led": "category/led",
  "components/diode": "category/diode",
  "components/inductor": "category/inductor",
  "components/burner-programmer": "category/burner-programmer",
  "components/crystal-oscillator": "category/crystal-oscillator",
  "components/solar": "category/solar",
  "displays/displays": "category/displays-new",
  "development-boards/arduino": "category/arduino",
  "development-boards/raspberry-pi": "category/raspberry-pi",
  "development-boards/breadboard": "category/breadboard",
  "development-boards/prototype-board": "category/prototype-board",
  "development-boards/shields": "category/shields",
  "micro-controllers/8051": "category/8051",
  "micro-controllers/avr": "category/avr",
  "micro-controllers/pic": "category/pic",
  "modules/modules": "category/modules-new",
  "sensors/light": "category/light",
  "sensors/ultrasonic": "category/ultrasonic",
  "sensors/temperature": "category/temperature",
  "sensors/gas-and-smoke": "category/gas-and-smoke",
  "sensors/biometric": "category/biometric",
  "sensors/pressure": "category/pressure",
  "sensors/soil-water": "category/soil-water",
  "sensors/audio": "category/audio",
  "sensors/vibration": "category/vibration",
  "sensors/flex": "category/flex",
  "tools/meter": "category/meter",
  "tools/soldering": "category/soldering",
  "tools/hand-tools": "category/hand-tools",
  "wireless/bluetooth": "category/bluetooth",
  "wireless/wifi": "category/wifi",
  "wireless/rf-transceiver": "category/rf-transceiver",
  "others/others": "category/others-new",
  "starter-kits/innovation-kit": "category/innovation-kit",
  "drone/quadcopter": "category/quadcopter",
  "drone/chassis-and-frames": "category/chassis-and-frames",
  "drone/flight-controller": "category/flight-controller",
  "drone/propeller": "category/propeller",
  "drone/brushless-dc-motors": "category/brushless-dc-motors",
  "drone/wireless-telemetry": "category/wireless-telemetry",
  "drone/camera": "category/camera",
  "internet-of-things-iot/wifi-development-boards-and-modules":
    "category/wifi-development-boards-and-modules",
  "internet-of-things-iot/gsm-and-gprs": "category/gsm-and-gprs",
  "internet-of-things-iot/zigbee": "category/zigbee",
  "internet-of-things-iot/lora": "category/lora",
};
