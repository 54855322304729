import { apiClient } from "@/services/api-client";

export const getCartDataApi = (headers = {}) => {
  return apiClient({
    url: "/v2/carts",
    method: "GET",
    headers,
  });
};

export const addToCartApi = (data) => {
  return apiClient({
    url: "/v2/carts",
    method: "PUT",
    data,
  });
};

export const deleteCartDataApi = (data) => {
  return apiClient({
    url: "/v2/carts",
    method: "DELETE",
    data,
  });
};

export const updateCartAddressApi = (payload) => {
  return apiClient({
    url: `/v1/carts-address`,
    method: "POST",
    data: payload,
  });
};

export const updateCartDeliveryMethod = (payload) => {
  return apiClient({
    url: `/v1/carts-delivery-method`,
    method: "POST",
    data: payload,
  });
};

export const getExpressAdress = () => {
  return apiClient({
    url: `/v1/express-addresses`,
    method: "GET",
  });
};
