import { useMutation, useQuery } from "@tanstack/react-query";

import {
  createProductQuestionsApi,
  createProductReviewApi,
  getProductListApi,
  getProductQuestionsApi,
  getProductReviewsApi,
  getProductSuggestionsApi,
  requestProductStockApi,
} from "@/api/end-points/product";

export const useGetProductReview = (useQueryOptions) => {
  const { query } = useQueryOptions || {};
  const { product_slug, page } = query;

  return useQuery({
    queryKey: ["reviews", product_slug, page],
    queryFn: () => getProductReviewsApi(product_slug, page),
    keepPreviousData: true,
    ...useQueryOptions,
    select: (res) => {
      const reviews = res.data?.data;
      return reviews;
    },
    onError: (error) => {
      return error.response.data;
    },
  });
};

export const useGetProductQuestions = (useQueryOptions) => {
  const { query } = useQueryOptions || {};
  const { product_slug, page } = query;

  return useQuery({
    queryKey: ["questions", product_slug, page],
    queryFn: () => getProductQuestionsApi(product_slug, page),
    keepPreviousData: true,
    ...useQueryOptions,
    select: (res) => {
      const questions = res.data?.data;
      return questions;
    },
    onError: (error) => {
      return error.response.data;
    },
  });
};

export const useCreateProductQuestion = (useQueryOptions) => {
  const { onSuccess = () => {}, onError = () => {} } = useQueryOptions || {};

  return useMutation(createProductQuestionsApi, {
    onSuccess: (res) => {
      onSuccess({
        status: res.status,
        message: res.message || "Question Created",
      });
    },
    onError: (error) => {
      onError({
        status: error.response.status,
        data: error.response.data,
        message: error?.response?.data?.errors?.[0]?.message,
      });
    },
  });
};

export const useCreateProductReview = (useQueryOptions) => {
  const { onSuccess = () => {}, onError = () => {} } = useQueryOptions || {};

  return useMutation(createProductReviewApi, {
    onSuccess: (res) => {
      onSuccess({
        status: res.status,
        message: res.message || "Review Created",
      });
    },
    onError: (error) => {
      onError({
        status: error.response.status,
        data: error.response.data,
        message: error?.response?.data?.errors?.[0]?.message,
      });
    },
  });
};

export const useGetProductSuggestions = (keyword, useQueryOptions) => {
  return useQuery({
    queryKey: ["searchSuggestions"],
    queryFn: () => getProductSuggestionsApi(keyword),
    ...useQueryOptions,
    select: (res) => {
      const suggestions = res.data?.data?.products;

      return suggestions;
    },
  });
};

export const useGetProductList = (url, useQueryOptions) => {
  return useQuery({
    queryKey: [url],
    queryFn: () => getProductListApi(url),
    keepPreviousData: true,
    ...useQueryOptions,
    select: (res) => {
      return res.data?.data;
    },
  });
};

export const useRequestProductStock = (useQueryOptions) => {
  const { onSuccess = () => {}, onError = () => {} } = useQueryOptions || {};

  return useMutation(requestProductStockApi, {
    onSuccess: (res) => {
      onSuccess({
        status: res.status,
        message: res.message || "Product requested",
      });
    },
    onError: (error) => {
      onError({
        status: error.response.status,
        data: error.response.data,
        message: error?.response?.data?.errors?.[0]?.message,
      });
    },
  });
};
