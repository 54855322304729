export const socialMediaPagesLink = {
  facebook: "https://www.facebook.com/robodocbd/",
  facebookGroup: "https://www.facebook.com/groups/robodoc",
  youtube: "https://youtube.com/@robodocbd1609",
  instagram: "https://www.instagram.com/robodoc_bd/",
};

export const messageProvider = {
  messenger: "https://m.me/robodocbd",
};
