import { useEffect } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import TagManager from "react-gtm-module";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { SessionProvider } from "next-auth/react";
import { appWithTranslation } from "next-i18next";
import { Exo_2 } from "next/font/google";
import { useRouter } from "next/router";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import Layout from "@/components/layout";
import { FacebookPixelEvents } from "@/components/sections/facebookPixel";
import MetaData from "@/components/seo/MetaData";
import { handleGuestUser } from "@/services/guest-user";
import store from "@/store/index";
import "@/styles/globals.css";
import { handleGlobalLoader } from "@/utils/helpers";

const exo2 = Exo_2({
  subsets: ["latin"],
  weight: ["100", "200", "300", "400", "500", "600", "700", "800", "900"],
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App({ Component, pageProps }) {
  const router = useRouter();

  useEffect(() => {
    handleGuestUser();

    TagManager.initialize({
      gtmId: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID,
    });

    router?.events?.on("routeChangeStart", (url) =>
      handleGlobalLoader({ url, action: "start" })
    );
    router?.events?.on("routeChangeComplete", (url) =>
      handleGlobalLoader({ url, action: "close" })
    );
    router?.events?.on("routeChangeError", (_, url) =>
      handleGlobalLoader({ url, action: "close" })
    );

    return () => {
      router.events.off("routeChangeStart", (url) =>
        handleGlobalLoader({ url, action: "start" })
      );
      router.events.off("routeChangeComplete", (url) =>
        handleGlobalLoader({ url, action: "close" })
      );
      router.events.off("routeChangeError", (_, url) =>
        handleGlobalLoader({ url, action: "close" })
      );
    };
  }, []);

  return (
    <div className={exo2.className}>
      <SessionProvider session={pageProps.session}>
        <Provider store={store}>
          <MetaData />
          <QueryClientProvider client={queryClient}>
            <GoogleReCaptchaProvider
              reCaptchaKey={process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITE_KEY}
              scriptProps={{
                async: false,
                defer: false,
                appendTo: "head",
                nonce: undefined,
              }}
            >
              <Layout>
                <FacebookPixelEvents />
                <noscript
                  dangerouslySetInnerHTML={{
                    __html: `
                      <img
                        height="1"
                        width="1"
                        alt="fb-noscript"
                        src="https://www.facebook.com/tr?id=${process.env.NEXT_PUBLIC_FB_PIXEL_ID}&ev=PageView&noscript=1"
                      />
                    `,
                  }}
                />

                <noscript
                  dangerouslySetInnerHTML={{
                    __html: `
                      <iframe
                        width="0"
                        height="0"
                        style="display:none;visibility:hidden"
                        src="https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}"
                      />
                    `,
                  }}
                />

                <Component {...pageProps} />
              </Layout>
            </GoogleReCaptchaProvider>
          </QueryClientProvider>
        </Provider>
      </SessionProvider>
      <ToastContainer />
    </div>
  );
}

export default appWithTranslation(App);
