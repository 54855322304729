import classNames from "classnames";
import PropTypes from "prop-types";

import { InlineLoaderIcon } from "@/components/data-display/icon";

function SecondaryBtn({
  onClick,
  btnText,
  className,
  isLoading,
  isDisabled,
  type,
}) {
  return (
    <button
      type={type}
      className={classNames(
        {
          "bg-disable-bg text-disable-text": isDisabled,
          "bg-secondary text-[#1F2A3B] transition-colors duration-500 hover:bg-secondary-900 active:bg-secondary-900":
            !isDisabled,
        },
        `relative rounded-[10px] text-[1.125rem] font-semibold tracking-normal ${className}`
      )}
      disabled={isDisabled || isLoading}
      onClick={onClick}
    >
      {isLoading && (
        <InlineLoaderIcon className="absolute left-[calc(50%_-_1.5rem)] top-[calc(50%_-_1.5rem)] inline-block h-[3rem] w-[3rem]" />
      )}
      <p
        className={classNames(
          {
            invisible: isLoading,
            visible: !isLoading,
          },
          "inline-block"
        )}
      >
        {btnText}
      </p>
    </button>
  );
}

SecondaryBtn.propTypes = {
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  btnText: PropTypes.string,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  type: PropTypes.string,
};

SecondaryBtn.defaultProps = {
  type: "button",
  isLoading: false,
  isDisabled: false,
};

export default SecondaryBtn;
