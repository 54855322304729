import * as Yup from "yup";

import { phoneRegex } from "@/utils/patterns";

export const shippingAddressSchema = Yup.object({
  name: Yup.string()
    .trim()
    .min(3, "Full Name must be at least 3 characters")
    .max(30, "Full Name must be at most 30 characters")
    .required("Full Name is required"),
  phone: Yup.string()
    .required("Phone Number is required")
    .matches(phoneRegex, "Invalid Phone number"),
  district: Yup.number().required("District is required"),
  area: Yup.string().required("Area is required"),
  address: Yup.string()
    .trim()
    .required("Street Address is required")
    .min(5, "Street Address must be at least 5 characters")
    .max(250, "Street Address must be at most 250 characters"),
  notes: Yup.string()
    .max(250, "Notes must be at most 250 characters")
    .nullable(true),
});
