import Image from "next/image";

import AccordionMinus from "@/icons/accordion-minus.svg";
import AccordionPlus from "@/icons/accordion-plus.svg";
import Account from "@/icons/account-icon.svg";
import Edit from "@/icons/address-edit.svg";
import AllCategories from "@/icons/all-categories.svg";
import ArrowLeft from "@/icons/arrow-left.svg";
import ArrowRight from "@/icons/arrow-right.svg";
import Bkash from "@/icons/bKash.png";
import CartDecrement from "@/icons/cart-dec-icon.svg";
import CartIncrement from "@/icons/cart-inc-icon.svg";
import CartOutline from "@/icons/cart-outline-icon.svg";
import CartSidebar from "@/icons/cart-sidebar.svg";
import Cart from "@/icons/cart.svg";
import CashOnDelivery from "@/icons/cash-on-delivery.svg";
import CheckSuccess from "@/icons/checkSuccess.svg";
import CheckMark from "@/icons/checkmark.svg";
import CheckoutEdit from "@/icons/checkout-edit.svg";
import ClearCoupon from "@/icons/clear-coupon.svg";
import Close from "@/icons/close.svg";
import ContactEmail from "@/icons/contact-email.svg";
import ContactLocation from "@/icons/contact-location.svg";
import ContactPhone from "@/icons/contact-phone.svg";
import CouponApplied from "@/icons/coupon-applied.svg";
import Date from "@/icons/date-icon.svg";
import Delete from "@/icons/delete-icon.svg";
import DownArrow from "@/icons/down-arrow.svg";
import DownloadInvoice from "@/icons/download-invoice.svg";
import DropDownArrow from "@/icons/drop-down-arrow.svg";
import Error from "@/icons/error-icon.svg";
import Exclamatory from "@/icons/exclamatory.svg";
import FacebookGroup from "@/icons/facebook-group.svg";
import FacebookTransparent from "@/icons/facebook-transparent.svg";
import Facebook from "@/icons/facebook.svg";
import Email from "@/icons/footer-mail.svg";
import Map from "@/icons/footer-map-pin.svg";
import Mobile from "@/icons/footer-phone.svg";
import GmailOutline from "@/icons/gmail-outline.svg";
import Gmail from "@/icons/gmail.svg";
import HamBurger from "@/icons/hamburger-icon.svg";
import HandCash from "@/icons/hand-cash.png";
import History from "@/icons/history.svg";
import Home from "@/icons/home.svg";
import Information from "@/icons/information.svg";
import Loader from "@/icons/inline-loader.svg";
import InstagramWithColor from "@/icons/instagram-color.svg";
import Instagram from "@/icons/instagram-gray.svg";
import Location from "@/icons/location.svg";
import MasterCard from "@/icons/mastercard.svg";
import Minus from "@/icons/minus.svg";
import Nagad from "@/icons/nagad.svg";
import Offers from "@/icons/offers.svg";
import Office from "@/icons/office.svg";
import OrderSuccess from "@/icons/order-success.svg";
import OrderSummaryView from "@/icons/order-summary-view.svg";
import Others from "@/icons/others.svg";
import PaperPlane from "@/icons/paper-plane.svg";
import Phone from "@/icons/phone.svg";
import PhoneFill from "@/icons/phoneFill-icon.svg";
import Plus from "@/icons/plus.svg";
import QuoteMarks from "@/icons/quotemarks-left.svg";
import Remove from "@/icons/remove-icon.svg";
import RightArrow from "@/icons/right-arrow.svg";
import Robodoc from "@/icons/robodoc-icon.svg";
import RobodocLogo from "@/icons/robodoc-logo.svg";
import Search from "@/icons/search-icon.svg";
import Sort from "@/icons/sort.svg";
import SSLCommerz from "@/icons/sslcommerz.svg";
import StarCelebration from "@/icons/star-celebration.svg";
import StarFill from "@/icons/star-fill.svg";
import StarHalfFill from "@/icons/star-half-fill.svg";
import Star from "@/icons/star.svg";
import TickMark from "@/icons/tick-mark.svg";
import Twitter from "@/icons/twitter.svg";
import VerifyEmail from "@/icons/verify-email.svg";
import Visa from "@/icons/visa.svg";
import WishlistActive from "@/icons/wishlist-active.svg";
import WishListWithBackground from "@/icons/wishlist-icon-with-background.svg";
import WishList from "@/icons/wishlist-icon.svg";
import WishlistInactive from "@/icons/wishlist-inactive.svg";
import YoutubeGray from "@/icons/youtube-gray.svg";
import Youtube from "@/icons/youtube.svg";

import Styles from "./Icons.module.css";

export function ClearCouponIcon({ className }) {
  return (
    <ClearCoupon className={`h-[1rem] w-[1rem] text-[#b6b3b9] ${className}`} />
  );
}

export function CouponAppliedIcon({ className }) {
  return (
    <CouponApplied
      className={`h-[1rem] w-[1rem] text-green-500 ${className}`}
    />
  );
}
export function InstagramIconWithColor({ className }) {
  return <InstagramWithColor className={`${className}`} />;
}

export function ExclamatoryIcon({ className }) {
  return (
    <Exclamatory
      className={`${className} h-[1rem] w-[1rem] text-primary-400 `}
    />
  );
}

export function HomeIcon({ className }) {
  return <Home className={`${className} h-[1rem] w-[1rem] text-green-500`} />;
}

export function OfficeIcon({ className }) {
  return <Office className={`${className} h-[1rem] w-[1rem] text-green-500`} />;
}

export function GmailIcon({ className }) {
  return <Gmail className={`${className} h-[1rem] w-[1rem]`} />;
}

export function EditIcon({ className }) {
  return (
    <Edit className={`${className} h-[1.125rem] w-[1.125rem] text-green-500`} />
  );
}

export function DateIcon({ className }) {
  return <Date className={`${className} h-[1rem] w-[1rem] text-green-500`} />;
}

export function OthersIcon({ className }) {
  return <Others className={`${className} h-[1rem] w-[1rem] text-green-500`} />;
}

export function InlineLoaderIcon({ className }) {
  return (
    <Loader className={`${className} h-[1.7rem] w-[1.7rem] text-green-500`} />
  );
}

export function LocationIcon({ className }) {
  return (
    <Location className={`${className} h-[1rem] w-[1rem] text-green-500`} />
  );
}

export function PhoneIcon({ className }) {
  return <Phone className={`${className}`} />;
}

export function DeleteIcon({ className }) {
  return <Delete className={`${className}h-[1rem] w-[1rem] text-green-500`} />;
}

export function IncrementIcon({ className }) {
  return <CartIncrement className={`${className} h-[1.5rem] w-[1.5rem] `} />;
}

export function DecrementIcon({ className }) {
  return <CartDecrement className={`${className} h-[1.5rem] w-[1.5rem]`} />;
}

export function RemoveIcon({ className }) {
  return <Remove className={`${className} h-[1rem] w-[1rem]`} />;
}

export function PlusIcon({ className }) {
  return <Plus className={`${className}`} />;
}

export function MinusIcon({ className }) {
  return <Minus className={`${className}`} />;
}

export function EmailIcon({ className }) {
  return <Email className={`text-primary ${className}`} />;
}

export function MapIcon({ className }) {
  return <Map className={`text-primary ${className}`} />;
}

export function MobileIcon({ className }) {
  return <Mobile className={`text-primary ${className}`} />;
}
export function InformationIcon({ className }) {
  return <Information className={`${className}`} />;
}

export function OrderSummaryViewIcon({ className }) {
  return <OrderSummaryView className={`${className}`} />;
}

export function CheckMarkIcon({ className }) {
  return <CheckMark className={`${className} h-[0.457rem] w-[0.633rem]`} />;
}

export function CheckSuccessIcon({ className }) {
  return <CheckSuccess className={`${className} h-[1rem] w-[1rem]`} />;
}

export function ArrowRightIcon({ className }) {
  return <ArrowRight className={`${className} h-[1rem] w-[1rem]`} />;
}

export function ArrowDownIcon({ className }) {
  return <DownArrow className={`${className} h-[1rem] w-[1rem]`} />;
}
export function ArrowLeftIcon({ className }) {
  return <ArrowLeft className={`${className} h-[1rem] w-[1rem]`} />;
}

export function CartIcon({ className }) {
  return <Cart className={`${className} h-[1.5rem] w-[1.5rem]`} />;
}

export function WishlistInactiveIcon({ className }) {
  return <WishlistInactive className={`${className} h-[1.5rem] w-[1.5rem]`} />;
}

export function WishlistActiveIcon({ className }) {
  return <WishlistActive className={`${className} h-[1.5rem] w-[1.5rem]`} />;
}

export function PaperPlaneIcon({ className }) {
  return <PaperPlane className={`${className} h-[1.5rem] w-[1.5rem]`} />;
}

export function FacebookIcon({ className }) {
  return <Facebook className={`${className}`} />;
}
export function FacebookTransparentIcon({ className }) {
  return <FacebookTransparent className={`${className}`} />;
}

export function TwitterIcon({ className }) {
  return <Twitter className={`${className}`} />;
}

export function FacebookGroupIcon({ className }) {
  return <FacebookGroup className={`${className}`} />;
}

export function YoutubeIcon({ className }) {
  return <Youtube className={`${className}`} />;
}

export function AccordionPlusIcon({ className }) {
  return <AccordionPlus className={`${className} h-[2.625rem] w-[2.5rem]`} />;
}

export function AccordionMinusIcon({ className }) {
  return <AccordionMinus className={`${className} h-[2.625rem] w-[2.5rem]`} />;
}

export function OffersIcon({ className }) {
  return <Offers className={`${className} h-[1.125rem] w-[1.125rem] `} />;
}

export function DropDownArrowIcon({ className }) {
  return <DropDownArrow className={`${className} h-[0.625rem] w-[0.625rem]`} />;
}

export function CloseIcon({ className }) {
  return (
    <Close className={`h-[0.75rem] w-[0.75rem] text-black ${className} `} />
  );
}

export function AllCategoriesIcon({ className }) {
  return <AllCategories className={`${className} h-[0.938rem] w-[0.938rem]`} />;
}

export function WishListWithBackgroundIcon({ className }) {
  return <WishListWithBackground className={`${className}`} />;
}

export function AccountIcon({ className }) {
  return (
    <Account
      className={`${className} ${Styles.account_icon} h-[2.125rem] w-[2.125rem] md:h-[2.425rem] md:w-[2.425rem] lg:h-[2.625rem] lg:w-[2.625rem]`}
    />
  );
}

export function WishlistIcon({ className }) {
  return (
    <WishList
      className={`${className} ${Styles.wishlist_icon} h-[2.125rem] w-[2.125rem] md:h-[2.425rem] md:w-[2.425rem] lg:h-[2.625rem] lg:w-[2.625rem]`}
    />
  );
}

export function GmailOutlineIcon({ className }) {
  return <GmailOutline className={`${className}`} />;
}

export function SearchIcon({ className }) {
  return <Search className={`${className}`} />;
}

export function CartOutlineIcon({ className }) {
  return <CartOutline className={`${className} ${Styles.cart_icon}`} />;
}

export function HamBurgerIcon({ className }) {
  return <HamBurger className={`${className}`} />;
}

export function PhoneFillIcon({ className }) {
  return (
    <PhoneFill
      className={`${className} h-[2.25rem] w-[2.25rem] text-primary sm:h-[2.5rem] sm:w-[2.5rem]`}
    />
  );
}

export function RoboDocIcon({ className }) {
  return (
    <Robodoc
      className={`${className} h-[3.65rem] w-[3.65rem] text-primary sm:h-[3.813rem] sm:w-[3.813rem]`}
    />
  );
}

export function RightArrowIcon({ className }) {
  return <RightArrow className={`${className} h-[0.491rem] w-[0.625rem]`} />;
}
export function VerifyEmailIcon({ className }) {
  return <VerifyEmail className={`${className}`} />;
}

export function RobodocLogoIcon({ className }) {
  return <RobodocLogo className={`${className} h-[3rem] w-[11rem]`} />;
}

export function CartSidebarIcon({ className }) {
  return (
    <CartSidebar
      className={`${className} h-[1.563rem] w-[1.563rem] text-tertiary-400`}
    />
  );
}

export function DownloadInvoiceIcon({ className }) {
  return (
    <DownloadInvoice className={`${className} h-[1.375rem] w-[1.125rem]`} />
  );
}

export function OrderSuccessIcon({ className }) {
  return <OrderSuccess className={`${className} h-[5rem] w-[5rem]`} />;
}

export function StarFillIcon({ className }) {
  return <StarFill className={`${className} h-[1rem] w-[1.063rem]`} />;
}

export function StarHalfFillIcon({ className }) {
  return <StarHalfFill className={`${className} h-[1rem] w-[1.063rem]`} />;
}

export function StarIcon({ className }) {
  return <Star className={`${className} h-[1rem] w-[1.063rem]`} />;
}

export function StarCelebrationIcon({ className }) {
  return <StarCelebration className={`${className} h-[1rem] w-[1.063rem]`} />;
}

export function ErrorIcon({ className }) {
  return <Error className={`${className} h-[1.25rem] w-[1.25rem]`} />;
}

export function HandCashIcon({ width, height }) {
  return (
    <Image
      width={width}
      src={HandCash}
      height={height}
      alt="Cash on Delivery"
    />
  );
}

export function BkashIcon({ width, height }) {
  return (
    <Image src={Bkash} width={width} height={height} alt="Pay with bKash" />
  );
}

export function CashOnDeliveryIcon({ className }) {
  return <CashOnDelivery className={`${className} h-[2.25rem] w-[4.313rem]`} />;
}

export function CheckoutEditIcon({ className }) {
  return (
    <CheckoutEdit
      className={`${className} h-[2.75rem] w-[2.75rem] text-grayDisplay-600`}
    />
  );
}

export function ContactEmailIcon({ className }) {
  return <ContactEmail className={`${className} h-[1.313rem] w-[1.4rem]`} />;
}
export function ContactPhoneIcon({ className }) {
  return <ContactPhone className={`${className} h-[1.313rem] w-[1.5rem]`} />;
}

export function ContactLocationIcon({ className }) {
  return <ContactLocation className={`${className} h-[1.313rem] w-[1.4rem]`} />;
}

export function TickMarkIcon({ className }) {
  return <TickMark className={`${className} h-[6.25rem] w-[6.25rem]`} />;
}

export function SortIcon({ className }) {
  return <Sort className={`${className} `} />;
}

export function HistoryIcon({ className }) {
  return <History className={`${className} h-[1.375rem] w-[1.375rem]`} />;
}

export function InstagramIcon({ className }) {
  return <Instagram className={`${className} h-[1.375rem] w-[1.375rem]`} />;
}

export function YoutubeGrayIcon({ className }) {
  return <YoutubeGray className={`${className} h-[1.375rem] w-[1.375rem]`} />;
}

export function QuoteMarksIcon({ className }) {
  return <QuoteMarks className={`${className} h-[1.75rem] w-[2.5rem]`} />;
}

export function SSLCommerzIcon({ className }) {
  return <SSLCommerz className={`${className} h-[1.938rem] w-[8.375rem]`} />;
}

export function MasterCardIcon({ className }) {
  return <MasterCard className={`${className} h-[1rem] w-[1.625rem]`} />;
}

export function NagadIcon({ className }) {
  return <Nagad className={`${className} h-[1rem] w-[2.375rem]`} />;
}

export function VisaIcon({ className }) {
  return <Visa className={`${className} h-[0.688rem] w-[2.125rem]`} />;
}
