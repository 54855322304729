import PropTypes from "prop-types";

import { LocationIcon, PhoneIcon } from "../../icon";

export const CustomerAddressCard = ({ address, fullName, phoneNumber }) => {
  return (
    <div className="rounded-[0.75rem] border border-[#EBEEF2]">
      <div className="border-b-1 rounded-t-[0.75rem] border-[#EBEEF2] p-5">
        <p className="text-[1.125rem] font-semibold leading-[1.75rem] text-grayDisplay-900">
          Shipping Details
        </p>
      </div>

      <div className="border-b border-[#EBEEF2]" />

      <div className="p-5">
        <p className="truncate text-[1.125rem] font-medium leading-[1.875rem] text-grayDisplay-900">
          {fullName}
        </p>

        <div className="my-[1.5rem] flex items-center">
          <div>
            <PhoneIcon className="h-[1rem] w-[1rem] text-primary" />
          </div>
          <p className="ml-[0.875rem]">{phoneNumber}</p>
        </div>

        <div className="flex items-start">
          <div>
            <LocationIcon className="mt-[0.45rem] h-[1.125rem] w-[0.938rem]" />
          </div>
          <p className="ml-[0.875rem] break-all">{address}</p>
        </div>
      </div>
    </div>
  );
};

CustomerAddressCard.propTypes = {
  fullName: PropTypes.string,
  address: PropTypes.string,
  phoneNumber: PropTypes.string,
};
