export function Checkbox({
  id,
  name,
  value,
  onChange,
  inputClassName,
  children,
}) {
  return (
    <div className="flex items-center">
      <input
        className={`${inputClassName} mr-[0.875rem] min-h-[1.125rem] min-w-[1.125rem] appearance-none rounded-[0.313rem] border-2 border-grayDisplay-850 checked:border-none checked:bg-primary checked:before:block checked:before:pl-[0.3rem] checked:before:text-[0.75rem] checked:before:text-white checked:before:content-['\\2713']`}
        type="checkbox"
        value={value}
        id={id}
        name={name}
        onClick={(e) => onChange(e)}
      />
      <label htmlFor={id}>{children}</label>
    </div>
  );
}
