import Link from "next/link";
import { useRouter } from "next/router";

import classNames from "classnames";

import { DropDownArrowIcon } from "@/components/data-display/icon";

import styles from "./MegaDropDown.module.css";

function MegaDropDown({ menuData, position, isLogoHidden }) {
  const { pathname, query } = useRouter();

  const isCategoryPage = pathname.split("/")?.[1] === "category";
  const isActive = isCategoryPage && menuData.slug === query?.slug;

  return (
    <div className="group relative px-3">
      <div
        className={classNames(
          {
            "text-tertiary": isActive,
            "text-grayDisplay-700": !isActive,
          },
          "inline-flex cursor-pointer items-center gap-[0.25rem] rounded py-2 text-[0.875rem] font-medium leading-[1.5rem] text-grayDisplay-400 group-hover:text-tertiary xl:gap-[0.375rem] xl:text-[1rem]"
        )}
      >
        {menuData?.child.length > 0 ? (
          <Link
            href={`/category/${menuData.slug}`}
            className="flex items-center gap-2"
          >
            <span className="group-hover:text-tertiary">{menuData.name}</span>
            <DropDownArrowIcon className="mt-[0.15rem] h-[0.625rem] w-[0.625rem]" />
          </Link>
        ) : (
          <span className="group-hover:text-tertiary">
            <Link href={`/category/${menuData.slug}`}>{menuData.name}</Link>
          </span>
        )}
      </div>
      <div
        className={`${styles.greenBar} absolute z-[100] hidden w-auto ${
          menuData.child.length === 0 ? "" : "border border-grayDisplay-100"
        } ${
          menuData?.child.length <= 10
            ? "rounded-[0.625rem]"
            : "rounded-[0.75rem]"
        } bg-white text-grayDisplay-700 drop-shadow-[0_14px_42px_rgba(10,16,26,0.1)] group-hover:block ${
          position === 0 ? "left-0" : "right-0"
        }`}
      >
        {menuData?.child.length > 0 && (
          <div
            className={`flex ${
              menuData?.child.length <= 10 ? "w-[14.563rem]" : "w-[26.125rem]"
            } flex-col flex-wrap gap-[1.25rem] py-[1.375rem] pr-[2.75rem] xl:gap-[1.5rem]`}
          >
            {menuData?.child?.map((submenu) => {
              return (
                <div
                  key={submenu.slug}
                  className="line-clamp-2 block w-[10rem] pl-[1.5rem] text-[0.875rem] hover:text-tertiary xl:text-[1rem]"
                >
                  <Link href={`/category/${submenu.slug}`}>{submenu.name}</Link>
                </div>
              );
            })}
          </div>
        )}
      </div>

      {isActive && (
        <span
          className={classNames(
            {
              "-bottom-[1.25rem]": !isLogoHidden,
              "-bottom-[1rem]": isLogoHidden,
            },
            "absolute inset-0 border-b-2 border-primary text-primary"
          )}
        />
      )}
    </div>
  );
}

export default MegaDropDown;
