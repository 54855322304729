export const RobodocInfo = {
  domain: "robodocbd.com",
  website: "https://robodocbd.com",
  phone1: "(+88) 01322 908 240",
  phoneBn: "০১৩২২-৯০৮২৪০",
  email1: "support@robodocbd.com",
  email2: "contact@robodocbd.com",
  officeAddress:
    "Zakir Complex (9th Floor), Ka-218, Kuril Chowrasta, Dhaka-1229, Bangladesh.",
  pickupPoint:
    "Siraj Garden, Ka-193/B, Sayed Ali Member Bari, Kuril Chowrasta, Dhaka-1229, Bangladesh.",
  address: "Zakir Complex (9th Floor), Ka-218",
  area: "Kuril Chowrastha",
  region: "Dhaka-1229",
  country: "Bangladesh",
  workingDay: "Sat - Thur #9:00 AM - 8:00 PM",
  offDay: "Friday #Closed",
  officeAddressUrl:
    "https://www.google.com/maps/place/23%C2%B048'57.5%22N+90%C2%B025'16.7%22E/@23.8159722,90.4213056,17z/data=!3m1!4b1!4m4!3m3!8m2!3d23.8159722!4d90.4213056?entry=ttu",
  pickupAddressUrl:
    "https://www.google.com/maps/place/RoboDoc+Limited/@23.8156527,90.4208057,17z/data=!3m1!4b1!4m6!3m5!1s0x3755c76ea2467083:0x6c13ca0026ba4d75!8m2!3d23.8156478!4d90.4233806!16s%2Fg%2F11rhqlwv33?entry=ttu",
};
