import { useId } from "react";

import Link from "next/link";

import { Checkbox } from "@/components/data-input";
import { paymentTermsLinks } from "@/utils/constants";

export function PaymentTerms({
  isPayementTermsChecked,
  setPayementTermsChecked,
}) {
  const id = useId();

  return (
    <Checkbox
      id={id}
      name="terms"
      value={isPayementTermsChecked}
      onChange={(e) => setPayementTermsChecked(e.target.checked)}
    >
      <p className="space-x-[3px] hover:cursor-pointer">
        <span>I have read and agree to the</span>
        {paymentTermsLinks.map((termLink, i) => {
          return (
            <span key={i}>
              <Link
                rel="noopener"
                target="_blank"
                href={termLink.link}
                className="text-primary underline"
              >
                {termLink.title}
              </Link>
              {termLink.followingText && (
                <span> {termLink.followingText} </span>
              )}
            </span>
          );
        })}
      </p>
    </Checkbox>
  );
}
