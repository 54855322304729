/* eslint-disable no-undef */
import { getSession } from "next-auth/react";
import { signOut } from "next-auth/react";

import axios from "axios";

import {
  generateGuestUser,
  getGuestUser,
  setGuestUser,
} from "@/services/guest-user";
import statusCodes from "@/utils/constants/http-status-codes";
import { errorLogger } from "@/utils/helpers/logger";

import { mockApiClient } from "./mock-api-client";

export const apiClient = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_BASE_URL,

  timeout: 20000,

  headers: {
    "X-Requested-With": "XMLHttpRequest",
  },
});

apiClient.interceptors.request.use(
  async function (config) {
    if (typeof window !== "undefined") {
      const session = await getSession();

      if (session?.user?.token) {
        config.headers.Authorization = `Bearer ${session?.user?.token}`;
      }

      const guestUser = getGuestUser();

      if (guestUser) {
        config.headers["X-Unique-Number"] = guestUser.id;
      }
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  function (response) {
    return {
      data: response.data,
      status: response.status,
      statusText: response.statusText,
    };
  },
  async function (error) {
    errorLogger.logApiError(error);
    if (error.response.status === statusCodes.UNAUTHORIZED) {
      const res = await signOut();
      if (res) setGuestUser(generateGuestUser());
    }
    return Promise.reject(error);
  }
);

export { mockApiClient };
