import { useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { useSession } from "next-auth/react";
import Link from "next/link";

import { TrackOrderForm } from "@/components/actions";
import { GmailOutlineIcon, PhoneIcon } from "@/components/data-display/icon";
import Tooltip from "@/components/data-display/tooltip";
import { updateTrackOrder } from "@/store/feature";
import { RobodocInfo } from "@/utils/constants";

function TopNavbar() {
  const [instance, setInstance] = useState(null);

  const dispatch = useDispatch();
  const trackOrderRef = useRef(null);

  const { data: session } = useSession();
  const isAuthenticated = !!session?.user?.token;

  const { phone1, email1 } = RobodocInfo;

  const handleFormClose = () => {
    if (instance) {
      instance.hide();
    }
  };

  const hideElement = (data) => {
    trackOrderRef.current.formReset();
    setInstance(data);
  };

  return (
    <>
      <div className="flex w-full items-center justify-between bg-grayDisplay-550 px-[1rem] py-[0.813rem] text-grayDisplay-400 lg:px-[2rem]">
        <section className="flex items-center space-x-[1rem] text-[0.75rem] md:space-x-[1.313rem]">
          <Link href={"tel:" + phone1}>
            <div className="flex cursor-pointer items-center space-x-[0.5rem]">
              <PhoneIcon className="mt-[0.3rem] h-[0.688rem] w-[0.688rem] text-grayDisplay-400 md:mt-1" />
              <span className="hidden md:block">{phone1}</span>
            </div>
          </Link>

          <Link href={"mailto:" + email1}>
            <div className="flex cursor-pointer items-center space-x-[0.5rem]">
              <GmailOutlineIcon className="mt-1 h-[0.625rem] w-[0.79rem] text-grayDisplay-400" />
              <span className="hidden md:block">{email1}</span>
            </div>
          </Link>
        </section>

        <section>
          <ul className="flex space-x-[1rem] text-[0.813rem] md:space-x-[1.625rem]">
            {isAuthenticated && (
              <>
                <li className="hidden md:block">
                  <Link href="/my-orders">My Orders</Link>
                </li>
                <li>
                  <div className="hidden cursor-pointer md:block">
                    <Tooltip
                      trigger="click"
                      placement="bottom-end"
                      content={
                        <TrackOrderForm
                          ref={trackOrderRef}
                          onCloseFormRequest={handleFormClose}
                        />
                      }
                      hideElement={hideElement}
                    >
                      Track My Order
                    </Tooltip>
                  </div>
                  <button
                    className="block md:hidden"
                    onClick={() => dispatch(updateTrackOrder(true))}
                  >
                    Track My Order
                  </button>
                </li>
                <li>
                  <Link href="/my-account">{session.user?.name}</Link>
                </li>
              </>
            )}

            {!isAuthenticated && (
              <li className="order-4">
                <Link href="/signin">Sign In</Link>
              </li>
            )}
          </ul>
        </section>
      </div>
    </>
  );
}

export default TopNavbar;
