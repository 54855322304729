import { apiClient } from "@/services/api-client";

export const getProfileInfoApi = () => {
  return apiClient({
    url: "/v1/profile",
    method: "GET",
  });
};
export const updateProfileInfoApi = (data) => {
  return apiClient({
    url: "/v1/profile",
    method: "POST",
    data,
  });
};

export const verifyPhoneNumberApi = (data) => {
  return apiClient({
    url: "/v1/phone-verification",
    method: "POST",
    data,
  });
};

export const updatePhoneNumberApi = (data) => {
  return apiClient({
    url: "/v1/phone-update",
    method: "POST",
    data,
  });
};

export const verifyAccountMailApi = (data) => {
  return apiClient({
    url: "/v1/email-verification",
    method: "POST",
    data,
  });
};

export const updateAccountMailApi = (data) => {
  return apiClient({
    url: "/v1/email-update",
    method: "POST",
    data,
  });
};
