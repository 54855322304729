import { useRouter } from "next/router";

import PropTypes from "prop-types";

import RecentSearch from "./RecentSearch";
import SearchedProduct from "./SearchedProduct";

function SearchSuggestions({ query, searchedProducts, deactivateFocus }) {
  const MAX_DISPLAYED_PRODUCTS = 6;
  const router = useRouter();

  const clickHandler = (url) => {
    router.push(url);
    deactivateFocus();
  };

  if (!query) {
    return <RecentSearch clickHandler={clickHandler} />;
  }

  if (searchedProducts?.length > 0) {
    return (
      <div className="w-full rounded-[0.625rem] border border-grayDisplay-100 bg-white px-[0.5rem] py-[1.5rem] shadow-main md:w-[23rem] lg:w-[25rem] min-[1200px]:w-[30rem] 2xl:w-[45rem]">
        <div className="flex flex-col gap-[1rem]">
          {searchedProducts.slice(0, MAX_DISPLAYED_PRODUCTS).map((product) => {
            return (
              <SearchedProduct
                product={product}
                key={product.slug}
                onClick={() => clickHandler(`/product/${product.slug}`)}
              />
            );
          })}
        </div>
        <div className="mt-[1.75rem] text-center text-[1rem] font-semibold leading-[1.063rem] tracking-[0.08px] text-tertiary">
          <span
            role="button"
            onClick={() => clickHandler(`/search?query=${query}`)}
          >
            See All Results
          </span>
        </div>
      </div>
    );
  }

  return null;
}

SearchSuggestions.propTypes = {
  searchedProducts: PropTypes.arrayOf(
    PropTypes.shape({
      product_id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      main_picture: PropTypes.string,
      price: PropTypes.number.isRequired,
    })
  ),
  query: PropTypes.string,
  deactivateFocus: PropTypes.func,
};

export default SearchSuggestions;
