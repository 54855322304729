import { useEffect, useState } from "react";

import { CloseIcon, HistoryIcon } from "@/components/data-display/icon";
import localStorage from "@/services/local-storage";

const RecentSearch = ({ clickHandler }) => {
  const [storedKeywords, setStoredKeywords] = useState(null);

  useEffect(() => {
    setStoredKeywords(localStorage.get("recentSearches"));
  }, []);

  const handleSearch = (keyword) => {
    clickHandler(`/search?query=${keyword}`);

    const newKeywords = storedKeywords.filter((el) => el !== keyword);
    newKeywords.unshift(keyword);
    localStorage.add("recentSearches", newKeywords);
  };

  const handleRemoveKeyword = (keyword) => (event) => {
    event.stopPropagation();

    const newKeywords = storedKeywords.filter((el) => el !== keyword);
    setStoredKeywords(newKeywords);
    localStorage.add("recentSearches", newKeywords);
  };

  if (storedKeywords?.length > 0) {
    return (
      <div className="w-full rounded-[0.625rem] border border-grayDisplay-100 bg-white px-[0.5rem] py-[1.5rem] shadow-main md:w-[23rem] lg:w-[25rem] min-[1200px]:w-[30rem] 2xl:w-[45rem]">
        <p className="mb-[1rem] px-[0.75rem] text-grayDisplay-300 md:px-[1rem]">
          Recent Searches
        </p>

        <div className="flex flex-col">
          {storedKeywords.map((keyword) => (
            <div
              key={keyword}
              onClick={() => handleSearch(keyword)}
              className="flex w-full cursor-pointer items-center justify-between gap-[0.938rem] rounded-[0.5rem] px-[0.75rem] py-[0.75rem] hover:bg-grayDisplay-150 md:px-[1rem]"
            >
              <div
                title={keyword}
                className="flex w-[calc(100%-36px)] items-center space-x-3"
              >
                <HistoryIcon className="mt-[0.15rem]" />
                <p className="w-full truncate">{keyword}</p>
              </div>

              <div onClick={handleRemoveKeyword(keyword)}>
                <CloseIcon className="!text-grayDisplay-300 hover:!text-error-color" />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return null;
};

export default RecentSearch;
