import classNames from "classnames";
import PropTypes from "prop-types";

import getRandomNumber from "@/utils/random-number";

function AppRadioInput({
  labelName,
  name,
  value,
  isChecked,
  onChange,
  id,
  isDisabled,
}) {
  const accessAbleId = id ? id : `${name}-${getRandomNumber()}`;

  return (
    <div className="flex items-center">
      <input
        type="radio"
        name={name}
        id={accessAbleId}
        className="h-[1rem] w-[1rem] appearance-none rounded-full border-[2px] border-grayDisplay-300 bg-transparent checked:border-[0.313rem] checked:border-tertiary-400 disabled:cursor-default md:h-[1.25rem] md:w-[1.25rem] md:checked:border-[0.375rem]"
        value={value}
        checked={isChecked}
        onChange={onChange}
        disabled={isDisabled}
      />
      <label
        htmlFor={accessAbleId}
        className={classNames(
          {
            "!cursor-default": isDisabled,
          },
          "ml-[0.625rem] cursor-pointer text-[1rem] leading-[1.188rem] text-grayDisplay-700 md:ml-[0.875rem] md:text-[1.125rem] md:leading-[1.375rem]"
        )}
      >
        {labelName}
      </label>
    </div>
  );
}

export default AppRadioInput;

AppRadioInput.propTypes = {
  id: PropTypes.string,
  labelName: PropTypes.string,
  name: PropTypes.string,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

AppRadioInput.defaultProps = {
  isChecked: false,
  isDisabled: false,
};
