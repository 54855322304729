import { useEffect, useRef } from "react";

export const useScrollToRef = (dependencies) => {
  const ref = useRef(null);

  useEffect(() => {
    if (!ref?.current) return;

    const refRect = ref.current.getBoundingClientRect();
    const isInView =
      refRect.top >= 0 &&
      refRect.left >= 0 &&
      refRect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      refRect.right <=
        (window.innerWidth || document.documentElement.clientWidth);

    if (!isInView) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
  }, [...dependencies]);

  return { ref };
};
