import { storeHours } from "@/utils/constants";

const StoreHours = ({ className, showTitle }) => {
  return (
    <div className={className ? className : ""}>
      {showTitle && (
        <p className="mb-[1.375rem] text-[1rem] font-semibold leading-[1.125rem] text-grayDisplay-700">
          Store Hours
        </p>
      )}

      <ul className="flex h-full flex-col gap-[1rem] overflow-hidden text-[0.938rem] leading-[1.125rem] text-grayDisplay-400">
        <li>{storeHours.workingDay}</li>
        <li>{storeHours.offDay}</li>
      </ul>
    </div>
  );
};

StoreHours.defaultProps = {
  showTitle: true,
};

export default StoreHours;
