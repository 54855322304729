export const newsletterMessages = {
  email_not_found: "Email not found",
  platform_not_found: "Unable to process",
  email_already_subscribed: "You are already subscribed",
  email_already_unsubscribed: "You are already unsubscribed",
  unable_to_process: "Unable to process",
  url_expired: "URL has been expired",
  false_signature: "Unable to process",
  subscribed: "Thank you for your subscription!",
  unsubscribed: "Successfully unsubscribed from newsletter",
};

export const emailUnsubscribeMessages = {
  unable_to_process: "Unable to process",
  invalid_signature: "Unable to process",
  already_unsubscribed: "You are already unsubscribed",
  unsubscribed: "Successfully unsubscribed",
};
