import Link from "next/link";

import { ArrowLeftIcon } from "@/components/data-display/icon";

function MenuBarResponsiveSubMenu({
  selectedMenu,
  onBackBtnClick,
  hideSideMenu,
}) {
  return (
    <div>
      <div
        className="flex items-center gap-[1.563rem]"
        onClick={onBackBtnClick}
      >
        <ArrowLeftIcon className="h-[0.917rem] w-[0.5rem]" />
        <p>Back</p>
      </div>

      <p className="mt-[2.25rem] text-[1.063rem] font-semibold leading-[1.5rem] text-tertiary">
        {selectedMenu.name}
      </p>

      <ul className="mr-[1.5rem] list-none">
        {selectedMenu &&
          selectedMenu.child.map((menu) => {
            return (
              <li
                key={menu.slug}
                className="mt-[2.3rem]"
                onClick={hideSideMenu}
              >
                <Link href={`/category/${menu.slug}`}>
                  <p className="font-medium">{menu.name}</p>
                </Link>
              </li>
            );
          })}
      </ul>
    </div>
  );
}

export default MenuBarResponsiveSubMenu;
