import { useEffect } from "react";

import { AnimatePresence, motion } from "framer-motion";
import PropTypes from "prop-types";

import { CloseIcon } from "@/components/data-display/icon";
import { hideMainScrollbar } from "@/utils/helpers";

const GenericDrawer = ({
  isActive,
  anchor,
  onHide,
  closeButton,
  className,
  children,
}) => {
  useEffect(() => {
    hideMainScrollbar({ isActive, delay: 400 });
  }, [isActive]);

  return (
    <AnimatePresence>
      {isActive && (
        <>
          <motion.div
            onClick={onHide}
            className={`fixed inset-0 z-[100] !mx-0 h-full w-full bg-[#1B2D49]/40`}
          />
          <motion.div
            variants={{
              open: {
                top: 0,
                [anchor]: 0,
              },
              closed: {
                [anchor]: "-100%",
              },
            }}
            initial="closed"
            animate="open"
            exit="closed"
            transition={{
              duration: 0.3,
              ease: "easeInOut",
            }}
            className="fixed right-0 top-0 z-[120] h-screen w-full overflow-hidden overflow-y-auto bg-white opacity-100 md:w-[27.5rem]"
          >
            <div className={`relative pb-[2.25rem] pt-[1.85rem] ${className}`}>
              {closeButton && (
                <button className="absolute right-[1rem]" onClick={onHide}>
                  <CloseIcon className="text-dark-500 h-[0.85rem] w-[0.85rem]" />
                </button>
              )}

              {children}
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};

GenericDrawer.defaultProps = {
  anchor: "right",
  closeButton: true,
};

GenericDrawer.propTypes = {
  closeButton: PropTypes.bool,
  isActive: PropTypes.bool,
  onHide: PropTypes.func,
  anchor: PropTypes.string,
  className: PropTypes.string,
};

export default GenericDrawer;
