import { apiClient, mockApiClient } from "@/services/api-client";

export const getCustomerInvoiceApi = async (orderId) => {
  try {
    return await apiClient({
      method: "GET",
      url: `/v1/orders/invoice/${orderId}`,
      headers: {
        Accept: "application/pdf",
      },
      responseType: "blob",
    });
  } catch (error) {
    return error.response;
  }
};

export const getCustomerOrdersApi = (ordersViewInfo) => {
  return apiClient({
    url: `/v1/orders`,
    method: "GET",
    params: ordersViewInfo,
  });
};

export const postCreateOrderApi = (payload) => {
  return apiClient({
    url: `/v1/orders`,
    method: "POST",
    data: payload,
  });
};

export const getSingleOrderDetailsApi = (orderId) => {
  return apiClient({
    url: `/v1/orders/${orderId}`,
    method: "GET",
  });
};

export const postCancelOrderApi = ({ orderId, reason, notes }) => {
  const payload = { reason, notes };
  return apiClient({
    url: `/v1/orders/cancel/${orderId}`,
    method: "POST",
    data: payload,
  });
};

export const getCancelReasonListApi = () => {
  return mockApiClient({
    url: `/v1/orders/cancel`,
    method: "GET",
  });
};

export const getOrderStatusListApi = () => {
  return mockApiClient({
    url: `/v1/orders/status`,
    method: "GET",
  });
};

export const orderTrackingApi = (payload) => {
  return apiClient({
    url: `/v1/orders/tracking`,
    method: "POST",
    data: payload,
  });
};
