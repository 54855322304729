import store from "@/store";
import { closeLoader, startLoader } from "@/store/feature/loaderSlice";

export const handleGlobalLoader = ({ url, action }) => {
  const queryMatched =
    !url.includes("callbackUrl") && (url.includes("?") || url.includes("&"));
  const pathMatched =
    url.match(/^\/my-account/) ||
    url.match(/^\/my-wishlist/) ||
    url.match(/^\/my-cart/) ||
    url.match(/^\/addresses/) ||
    url.match(/^\/checkout/) ||
    url.match(/^\/payment-info/) ||
    url.match(/^\/my-orders/) ||
    url.match(/^\/order-cancel-confirm/) ||
    url.match(/^\/order-cancel-success/) ||
    url.match(/^\/order-success/); // skipped CSR pages;

  if (queryMatched || pathMatched) {
    const { isLoading } = store.getState().loader;
    if (isLoading) store.dispatch(closeLoader());
    return;
  }

  if (action === "start") {
    store.dispatch(startLoader());
  } else {
    store.dispatch(closeLoader());
  }
};
