import { MasterCardIcon, NagadIcon, SSLCommerzIcon, VisaIcon } from "../icon";

export function SSLCommerzImage() {
  return (
    <div className="flex flex-col items-center space-y-1">
      <SSLCommerzIcon />

      <div className="flex items-center space-x-[0.813rem]">
        <VisaIcon />
        <MasterCardIcon />
        <NagadIcon />
      </div>
    </div>
  );
}
