import {
  FacebookGroupIcon,
  FacebookIcon,
  InstagramIconWithColor,
  YoutubeIcon,
} from "@/components/data-display/icon";
import { socialMediaPagesLink } from "@/utils/constants/socialMedia.constant";

function SocialMediaLink() {
  return (
    <div className="mt-[2.563rem] flex items-center gap-[2.125rem] md:mt-0">
      <a href={socialMediaPagesLink.facebook} target="_blank" rel="noreferrer">
        <FacebookIcon className="h-[1.5rem] w-[1.5rem] sm:h-[1.75rem] sm:w-[1.75rem]" />
      </a>

      <a
        href={socialMediaPagesLink.facebookGroup}
        target="_blank"
        rel="noreferrer"
      >
        <FacebookGroupIcon className="h-[1.5rem] w-[1.875rem] sm:h-[1.75rem] sm:w-[2.188rem]" />
      </a>

      <a href={socialMediaPagesLink.youtube} target="_blank" rel="noreferrer">
        <YoutubeIcon className="h-[1.5rem] w-[2.125rem] sm:h-[1.75rem] sm:w-[2.5rem]" />
      </a>

      <a href={socialMediaPagesLink.instagram} target="_blank" rel="noreferrer">
        <InstagramIconWithColor className="h-[1.5rem] w-[1.5rem] sm:h-[1.75rem] sm:w-[1.75rem]" />
      </a>
    </div>
  );
}
export default SocialMediaLink;
