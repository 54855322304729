import { useState } from "react";
import { useSelector } from "react-redux";

import Image from "next/image";
import Link from "next/link";

import { AnimatePresence } from "framer-motion";

import {
  CartOutlineIcon,
  HamBurgerIcon,
  WishlistIcon,
} from "@/components/data-display/icon";
import Indicator from "@/components/data-display/indicator";
import { MenuBarResponsive } from "@/components/layout/menuBar";
import { getCartState } from "@/store/feature/cartSlice";
import { getWishlistState } from "@/store/feature/wishlistSlice";

import { AccountMenu } from "./AccountMenu";
import { SearchBar } from "./SearchBar";

function NavBar({ menuData }) {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const { cartProductCount } = useSelector(getCartState);
  const { wishlistProductCount } = useSelector(getWishlistState);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <>
      <div className="container grid grid-cols-2 py-[1.25rem] md:flex md:items-center md:justify-between lg:pb-0 lg:pt-[2rem]">
        <div className="order-1 flex cursor-pointer items-center space-x-[1.25rem] lg:space-x-0">
          <button className="lg:hidden" onClick={() => toggleMobileMenu()}>
            <HamBurgerIcon className="block shrink-0" />
          </button>

          <Link href="/">
            <div className="relative h-[2.125rem] w-[7.688rem] md:h-[2.5rem] md:w-[8.75rem] lg:h-[3rem] lg:w-[11rem]">
              <Image
                src="/assets/logo/logo-vertical.jpg"
                alt="The largest Robotic website in Bangladesh for Embedded Electronics."
                fill
                priority
              />
            </div>
          </Link>
        </div>

        <SearchBar />

        <div className="order-2 flex items-center space-x-[1.625rem] justify-self-end md:order-3">
          <AccountMenu />

          <Link href="/my-wishlist" className="hidden lg:block">
            <Indicator itemCount={wishlistProductCount}>
              <WishlistIcon className="lg:h-[2.625rem] lg:w-[2.625rem]" />
            </Indicator>
          </Link>

          <Link href="/my-cart">
            <Indicator itemCount={cartProductCount} className="relative">
              <CartOutlineIcon className="h-[2.125rem] w-[2.125rem] cursor-pointer md:h-[2.425rem] md:w-[2.425rem] lg:h-[2.625rem] lg:w-[2.625rem]" />
            </Indicator>
          </Link>
        </div>
      </div>

      <AnimatePresence>
        {isMobileMenuOpen && (
          <MenuBarResponsive
            menuData={menuData}
            isMobileMenuOpen={isMobileMenuOpen}
            closeMobileMenu={() => setIsMobileMenuOpen(false)}
          />
        )}
      </AnimatePresence>
    </>
  );
}

export default NavBar;
