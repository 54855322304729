import { useRef, useState } from "react";

import PropTypes from "prop-types";

import { GenericModal } from "../GenericModal";
import QuestionForm from "./QuestionForm";
import QuestionSubmitted from "./QuestionSubmitted";

export const AskQuestionModal = ({ productName }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [sectionType, setSectionType] = useState("form");
  const resetFormRef = useRef(null);

  const handleModalChange = () => {
    setIsOpen((prevState) => !prevState);

    if (isOpen) {
      setSectionType("form");
      resetFormRef.current?.formReset();
    }
  };

  const handleSwitch = (type) => {
    setSectionType(type);
  };

  const sectionTypePresenter = {
    form: (
      <QuestionForm
        ref={resetFormRef}
        onHide={handleModalChange}
        productName={productName}
        triggerSwitch={() => handleSwitch("submitted")}
      />
    ),
    submitted: <QuestionSubmitted onHide={handleModalChange} />,
  };

  return (
    <GenericModal isOpen={isOpen} onOpenChange={handleModalChange}>
      <GenericModal.Trigger>
        <p
          role="button"
          className="flex h-[2.75rem] w-[9.375rem] items-center justify-center rounded-[0.625rem] border-[0.125rem] border-primary-400 bg-white text-[1.125rem] font-semibold tracking-normal text-primary transition-colors duration-500 lg:h-[3rem] lg:w-[10.625rem]"
        >
          Ask Question
        </p>
      </GenericModal.Trigger>
      <GenericModal.Content
        title={sectionType === "form" ? "Ask Question" : ""}
      >
        {sectionTypePresenter[sectionType]}
      </GenericModal.Content>
    </GenericModal>
  );
};

AskQuestionModal.propTypes = {
  productName: PropTypes.string,
};
