import Image from "next/image";

import PropTypes from "prop-types";

function PageBanner({ bannerText, secondText }) {
  const pageBannerUrl =
    "https://robodocstorage.blob.core.windows.net/static-images/ContactBanner.jpg";

  return (
    <div className="relative h-[8.125rem] w-full lg:h-[13.75rem]">
      <Image
        fill
        priority
        quality={40}
        sizes="100vw"
        src={pageBannerUrl}
        alt="Banner"
      />
      <div className="absolute inset-0 flex flex-col items-center justify-center">
        <h1 className="mx-[0.5rem] text-[1.5rem] font-bold md:text-[2.25rem]">
          {bannerText}
          <br />
        </h1>

        {secondText && (
          <p className="mx-[0.5rem] text-center text-[0.875rem] font-bold">
            {secondText}
          </p>
        )}
      </div>
    </div>
  );
}

PageBanner.propTypes = {
  bannerText: PropTypes.string.isRequired,
  secondText: PropTypes.string,
};

export default PageBanner;
