import { useQuery } from "@tanstack/react-query";

import { getNotificationsApi } from "@/api/end-points/notifications";

export const useGetNotificationsData = (useQueryOptions) => {
  return useQuery({
    queryKey: ["notifications"],
    queryFn: getNotificationsApi,
    ...useQueryOptions,
    select: (res) => {
      return {
        cartProductCount: res.data?.data?.cart_product_count,
        wishlistProductCount: res.data?.data?.wishlist_product_count,
      };
    },
  });
};
