import { useMutation, useQuery } from "@tanstack/react-query";

import {
  getCancelReasonListApi,
  getCustomerOrdersApi,
  getOrderStatusListApi,
  getSingleOrderDetailsApi,
  orderTrackingApi,
  postCancelOrderApi,
  postCreateOrderApi,
} from "@/api/end-points/order";

export const useGetCustomerOrders = (useQueryOptions) => {
  const { query } = useQueryOptions || {};
  const page = query?.page ? parseInt(query?.page) : 1;
  const status =
    query?.status && query?.status !== "failed" ? query.status : "all";
  const filter = query?.filter ? query?.filter : "all";
  const queries = { page, status, filter };

  return useQuery({
    queryKey: ["orders", page, status, filter],
    queryFn: () => getCustomerOrdersApi(queries),
    keepPreviousData: true,
    ...useQueryOptions,
    select: (res) => {
      const orders = res.data?.data;
      return orders;
    },
    onError: (error) => {
      return {
        data: error.response.data,
        message: error.message,
      };
    },
  });
};

export const useCreateOrder = (useQueryOptions) => {
  const { onSuccess = () => {}, onError = () => {} } = useQueryOptions || {};

  return useMutation(postCreateOrderApi, {
    onSuccess: (res) => {
      onSuccess({
        status: res.status,
        data: res.data,
        message: res.data.message || "Order Created",
      });
    },
    onError: (error) => {
      onError({
        status: error.response.status,
        data: error.response.data,
        message: error.response?.data?.errors[0]?.message,
      });
    },
  });
};

export const useGetSingleOrderDetails = (orderId, useQueryOptions) => {
  return useQuery({
    queryKey: ["order-details", orderId],
    queryFn: () => getSingleOrderDetailsApi(orderId),
    keepPreviousData: true,
    ...useQueryOptions,
    select: (res) => {
      const singleOrder = res.data?.data?.order;
      return singleOrder;
    },
    onError: (error) => {
      return error.response.data;
    },
  });
};

export const useCancelOrder = (useQueryOptions) => {
  const { onSuccess = () => {}, onError = () => {} } = useQueryOptions || {};

  return useMutation(postCancelOrderApi, {
    onSuccess: (res) => {
      onSuccess({
        status: res.status,
        message: res.data.message || "Order Cancelled",
      });
    },
    onError: (error) => {
      onError({
        status: error.response.status,
        data: error.response.data,
        message: error.response.data.errors[0].message,
      });
    },
  });
};

export const useGetCancelReasonList = (useQueryOptions) => {
  return useQuery({
    queryKey: ["cancel-reason-list"],
    queryFn: () => getCancelReasonListApi(),
    keepPreviousData: true,
    ...useQueryOptions,
    select: (res) => {
      const cancelReasonList = res.data?.data?.orderReasons;
      return cancelReasonList;
    },
    onError: (error) => {
      return error?.response?.data;
    },
  });
};

export const useGetOrderStatusList = (useQueryOptions) => {
  return useQuery({
    queryKey: ["order-status-list"],
    queryFn: () => getOrderStatusListApi(),
    keepPreviousData: true,
    ...useQueryOptions,
    select: (res) => {
      const orderStatusList = res.data?.data?.orderStatus;
      return orderStatusList;
    },
    onError: (error) => {
      return error.response.data;
    },
  });
};

export const usePostOrderTracking = (useQueryOptions) => {
  const { onSuccess = () => {}, onError = () => {} } = useQueryOptions || {};

  return useMutation(orderTrackingApi, {
    onSuccess: (res) => {
      onSuccess({
        data: res.data?.data?.order,
        message: res.data.message,
      });
    },
    onError: (error) => {
      onError({
        data: error.response.data,
        error: error.response.data?.errors[0].message,
      });
    },
  });
};
