import * as Dialog from "@radix-ui/react-dialog";
import classNames from "classnames";

import { CloseIcon } from "@/components/data-display";

export function GenericModal({ children, isOpen, onOpenChange }) {
  return (
    <Dialog.Root open={isOpen} onOpenChange={onOpenChange}>
      {children}
    </Dialog.Root>
  );
}

function ModalContent({ title, className, children }) {
  return (
    <Dialog.Portal>
      <Dialog.Overlay className="fixed inset-0 z-[999] grid items-center overflow-y-auto bg-black/50" />
      <Dialog.Content
        className={`fixed left-[50%] top-[50%] z-[1000] min-h-full w-full max-w-full -translate-x-[50%] -translate-y-[50%] overflow-auto rounded-none bg-white p-7 focus:outline-none md:min-h-0 md:max-w-[40rem] md:rounded-lg ${className}`}
      >
        <div
          className={classNames(
            {
              "justify-between": title,
              "justify-end": !title,
            },
            "mt-3 flex items-start"
          )}
        >
          {title && (
            <Dialog.Title className="text-[1.25rem] font-semibold leading-none text-grayDisplay-900 md:text-[1.5rem]">
              {title}
            </Dialog.Title>
          )}
          <Dialog.Close>
            <CloseIcon className="h-[0.9rem] w-[0.9rem]" />
          </Dialog.Close>
        </div>

        {children}
      </Dialog.Content>
    </Dialog.Portal>
  );
}

GenericModal.Trigger = Dialog.Trigger;
GenericModal.Content = ModalContent;
GenericModal.Close = Dialog.Close;
