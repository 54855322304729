import { useEffect } from "react";

import { useRouter } from "next/router";

export const FacebookPixelEvents = () => {
  const { pathname, query } = useRouter();
  useEffect(() => {
    import("react-facebook-pixel")
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init(process.env.NEXT_PUBLIC_FB_PIXEL_ID);
        ReactPixel.pageView();
      });
  }, [pathname, query]);

  return null;
};
