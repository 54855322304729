import { useState } from "react";

import PropType from "prop-types";

import PrimaryBtn from "@/components/actions/button/primaryBtn";
import useCountdown from "@/hooks/useCountdown";

import AppOtpInput from "../input-otp";

function Otp(props) {
  const {
    isLoading,
    submitOtp,
    phoneNumber,
    changePhoneHandler,
    updatePhoneNumber,
  } = props;
  const [value, setValue] = useState(null);
  const { minutes, seconds, timerOver, resetTimer } = useCountdown();

  const handleResetOtp = () => {
    updatePhoneNumber({ phone: phoneNumber });
    resetTimer();
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <h1 className=" text-[1.75rem] font-semibold text-grayDisplay-900">
        One Time Password
      </h1>
      <p className="mt-[2.188rem] text-grayDisplay-400">
        Enter the 6-digit code sent to your number
      </p>
      <p className="mb-[1.75rem] mt-[0.625rem] font-semibold text-grayDisplay-900">
        &quot;{phoneNumber}&quot;
      </p>

      <AppOtpInput onOtpChange={(e) => setValue(e)} otpValue={value} />

      {!timerOver && (
        <p className="mt-[1.875rem] text-grayDisplay-700">
          Resend Code in {minutes}:{seconds}
        </p>
      )}
      {timerOver && (
        <button className="mt-[1.875rem] text-primary" onClick={handleResetOtp}>
          Resend OTP!
        </button>
      )}

      <p
        role="button"
        onClick={changePhoneHandler}
        className="mt-[1.625rem] font-medium text-grayDisplay-900 underline"
      >
        Change phone number
      </p>

      <PrimaryBtn
        btnText="Confirm"
        isLoading={isLoading}
        onClick={() => submitOtp(value)}
        className="mt-[3.375rem] h-[3.5rem] w-[13.75rem]"
      />
    </div>
  );
}

Otp.defaultProps = {
  phoneNumber: "",
};

Otp.propTypes = {
  submitOtp: PropType.func,
  isLoading: PropType.bool,
  phoneNumber: PropType.string,
  errorMessage: PropType.string,
};

export default Otp;
