import PropTypes from "prop-types";

export const OrderSummaryCard = ({ data }) => {
  return (
    <div className="rounded-[0.75rem] border border-[#EBEEF2]">
      <div className="rounded-t-[0.75rem] p-[1.25rem]">
        <p className="text-[1.125rem] font-semibold leading-[1.75rem] text-grayDisplay-900">
          Order Summary
        </p>
      </div>

      <div className="border-b border-[#EBEEF2]" />

      <div className="p-[1.25rem]">
        <div className="space-y-[1.563rem] text-[1rem] leading-[1.625rem]">
          <div className="flex items-center justify-between">
            <p className="font-normal  text-grayDisplay-400">Sub Total </p>
            <p className="font-semibold text-grayDisplay-900">
              ৳{data?.subtotal}
            </p>
          </div>

          {data?.shipping_fees?.map(({ text, amount, key }) => (
            <div key={key} className="flex items-center justify-between">
              <p className="font-normal text-grayDisplay-400">{text}</p>
              <p className="font-semibold text-grayDisplay-900">৳{amount}</p>
            </div>
          ))}

          <div className="mb-[1.563rem] border-b border-b-grayDisplay-100" />

          {data?.discounts?.map((discount, i) => (
            <div key={i} className="flex items-center justify-between">
              <p className="font-normal text-grayDisplay-400">
                {discount?.text}
              </p>
              <p className="font-semibold text-[#FF4242]">
                ৳{discount?.amount}
              </p>
            </div>
          ))}

          <div className="flex items-center justify-between">
            <p className="font-normal text-grayDisplay-900">Total Amount </p>
            <p className="text-[1.125rem] font-semibold text-grayDisplay-900">
              ৳{data?.total}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

OrderSummaryCard.propTypes = {
  data: PropTypes.shape({
    subtotal: PropTypes.number,
    shipping_cost: PropTypes.number,
    discounts: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        amount: PropTypes.number,
      })
    ),
    total: PropTypes.number,
  }),
};
