import { useRouter } from "next/router";

import PropTypes from "prop-types";

import PrimaryBtn from "@/components/actions/button/primaryBtn";

export const CompletePayment = ({ url }) => {
  const router = useRouter();
  const handleButtonClick = () => {
    router.push(url);
  };

  return (
    <>
      <p>
        You haven’t complete your order. Please select payment method and
        complete your order.
      </p>

      <div className="mt-[3rem]">
        <PrimaryBtn
          btnText="Complete Order"
          onClick={handleButtonClick}
          className="h-[3rem] w-full"
        />
      </div>
    </>
  );
};

CompletePayment.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.node,
};
