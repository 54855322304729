import { useState } from "react";
import { useForm } from "react-hook-form";

import { useSession } from "next-auth/react";

import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import PropTypes from "prop-types";

import { useApplyCoupon, useRemoveCoupon } from "@/api/client/coupon";
import { RemoveModal } from "@/components/actions";
import QuaternaryBtn from "@/components/actions/button/quaternaryBtn";
import {
  ClearCouponIcon,
  CouponAppliedIcon,
  ExclamatoryIcon,
  InlineLoaderIcon,
} from "@/components/data-display/icon";
import { errorToast, successToast } from "@/components/feedback/toast";
import { couponValidationSchema } from "@/schema/formSchema/coupon";
import { unauthorizedRedirection } from "@/utils/helpers";

function CouponForm({ coupon, refetchCart, isCartRefetching }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: session } = useSession();
  const authToken = session?.user?.token;

  const defaultValues = { coupon: coupon?.code ?? "" };
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues,
    values: defaultValues,
    resolver: yupResolver(couponValidationSchema),
  });

  const { isLoading: isCouponApplying, mutate: applyCoupon } = useApplyCoupon({
    onSuccess: ({ message }) => {
      refetchCart();
      successToast({ message });
    },
    onError: (error) => {
      if (error.status === 422) {
        setError("coupon", {
          type: "custom",
          message: error.data.errors[0].message,
        });
      } else {
        errorToast({ message: error.data.errors[0].message });
      }
    },
  });

  const { isLoading: isCouponRemoving, mutate: removeCoupon } = useRemoveCoupon(
    {
      onSuccess: ({ message }) => {
        refetchCart();
        setIsModalOpen(false);
        successToast({ message });
      },
      onError: ({ message }) => {
        setIsModalOpen(false);
        errorToast({ message });
      },
    }
  );

  const onApplyCoupon = (data) => {
    applyCoupon({ code: data.coupon });
  };

  const onRemoveCoupon = () => {
    removeCoupon({ code: coupon.code });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onApplyCoupon)}>
        <div className="flex w-full items-center justify-between space-x-[0.75rem]">
          <div className="relative w-full">
            <input
              type="text"
              placeholder="Enter your coupon code"
              className={classNames(
                {
                  "ring-[#FF4249] focus:ring-[#FF4249]": errors?.coupon,
                  "ring-grayDisplay-200 focus:ring-grayDisplay-400":
                    !errors?.coupon,
                },
                "h-[2.75rem] w-full rounded-[0.625rem] px-[1.25rem] py-[0.781rem] outline-0 ring-1 focus:outline-none"
              )}
              {...register("coupon")}
              disabled={coupon?.name}
            />

            {((isCartRefetching && !!coupon?.code) || isCouponRemoving) && (
              <div className="absolute inset-0 z-50 rounded-[0.625rem] bg-[#FFFFFFcc]">
                <InlineLoaderIcon className="absolute left-[calc(50%_-_1.5rem)] top-[calc(50%_-_1.5rem)] inline-block h-[3rem] w-[3rem]" />
              </div>
            )}

            {coupon?.code && (
              <button
                type="button"
                onClick={() => setIsModalOpen(true)}
                className="absolute right-[.8%] top-[50%] translate-x-[-50%] translate-y-[-40%] cursor-pointer"
              >
                <div className="relative h-[1rem] w-[1rem]">
                  <ClearCouponIcon className="hover:text-red-500" />
                </div>
              </button>
            )}
          </div>
          <QuaternaryBtn
            btnText="Apply"
            className="h-[2.75rem] w-[6.75rem]"
            isDisabled={coupon?.code || !authToken}
            isLoading={(isCartRefetching && coupon?.code) || isCouponApplying}
          />
        </div>

        <div className="mt-[0.75rem] flex items-center space-x-[0.5rem]">
          {!!errors?.coupon && (
            <>
              <ExclamatoryIcon className="text-red-500" />
              <p className="text-[1rem] text-coupon-error">
                {errors?.coupon?.message}
              </p>
            </>
          )}

          {!!coupon?.name && (
            <>
              <div className="relative h-[1rem] w-[1rem]">
                <CouponAppliedIcon />
              </div>

              <p className="text-[1rem] text-[#10BB69]">Coupon Applied</p>
            </>
          )}
        </div>

        {!authToken && (
          <div>
            * Please
            <button
              onClick={unauthorizedRedirection}
              className="mx-1 text-primary hover:underline"
            >
              Sign In
            </button>
            to apply coupon
          </div>
        )}
      </form>

      <RemoveModal
        isModalOpen={isModalOpen}
        title="Remove Coupon From Cart"
        requestConfirmation={onRemoveCoupon}
        description="Confirm coupon removal?"
        hideModal={() => setIsModalOpen(false)}
      />
    </>
  );
}

export default CouponForm;

CouponForm.propTypes = {
  coupon: PropTypes.object,
  refetchCartData: PropTypes.func,
  isCartDataFetching: PropTypes.bool,
};
