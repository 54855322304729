export const accountDropdownList = [
  {
    label: "My Profile",
    slug: "my-account",
  },
  {
    label: "Orders",
    slug: "my-orders",
  },
  {
    label: "Wishlist",
    slug: "my-wishlist",
  },
  {
    label: "Order Track",
    slug: "order-track",
  },
  {
    label: "My Addresses",
    slug: "addresses",
  },
  {
    label: "Sign Out",
    slug: "sign-out",
  },
];
