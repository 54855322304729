import { useMutation, useQuery } from "@tanstack/react-query";

import {
  getProfileInfoApi,
  updateAccountMailApi,
  updatePhoneNumberApi,
  updateProfileInfoApi,
  verifyAccountMailApi,
  verifyPhoneNumberApi,
} from "@/api/end-points/profile";

export const useGetAccountInfo = (useQueryOptions) => {
  return useQuery({
    queryKey: ["profile-information"],
    queryFn: getProfileInfoApi,
    ...useQueryOptions,
    select: (res) => {
      return res.data?.data?.customer;
    },
    onError: (error) => {
      return error.response.data;
    },
  });
};

export const useUpdateAccountInfo = (useQueryOptions) => {
  const { onSuccess = () => {}, onError = () => {} } = useQueryOptions || {};
  return useMutation(updateProfileInfoApi, {
    onSuccess: (res) => {
      onSuccess({
        status: res.status,
        message: res.data.message || "Update Profile Data",
        data: res.data.data.customer,
      });
    },
    onError: (error) => {
      onError({
        status: error.response.status,
        data: error.response.data,
        message: error.response.data.errors[0].message,
      });
    },
  });
};

export const useVerifyPhoneNumber = (useQueryOptions) => {
  const { onSuccess = () => {}, onError = () => {} } = useQueryOptions || {};
  return useMutation(verifyPhoneNumberApi, {
    onSuccess: (res) => {
      onSuccess({
        status: res.status,
        message: res.data.message || "Success! Phone verified.",
        data: res.data.data.customer,
      });
    },
    onError: (error) => {
      onError({
        status: error.response.status,
        data: error.response.data,
        message: error.response.data.errors[0].message,
      });
    },
  });
};

export const useUpdatePhoneNumber = (useQueryOptions) => {
  const { onSuccess = () => {}, onError = () => {} } = useQueryOptions || {};
  return useMutation(updatePhoneNumberApi, {
    onSuccess: (res) => {
      onSuccess({
        status: res.status,
        data: res.data.data,
        message: res.data.message,
      });
    },
    onError: (error) => {
      onError({
        status: error.response.status,
        data: error.response.data,
        message: error.response.data.errors[0].message,
      });
    },
  });
};

export const useVerifyEmail = (useQueryOptions) => {
  const { onSuccess = () => {}, onError = () => {} } = useQueryOptions || {};
  return useMutation(verifyAccountMailApi, {
    onSuccess: (res) => {
      onSuccess({
        status: res.status,
        message: res.data.message || "Success! email verified.",
        data: res.data.data.customer,
      });
    },
    onError: (error) => {
      onError({
        status: error.response.status,
        data: error.response.data,
        message: error.response.data.errors[0].message,
      });
    },
  });
};

export const useUpdateEmail = (useQueryOptions) => {
  const { onSuccess = () => {}, onError = () => {} } = useQueryOptions || {};
  return useMutation(updateAccountMailApi, {
    onSuccess: (res) => {
      onSuccess({
        status: res.status,
        message: res.data.message,
      });
    },
    onError: (error) => {
      onError({
        status: error.response.status,
        data: error.response.data,
        message: error.response.data.errors[0].message,
      });
    },
  });
};
