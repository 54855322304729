export const PRODUCT_LABEL_MAP = {
  FREE_DELIVERY: "free_delivery",
  PERCENTAGE_DISCOUNT: "percentage_discount",
  COUPON_DISCOUNT: "coupon_discount",
  HOT: "hot",
  NEW: "new",
  FEATURED: "featured",
  POPULAR: "popular",
};

export const deliveryMethods = {
  HOME_DELIVERY: "home_delivery",
  EXPRESS_DELIVERY: "express_delivery",
  OFFICE_PICKUP: "office_pickup",
};

export const DELIVERY_ADDRESS_COUPON = "discount_on_delivery_address";
