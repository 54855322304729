import { useState } from "react";

import { AnimatePresence, motion } from "framer-motion";
import PropTypes from "prop-types";

import { MinusIcon, PlusIcon } from "../icon";

const Accordion = ({ label, Component, isAccordionOpen, className }) => {
  const [isOpened, setIsOpened] = useState(isAccordionOpen);

  return (
    <div>
      <div className={`flex items-center justify-between ${className}`}>
        <p className="text-[1rem] font-semibold leading-[1.125rem] text-grayDisplay-700">
          {label}
        </p>
        <div>
          {isOpened ? (
            <button onClick={() => setIsOpened((isOpened) => !isOpened)}>
              <MinusIcon className="h-[2.625rem] w-[2.5rem] text-primary" />
            </button>
          ) : (
            <button onClick={() => setIsOpened((isOpened) => !isOpened)}>
              <PlusIcon className="h-[2.625rem] w-[2.5rem] text-primary" />
            </button>
          )}
        </div>
      </div>
      <AnimatePresence>
        {isOpened && (
          <motion.div
            variants={{
              open: {
                height: "auto",
                overflow: "hidden",
              },
              closed: {
                height: 0,
                overflow: "hidden",
              },
            }}
            initial="closed"
            animate="open"
            exit="closed"
            transition={{ delay: 0.1, duration: 0.4 }}
          >
            {Component}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Accordion;

Accordion.propTypes = {
  label: PropTypes.string.isRequired,
  Component: PropTypes.node.isRequired,
  className: PropTypes.string,
  isAccordionOpen: PropTypes.bool,
};

Accordion.defaultProps = {
  isAccordionOpen: false,
};
