import { useId } from "react";

import PropTypes from "prop-types";

import { getBorderColor } from "@/utils/helpers";

function AppTextAreaInput({
  labelName,
  isFieldRequired,
  className,
  placeholder,
  name,
  register,
  error,
}) {
  const accessAbleId = useId();

  return (
    <div>
      <div className="mb-[.875rem] flex justify-between md:mb-[1rem]">
        <label className="text-grayDisplay-700" htmlFor={accessAbleId}>
          {labelName}
          {isFieldRequired && (
            <span className="text-required-color">&nbsp;*</span>
          )}
        </label>
      </div>

      <textarea
        id={accessAbleId}
        className={`min-h-[11.25rem] w-full resize-none rounded-[10px] border p-[1.25rem] text-[1rem] text-grayDisplay-700 placeholder-grayDisplay-300 outline-0 focus:outline-none focus:ring-0 ${getBorderColor(
          error
        )} ${className}`}
        placeholder={placeholder}
        {...register(name, { isFieldRequired })}
      />

      {error?.message && (
        <p className="mt-[0.35rem] text-[#FF4242] text-[0.875] first-letter:uppercase">
          {error?.message}
        </p>
      )}
    </div>
  );
}

AppTextAreaInput.defaultProps = {
  isFieldRequired: false,
};

AppTextAreaInput.propTypes = {
  labelName: PropTypes.string,
  isFieldRequired: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  register: PropTypes.func,
  error: PropTypes.object,
};

export default AppTextAreaInput;
