import * as Dialog from "@radix-ui/react-dialog";
import classNames from "classnames";

import { CloseIcon } from "@/components/data-display";

export function CenteredModal({ children, isOpen, onOpenChange }) {
  return (
    <Dialog.Root open={isOpen} onOpenChange={onOpenChange} className="z-[1000]">
      {children}
    </Dialog.Root>
  );
}

function ModalContent({ title, children }) {
  return (
    <Dialog.Portal>
      <Dialog.Overlay className="fixed inset-0 z-[999] grid items-center overflow-y-auto bg-black/50" />
      <Dialog.Content
        className={`fixed left-[50%] top-[50%] z-[1000] min-w-[90vw] -translate-x-[50%] -translate-y-[50%] rounded-lg bg-white p-6 focus:outline-none md:min-w-[40rem]`}
      >
        <div
          className={classNames(
            {
              "justify-between": title,
              "justify-end": !title,
            },
            "flex items-center"
          )}
        >
          {title && (
            <Dialog.Title className="text-[1.125rem] font-semibold leading-none text-grayDisplay-900 md:text-[1.5rem]">
              {title}
            </Dialog.Title>
          )}
          <Dialog.Close className="-mb-1">
            <CloseIcon className="h-[0.9rem] w-[0.9rem]" />
          </Dialog.Close>
        </div>

        {children}
      </Dialog.Content>
    </Dialog.Portal>
  );
}

CenteredModal.Trigger = Dialog.Trigger;
CenteredModal.Content = ModalContent;
CenteredModal.Close = Dialog.Close;
