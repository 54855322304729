import { getSession } from "next-auth/react";
import Router from "next/router";

export const isAuthorizedUser = async () => {
  const session = await getSession();
  const isAuthenticated = !!session?.user?.token;

  if (isAuthenticated) return true;

  unauthorizedRedirection();
  return false;
};

export const unauthorizedRedirection = () => {
  const urlObject = {
    pathname: "/signin",
  };

  if (Router.pathname !== "/") {
    urlObject.query = { callbackUrl: Router.asPath };
  }

  Router.push(urlObject);
};

export const authorizedRedirection = () => {
  if (Router.query?.callbackUrl) {
    Router.replace(Router.query.callbackUrl);
  } else {
    Router.replace("/");
  }
};
