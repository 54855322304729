export const customerServiceLinks = [
  {
    title: "Privacy Policy",
    link: "/privacy-policy",
  },
  {
    title: "Terms of Use",
    link: "/terms-of-use",
  },
  {
    title: "Shipping and Refund Policy",
    link: "/shipping-and-refund-policy",
  },
];

export const aboutLinks = [
  {
    title: "Contact Us",
    link: "/contact",
  },
  {
    title: "About Us",
    link: "/about-us",
  },
  {
    title: "Blog",
    link: "/blogs",
  },
  {
    title: "FAQs",
    link: "/faq",
  },
];

export const accountLinks = [
  {
    title: "My Profile",
    link: "/my-account",
  },
  {
    title: "Order History",
    link: "/my-orders",
  },
  {
    title: "Track My Order",
    link: "track-order",
  },
  {
    title: "My Addresses",
    link: "/addresses",
  },
  {
    title: "Wishlists",
    link: "/my-wishlist",
  },
];

export const otherLinks = [
  {
    title: "Innovation Kit",
    link: process.env.NEXT_PUBLIC_INNOVATION_KIT_ABSOLUTE_URL,
  },
];

export const storeHours = {
  offDay: "Friday - Closed, No delivery",
  workingDay: "Sat - Thur - 9:00 AM - 8:00 PM",
};
