import Link from "next/link";
import { useRouter } from "next/router";

import classNames from "classnames";

import { OffersIcon } from "@/components/data-display";
import { FixedMenus } from "@/utils/constants/mainMenus.constants";

export const ExtraMenu = ({ isLogoHidden }) => {
  const { pathname, query } = useRouter();

  return (
    <>
      {FixedMenus.map((menu, index) => (
        <Link key={index} href={`/category/${menu.slug}`}>
          <div
            className={classNames(
              {
                "text-primary": query?.slug === menu.slug,
                "text-grayDisplay-700": query?.slug !== menu.slug,
              },
              "relative flex items-center px-3"
            )}
          >
            <p className="cursor-pointer text-[0.875rem] font-medium xl:text-[1rem]">
              {menu.name}
            </p>

            {query?.slug === menu.slug && (
              <span
                className={classNames(
                  {
                    "-bottom-[1.85rem] xl:-bottom-[1.75rem]": !isLogoHidden,
                    "-bottom-[1.6rem] xl:-bottom-[1.55rem]": isLogoHidden,
                  },
                  "absolute inset-0 border-b-2 border-primary text-primary"
                )}
              />
            )}
          </div>
        </Link>
      ))}

      <Link href={`/offers`}>
        <div
          className={classNames(
            {
              "text-primary": pathname === "/offers",
              "text-quaternary-600": pathname !== "/offers",
            },
            "relative flex items-center px-3"
          )}
        >
          <OffersIcon className="h-[0.875rem] w-[0.875rem] xl:h-[1.125rem] xl:w-[1.125rem]" />
          <p className="ml-[.5rem] cursor-pointer text-[0.875rem] font-medium  xl:text-[1rem]">
            Offers
          </p>

          {pathname === "/offers" && (
            <span
              className={classNames(
                {
                  "-bottom-[1.85rem] xl:-bottom-[1.75rem]": !isLogoHidden,
                  "-bottom-[1.6rem] xl:-bottom-[1.55rem]": isLogoHidden,
                },
                "absolute inset-0 border-b-2 border-primary text-primary"
              )}
            />
          )}
        </div>
      </Link>
    </>
  );
};
