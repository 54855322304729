import { useId } from "react";

import PropTypes from "prop-types";

import { getBorderColor } from "@/utils/helpers";

function AppInput(props) {
  const {
    labelName,
    isFieldRequired,
    name,
    register,
    error,
    placeholder,
    className,
    type,
    isDisabled,
  } = props;
  const accessAbleId = useId();

  return (
    <div className={`w-full`}>
      {labelName && (
        <div className="mb-[.875rem] flex justify-between md:mb-[1rem]">
          <label className="text-grayDisplay-700" htmlFor={accessAbleId}>
            {labelName}
            {isFieldRequired && (
              <span className="text-required-color">&nbsp;*</span>
            )}
          </label>
        </div>
      )}

      <div className="w-full">
        <input
          type={type}
          id={accessAbleId}
          disabled={isDisabled}
          className={`${className} h-[3.5rem] w-full rounded-[10px] border  pl-[1.25rem] text-[1rem] text-grayDisplay-700 placeholder-grayDisplay-300 outline-0 focus:outline-none focus:ring-0 ${getBorderColor(
            error
          )}`}
          placeholder={placeholder}
          autoComplete={"off"}
          {...register(name)}
        />
      </div>
      {error?.message && (
        <p className="mt-[0.35rem] text-[0.875] text-error-color first-letter:uppercase">
          {error?.message}
        </p>
      )}
    </div>
  );
}

AppInput.propTypes = {
  labelName: PropTypes.string.isRequired,
  isFieldRequired: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  register: PropTypes.func,
  error: PropTypes.object,
  className: PropTypes.string,
  type: PropTypes.string,
  isDisabled: PropTypes.bool,
};

AppInput.defaultProps = {
  isDisabled: false,
  isFieldRequired: false,
};

export default AppInput;
