import Image from "next/image";

function SearchedProduct({ product, onClick }) {
  return (
    <div
      onClick={onClick}
      key={product.slug}
      className="flex cursor-pointer items-start gap-[0.938rem] rounded-[0.5rem] py-[0.75rem] pl-[0.5rem] pr-[0.75rem] hover:bg-grayDisplay-150 md:px-[1rem]"
    >
      <Image
        src={product.image}
        alt={product.name}
        height={54}
        width={54}
        sizes="54px"
      />
      <div className="flex flex-col gap-[0.125rem]">
        <p className="text-[1rem] leading-[1.5rem] text-grayDisplay-900">
          {product.name}
        </p>
        <p className="text-[1rem] font-semibold leading-[1.063rem] tracking-[0.08px] text-tertiary">
          ৳{product.has_discount_price ? product.discount_price : product.price}
        </p>
      </div>
    </div>
  );
}

export default SearchedProduct;
