import { useMutation } from "@tanstack/react-query";

import { postSubscribeToNewsletter } from "@/api/end-points/newsletters";

export const usePostSubscribeToNewsletter = (useQueryOptions) => {
  const { onSuccess = () => {}, onError = () => {} } = useQueryOptions || {};

  return useMutation(postSubscribeToNewsletter, {
    onSuccess: (newsletterResponse) => {
      onSuccess({
        status: newsletterResponse.status,
        message: newsletterResponse.data.message || "Subscribed to newsletter",
      });
    },
    onError: (newsletterError) => {
      onError({
        status: newsletterError.response.status,
        data: newsletterError.response.data,
        message: newsletterError.message,
      });
    },
  });
};
