import { apiClient } from "@/services/api-client";

export const getSingleProductApi = (product_slug) => {
  return apiClient({
    url: `/v1/products/${product_slug}`,
    method: "GET",
  });
};

export const getProductReviewsApi = (product_slug, page = 1) => {
  return apiClient({
    url: `/v1/product/${product_slug}/reviews?page=${page}`,
    method: "GET",
  });
};

export const getProductQuestionsApi = (product_slug, page = 1) => {
  return apiClient({
    url: `/v1/product/${product_slug}/questions?page=${page}`,
    method: "GET",
  });
};

export const createProductQuestionsApi = ({ product_slug, payload }) => {
  return apiClient({
    url: `/v1/product/${product_slug}/questions`,
    method: "POST",
    data: payload,
  });
};

export const createProductReviewApi = ({ product_slug, payload }) => {
  return apiClient({
    url: `/v1/product/${product_slug}/reviews`,
    method: "POST",
    data: payload,
  });
};

export const getProductSuggestionsApi = (keyword) => {
  const params = keyword ? { query: keyword } : null;

  return apiClient({
    url: "/v1/search-suggestion",
    method: "GET",
    params,
  });
};

export const getProductListApi = (url) => {
  return apiClient({
    url: `v1/products${url}`,
    method: "GET",
  });
};

export const getOfferProductsApi = ({ page }) => {
  const queries = { page };

  return apiClient({
    url: `/v1/offer-products`,
    method: "GET",
    params: queries,
  });
};

export const getPopularProductsApi = ({ page }) => {
  const queries = { page };

  return apiClient({
    url: `/v1/popular-products`,
    method: "GET",
    params: queries,
  });
};

export const getTodayDealsApi = ({ page }) => {
  const queries = { page };

  return apiClient({
    url: `/v1/today-deals`,
    method: "GET",
    params: queries,
  });
};

export const getSpecialProductsApi = ({ page }) => {
  const queries = { page };

  return apiClient({
    url: `/v1/special-products`,
    method: "GET",
    params: queries,
  });
};

export const getNewProductsApi = ({ page }) => {
  const queries = { page };

  return apiClient({
    url: `/v1/new-products`,
    method: "GET",
    params: queries,
  });
};

export const requestProductStockApi = ({ slug }) => {
  const queries = { slug };

  return apiClient({
    url: `/v1/products/${slug}/stock-request`,
    method: "POST",
    params: queries,
  });
};
