/* eslint-disable react/display-name */
import { forwardRef, useImperativeHandle } from "react";
import { useForm } from "react-hook-form";

import { useRouter } from "next/router";

import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";

import { useCreateProductQuestion } from "@/api/client/product";
import AppInput from "@/components/data-input/input-field";
import AppTextAreaInput from "@/components/data-input/inputTextArea";
import { errorToast } from "@/components/feedback/toast";
import { questionSchema } from "@/schema/formSchema";

import PrimaryBtn from "../../button/primaryBtn";
import SecondaryBtn from "../../button/secondaryBtn";

const QuestionForm = forwardRef((props, ref) => {
  const { onHide, productName, triggerSwitch } = props;
  const router = useRouter();

  const defaultValues = { title: "", product: productName };

  useImperativeHandle(ref, () => ({ formReset: () => reset(defaultValues) }));

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(questionSchema),
  });

  const { isLoading, mutate: createQuestion } = useCreateProductQuestion({
    onSuccess: () => {
      triggerSwitch();
    },
    onError: ({ message }) => {
      errorToast({ message });
    },
  });

  const onSubmit = async ({ title }) => {
    const { product_slug } = router.query;
    const payload = { title };
    createQuestion({ product_slug, payload });
  };

  return (
    <form className="mt-6" onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-5">
        <AppInput
          isDisabled
          name="product"
          labelName="Product"
          register={register}
        />
      </div>

      <div className="mb-5">
        <AppInput
          name="email"
          isFieldRequired
          register={register}
          error={errors.email}
          labelName="Email"
          placeholder="Enter your email here"
        />
      </div>

      <div className="mb-5">
        <AppTextAreaInput
          name="title"
          isFieldRequired
          register={register}
          error={errors.title}
          labelName="Your Question"
          placeholder="Enter your question here"
        />
      </div>

      <div className="mt-3 flex justify-between gap-[1rem] md:justify-end md:gap-[1.25rem]">
        <SecondaryBtn
          btnText="Cancel"
          onClick={onHide}
          isDisabled={isLoading}
          className="w-full py-[0.875rem] md:w-[10rem] md:py-[1rem]"
        />

        <PrimaryBtn
          type="submit"
          btnText="Submit"
          isLoading={isLoading}
          isDisabled={isLoading}
          className="w-full py-[.875rem] md:w-[10rem] md:py-[1rem]"
        />
      </div>
    </form>
  );
});

QuestionForm.propTypes = {
  onHide: PropTypes.func,
  triggerSwitch: PropTypes.func,
  productName: PropTypes.string,
};

export default QuestionForm;
