import PropTypes from "prop-types";

import { InlineLoaderIcon, SearchIcon } from "@/components/data-display/icon";

function SearchInput({
  value,
  onSearch,
  onChange,
  onBlur,
  onFocus,
  isLoading,
}) {
  const handleSearch = (e) => {
    e.preventDefault();
    onSearch();
  };

  return (
    <div className="relative h-[3rem] rounded-[2.625rem] border border-grayDisplay-200 md:w-[23rem] lg:w-[25rem] min-[1200px]:w-[30rem] 2xl:w-[45rem]">
      <form
        onSubmit={handleSearch}
        className="h-full w-full rounded-[2.625rem]"
      >
        <input
          type="text"
          value={value}
          onBlur={onBlur}
          onFocus={onFocus}
          placeholder="Search anything..."
          onChange={(e) => onChange(e.target.value)}
          className="h-full w-full rounded-[2.625rem] border-0 pl-4 pr-[2.75rem] focus:border-grayDisplay-200 focus:outline-none focus:ring-1 focus:ring-grayDisplay-200"
        />

        <button
          type="submit"
          disabled={isLoading}
          className="absolute right-[.8%] top-[50%] translate-x-[-50%] translate-y-[-50%]"
        >
          {isLoading ? (
            <InlineLoaderIcon className="-m-[0.25rem] !h-[2rem] !w-[2rem]" />
          ) : (
            <SearchIcon />
          )}
        </button>
      </form>
    </div>
  );
}

SearchInput.propTypes = {
  value: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default SearchInput;
