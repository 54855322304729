import PropTypes from "prop-types";

export const PaymentMethodBlock = ({ title, icon }) => {
  return (
    <div className="flex items-center justify-between gap-2 lg:gap-4">
      <p className="min-w-[6.875rem] break-all text-sm text-[#414E5F]">
        Payment Method
      </p>
      <div className="flex items-center justify-end gap-1">
        <div>{icon}</div>
        {title && <p className="font-medium">{title}</p>}
      </div>
    </div>
  );
};

PaymentMethodBlock.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.node,
};
