import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { useSession } from "next-auth/react";
import Link from "next/link";
import { useRouter } from "next/router";

import classNames from "classnames";
import { motion } from "framer-motion";

import { useGetCategoryMenu } from "@/api/client/category";
import {
  AllCategoriesIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  CloseIcon,
  OffersIcon,
} from "@/components/data-display/icon";
import { updateTrackOrder } from "@/store/feature";
import { FixedMenus } from "@/utils/constants/mainMenus.constants";
import { hideMainScrollbar, showMainScrollbar } from "@/utils/helpers";

import MenuBarResponsiveSubMenu from "./MenuBarResponsiveSubMenu";

function MenuBarResponsive({ menuData, isMobileMenuOpen, closeMobileMenu }) {
  const [selectedMenuIndex, setSelectedMenuIndex] = useState(0);
  const [isAllCategoriesClicked, setIsAllCategoriesClicked] = useState(false);
  const [menus, setMenus] = useState(menuData);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [allCategoriesMenus, setAllCategoriesMenus] = useState([]);

  const router = useRouter();
  const { query, pathname } = router;

  const dispatch = useDispatch();

  const { data: session } = useSession();
  const isAuthenticated = !!session?.user?.token;

  useGetCategoryMenu({
    query: pathname.includes("category") ? { slug: query?.slug } : {},
    onSuccess: (data) => {
      setAllCategoriesMenus(data.categories);
      setMenus(data.main_menu);
    },
  });

  useEffect(() => {
    setMenus(isAllCategoriesClicked ? allCategoriesMenus : menuData);
  }, [isAllCategoriesClicked]);

  useEffect(() => {
    hideMainScrollbar({ isActive: isMobileMenuOpen });

    return () => {
      showMainScrollbar();
    };
  }, [isMobileMenuOpen]);

  const hideSideMenu = () => {
    setIsSubMenuOpen(false);
    closeMobileMenu();
    setIsAllCategoriesClicked(false);
  };

  const onMenuSelect = (selectedSubMenuIndex) => {
    setSelectedMenuIndex(selectedSubMenuIndex);
    setIsSubMenuOpen(true);
  };

  const handleTrackOrder = () => {
    closeMobileMenu();
    setTimeout(() => {
      dispatch(updateTrackOrder(true));
    }, 600);
  };

  return (
    <motion.div
      className={`fixed inset-0 z-[99999] bg-grayDisplay-700 bg-opacity-40 lg:hidden`}
    >
      <motion.div
        variants={{
          open: {
            x: "0%",
          },
          closed: {
            x: "-100%",
          },
        }}
        initial="closed"
        animate="open"
        exit="closed"
        transition={{
          type: "spring",
          bounce: 0.1,
          duration: 0.4,
          delay: 0.1,
        }}
        className="h-full w-[18.5rem] overflow-y-auto bg-white pb-[2.25rem] pl-[1.5rem] pt-[1.938rem] opacity-100"
      >
        <div>
          <div className="mr-[1.5rem] flex items-center justify-between">
            <div
              className={classNames(
                {
                  invisible: isSubMenuOpen || isAllCategoriesClicked,
                  visible: !isSubMenuOpen || !isAllCategoriesClicked,
                },
                `space-x-1 text-[0.938rem] text-grayDisplay-400 `
              )}
              onClick={() => hideSideMenu()}
            >
              {isAuthenticated ? (
                <Link href="/my-account">{`${session?.user?.name}'s Account`}</Link>
              ) : (
                <Link href="/signin">Sign In</Link>
              )}
            </div>

            <button onClick={hideSideMenu}>
              <CloseIcon className="h-[0.75rem] w-[0.75rem]" />
            </button>
          </div>

          <div
            className={`flex items-center gap-[1.563rem] ${
              isAllCategoriesClicked ? "visible" : "hidden"
            } ${isSubMenuOpen ? "hidden" : "visible"}`}
            onClick={() => setIsAllCategoriesClicked(false)}
          >
            <ArrowLeftIcon className="h-[0.917rem] w-[0.5rem]" />
            <p>Back</p>
          </div>

          <div
            className={`pt-[0.75rem] text-[1rem] font-medium leading-[1.5rem] text-grayDisplay-700 ${
              isSubMenuOpen ? "hidden" : "visible"
            }`}
          >
            <div
              className={`mr-[1.5rem] mt-[2.25rem] flex items-center justify-between ${
                isAllCategoriesClicked ? "hidden" : "visible"
              }`}
              onClick={() => setIsAllCategoriesClicked(true)}
            >
              <div className="flex items-center gap-[0.625rem]">
                <AllCategoriesIcon className="h-[0.938rem] w-[0.938rem]" />
                <p>All Categories</p>
              </div>
              <ArrowRightIcon className="h-[0.917rem] w-[0.5rem]" />
            </div>

            <ul className="mr-[1.5rem] list-none">
              {menus &&
                menus.map((menu, index) => {
                  return (
                    <li key={menu.slug} className="mt-[2.25rem] cursor-pointer">
                      {menu.child.length > 0 ? (
                        <div
                          className="flex items-center justify-between"
                          onClick={() => onMenuSelect(index)}
                        >
                          <p>{menu.name}</p>
                          <ArrowRightIcon className="h-[0.917rem] w-[0.5rem]" />
                        </div>
                      ) : (
                        <div onClick={hideSideMenu}>
                          <Link href={`/category/${menu.slug}`}>
                            <p>{menu.name}</p>
                          </Link>
                        </div>
                      )}
                    </li>
                  );
                })}

              {FixedMenus.map((menu, index) => (
                <li
                  key={index}
                  className={`mt-[2.25rem] flex items-center gap-[0.5rem] ${
                    isAllCategoriesClicked ? "hidden" : "visible"
                  }`}
                >
                  <Link
                    onClick={hideSideMenu}
                    className="font-medium"
                    href={`/category/${menu.slug}`}
                  >
                    {menu.name}
                  </Link>
                </li>
              ))}

              <li
                className={`mt-[2.25rem] flex items-center gap-[0.5rem] text-quaternary-600 ${
                  isAllCategoriesClicked ? "hidden" : "visible"
                }`}
              >
                <OffersIcon className="h-[1.125rem] w-[1.125rem]" />
                <button
                  className="font-medium"
                  onClick={() => {
                    router.push("/offers");
                    hideSideMenu();
                  }}
                >
                  Offers
                </button>
              </li>
            </ul>

            {isAuthenticated && !isAllCategoriesClicked && (
              <div className="mt-[3.625rem] flex flex-col gap-[1.625rem] text-[0.938rem] leading-[1.25rem] text-grayDisplay-400">
                <div onClick={hideSideMenu}>
                  <Link href="/my-wishlist">
                    <p>Wishlist</p>
                  </Link>
                </div>

                <div onClick={hideSideMenu}>
                  <Link href="/my-orders">
                    <p>My Orders</p>
                  </Link>
                </div>

                <div>
                  <button type="button" onClick={handleTrackOrder}>
                    Track Order
                  </button>
                </div>
              </div>
            )}
          </div>

          {isSubMenuOpen && (
            <div>
              <MenuBarResponsiveSubMenu
                hideSideMenu={hideSideMenu}
                selectedMenu={menus[selectedMenuIndex]}
                onBackBtnClick={() => setIsSubMenuOpen(false)}
              />
            </div>
          )}
        </div>
      </motion.div>
    </motion.div>
  );
}

export default MenuBarResponsive;
