import * as Yup from "yup";

export const contactSchema = Yup.object({
  name: Yup.string()
    .trim()
    .required("Full Name is a required field")
    .max(30, "Full Name must be at most 30 characters")
    .min(3, "Full Name must be at least 3 characters"),
  email: Yup.string().email().required(),
  title: Yup.string()
    .trim()
    .required("Title is a required field")
    .max(250, "Title must be at most 250 characters")
    .min(10, "Title must be at least 10 charcters"),
  comment: Yup.string()
    .trim()
    .required("Message is a required field")
    .max(1000, "Message must be at most 1000 characters")
    .min(20, "Message must be at least 20 characters"),
});
